import { t } from 'shared/i18n';
import substituteString from 'shared/utils/formatting/substituteString';
/**
 * @module squarespace-follow-button
 */
YUI.add('squarespace-follow-button', function (Y) {
  /**
   * @class       FollowButton
   * @extends     Widget
   * @namespace   Squarespace.Widgets
   * @constructor
   */
  Y.namespace('Squarespace.Widgets').FollowButton = Y.Base.create('followButton', Y.Widget, [], {
    renderUI: function () {
      this.get('contentBox').setContent(
      substituteString(
      '<a href="https://twitter.com/{username}" class="twitter-follow-button" data-show-count="false">' + t("Follow @{username}") +



      '</a>', {
        username: this.get('username') }));



    },
    syncUI: function () {
      var logError = function () {
        if (__DEV__) {
          console.warn('Twitter Follow Button JS failed to load');
        }
      };
      var showWidget = function () {
        this.show();
      };

      Y.Get.script('//platform.twitter.com/widgets.js', {
        onSuccess: showWidget,
        onFailure: logError,
        onTimeout: logError,
        timeout: 10000,
        context: this,
        win: Y.config.win });

    } },
  {
    ATTRS: {
      /**
       * @attribute username
       * @type      String
       */
      username: {
        value: '' },


      /**
       * @attribute visible
       * @type      Boolean
       */
      visible: {
        value: false } },


    CSS_PREFIX: 'sqs-follow-button' });

}, '1.0', {
  requires: [
  'base',
  'widget'] });