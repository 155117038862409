import "shared/i18n/configurations/commerce-currency.js";import isInteger from 'lodash/isInteger';
import Money from '@sqs/i18n-ui/lib/money';
import { getFractionDigits, CurrencyTemplates, DEFAULT_CURRENCY_TEMPLATE } from '@sqs/commerce-currency';
import round from 'lodash/round';

const DEFAULT_OPTS = {
  // Whether or not to show the cents.
  // For example, if true: what would be $50.00 comes out as $50.
  trimZeroFractions: false,
  // Whether or not to include a thousands place separator string.
  group: true };


// matches groups of at least 3 digits
const THOUSAND_REGEX = /^(\d+)(\d{3})/;

// Sets the decimal separator character in the given number string
function setDecimalSeparator(numString, decimalSeparator = '.') {
  return numString.replace('\.', decimalSeparator);
}

// adds a separator between groups of 3 digits
function addThousandsSep(numString, thousandsSep = ',') {
  while (numString.match(THOUSAND_REGEX)) {
    numString = numString.replace(THOUSAND_REGEX, `$1${thousandsSep}$2`);
  }
  return numString;
}

// merges this template's defaults
// with the standard defaults
function createDefaults(templates) {
  return {
    ...DEFAULT_CURRENCY_TEMPLATE,
    ...(templates.DEFAULT || {}) };

}

// merges the template for the given currency code with
// the template's default values
function mergeWithDefaults(templates, currencyCode) {
  return {
    ...templates.DEFAULT,
    ...(templates[currencyCode] || {}) };

}

// determines how many decimal places are required for the given
// template and options
function determineDecimalPlaces(value, currencyCode, opts) {
  return opts.trimZeroFractions && isInteger(value) ? 0 :
  getFractionDigits(currencyCode);
}

/**
 * Money Formatter
 * Instantiated with a locale, and provides localized format functions for
 * Money objects.
 *
 * Except when overridden with custom templates, this formatter is compatible
 * with JS native Intl.NumberFormat.
 *
 * NOTE TO DEVELOPER: We plan to replace this implementation with native
 * Intl.NumberFormat. Do not add any functionality or formatting that cannot
 * be implemented with Intl.NumberFormat.
 */
export default class MoneyFormatter {

  /**
   * Enable global debugging.
   */
  static enableDebugging() {
    MoneyFormatter.debug = true;
  }

  /**
   * Enable global debugging.
   */
  static disableDebugging() {
    MoneyFormatter.debug = false;
  }

  constructor(locale, debug = false) {
    this.templates = CurrencyTemplates[locale] || {};
    this.templates.DEFAULT = createDefaults(this.templates);

    if (!this.templates && __DEV__) {
      console.error('Locale ' + locale + ' not yet implemented.');
    }

    if (debug) {
      MoneyFormatter.enableDebugging();
    }

    /**
     * @todo multi-currency - figure out the best way to manage these
     * formatters considering that a user exporting just the formatted
     * function will lose the current this context.
     */
    this.locale = locale;
  }

  /**
   * Format a Money object as a display string.
   *
   * @todo ensure that people consuming only format get the right
   * context argument.
   *
   * @param  {Money} money money object
   * @param  {Object} opts formatting options
   */
  format(money, opts = {}) {
    if (!(money instanceof Money)) {
      throw new Error('Format expects a Money instance.');
    }

    opts = { ...DEFAULT_OPTS, ...opts };

    const debugSymbol = MoneyFormatter.debug ? '💰' : '';
    const value = money.toFloat();
    const positiveValue = Math.abs(value);
    const sign = value < 0 ? '-' : '';

    const currencyCode = money.currencyCode;
    const template = mergeWithDefaults(this.templates, currencyCode);

    const decimalPlaces = determineDecimalPlaces(value, currencyCode, opts);
    let stringValue = String(round(positiveValue, decimalPlaces).toFixed(decimalPlaces));

    stringValue = setDecimalSeparator(stringValue, template.decimalSeparator);

    if (opts.group) {
      stringValue = addThousandsSep(stringValue, template.thousandsSeparator);
    }

    const symbol = template.symbol;

    const formatter = template.formatter;
    const formattedValue = formatter({
      currencyCode,
      symbol,
      value: stringValue,
      sign });


    return debugSymbol + formattedValue + debugSymbol;
  }}