const DEBUG = false;

const MAX_SAMPLE_SIZE = 22;
const DOWNTIME_FACTOR = 2;

/**
 * Self throttle is a throttling function that throttles itself based on a downtime factor.
 * The self throttle measures how long it takes for the work function to execute, and uses the sampled
 * average of the last invocations to decide how long to wait before the function should be executed again.
 *
 * SAMPLED_AVERAGE_EXECUTION_TIME x DOWNTIME_FACTOR = how often the function will run at a maximum.
 *
 * @class SelfThrottle
 */
class SelfThrottle {
  constructor(workFn) {
    this.workFn = workFn;

    this.pending = false;
    this.samples = [];
    this.lastCompleteExecution = null;
    this.sampledAverageExecution = null;
    this.pendingDowntime = null;
  }

  invoke() {
    const {
      lastCompleteExecution,
      sampledAverageExecution
    } = this;

    if (this.pending) {
      if (DEBUG) {
        console.log('pending!');
      }

      return;
    }

    if (lastCompleteExecution !== null && sampledAverageExecution !== null) {
      const timeSinceLastCompleteExecution = Date.now() - lastCompleteExecution;
      const expectedDowntime = sampledAverageExecution * DOWNTIME_FACTOR;

      if (timeSinceLastCompleteExecution < expectedDowntime) {
        const waitTime = expectedDowntime - timeSinceLastCompleteExecution;

        if (this.pendingDowntime) {
          if (DEBUG) {
            console.log('no downtime, and timer is set just going to wait');
          }

          return;
        }

        if (DEBUG) {
          console.log('no downtime, not allowing. need to wait another ', waitTime);
        }

        this.pendingDowntime = setTimeout(() => {
          this.invoke();
          this.pendingDowntime = null;
        }, waitTime);

        return;
      }
    }

    this.doWork();
  }

  doWork() {
    const startTime = Date.now();
    this.pending = true;

    try {
      this.workFn();
    } catch (e) {
      console.error(e);
    }

    this.lastCompleteExecution = Date.now();
    const executionTime = this.lastCompleteExecution - startTime;
    this.pending = false;

    let samples = this.samples;
    samples.unshift(executionTime);

    if (samples.length > MAX_SAMPLE_SIZE) {
      this.samples = samples = samples.slice(0, MAX_SAMPLE_SIZE);
    }

    this.sampledAverageExecution = samples.reduce((total, sample) => total + sample, 0) / samples.length;

    if (DEBUG) {
      console.log('executionTime', executionTime, 'sampledAverageExecution', this.sampledAverageExecution);
    }
  }
}

/**
 * A helper function to wrap a working function and get a throttled method back.
 * @method selfThrottle
 * @param  {Function}     workFn The work to be done
 * @return {Function}            The work function guarded by the self throttle.
 */
function selfThrottle(workFn) {
  const instance = new SelfThrottle(workFn);
  return () => instance.invoke();
}

export default selfThrottle;