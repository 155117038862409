/**
 * This is required in the case of loading `@sqs/commerce-afterpay` before `@sqs/core-components` or `@sqs/rosetta-*`
 * Both packages are transitive/direct dependencies of commerce-afterpay
 */
import './core-components';

import { setI18nInstance } from '@sqs/commerce-afterpay/cjs/i18n';
import i18nInstance from 'shared/i18n';

setI18nInstance(i18nInstance);
