import alt from 'shared/dispatchers/alt';

/**
 * Allows React components to request the Damask Frame to refresh (or perhaps do other things)
 */
class FrameActions {
  constructor() {
    this.generateActions(
      'refresh',
      'setUrl',
      'setEditingCustomCss',
      'ifLoaded',
      'removeLoadHandler',
      'disableEditing',
      'enableEditing',
      'exitSiteStylesEditMode',
      'disableSevenZeroFullScreenPreview',
      'toggleFullScreenPreviewMode',
      'setIsMobileDrawerExpanded',
      'enableSevenZeroFullScreenPreview',
      'frameUnload',
      'disableLinks',
      'enableLinks',
      'enterItemEditingMode',
      'enterItemInlineEditingMode',
    );
  }

  addLoadHandler(key, callback) {
    return { key, callback };
  }
}

export default alt.createActions(FrameActions);
