import { t } from 'shared/i18n';
import createEnum from 'shared/utils/enum';

// More defaults are defined in visitorDataTransformations.js
export const DEFAULT_CTA_TEXT = t("Accept");



export const DEFAULT_TEXT = t("By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.");







export const VARIANTS = createEnum('BAR', 'POPUP');
export const BAR_POSITIONS = createEnum('TOP', 'BOTTOM');
export const POPUP_POSITIONS = createEnum(
'TOP_LEFT',
'TOP_RIGHT',
'BOTTOM_LEFT',
'BOTTOM_RIGHT');

export const THEMES = createEnum('LIGHT', 'DARK');
export const CTA_VARIANTS = createEnum('TEXT', 'BUTTON', 'ICON');

export const DEFAULT_OPT_OUT_TEXT = t("Decline");




export const ACCEPT_TYPES = createEnum('OPT_IN', 'OPT_IN_AND_OUT');

export const STRINGS = {
  [VARIANTS.BAR]: t("Bar"),



  [VARIANTS.POPUP]: t("Pop-up"),
  [BAR_POSITIONS.TOP]: t("Top"),
  [BAR_POSITIONS.BOTTOM]: t("Bottom"),
  [POPUP_POSITIONS.TOP_LEFT]: t("Top Left"),
  [POPUP_POSITIONS.TOP_RIGHT]: t("Top Right"),
  [POPUP_POSITIONS.BOTTOM_LEFT]: t("Bottom Left"),
  [POPUP_POSITIONS.BOTTOM_RIGHT]: t("Bottom Right"),
  [THEMES.LIGHT]: t("Light"),
  [THEMES.DARK]: t("Dark"),
  [CTA_VARIANTS.TEXT]: t("Text"),
  [CTA_VARIANTS.BUTTON]: t("Button"),
  [CTA_VARIANTS.ICON]: t("Icon"),
  [ACCEPT_TYPES.OPT_IN]: t("Opt-in"),
  [ACCEPT_TYPES.OPT_IN_AND_OUT]: t("Opt-in & Out") };