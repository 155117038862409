"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setI18nInstance = exports.getI18nInstance = void 0;

var _libraryHelpers = require("@sqs/i18n-ui/lib/library-helpers");

var PACKAGE_NAME = 'commerce.utils';

function translationsLoader(translationLocale) {
  // Make a dynamic request via a `require()` statement to load locale-specific translations
  // Should point to the location of your translated YAML files
  return require("../../sqs-i18n-translations/strings/".concat(PACKAGE_NAME, ".").concat(translationLocale, ".yaml"));
}

var _setupLibrary = (0, _libraryHelpers.setupLibrary)(PACKAGE_NAME, translationsLoader),
    getI18nInstance = _setupLibrary.getI18nInstance,
    setI18nInstance = _setupLibrary.setI18nInstance;

exports.setI18nInstance = setI18nInstance;
exports.getI18nInstance = getI18nInstance;