/**
* Holds the set of beautiful, Squarespace default animations,
* available as node plugins. Just plug one in and call show/hide
*
* @module squarespace-animations
*/
YUI.add('squarespace-animations', function(Y) {
  Y.namespace('Squarespace.Animations');

  var STATUS_CLASS_PREFIX = 'display-status-',
    PLUGGED_CLASS_SUFFIX = '-plugged',

    SHOWN_EVENT = 'shown',
    HIDDEN_EVENT = 'hidden';

  // ------------------------------------------------------------------------------------------------------------
  // Expandable
  // ------------------------------------------------------------------------------------------------------------
  /**
   * @class Expandable
   * @namespace Squarespace.Animations
   */
  Y.Squarespace.Animations.Expandable = Y.Base.create('expandable', Y.Plugin.Base, [], {
    initializer: function(config) {
      this.doBefore('show', this.open);
      this.doBefore('hide', this.close);
      this._running = false;
    },

    /**
     * @method close
     * @param hard
     */
    close: function(hard) {
      var host = this.get('host'),
        easing = this.get('easing'),
        duration = this.get('duration'),
        anim,
        wrapper;

      // In the special case where the node to be animated
      // is not in the DOM, do not animate, just perform
      // a hard close.
      hard = (host.inDoc() ? hard : true);

      host.fire('close');
      if (hard) {
        this._hardClose();
      } else if (!this._running) {
        this._running = true;
        host.wrap('<div class="expandable-plugin-wrapper"></div>');

        wrapper = host.ancestor('.expandable-plugin-wrapper');
        wrapper.setStyles({
          'overflow': 'hidden',
          'height': host.get('offsetHeight')
        });

        this._closeWrapper = wrapper;

        anim = new Y.Anim({
          node: wrapper,
          to: {
            height: 0
          },
          easing: easing,
          duration: duration
        });

        anim.on('end', function() {

          if (host.inDoc()) {
            host.setStyles({
              'height': 0,
              'overflow': 'hidden',
              'display': 'none'
            });
            host.unwrap();
            host.addClass('expandable-hidden');
          }
          this._running = false;
          host.fire('closed');
        }, this);

        this._closeAnim = anim;
        anim.run();

      }

      return new Y.Do.Prevent('Expandable prevented default hide.');
    },

    /**
     * @method  _hardClose
     * @private
     */
    _hardClose: function() {
      var host = this.get('host');

      host.setStyles({
        'height': 0,
        'overflow': 'hidden',
        'display': 'none'
      });

      host.addClass('expandable-hidden');

      /**
       * @event closed
       */
      host.fire('closed');
    },


    /**
     * @method  _hardOpen
     * @private
     */
    _hardOpen: function() {
      var host = this.get('host');

      host.setStyles({
        'height': null,
        'display': null
      });

      host.removeClass('expandable-hidden');

      /**
       * @event opened
       */
      host.fire('opened');
    },

    /**
     * Open
     * @method open
     * @param  hard
     */
    open: function(hard) {
      var host = this.get('host'),
        easing = this.get('easing'),
        duration = this.get('duration'),
        anim,
        hostHeight,
        proxy,
        wrapper;


      // In the special case where the node to be animated
      // is not in the DOM, do not animate, just perform
      // a hard open.
      hard = (host.inDoc() ? hard : true);

      host.fire('open');
      if (hard) {
        this._hardOpen();
      } else if (!this._running) {
        this._running = true;
        if (host.getComputedStyle('display') === 'none') {
          host.setStyle('display', null);
        }

        proxy = host.cloneNode(true);
        proxy.setStyles({
          'height': 'auto',
          'visibility': 'hidden',
          'display': null,
          'position': 'absolute',
          'width': host.get('offsetWidth')
        });
        host.insert(proxy, 'before');
        hostHeight = proxy.get('offsetHeight') + parseInt(proxy.getComputedStyle('marginTop'), 10) +
                                                 parseInt(proxy.getComputedStyle('marginBottom'), 10);
        proxy.remove(true);

        host.wrap('<div class="expandable-plugin-wrapper"></div>');

        wrapper = host.ancestor('.expandable-plugin-wrapper');
        wrapper.setStyles({
          'overflow': 'hidden',
          'height': 0
        });

        host.setStyles({
          'height': null,
          'overflow': null
        });

        this._openWrapper = wrapper;

        anim = new Y.Anim({
          node: wrapper,
          to: {
            height: hostHeight
          },
          easing: easing,
          duration: duration
        });

        anim.on('end', function() {
          var thisHost = this.get('host');
          if (thisHost) {
            this._running = false;
            thisHost.unwrap();
            thisHost.removeClass('expandable-hidden');
            thisHost.fire('opened');
          }
        }, this);

        this._openAnim = anim;
        anim.run();
      }

      return new Y.Do.Prevent('Expandable prevented default show.');
    },

    destructor: function() {

      if (this._closeWrapper) {
        this._closeWrapper.remove(true);
      }

      if (this._openWrapper) {
        this._openWrapper.remove(true);
      }

      if (this._openAnim) {
        this._openAnim.stop().destroy();
      }

      if (this._closeAnim) {
        this._closeAnim.stop().destroy();
      }
    }
  },
  {
    NS: 'expandablePlugin',
    ATTRS: {
      /**
       * Duration
       * @attribute duration
       * @type {Number}
       */
      duration: {
        value: 0.3,
        validator: Y.Lang.isNumber
      },

      /**
       * @attribute easing
       * @type {Easing}
       */
      easing: {
        value: Y.Easing.easeOutStrong
      }
    }
  });


  // ------------------------------------------------------------------------------------------------------------
  // Fadeable
  // ------------------------------------------------------------------------------------------------------------
  /**
   * @class Fadeable
   * @namespace Squarespace.Animations
   */
  Y.Squarespace.Animations.Fadeable = Y.Base.create('fadeable', Y.Plugin.Base, [], {

    /**
     * @event shown
     */
    /**
     * @event hidden
     */
    initializer: function(config) {
      this.beforeHostMethod('show', this.show);
      this.beforeHostMethod('hide', this.hide);
      this.get('host').addClass(this.name + PLUGGED_CLASS_SUFFIX);
      this._onlyUseOpacity = (config.onlyUseOpacity === undefined) ? false : config.onlyUseOpacity;
      this._running = false;
    },

    destructor: function() {
      if (this._anim) {
        this._anim.stop().destroy();
      }
      this.get('host').removeClass(this.name + PLUGGED_CLASS_SUFFIX);
    },

    /**
     * Show
     * @method show
     * @param  hard
     * @return {Do.Prevent}
     */
    show: function(hard) {
      return this._changeVisibility(true, hard);
    },

    /**
     * Hide
     * @method hide
     * @param  {} hard
     * @return {Do.Prevent}
     */
    hide: function(hard) {
      return this._changeVisibility(false, hard);
    },

    /**
     *
     * @method  _changeVisibility
     * @param   {Boolean} showing
     * @param   {Boolean} hard
     * @private
     * @return  {Do.Prevent}
     */
    _changeVisibility: function(showing, hard) {

      var host = this.get('host');

      hard = (host.inDoc() ? hard : true);

      if (showing) {
        host.removeClass(STATUS_CLASS_PREFIX + 'hidden');
      }

      if (hard) {

        this._hardChangeVisibility(showing);

      } else {

        if (this._anim) {
          this._anim.stop().destroy();
          this._anim = null;
        }

        // remove conflicting inline style
        if (showing && host.getComputedStyle('display') === 'none') {
          host.setStyle('display', null);
        }

        this._anim = new Y.Anim({
          node: host,
          to: {
            opacity: (showing ? this.get('opacity') : 0)
          },
          easing: this.get('easing'),
          duration: this.get('duration')
        });

        this._anim.onceAfter('end', function() {
          this._hardChangeVisibility(showing);

          this._running = false;
          if (!showing && !this._onlyUseOpacity && host.getDOMNode()) {
            host.setStyle('display', 'none');
          }
          host.fire(showing ? 'shown' : 'hidden');
          delete this._anim;

        }, this);

        this._anim.run();
      }

      return new Y.Do.Prevent('Fadeable prevented default ' + (showing ? 'show' : 'hide') + ' and used its own.');

    },

    _hardChangeVisibility: function(showing) {
      var host = this.get('host');

      if (!showing) {
        host.addClass(STATUS_CLASS_PREFIX + 'hidden');
      }

      host.setStyles({
        'opacity': (showing ? this.get('opacity') : 0)
      });

      this._anim = null;

      host.fire(showing ? SHOWN_EVENT : HIDDEN_EVENT);
    }

  },
  {
    NS: 'fadeablePlugin',
    ATTRS: {
      duration: {
        value: 0.3,
        validator: Y.Lang.isNumber
      },

      easing: {
        value: Y.Easing.easeOutStrong
      },

      opacity: {
        value: 1
      }
    }
  });


  // ------------------------------------------------------------------------------------------------------------
  // Scalable
  // ------------------------------------------------------------------------------------------------------------
  /**
   * @class Scalable
   * @namespace Squarespace.Animations
   */
  Y.Squarespace.Animations.Scalable = Y.Base.create('scalable', Y.Plugin.Base, [], {
    /**
     * @event show
     */
    /**
     * @event hide
     */
    /**
     * @event shown
     */
    /**
     * @event hidden
     */

    initializer: function(config) {
      this.doBefore('show', this.show);
      this.doBefore('hide', this.hide);
      this._running = false;
    },

    /**
     * Show
     * @method show
     * @param  {Boolean} hard
     * @return {Do.Prevent}
     */
    show: function(hard) {
      return this._changeVisibility(true, hard);
    },

    /**
     * Hide
     * @method hide
     * @param  {Boolean} hard
     * @return {Do.Prevent}
     */
    hide: function(hard) {
      return this._changeVisibility(false, hard);
    },

    /**
     *
     * @method  _changeVisibility
     * @param   {Boolean} showing
     * @param   {Boolean} hard
     * @private
     * @return  {Do.Prevent}
     */
    _changeVisibility: function(showing, hard) {
      var host = this.get('host'),
        duration = this.get('duration');


      this.fire(showing ? 'show' : 'hide');
      host.fire(showing ? 'show' : 'hide');

      if (showing) {
        host.removeClass('sqs-scalable-hidden');
      }

      hard = (host.inDoc() ? hard : true);

      if (hard) {

        this._hardChangeVisibility(showing);

        if (!showing) {
          host.addClass('sqs-scalable-hidden');
        }

      } else if (!this._running) {

        this._running = true;

        var eventFired = false;

        var eventFn = Y.bind(function() {
          if (eventFired) { return; }

          eventFired = true;

          if (!host.getDOMNode()) { return; }

          this._running = false;

          if (!showing) {
            host.addClass('sqs-scalable-hidden');
          }

          this.fire(showing ? SHOWN_EVENT : HIDDEN_EVENT);
          host.fire(showing ? SHOWN_EVENT : HIDDEN_EVENT);
        }, this);

        host.setStyles({
          transition: [
            'opacity ' + duration + 's ease-out',
            'visibility ' + duration + 's ease-out',
            'transform ' + duration + 's ease-out'
          ].join(', ')
        });

        Y.config.win.setTimeout(function() {
          // Since runs in timeout, may happen after host is destroyed!
          if (!host) {
            return;
          }

          if (showing) {
            host.setStyles({
              opacity: 1,
              visibility: 'visible',
              transform: 'scale(1)'
            });
          } else {
            host.setStyles({
              opacity: 0,
              visibility: 'hidden',
              transform: 'scale(0.94)'
            });
          }
        }, 1);

        Y.config.win.setTimeout(function() {
          eventFn();
        }, 1000 * duration);
      }

      return new Y.Do.Prevent('Scalable prevented default ' + (showing ? 'show' : 'hide') + ' and used its own.');
    },


    /**
     *
     * @method  _hardChangeVisibility
     * @param   {Boolean} showing
     * @private
     */
    _hardChangeVisibility: function(showing) {
      var host = this.get('host');
      var transformOkay = !!(this.get('host').getDOMNode().style.transitionDuration ||
        Y.DOM.CUSTOM_STYLES.transitionDuration);

      if (transformOkay) {
        host.setStyles({
          'visibility': (showing ? 'visible' : 'hidden'),
          'opacity': (showing ? 1 : 0),
          'transform': 'scale(' + (showing ? '1' : '0.94') + ')'
        });
      } else {
        host.setStyles({
          'visibility': (showing ? 'visible' : 'hidden'),
          'opacity': (showing ? 1 : 0)
        });
      }

      host.fire(showing ? SHOWN_EVENT : HIDDEN_EVENT);
    }

  },
  {
    NS: 'scalablePlugin',
    ATTRS: {
      /*
      @attribute duration
      @default 0.3
      @type Number
       */
      duration: {
        value: 0.3,
        validator: Y.Lang.isNumber
      },
      /**
       * @attribute easting
       * @type {Easing}
       */
      easing: {
        value: Y.Easing.easeOutStrong
      },

      /**
       * @attribute opacity
       * @type {Number}
       * @default 1
       */
      opacity: {
        value: 1
      }
    }
  });
}, '1.0', { requires: [
  'anim',
  'anim-easing',
  'base',
  'plugin',
  'transition'
] });
