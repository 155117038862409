/**
 * returns a number at the position between the start and end values provided
 * @param {number} start beginning of the possible range
 * @param {number} end end of the possible range
 * @param {number} amount position as a number representing percent distance of
 *  the range between start and end to find the lerped number
 * @returns {number} lerped number
 */

const lerp = (start, end, amount) => start * (1 - amount) + end * amount;

export default lerp;
