import FrameActions from 'apps/App/shared/actions/FrameActions';
import { LayoutEngineClassnames } from '@sqs/websites-constants';

/**
 * Esc Key Manager
 * @module squarespace-escmanager
 */
YUI.add('squarespace-escmanager', function(Y) {

  /**
  An array of class selectors which represent ui control wrappers.
  @property STANDARD_CONTROL_CLASSES
  @for Squarespace
  @type [String]
  */
  Y.Squarespace.STANDARD_CONTROL_CLASSES = [
    '.standard-dialog-wrapper',
    '.yui3-panel-type-tooltip',
    '.sqsp-tooltip',
    '.tooltip',
    '.inline-field-title',
    '.workflow-flyout',
    '.page-map',
    '.map-widget',
    '.dialog-element',
    '.error-message-wrapper',
    '.squarespace-block',
    '.sqs-block',
    '.buttons a',
    '.sqs-shopping-cart',
    '.panel-menu-item',
    '.flyout-variant-edit',
    '.image-focal-point',
    'a.return-button',
    'a#deactivated-account-link',
    '.site-chooser-glyph',
    '.lightbox-inner',
    '.sqs-configuration-menu .site-title-text',
    '.sqs-configuration-menu .user-id',
    '.sqs-add-to-cart-button',
    '.additional-fields',
    '.rte',
    '.sqs-scroll-ac-item',
    '.sqs-layout-insert-block-menu',
    '.sqs-widgets-confirmation',
    '.sqs-password-prompt',
    '.tag-bubble',
    '.sqs-fullscreen-overlay-imagepicker',
    '.u-ActionButtons-container',
    '.ReactModal__Overlay',
    '.' + LayoutEngineClassnames.ESCMANAGER_IGNORE_CLASSNAME,
    '.CCLinkEditorModal', // This is required for CC LinkEditor as it manages it's own ESC
    '.CCPageDropDown', // // This is required for CC LinkEditor as it manages it's own ESC
    '.sqs-dialog-field:not(.sqs-dialog-layout-wrapper)',
  ];

  Y.namespace('SQS.DDWrappers').STANDARD_CONTROL_CLASSES = Y.Squarespace.STANDARD_CONTROL_CLASSES;

  /**
    A static class that takes care of closing open popups and flyouts.
    Add the object with a close() or cancel() as a target and Esc manager will
    close it after the escape key is pressed or there is a click on the body.
    It also does the login/logout on escape logic.
    @class EscManager
    @static
    @namespace Squarespace
  */
  Y.Squarespace.EscManager = {

    closeTargets: [ ],
    ignores: 0,
    attached: [],

    /**
     * @method attach
     * @param target selector or node
     */
    attach: function(target) {
      target = target || Y.one(Y.config.win);

      var targetId = target.generateID();
      if (this.attached.indexOf(targetId) !== -1) {
        // console.log('[ESCMGR] NOT BINDING ... ', targetId, target._node.document);
        return;
      }

      // console.log('[ESCMGR] binding ... ', targetId, target._node.document);

      // first global init
      var EscManager = Y.Squarespace.EscManager;
      if (Y.Squarespace.Utils.isInDamaskFrame()) {
        EscManager = Y.Squarespace.Damask.ContextGlobals.fromTop('Y.Squarespace.EscManager');
      }

      // @TODO double check delegates here
      var targetNode = target;
      targetNode.on('keyup', EscManager.onKeyUp, EscManager);
      targetNode.on('click', EscManager.onBodyClick, EscManager);
      targetNode.on('mousedown', EscManager._onBodyDown, EscManager);
      targetNode.on('mouseup', EscManager._onBodyUp, EscManager);

      this.attached.push(targetId);
      this._disabled = false;
    },

    destroy: function () {
      this.detach();
      this._bodyDownTarget = null;
      this._bodyUpTarget = null;
    },

    detach: function (target) {
      target = target || Y.one(Y.config.win);

      target.detachAll();
    },

    onShiftEsc: function() {
      Y.Squarespace.Utils.logout();
    },

    addIgnore: function() {
      this.ignores++;
    },

    /**
      A way to temporary disable the escManager

      @method disable
    */
    disable: function() {
      this._disabled = true;
    },

    /**
      A way to enable the escManager

      @method disable
    */
    enable: function() {
      this._disabled = false;
    },


    onEsc: function() {

      //how many should we ignore
      if (this.ignores) {
        this.ignores--;
        return;
      }

      if (Y.config.win.Static && Static.IN_BACKEND) {

        if (Y.one('html').hasClass('sqs-guide-open')) {
          window.CONFIG_PANEL.closeGuide();
        } else {
          FrameActions.toggleFullScreenPreviewMode();
        }

      } else if (window.SQUARESPACE_LOGIN && window.SQUARESPACE_LOGIN.getAccount()) {

        this._redirectToConfig();

      } else {

        var useEscapeKeyToLogin = Y.Object.getValue(Y.config.win,
          'Static.SQUARESPACE_CONTEXT.websiteSettings.useEscapeKeyToLogin'.split('.'));

        if (!useEscapeKeyToLogin) {
          return;
        }

        if (window.SQUARESPACE_LOGIN && window.SQUARESPACE_LOGIN.params) {
          window.SQUARESPACE_LOGIN.params.disableCreateAccount = true;
        }

        if (Y.Squarespace.Signup &&
          Y.Squarespace.Signup.signupDialog &&
          Y.Squarespace.Signup.signupDialog.isVisible()) {

          Y.Squarespace.Signup.signupDialog.cancel();

        } else {

          this._redirectToConfig();

        }

      }
    },

    addTarget: function(o) {
      this.closeTargets.push(o);
    },

    removeTarget: function(o) {
      var targetIdx = this.closeTargets.indexOf(o);
      if (targetIdx !== -1) { this.closeTargets.splice(targetIdx, 1); }
    },

    onKeyUp: function(e) {
      if (e.keyCode === 27 && !this._disabled) {

        e.halt();

        if (this.closeTargets.length > 0) {

          var t = this.closeTargets.pop();

          this._closeTarget(t, e);

        } else if (e.shiftKey) {

          Y.later(10, this, this.onShiftEsc, e);

        } else {

          Y.later(10, this, this.onEsc, e);

        }

      }
    },

    _redirectToConfig: function() {
      var location = Y.config.win.location;
      var newLocation = 'https://' +
        Static.SQUARESPACE_CONTEXT.website.identifier +
        '.' +
        Static.SQUARESPACE_CONTEXT.appDomain +
        '/config/?frameUrl=' +
        location.pathname +
        location.search;
      Y.config.win.location = newLocation;
    },

    _closeTarget: function (target, originalEvent) {
      if (Y.Lang.isFunction(target.close)) {
        target.close(originalEvent, function(shouldRemoveTarget) {
          if (shouldRemoveTarget === false) {
            this.closeTargets.push(target);
          }
        }.bind(this));
      } else if (Y.Lang.isFunction(target.cancel)) {
        target.cancel(originalEvent);
      } else if (Y.Lang.isFunction(target.hide)) {
        target.hide(originalEvent);
      } else if (__DEV__) {
        console.error('Escape target had no valid closing or hiding methods.', target);
      }
    },


    /**
     * Chrome 33 follows the Level 3 DOM click spec, which means it fires a
     * click on common ancestors of the mousedown/mouseup combo. So a body
     * click listener is super problematic. It'll be fixed with damask, so in
     * the mean time, just keeps track of mousedown/mouseup targets and compare
     * them.
     *
     * This will of course break down if we call stopPropagation on the mousedown,
     * but not the mouseup.
     *
     * @method _onBodyDown
     * @param e {EventFacade}
     * @private
     */
    _onBodyDown: function (e) {
      this._bodyDownTarget = e.target;
    },


    /**
     * See onBodyDown comment, but this keeps track of the mouseup target
     *
     * @method _onBodyUp
     * @param e {EventFacade}
     * @private
     */
    _onBodyUp: function (e) {
      this._bodyUpTarget = e.target;
    },


    /**
     * Determines if the previous mousedown's target was the same as the
     * previous mouseup's target. Also deletes the previous entries for those
     * values
     *
     * @method _didMouseDownMouseUpMatch
     * @returns {Boolean}
     * @private
     */
    _didMouseDownMouseUpMatch: function () {

      var didMatch = (this._bodyDownTarget === this._bodyUpTarget);
      this._bodyDownTarget = null;
      this._bodyUpTarget = null;

      return didMatch;

    },

    onBodyClick: function(e) {
      if (this.closeTargets && this.closeTargets.length > 0) {

        var t = this.closeTargets[this.closeTargets.length - 1];

        // If the last mousedown target wasn't equal to the last mouseup target,
        // don't close the dialog
        if (!this._didMouseDownMouseUpMatch()) {
          return;
        }

        if (t.ignoreBodyClicks) {
          return;
        }

        var cx = e.clientX;
        var cy = e.clientY;
        var vw = e.target.get('winWidth');
        var vh = e.target.get('winHeight');

        if (cx < 0 || cx > vw || cy < 0 || cy > vh) {
          // click was offscreen, so ignore it
          // this fixes a bug in Firefox where dragging a dialog offscreen
          // would cause it to close
          return;
        }

        if (t.activeFlyout) {

          if (!e.target.ancestor(t.activeFlyout.constraintClasses, true)) {
            t.activeFlyout.field.closeFlyout();
            t.activeFlyout = null;
            Y.fire('target-closed', {
              target: t
            });

          }

        } else if (!e.target.ancestor(Y.Squarespace.STANDARD_CONTROL_CLASSES.join(', '), true)) {
          // if we DIDN'T click inside a valid control
          Y.fire('target-closed', {
            target: t
          });
          e.halt();

          this.closeTargets.pop();

          this._closeTarget(t, e);

        }

      }

    }
  };

  // ensure a global singleton

  if (typeof window !== 'undefined') {
    if (window.ESC_MANAGER) {

      Y.Squarespace.EscManager = window.ESC_MANAGER;

    } else {

      window.ESC_MANAGER = Y.Squarespace.EscManager;

    }
  }

}, '1.0', { requires: [
  'event',
  'node',
  'squarespace-damask-context-globals',
  'squarespace-util'
] });
