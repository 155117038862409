import { t } from 'shared/i18n';
/**
 * Upgraded alert dialog widget.
 *
 * @example
 *     // this dialog will be centered around the mouse
 *     var alert = new Y.Squarespace.Widgets.Alert({
 *       'strings.title': 'Hello',
 *       'strings.message': 'World'
 *     });
 *
 *     alert.on('confirm', function() {
 *       console.log('You did it!');
 *     });
 *
 *     // confirmation centered on screen
 *     alert = new Y.Squarespace.Widgets.Alert({
 *       position: Y.Squarespace.Widgets.Confirmation.ANCHOR.CENTER,
 *       'strings.title': 'Centered',
 *       'strings.message': "I'm in the center!"
 *     });
 *
 * @module squarespace-widgets-alert
 */
YUI.add('squarespace-widgets-alert', function (Y) {

  Y.namespace('Squarespace.Widgets');

  /**
   * An alert-like version of the confirmation dialog.
   *
   * @class Alert
   * @constructor
   * @namespace Squarespace.Widgets
   * @extends Squarespace.Widgets.Confirmation
   */
  var Alert =
  Y.Squarespace.Widgets.Alert =
  Y.Base.create('alert', Y.Squarespace.Widgets.Confirmation, [], {},
  {

    CSS_PREFIX: 'sqs-widgets-confirmation',

    TYPE: Y.Squarespace.Widgets.Confirmation.TYPE,

    ANCHOR: Y.Squarespace.Widgets.Confirmation.ANCHOR,

    TEMPLATE: Y.Squarespace.Widgets.Confirmation.TEMPLATE,

    show: function (options) {
      return new Alert(options);
    },

    ATTRS: {
      // Override
      className: {
        value: 'alert' },

      // Override
      style: {
        value: Y.Squarespace.Widgets.Confirmation.TYPE.CONFIRM_ONLY },

      // Override
      'strings.confirm': {
        value: t("Okay") } } });






}, '1.0', {
  requires: [
  'base',
  'squarespace-widgets-confirmation'] });