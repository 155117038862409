/**
 * Holds a set of common attribute validators for classes that extend from
 * Base.
 *
 * @module squarespace-attr-validators
 */
YUI.add('squarespace-attr-validators', function(Y) {

  Y.namespace('Squarespace');

  function logError(expected, val, attrName, template) {
    if (__DEV__) {
      template = (template ||
        '[{className}][{attrName}] Validation Error: Expected {expectedVal}, got "{actualVal}" instead.'
      );

      var error = Y.Lang.sub(template, {
        className: this.constructor.NAME + '',
        attrName: attrName + '',
        expectedVal: expected + '',
        actualVal: val + ''
      });

      console.error(error, { expected: expected, actual: val });
    }
  }

  /**
   * Holds a set of common attribute validators for classes that extend from Base.
   * @class AttrValidators
   * @namespace Squarespace
   * @static
   */
  var AttrValidators =
  Y.Squarespace.AttrValidators = {
    /**
     * Checks if a value is a valid boolean.
     *
     * @method isBoolean
     * @param val {Boolean} The value to check
     */
    isBoolean: function (val, attrName) {

      if (!Y.Lang.isBoolean(val)) {
        logError.call(this, 'boolean', val, attrName);
        return false;
      }

      return true;
    },

    /**
     * Checks if a value is a valid number.
     *
     * @method isNumber
     * @param val {Boolean} The value to check
     */
    isNumber: function (val, attrName) {

      if (!Y.Lang.isNumber(val)) {
        logError.call(this, 'number', val, attrName);
        return false;
      }

      return true;
    },

    /**
     * Checks if a value is a string valid number.
     *
     * @method isString
     * @param val {Boolean} The value to check
     */
    isString: function (val, attrName) {

      if (!Y.Lang.isString(val)) {
        logError.call(this, 'string', val, attrName);
        return false;
      }

      return true;
    },

    /**
     * Checks if a value is a valid non-array object.
     *
     * @method isNonArrayObject
     * @param val {Object} The value to check
     */
    isNonArrayObject: function (val, attrName) {

      if (Y.Lang.isArray(val)) {
        logError.call(this, 'Array', val, attrName,
          '[{attrName}] Validation Error: Expected {expectedVal}, got Array instead.'
        );
        return false;

      } else if (!Y.Lang.isObject(val)) {
        logError.call(this, 'Object', val, attrName);
        return false;
      }

      return true;
    },

    /**
     * Checks if a value is a valid object.
     *
     * @method isObject
     * @param val {Object} The value to check
     */
    isObject: function (val, attrName) {
      if (!Y.Lang.isObject(val)) {
        logError.call(this, 'Object', val, attrName);
        return false;
      }
      return true;
    },

    /**
     * Checks if a value is a valid object.
     *
     * @method isArray
     * @param val {Object} The value to check
     */
    isArray: function (val, attrName) {

      if (!Y.Lang.isArray(val)) {
        logError.call(this, 'Array', val, attrName);
        return false;
      }
      return true;
    },

    /**
     * Checks if a value is a valid function.
     * @method isFunction
     * @param val {Mixed} The value to check
     */
    isFunction: function (val, attrName) {
      if (!Y.Lang.isFunction(val)) {
        logError.call(this, 'Function', val, attrName);
        return false;
      }
      return true;
    },

    /**
     * Checks if a value is a valid node.
     * @method isNode
     * @param val {Object} The value to check
     */
    isNode: function (val, attrName) {
      if (!Y.instanceOf(val, Y.Node)) {
        logError.call(this, 'Node', val, attrName);
        return false;
      }
      return true;
    },

    /**
     * Checks if a value is a valid node list.
     * @method isNodeList
     * @param val {Object} The value to check
     */
    isNodeList: function (val, attrName) {
      if (!Y.instanceOf(val, Y.NodeList)) {
        logError.call(this, 'NodeList', val, attrName);
        return false;
      }

      return true;
    },

    /**
     * The following functions return other validator functions and should be called inline when
     * used as an attr's 'validator' value.
     */

    /**
     * Returns a function that checks if a value is null or passes the given
     * validator function.
     *
     * @method isNullOr
     * @param fn {Object} The other validator function
     */
    isNullOr: function (fn) {
      if (!Y.Lang.isFunction(fn)) {
        console.error('The validator function argument is required.');
        return false;
      }
      return function (val, attrName) {
        if (Y.Lang.isNull(val)) {
          return true;
        }
        return fn(val, attrName);

      };
    },

    /**
     * Returns a function that checks if a value is an instance of the given
     * contructor isInstanceOf.
     *
     * @method isInstanceOf
     * @param ctor {Object} The contructor function
     */
    isInstanceOf: function(ctor) {
      if (!ctor) {
        if (__DEV__) {
          console.error(ctor);
          console.error('Constructor object to check against was falsy.');
        }
        return false;
      }

      if (Y.Lang.isString(ctor)) {
        ctor = ctor.split('.');

        return function (val, attrName) {
          if (
            val instanceof (Y.Squarespace.Damask.ContextGlobals.fromTop(ctor)) ||
            val instanceof (Y.Squarespace.Damask.ContextGlobals.fromFrame(ctor))
          ) {
            return true;
          }
          logError.call(this, ctor.join('.'), val, attrName,
            '[{attrName}] Validation Error: {actualVal} is not an instance of {expectedVal}.'
          );
          return false;
        };
      }

      return function (val, attrName) {
        if (val instanceof ctor) {
          return true;
        }
        logError.call(this, ctor, val, attrName,
          '[{attrName}] Validation Error: {actualVal} is not an instance of {expectedVal}.'
        );
        return false;

      };
    },

    /**
     * Returns a function that checks if a value is an extension (that was created through Y.Base.create)
     *   of the given constructor.
     *
     * @method isExtensionOf
     * @param  superclassCtor {Function}  Constructor function to be checked as a superclass
     * @return {Boolean}
     */
    isExtensionOf: function (superclassCtor) {

      if (!Y.Lang.isFunction(superclassCtor)) {
        if (__DEV__) {
          console.error(superclassCtor, 'Constructor function to check against is not a function.');
        }
        return false;
      }

      return function (val, attrName) {

        var superclasses = (Y.Lang.isFunction(val.prototype._getClasses) && val.prototype._getClasses());
        if (superclasses && Y.Array.indexOf(superclasses, superclassCtor) !== -1) {
          return true;
        }

        logError.call(this, superclassCtor, val, attrName,
          '[{attrName}] Validation Error: {actualVal} is not an extension of the {expectedVal}.'
        );
        return false;
      };

    },

    /**
     * Returns a function that checks if a value is an of the given type.
     *
     * @method isTypeOf
     * @param type {Object} The type to check for if not null
     */
    isTypeOf: function(type) {
      if (!type) {
        if (__DEV__) {
          console.error('Type string to check against was falsy. Type was:', type);
        }
        return false;
      }
      return function (val, attrName) {
        if ((typeof val) === type) {
          return true;
        }
        logError.call(this, type, val, attrName,
          '[{attrName}] Validation Error: {actualVal} is not of type "{expectedVal}".'
        );
        return false;

      };
    },

    /**
     * Returns a function that checks if a value is in the given constants object.
     *
     * @method isValueIn
     * @param obj {Object} The object of constants (key integer pairs)
     */
    isValueIn: function (obj) {
      if (!Y.Lang.isObject(obj)) {
        if (__DEV__) {
          console.error(obj, 'Constants object to check is not an object');
        }
        return false;
      }

      if (Y.Lang.isArray(obj)) {
        return function (val, attrName) {
          if (Y.Array.indexOf(obj, val) !== -1) {
            return true;
          }
          logError.call(this, obj, val, attrName,
            '[{attrName}] Validation Error: {actualVal} is not a value in {expectedVal}'
          );
          return false;

        };
      }

      return function (val, attrName) {
        if (Y.Object.values(obj).indexOf(val) !== -1) {
          return true;
        }
        logError.call(this, obj, val, attrName,
          '[{attrName}] Validation Error: {actualVal} is not a value in {expectedVal}'
        );
        return false;

      };
    }
  };

  // convenience methods that return validator functions
  AttrValidators.isNullOrInstanceOf = function (ctor) {
    return AttrValidators.isNullOr(AttrValidators.isInstanceOf(ctor));
  };

  AttrValidators.isNullOrExtensionOf = function (func) {
    return AttrValidators.isNullOr(AttrValidators.isExtensionOf(func));
  };

  AttrValidators.isNullOrOfType = function (type) {
    return AttrValidators.isNullOr(AttrValidators.isTypeOf(type));
  };

  AttrValidators.isNullOrValueIn = function(object) {
    return AttrValidators.isNullOr(AttrValidators.isValueIn(object));
  };

  // additional convenience validators
  AttrValidators.isDate = AttrValidators.isInstanceOf(Date);
  AttrValidators.isNullOrString = AttrValidators.isNullOr(AttrValidators.isString);
  AttrValidators.isNullOrDate = AttrValidators.isNullOr(AttrValidators.isDate);
  AttrValidators.isNullOrBoolean = AttrValidators.isNullOr(AttrValidators.isBoolean);
  AttrValidators.isNullOrNumber = AttrValidators.isNullOr(AttrValidators.isNumber);
  AttrValidators.isNullOrObject = AttrValidators.isNullOr(AttrValidators.isObject);
  AttrValidators.isNullOrNonArrayObject = AttrValidators.isNullOr(AttrValidators.isNonArrayObject);
  AttrValidators.isNullOrArray = AttrValidators.isNullOr(AttrValidators.isArray);
  AttrValidators.isNullOrFunction = AttrValidators.isNullOr(AttrValidators.isFunction);
  AttrValidators.isNullOrNode = AttrValidators.isNullOr(AttrValidators.isNode);
  AttrValidators.isNullOrNodeList = AttrValidators.isNullOr(AttrValidators.isNodeList);

}, '1.0', {
  requires: [
    'squarespace-damask-context-globals'
  ]
});
