import ErrorReporter from '@sqs/error-reporter';

const GOOGLE_FONTS_BASE_URL = 'https://fonts.googleapis.com/css2';

export default (win) => {
  function buildGoogleFontsHref(families) {
    const params = families.map((f) => `family=${f}`).join('&');
    return `${GOOGLE_FONTS_BASE_URL}?${params}`;
  }

  function injectStylesheet(href) {
    const tag = win.document.createElement('link');
    tag.rel = 'stylesheet';
    tag.href = href;
    const s = win.document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(tag, s);
  }

  return {
    alreadyLoaded: [],
    alreadyPreloaded: [],

    load: function(params) {
      params = params || {};
      params.googleFamilies = params.googleFamilies || [];

      if (params.googleFamilies === 'all') {
        if (this.everythingLoaded) {
          return;
        }

        params.googleFamilies = [];
        for (const k in win.Static.SQUARESPACE_CONTEXT.googleFonts) {
          const fontDef = win.Static.SQUARESPACE_CONTEXT.googleFonts[k];
          params.googleFamilies.push(fontDef.includeName);
        }

        this.everythingLoaded = true;

      } else {
        for (let j = 0; j < params.googleFamilies.length; j++) {
          if (this.alreadyLoaded.indexOf(params.googleFamilies[j]) === -1) {
            this.alreadyLoaded.push(params.googleFamilies[j]);
          } else {
            params.googleFamilies.splice(j, 1);
            j--;
          }
        }
      }

      if (params.googleFamilies.length === 0) {
        return;
      }

      injectStylesheet(buildGoogleFontsHref(params.googleFamilies));
    },

    loadViaContext: function () {
      // initialize fonts
      const fontData = {
        googleFamilies: win.Static.SQUARESPACE_CONTEXT.googleFonts
      };

      if (win.Static.SQUARESPACE_CONTEXT.website.typekitId) {
        fontData.typeKit = { id: win.Static.SQUARESPACE_CONTEXT.website.typekitId };
      }

      this.load(fontData);
    },

    setupTypekit: function () {
      if (!win.TypekitPreview) {
        console.error('TypekitPreview not available');
        return;
      }

      if (this.isTypekitSetup) {
        return;
      }

      try {
        win.TypekitPreview.setup({
          'auth_id': 'ss',
          'auth_token': 'BInhlQ%2Bl4i%2FTK2LNzmpe2QC%2Bdl6Qc7iJa15oy7OfSGK%2BY7lwDRam4FlQCIzQGEqAiJZo10MshaDLZWgX%2FckdPK0bOW%2FAfpkj2O5MO1d7Aul7v1RGoa7OBxfdciGAxZqjHAPKKo0fqkxxKF%2F9mM9JjA%3D%3D' // eslint-disable-line max-len
        });
        this.isTypekitSetup = true;
      } catch (err) {
        console.error(err);
        ErrorReporter.trackCaughtError('common', 'TypekitPreview.setup issue', err.stack);
      }
    },

    loadTypekit: function(fontNameOrList, callback) {
      this.setupTypekit();

      const list = [];
      let fontData;

      try {
        if (win.Y.Lang.isArray(fontNameOrList)) {
          fontNameOrList.forEach(function (fontName, i) {
            if ((this.alreadyLoaded.indexOf(fontName) !== -1) && (this.alreadyPreloaded.indexOf(fontName) !== -1)) {
              return;
            }

            fontData = win.Static.SQUARESPACE_CONTEXT.typekitFonts[fontName];
            if (!fontData) {
              throw new Error('Requested font no longer in Typekit "' + fontName + '"');
            }

            this.alreadyPreloaded.push(fontName);
            list.push({
              'id': fontData.id,
              'css_name': fontName,
              'variations': ['n4'] // win.Static.SQUARESPACE_CONTEXT.typekitFonts[font[i]].variations
            });
          });
        } else {
          if (this.alreadyLoaded.indexOf(fontNameOrList) !== -1) {
            if (callback) {
              callback();
            }
            return;
          }

          fontData = win.Static.SQUARESPACE_CONTEXT.typekitFonts[fontNameOrList];
          if (!fontData) {
            throw new Error('Requested font no longer in Typekit "' + fontNameOrList + '"');
          }

          this.alreadyLoaded.push(fontNameOrList);
          list.push({
            'id': fontData.id,
            'css_name': fontNameOrList,
            'variations': fontData.variations
          });
        }

        win.TypekitPreview.load(list, {
          // Font event callbacks
          active: function() {
            // Called after these fonts are done loading and at least one is active
            if (callback) {
              callback();
            }
          },
          inactive: function() {
            // Called after these fonts are done loading and none are active
            // Also called immediately if fonts are not supported
            if (callback) {
              callback();
            }
          }
          // fontloading: function(fontFamily, fontDescription) {
          //   // Called as each font is requested
          // },
          // fontactive: function(fontFamily, fontDescription) {
          //   // Called after a font finishes loading and is active
          // },
          // fontinactive: function(fontFamily, fontDescription) {
          //   // Called after a font fails to load
          // }
        });

      } catch (err) {
        console.error(err);
        ErrorReporter.trackCaughtError('common', 'loadTypekit issue', err.stack);
      }
    },

    preloadTypekit: function(font, callback) {
      this.setupTypekit();
      const list = [];

      for (let i = 0; i < font.length; i++) {
        if ((this.alreadyPreloaded.indexOf(font[i]) !== -1) || (this.alreadyLoaded.indexOf(font[i]) !== -1)) {
          continue;
        }
        this.alreadyPreloaded.push(font[i]);
        list.push({
          'id': win.Static.SQUARESPACE_CONTEXT.typekitFonts[font[i]] ?
            win.Static.SQUARESPACE_CONTEXT.typekitFonts[font[i]].id :
            win.Static.SQUARESPACE_CONTEXT.typekitkitFonts[font[i]].id,
          'css_name': font[i],
          'variations': ['n4'] // win.Static.SQUARESPACE_CONTEXT.typekitFonts[font[i]].variations
        });
      }

      win.TypekitPreview.load(list, {
        // Font event callbacks
        // loading: function() {
        //   // Called when these fonts are requested
        //   console.log('The requested fonts are loading');
        // },
        active: function() {
          // Called after these fonts are done loading and at least one is active
          // console.log('The requested fonts are done loading and at least one is active');
          if (callback) {
            callback();
          }
        },
        inactive: function() {
          // Called after these fonts are done loading and none are active
          // Also called immediately if fonts are not supported
          // console.log('The requested fonts are done loading and none are active or the fonts are unsupported');
          if (callback) {
            callback();
          }
        }
        // fontloading: function(fontFamily, fontDescription) {
        //   // Called as each font is requested
        //   console.log(font + ' is requested');
        // },
        // fontactive: function(fontFamily, fontDescription) {
        //   // Called after a font finishes loading and is active
        //   console.log(font + ' is finished');
        // },
        // fontinactive: function(fontFamily, fontDescription) {
        //   // Called after a font fails to load
        //   console.log(font + ' is inactive');
        // }
      });
    },
  };
};
