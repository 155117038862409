/**
 * @module squarespace-widgets-lightbox2-overlay
 */
YUI.add('squarespace-widgets-lightbox2-overlay', function(Y) {

  Y.namespace('Squarespace.Widgets');

  /**
   * The overlay for LightBox2
   *
   * @class Lightbox2Overlay
   * @namespace Squarespace.Widgets
   * @extends Squarespace.Widgets.Gismo
   */
  Y.Squarespace.Widgets.Lightbox2Overlay = Y.Squarespace.Lightbox2Overlay =
  Y.Base.create('LightboxOverlay', Y.Squarespace.Widgets.Gismo, [], {

    destructor: function () {
      if (Y.Lang.isValue(this._overlayEl)) {
        this._overlayEl.destroy(true);
        this._overlayEl = null;
      }
    },

    renderUI: function() {
      this._overlayEl = Y.Node.create('<div class="sqs-lightbox-overlay"></div>');
      this._overlayEl.addClass('sqs-lightbox-overlay-' + this.get('overlayStyle'));

      if (this.get('theme') === 'light') {
        this._overlayEl.addClass('light');
      }

      this.get('contentBox').appendChild(this._overlayEl);
      this.get('contentBox').plug(Y.Squarespace.Plugin.ScrollLock);

      this.show();
    },

    show: function() {

      this._overlayEl.setStyles({ zIndex: '10000000' });

      var fadeInAnim = this._anim(this._overlayEl,
        { opacity: this.get('opacity') },
        { duration: this.get('speed') }
      );

      fadeInAnim.on('end', function() {
        this.fire('shown');
      }, this);

      fadeInAnim.run();

    },

    overlayHideAndDestroy: function() {

      this.once('hidden', this.destroy);
      this._hide();

    },

    _hide: function() {
      this.fire('hide');

      var fadeOutAnim = this._anim(this._overlayEl, {
        opacity: 0
      }, {
        duration: this.get('speed')
      });

      fadeOutAnim.on('end', function() {
        this.fire('hidden');
      }, this);

      if (this.get('destroyed')) {
        this.fire('hidden');
      } else {
        fadeOutAnim.run();
      }
    },

    _getContainer: function() {
      return this._overlayEl;
    }
  }, {
    NS: 'overlay',
    ATTRS: {

      opacity: {
        value: 0.95
      },

      overlayStyle: {
        value: 'default'
      },

      /**
        * Toggles Lightbox Overlay background color. Options are 'dark' or 'light'.
        *
        * @attribute theme
        * @type String
        */
      theme: {
        value: 'dark'
      },

      container: {
        getter: '_getContainer'
      },

      speed: {
        value: 0.3
      }
    }
  });

}, '1.0', {
  requires: [
    'base',
    'node',
    'squarespace-plugin-scroll-lock',
    'squarespace-widgets-gismo'
  ]
});
