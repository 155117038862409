/**
  Home of Anim, a tiny utility class for auto animation cleanup
  @module squarespace-anims
*/
YUI.add('squarespace-anims', function(Y) {

  Y.namespace('Squarespace');

  /**
    A class for simple animation management. It stores a group of animations,
    automatically stopping them when this gets destroyed
    @class Anims
    @extends ArrayList
    @namespace Squarespace
    @constructor
  */

  function Anims(config) {
    Anims.superclass.constructor.apply(this, arguments);
  }

  Y.Squarespace.Anims = Y.extend(Anims, Y.Base, {

    initializer: function() {
      this._items = [];
    },

    destructor: function() {
      // shallow copy of the _items
      var toRemove = [];
      this.each(function(anim) {
        toRemove.push(anim);
      });
      // stop all animations
      Y.Array.each(toRemove, function(anim) {
        if (anim.get('running')) {
          anim.stop(false); // don't complete the animation, doing that causes flicker
        }
        anim.destroy();
      });
      Y.detach(this.get('id') + '|*');
    },


    /**
      Adds like an array list normally would, except it first checks if the node
      you're animating is valid
      @method add
      @param anim {Y.Anim} An animation instance
    */
    add: function(anim) {

      // Warn you of your terrible code
      if (!anim.get('node')) {
        throw new Error(this.name + ": Animation doesn't specify a node");
      }

      if (__DEV__) {
        if (!anim.get('node').ancestor('body')) { // node not in the dom
          console.warn(this.name + ': Animation with a YUI node not in the DOM added');
        } else if (!anim.get('node')._node) { // yui node isn't pointing to an actual DOM node
          console.warn(this.name + ': Animation with a _node = null added');
        }
      }

      anim.on(this.get('id') + '|end', function(e) {
        this.remove(anim);
      }, this);

      this._items.push(anim);
    },

    /**
      Removes an item form this array list
      @method remove
      @param item {Anim|Number} An animation instance or the index of the item
        to remove
      @return {Anim} The removed instance
    */
    remove: function(item) {
      if (Y.Lang.isNumber(item)) {
        return this._items.splice(item, 1);
      }
      var i = this._items.indexOf(item);
      if (i !== -1) {
        return this._items.splice(this._items.indexOf(item), 1);
      }

    }

  }, {
    NAME: 'anims'
  });

  Y.augment(Anims, Y.ArrayList);

}, '1.0', { requires: [
  'arraylist',
  'base',
  'base-build'
] });
