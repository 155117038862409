import Flag from '@sqs/enums/Flag';
import { legacyV6Flags as BetaFeaturesUtils } from '@sqs/universal-flags';
import { formatMoney } from 'shared/i18n';
import MoneyFormatter from './MoneyFormatter';

/**
 * This is a wrapper mocking the custom Commerce Money Formatter
 * Do not use if you rely on accessing custom 'templates'
 *
 * @see {@class shared/i18n/commerce/MoneyFormatter.js}
 */
const cldrFormatMoneyWrapper: MoneyFormatter = {
  format: formatMoney,
  get templates() {
    throw new Error('[MoneyFormatterProvider] custom templates are not supported in CLDR mode');
  }
};

class MoneyFormatterProvider {
  private useCldrMode: boolean;
  private commerceMoneyFormatter: MoneyFormatter;

  constructor(useCldrMode = false) {
    this.useCldrMode = useCldrMode;
    this.commerceMoneyFormatter = new MoneyFormatter('native');
  }

  setUseCldrMode(useCldrMode: boolean) {
    this.useCldrMode = useCldrMode;
  }

  get formatter() {
    if (this.useCldrMode) {
      return cldrFormatMoneyWrapper;
    }

    return this.commerceMoneyFormatter;
  }
}

/**
 * By default, this relies on Flag.CLDR_NUMBER_FORMATTER to use
 * formatMoneyfrom @sqs/i18n-ui or shared/i18n/commerce/MoneyFormatter
 *
 * This call is safe at the module level because BetaFeatureutils reads
 * from window.Static.SQUARESPACE_CONTEXT which is always avaliable first
 */
const moneyFormatterProvider = new MoneyFormatterProvider(
  BetaFeaturesUtils.isFeatureEnabled(Flag.CLDR_NUMBER_FORMATTER)
);

/**
 * This is a singleton. Only one provider should exist.
 */
export default moneyFormatterProvider;