import Alt from '@sqs/alt';

/**
 * Instantiate the main flux implementation to use across our app.
 * {@link https://github.com/goatslacker/alt}
 */

const alt = new Alt();

if (__DEV__) {
  const EXCLUDE_FROM_DEBUG_LOGGING = ['AnnotationsActions'];

  /**
   * attach alt to window for easier debugging
   * e.g., alt.stores.BillingStatusStore.getState()
   */
  window.alt = alt;

  /**
   * In the meantime, log all dispatches to the console.
   */
  const dispatcher = alt.dispatcher;
  dispatcher.register(payload => {
    if (EXCLUDE_FROM_DEBUG_LOGGING.includes(payload.details.namespace)) {
      return;
    }

    console.debug(payload);
  });

  /**
   * Intercept calls to createStore to ensure that stores are named.
   */
  const originalCreateStore = alt.createStore.bind(alt);

  alt.createStore = <S>(
    storeModel: AltJS.StoreModel<S>,
    name: string,
    ...otherArgs: any
  ) => {
    if (!name) {
      const storeName = storeModel.displayName ?? 'StoreClass';

      throw new Error(`Please name your alt store, i.e. alt.createStore(${storeName}, '${storeName}').` +
        ' Otherwise, this can cause problems in production after minification obfuscates class names.'
      );
    }

    return originalCreateStore<S>(storeModel, name, ...otherArgs);
  };
}

export default alt;