/*

   ______   __  __    _______   __        ________  ______       ________   ______   ________
  /_____/\ /_/\/_/\ /_______/\ /_/\      /_______/\/_____/\     /_______/\ /_____/\ /_______/\
  \:::_ \ \\:\ \:\ \\::: _  \ \\:\ \     \__.::._\/\:::__\/     \::: _  \ \\:::_ \ \\__.::._\/
   \:(_) \ \\:\ \:\ \\::(_)  \/_\:\ \       \::\ \  \:\ \  __    \::(_)  \ \\:(_) \ \  \::\ \
    \: ___\/ \:\ \:\ \\::  _  \ \\:\ \____  _\::\ \__\:\ \/_/\    \:: __  \ \\: ___\/  _\::\ \__
     \ \ \    \:\_\:\ \\::(_)  \ \\:\/___/\/__\::\__/\\:\_\ \ \    \:.\ \  \ \\ \ \   /__\::\__/\
      \_\/     \_____\/ \_______\/ \_____\/\________\/ \_____\/     \__\/\__\/ \_\/   \________\/



  A package to declare the packages that we expose to public developers.

  @module squarespace-public-api

 */
import ImageLoaderModule from '@sqs/imageloader';
import ImageLoader from '@sqs/imageloader/dist/indexBuilder';
import { t } from 'shared/i18n';
import Singleton from 'shared/utils/Singleton';
import TemplateVersionUtils from 'shared/utils/TemplateVersionUtils';

YUI.add('squarespace-public-api', function (Y) {

  // this class will be instantiated after YUI is instantiated on the
  // clients website.

  Y.namespace('Squarespace');

  var lazyLoadingEnabled = TemplateVersionUtils.isSevenOne();

  if (lazyLoadingEnabled) {
    Y.config.win.ImageLoader = new ImageLoader.Builder({ allowConcurrentLoads: true }).withLazyLoading().build();
    Y.config.win.ImageLoader.bootstrap = Y.config.win.ImageLoader.loadAllLazy;
  } else {
    Y.config.win.ImageLoader = new ImageLoaderModule({ allowConcurrentLoads: true });
    Y.config.win.ImageLoader.bootstrap = Y.config.win.ImageLoader.loadAll;
  }

  /**
   * @class Template
   * @namespace Squarespace
   * @static
   */
  Y.Squarespace.Template = Singleton.create({

    /**
     Utility to get the correct tweakJSON value
     @method getTweakValue
     @param key
    */
    getTweakValue: function (key) {
      if (key) {
        return Y.config.win.Static.SQUARESPACE_CONTEXT.tweakJSON[key] ||
        Y.config.win.Static.SQUARESPACE_CONTEXT.tweakJSON[key.replace('@', '').replace('.', '')];
      }
    } });



  /**
   * Core/frontend node extensions (more/backend in ui-base)
   * @class Node
   */
  Y.augment(Y.Node, Y.Squarespace.Legacy.Class.create({
    /**
     * @method width
     */
    width: function () {
      return this.get('offsetWidth');
    },

    /**
     * @method height
     */
    height: function () {
      return this.get('offsetHeight');
    } }));



  /**
   * JSTween Placeholder
   * A really simple JSTween implementation so we can remove the larger JSTween dependency.
   * Users of JSTween on the window should stop using it immediately.
   */
  var queue = [];
  Y.config.win.JSTween = {
    tween: function (node, config) {
      console.error(t("You are using the Squarespace provided global JSTween which is no longer supported. Please either remove your usage of JSTween or include JSTween yourself."));







      queue.push({
        node: node,
        config: config });

    },
    play: function () {
      queue.forEach(function (entry) {
        console.error(t("You are using the Squarespace provided global JSTween which is no longer supported. Please either remove your usage of JSTween or include JSTween yourself."));








        var node = entry.node;
        var config = entry.config;

        var fromStyles = {};
        var toStyles = {};
        var onStartFuncs = [];
        var onEndFuncs = [];

        for (var key in config) {
          fromStyles[key] = config[key].start;
          toStyles[key] = config[key].stop;

          if (config[key].onStart) {
            onStartFuncs.push(config[key].onStart);
          }

          if (config[key].onStop) {
            onEndFuncs.push(config[key].onStop);
          }
        }

        Y.one(node).anim(toStyles, {
          from: fromStyles,
          duration: config[Object.keys(config)[0]].duration,
          start: function () {
            onStartFuncs.forEach(function (fn) {
              fn();
            });
          },
          end: function () {
            onEndFuncs.forEach(function (fn) {
              fn();
            });
          } }).
        run();

      });

      queue = [];
    } };


}, '1.0', {
  requires: [
  'history',
  'node',
  'node-event-simulate',
  'node-load',
  'squarespace-dom-emitters-resize',
  'squarespace-gallery-manager',
  'squarespace-gallery-ng',
  'squarespace-legacy-class',
  'thirdparty-modernizr',
  'yui-throttle'] });