/**
 * common integration into @squarespace/mercury
 */

/**
 * Functions called on page load when a template has AJAX navigation
 * enabled via @squarespace/mercury.
 * Use onInitialize(Y, f) to add to this array.
 *
 * @type {function[]}
 */
export const initializers = [];

/**
 * Functions called on page unload when a template has AJAX navigation
 * enabled via @squarespace/mercury.
 * Use onDestroy(Y, f) to add to this array.
 *
 * @type {function[]}
 */
export const destructors = [];

/**
 * Append to function INITIALIZERS if not present
 *
 * @param {YUI} Y
 * @param {function} f
 */
export const onInitialize = (Y, f) => {
  if (initializers.map(i => i.toString()).indexOf(f.toString()) === -1) {
    // Fix the race condition: if we get this script after globalMarkReady runs,
    // run the function before adding it to array.
    if (Y.config.win.Squarespace.INITIALIZED) {
      f();
    }
    initializers.push(f);
  } else if (__DEV__) {
    console.warn('[mercury.onInitialize] Initializer already exists!');
  }
};

/**
 * Append to destructors
 *
 * @param {YUI} Y
 * @param {function} f
 */
export const onDestroy = (Y, f) => {
  if (destructors.map(d => d.toString()).indexOf(f.toString()) === -1) {
    destructors.push(f);
  } else if (__DEV__) {
    console.warn('[mercury.onDestroy] Destructor already exists!');
  }
};

/**
 * Run initializers and marks the page as INITIALIZED and READY.
 *
 * @param {YUI} Y
 */
export const markReady = (Y) => {
  if (Y.config.win.Squarespace.INITIALIZED) {
    return;
  }

  // let scripts react
  // console.trace('calling Y.fire(squarespace:initialized)', Y.config.doc.readyState);
  // Y.fire('squarespace:initialized');

  initializers.forEach((fn) => {
    try {
      fn();
    } catch (err) {
      console.error('[mercury.markReady] Error invoking initializer.', err);
    }
  });

  Y.config.win.Squarespace.INITIALIZED = true;
  Y.config.win.Squarespace.SQUARESPACE_INITIALIZED_ONCE = true;
};

/**
 * Call when destroying a page via mercury navigation to run destructors and
 * unmark page INITIALIZED state.
 *
 * @param {YUI} Y
 */
export const globalDestroy = (Y) => {
  if (!Y.config.win.Squarespace.INITIALIZED) {
    return;
  }

  Y.config.win.Squarespace.INITIALIZED = false;
  destructors.forEach((fn) => fn());
};

/**
 * Given a context such as a Window object, expose the required
 * @squarespace/mercury functions that blocks and templates expect to call.
 *
 * @param {object} context to expose the mercury methods
 */
export const mixin = (context) => {

  context.Squarespace = context.Squarespace || {};

  /**
   * should only be used for objects inside of the frame. Many parts of
   * squarespace use this to hook into the mercury ajax page initialization
   *
   * @type {function}
   */
  context.Squarespace.onInitialize = onInitialize;

  /**
   * should only be used for objects inside of the frame. Many parts of
   * squarespace use this to hook into the mercury ajax page destruction
   *
   * @type {function}
   */
  context.Squarespace.onDestroy = onDestroy;

  /**
   * The templates-v6/checkout.html AJAX loaded template relies on this
   *
   * @type {function}
   */
  context.Squarespace.globalMarkReady = markReady;

  /**
   * This is called by Lifecycle.js in @squarespace/core (which is in turn
   * hooked up to AJAX page loading via @squarespace/mercury on mercury:unload)
   *
   * @type {function}
   */
  context.Squarespace.globalDestroy = globalDestroy;

};
