/**
 * Note: This Intersection Observer polyfill loading is no longer necessary
 * We've decided it's a small enough polyfill that it can just be statically
 * imported (see AssistantWrapper.tsx) for any usages in /config. This method should now
 * immediately return the polyfilled Intersection observer, even in old browsers, so
 * there's no need to migrate away immediately but there's very little reason to add
 * this to new code.
 */

/**
 * @return {Promise} resolved with IntersectionObserver
 */
export default async () => {
  if (!window || !window.document) {
    throw new Error('Cannot init animations without a window context');
  }

  if (!window.hasOwnProperty('IntersectionObserver')) {
    await import(
      /* webpackChunkName: "async-intersection-observer" */ 'polyfill-library/polyfills/__dist/IntersectionObserver/min'
    );
  }
};
