import "shared/i18n/configurations/commerce-currency.js";import { fromCents } from '@sqs/commerce-currency';
/**
 * A facade ShoppingCart Singleton model, for usage in the scenario that
 * squarespace-commerce is not loaded on the page, but there is code in the user
 * space (developers, templates) that depend on this class.
 *
 * Thus this facade class exists, to have the model available.
 *
 * @singleton
 * @module squarespace-models-shopping-cart-facade
 */
YUI.add('squarespace-models-shopping-cart-facade', function (Y) {

  Y.namespace('Squarespace.Singletons');

  var ShoppingCartFacade =
  Y.Base.create('ShoppingCartFacade', Y.Base, [], {}, {
    ATTRS: {
      /**
       * Stored values
       */
      id: {
        validator: Y.Lang.isString },

      websiteId: {
        validator: Y.Lang.isString },

      orderId: {
        validator: Y.Lang.isString },

      created: {
        getter: function (value) {
          if (!Y.Lang.isValue(value)) {
            return null;
          }
          return new Date(value);

        },
        setter: function (value) {
          if (Y.Lang.isDate(value)) {
            return value.getTime();
          }
          return value;

        },
        validator: Y.Lang.isNumber },

      isPurchased: {
        value: false,
        validator: Y.Lang.isBoolean },

      entries: {
        value: [],
        validator: Y.Lang.isArray },

      shippingOptions: {
        value: [],
        validator: Y.Lang.isArray },

      selectedShippingOption: {
        validator: Y.Lang.isObject },

      shippingLocation: {
        validator: Y.Lang.isObject },

      applicableTaxRules: {
        value: [],
        validator: Y.Lang.isArray },

      coupons: {
        value: [],
        validator: Y.Lang.isArray },


      /**
       * Transient values
       */
      validCoupons: {
        value: [],
        validator: Y.Lang.isArray },

      invalidCoupons: {
        value: [],
        validator: Y.Lang.isArray },

      subtotalCents: {
        validator: Y.Lang.isNumber,
        value: 0 },

      subtotalAmount: {
        value: fromCents(0, 'USD') },

      taxCents: {
        validator: Y.Lang.isNumber,
        value: 0 },

      shippingCostCents: {
        validator: Y.Lang.isNumber,
        value: 0 },

      discountCents: {
        validator: Y.Lang.isNumber,
        value: 0 },

      grandTotalCents: {
        validator: Y.Lang.isNumber,
        value: 0 },

      totalQuantity: {
        validator: Y.Lang.isNumber,
        value: 0 },

      hasDigital: {
        validator: Y.Lang.isBoolean,
        value: false },

      purelyDigital: {
        validator: Y.Lang.isBoolean,
        value: false },

      requiresShipping: {
        validator: Y.Lang.isBoolean,
        value: false } } });




  Y.Squarespace.Singletons.ShoppingCart = new ShoppingCartFacade();

}, '1.0', {
  requires: [
  'base'] });