/**
 * Image loader
 *
 * @module squarespace-image-loader
 */
import TemplateVersionUtils from 'shared/utils/TemplateVersionUtils';

YUI.add('squarespace-image-loader', function(Y) {

  Y.namespace('Squarespace');

  /**
   * It's not Loader2 or 3
   * Image loader
   *
   * @namespace   Squarespace
   * @class       Loader
   * @constructor
   */
  Y.Squarespace.Loader = Y.Base.create('Loader', Y.Base, [], {
    initializer: function() {
      if (this.get('frames')) {
        this._processFrames();
      }

      if (this.get('img')) {
        this._processImages();
      }
    },

    _processFrames: function() {
      this._getNodeList('frames').each(function(frame) {
        if (frame.getAttribute('data-embed')) {
          frame.resizeToParent({
            scale: 'contain',
            sizeOnly: true
          });
        }
      });
    },

    _processImages: function() {
      this._getNodeList('img').each(function(img) {
        var alreadyHasLoader = false; // because i still want it to refresh the values.
        if (img.loader) {
          alreadyHasLoader = true;
        }

        img.plug(Y.Squarespace.Loader2, this.get('loaderOptions') || {});

        if (alreadyHasLoader) {
          img.fire('refresh');
        }
      }, this);
    },

    _getNodeList: function(attr) {

      var value = this.get(attr);

      if (Y.Lang.isString(value)) {
        return Y.all(value);
      } else if (!value.size) {
        return new Y.NodeList(value);
      }

      return value;
    }

  }, {
    ATTRS: {
      /**
       * @attribute frames
       * @type      Node[]
       */
      frames: {},
      /**
       * @attribute img
       */
      img: {},
      /**
       * @attribute loaderOptions
       */
      loaderOptions: {}
    }
  });

  /**
   * There's also Loader3 and 1
   *
   * @namespace   Squarespace
   * @class       Loader2
   * @extends     Plugin.Base
   * @constructor
   */
  Y.Squarespace.Loader2 = Y.Base.create('Loader2', Y.Plugin.Base, [], {

    initializer: function() {

      var node = this.get('host');
      var _maxTries = 100;
      var _currentTry = 0;

      function _check() {
        if (!node.inDoc() && (++_currentTry < _maxTries)) {
          Y.later(10, this, _check);
        } else {
          this.load();
        }
      }

      // we make sure that we fire the initial "refresh" only when the element is in the document.
      _check.call(this);

      // refresh on host load.
      this.on('refresh', this.load, this);
      this.afterHostEvent('refresh', this.load, this);

    },

    /**
     * Returns whether the host is still currently valid.
     * @return {Boolean} True if the host's dom node exists in the current document.
     */
    _isInvalidHost: function () {
      return (this.get('host').getDOMNode() === null || !this.get('host').inDoc());
    },

    /**
     * @method load
     */
    load: function() {

      var host = this.get('host');
      if (!!host.getAttribute('src') && host.get('complete')) {
        host.fire('loaded');
      }

      this.afterHostEvent('load', function() {

        if (this._isInvalidHost()) {
          return;
        }

        this.get('host').removeClass('loading').addClass('loaded');
        this.get('host').fire('loaded');

      }, this);

      if (this._isInvalidHost()) {
        return;
      }

      var loadSetting = this.get('load');
      var lazyLoadingEnabled = TemplateVersionUtils.isSevenOne() &&
        Y.config.win.ImageLoader.loadLazy &&
        // If `viewport`, the image width and height are calculated, but no image is sourced.
        // So `viewport` should not be lazy loaded.
        loadSetting !== 'viewport';
      var loadImage = (
        lazyLoadingEnabled ? Y.config.win.ImageLoader.loadLazy : Y.config.win.ImageLoader.load
      ).bind(Y.config.win.ImageLoader);
      loadImage(host.getDOMNode(), Y.merge(this.getAttrs(), {
        load: loadSetting
      }));
    },

    /**
     * @method getDimensionForValue
     * @param  dimension
     * @param  value
     */
    getDimensionForValue: function(dimension, value) {
      var node = this.get('host').getDOMNode();
      var data = Y.config.win.ImageLoader._getDataFromNode(node);
      return Y.config.win.ImageLoader.getDimensionForValue(dimension, value, data);
    },

    /**
     * @method getBareElement
     * @return {Node}
     */
    getBareElement: function() {
      var img = this.get('host');
      var imgEl = Y.Node.create('<img />');
      imgEl.setAttribute('data-src', img.getAttribute('data-src'));
      imgEl.setAttribute('data-image-dimensions', img.getAttribute('data-image-dimensions'));
      imgEl.setAttribute('data-image-focal-point', img.getAttribute('data-image-focal-point'));
      imgEl.setAttribute('alt', img.getAttribute('alt'));

      return imgEl;
    },

    destructor: function() {

    }

  }, {
    NS: 'loader',
    ATTRS: {
      /**
       * @attribute stretch
       */
      stretch: {

      },

      /**
       * @attribute load
       * @default true
       */
      load: {
        value: true
      },

      /**
       * @attribute source
       */
      source: {

      },

      /**
       * @attribute fixedRatio
       */
      fixedRatio: {
        value: false
      },

      /**
       * @attribute fitAlignment
       */
      fitAlignment: {

      },

      /**
       * set mode to fit
       * @attribute fit
       * @type      Boolean
       */
      fit: {
        value: false,
        setter: function(val) {
          if (val) { this.set('mode', 'fit'); }
        }
      },

      /**
       * set mode to fill
       * @attribute fill
       * @type      boolean
       */
      fill: {
        value: false,
        setter: function(val) {
          if (val) { this.set('mode', 'fill'); }
        }
      },

      /**
       * `x` and `y` points.
       * @attribute focalPoint
       * @type      Object
       */
      focalPoint: {
        setter: function(val) {
          Y.config.win.ImageLoader.load(this.get('host').getDOMNode(), {
            focalPoint: val.x + ',' + val.y
          });

          return val;
        }
      },

      /**
       * Mode: fit, fill, or none
       * @attribute mode
       * @type      String
       */
      mode: {
        validator: function(val) {
          return ['fit', 'fill', 'none', null].indexOf(val) !== -1;
        }
      },

      /**
       * @attribute refreshOnResize
       * @type      Boolean
       */
      refreshOnResize: {
        value: false
      },

      /**
       * @attribute useBgImage
       * @type      Boolean
       */
      useBgImage: {
        validator: function(val) {
          return Y.Lang.isBoolean(val);
        }
      }
    }
  });

  Y.Squarespace.ImageLoadManager = new (Y.Base.create('ImageLoadManager', Y.Base, [], {

    refreshAll: function() {
      Y.all('img[data-src]').each(function(imgEl) {
        imgEl.fire('refresh');
      });
    }

  }))();

  Y.augment(Y.Node, Class.create({
    refresh: function() {
      this.fire('refresh');
    }
  }));

}, '1.0', {
  requires: [
    'base',
    'node',
    'plugin'
  ]
});
