/**
 *
 *                  uuuuuuu
 *              uu$$$$$$$$$$$uu
 *           uu$$$$$$$$$$$$$$$$$uu
 *          u$$$$$$$$$$$$$$$$$$$$$u
 *         u$$$$$$$$$$$$$$$$$$$$$$$u
 *        u$$$$$$$$$$$$$$$$$$$$$$$$$u
 *        u$$$$$$$$$$$$$$$$$$$$$$$$$u
 *        u$$$$$$"   "$$$"   "$$$$$$u
 *        "$$$$"      u$u       $$$$"
 *         $$$u       u$u       u$$$
 *         $$$u      u$$$u      u$$$
 *          "$$$$uu$$$   $$$uu$$$$"
 *           "$$$$$$$"   "$$$$$$$"
 *             u$$$$$$$u$$$$$$$u
 *              u$"$"$"$"$"$"$u
 *   uuu        $$u$ $ $ $ $u$$       uuu
 *  u$$$$        $$$$$u$u$u$$$       u$$$$
 *   $$$$$uu      "$$$$$$$$$"     uu$$$$$$
 * u$$$$$$$$$$$uu    """""    uuuu$$$$$$$$$$
 * $$$$"""$$$$$$$$$$uuu   uu$$$$$$$$$"""$$$"
 *  """      ""$$$$$$$$$$$uu ""$"""
 *            uuuu ""$$$$$$$$$$uuu
 *   u$$$uuu$$$$$$$$$uu ""$$$$$$$$$$$uuu$$$
 *   $$$$$$$$$$""""           ""$$$$$$$$$$$"
 *    "$$$$$"                      ""$$$$""
 *      $$$"                         $$$$"
 *
 * Stay out!!! Turn back!
 *
 * This is for old code! Don't you have something fun to write??
 *
 * @module squarespace-legacy-class
 */
YUI.add('squarespace-legacy-class', function(Y) {

  Y.namespace('Squarespace.Legacy');

  // -----------------------------------------------------------------------------
  // OOPs
  // -----------------------------------------------------------------------------

  /**
   * *************** WARNING *******************
   * DEPRECATED
   * Do not use for new components. Stick it on the Y.Squarespace.Singletons namespace instead.
   */

  Y.Squarespace.Legacy.Class = {

    create: function(protoDef) {

      var c;

      // create object based on constructor method

      if (protoDef.initialize) {
        c = function() {
          this.initialize.apply(this, arguments);
        };
      } else {
        c = function() { };
      }

      // assign prototype

      c.prototype = protoDef;

      // allow extension / specialization

      c.extend = function(def) { // create a new class based on this one
        return Class.extend(this, def);
      };

      c.specialize = function(def, _parent) { // create a new class based on this one
        var sp = Class.specialize(this, def);
        sp._parent = _parent;
        return sp;
      };

      return c;

    },

    extend: function(base, protoDef) {

      if (!protoDef.initialize) { // non standard -- constructor inheritance
        protoDef.initialize = base.prototype.initialize;
      }

      var c = Class.create(protoDef); // start with our derived methods
      // http://stackoverflow.com/questions/3911690
      var fnTest = /xyz/.test(function(){xyz;}) ? /\b_super\b/ : /.*/; // eslint-disable-line

      for (var property in base.prototype) {

        if (!c.prototype[property]) { // if it's missing?

          // just make it a reference to the base property
          c.prototype[property] = base.prototype[property];

          // if this property exists in our new class, it's a function, and it has usage of _super in it's definition.
        } else if ( typeof (c.prototype[property]) === 'function' && fnTest.test(c.prototype[property]) ) {

          // put a layer on top of it, and modify the prototype of the property.
          c.prototype[property] = (function(name, fn) {
            return function() {
              var tmp = this._super;

              // Add a new ._super() method that is the same method
              // but on the super-class
              this._super = base.prototype[name];

              // The method only need to be bound temporarily, so we
              // remove it when we're done executing
              var ret = fn.apply(this, arguments);

              this._super = tmp;

              return ret;
            };
          })(property, c.prototype[property]);

          /*
           * Check out http://ejohn.org/blog/simple-javascript-inheritance/
           *
           *
           * It's pronunced Rez-ig.
           */

        }

      }

      c.prototype.superclass = base.prototype;

      return c;
    },

    specialize: function(base, protoDef) {

      var Cx = Class.extend(base, protoDef);
      return new Cx();

    }
  };


}, '1.0', { requires: [] });
