/**
 * Contains utility functions for positioning elements.
 *
 * @module squarespace-widgets-position-helper
 */
YUI.add('squarespace-widgets-position-helper', function(Y) {

  Y.namespace('Squarespace.Widgets');

  // general padding value for elements that approach borders
  var GENERAL_PADDING = 20;

  /**
   * Contains a region within the window.
   *
   * @method contain
   * @param {Object} pos The region's position.
   * @param {Number} pos.x The region's x coordinate.
   * @param {Number} pos.y The region's y coordinate.
   * @param {Object} size The dimensions of the region.
   * @param {Number} pos.width The region's width.
   * @param {Number} pos.height The region's height.
   * @param {Number} padding Amount of padding from the sides of the window.
   * @return {Object} Coordinates guaranteed to be within the DOM window.
   */
  function contain(pos, size, padding, node) {

    var yInstance;

    try {
      yInstance = Y.Squarespace.Damask.ContextGlobals.relative(node, 'Y');
    } catch (e) {
      yInstance = Y;
    }

    var viewPortRegion = yInstance.DOM.viewportRegion();
    var winWidth = viewPortRegion.width;
    var winHeight = viewPortRegion.height;

    var minimum = {
      x: padding,
      y: padding
    };

    if (pos.x + size.width >= winWidth) {
      pos.x = winWidth - size.width - padding;
    }
    if (pos.x <= minimum.x) {
      pos.x = minimum.x;
    }

    if (pos.y + size.height >= winHeight) {
      pos.y = winHeight - size.height - padding;
    }
    if (pos.y <= minimum.y) {
      pos.y = minimum.y;
    }

    return pos;

  }

  /**
   * Stacks elements in a given direction if there are already similar elements
   * on the screen.
   *
   * @method stack
   * @param {Node} el The element to be stacked.
   * @param {String} direction The desired css direction (top, right, bottom, left).
   * @param {Number} padding Amount of padding to add to the element.
   * @return {Number} The new value for the direction for stacking.
   */
  function stack(el, direction, padding) {

    var classNames = el.get('className').split(' ').map(function(c) {
      return '.' + c;
    }).join('');

    var count = Y.all(classNames).size();
    var value = padding;

    if (count === 1) {
      return value;
    }

    var dimension;

    switch (direction) {
    case 'top':
    case 'bottom':
      dimension = el.get('offsetHeight');
      break;
    case 'left':
    case 'right':
      dimension = el.get('offsetWidth');
      break;
    }

    value = (dimension * (count - 1)) + (padding * count);

    return value;

  }

  /**
   * Returns styles to position an element centered around the mouse.
   *
   * @for Squarespace.Widgets.PositionHelper.ANCHOR
   * @method mouse
   * @param {Node} el The element to be positioned.
   * @return {Object} An object of styles to set to get the desired position.
   */
  var mouse = function(el) {

    var pos = {
      x: Y.Squarespace.Mouse.client.lastX,
      y: Y.Squarespace.Mouse.client.lastY
    };

    var size = {
      width: el.get('offsetWidth'),
      height: el.get('offsetHeight')
    };

    var target = contain({
      x: pos.x - Math.floor(size.width / 2),
      y: pos.y - Math.floor(size.height / 2)
    }, size, this.padding, el);

    return {
      position: 'fixed',
      top: target.y,
      left: target.x
    };

  };

  /**
   * Returns styles to position an element at the top left corner of the screen.
   *
   * @for Squarespace.Widgets.PositionHelper.ANCHOR
   * @method topLeft
   * @param {Node} el The element to be positioned.
   * @return {Object} An object of styles to set to get the desired position.
   */
  var topLeft = function(el) {
    return {
      position: 'fixed',
      top: stack(el, 'top', this.padding),
      left: this.padding
    };
  };

  /**
   * Returns styles to position an element at the top right corner of the screen.
   *
   * @for Squarespace.Widgets.PositionHelper.ANCHOR
   * @method topRight
   * @param {Node} el The element to be positioned.
   * @return {Object} An object of styles to set to get the desired position.
   */
  var topRight = function(el) {
    return {
      position: 'fixed',
      top: stack(el, 'top', this.padding),
      right: this.padding
    };
  };

  /**
   * Returns styles to position an element at the bottom left corner of the screen.
   *
   * @for Squarespace.Widgets.PositionHelper.ANCHOR
   * @method bottomLeft
   * @param {Node} el The element to be positioned.
   * @return {Object} An object of styles to set to get the desired position.
   */
  var bottomLeft = function(el) {
    return {
      position: 'fixed',
      bottom: stack(el, 'bottom', this.padding),
      left: this.padding
    };
  };

  /**
   * Returns styles to position an element at the bottom right corner of the screen.
   *
   * @for Squarespace.Widgets.PositionHelper.ANCHOR
   * @method bottomRight
   * @param {Node} el The element to be positioned.
   * @return {Object} An object of styles to set to get the desired position.
   */
  var bottomRight = function(el) {
    return {
      position: 'fixed',
      bottom: stack(el, 'bottom', this.padding),
      right: this.padding
    };
  };

  /**
   * Returns styles to position an element anchored to the right of another element. This
   * defaults to the mouse if the anchor node doesn't exist.
   *
   * @for Squarespace.Widgets.PositionHelper.ANCHOR
   * @method element
   * @param {Node} el The element to be positioned.
   * @param {Node} anchor An anchor element to guide the position of el.
   * @return {Object} An object of styles to set to get the desired position.
   */
  var element = function(el, anchor) {

    // if the anchor node doesn't exist.
    if (!Y.Lang.isValue(anchor)) {
      return mouse(el);
    }

    var x = anchor.getX() + anchor.get('offsetWidth') + this.padding;

    var size = {
      width: el.get('offsetWidth'),
      height: el.get('offsetHeight')
    };

    var target = contain({
      x: x,
      y: anchor.getY()
    }, size, this.padding, el);

    return {
      top: target.y,
      left: target.x
    };

  };

  /**
   * Returns styles to position an element at the center of the screen.
   *
   * @for Squarespace.Widgets.PositionHelper.ANCHOR
   * @method center
   * @param {Node} el The element to be positioned.
   * @return {Object} An object of styles to set to get the desired position.
   */
  var center = function(el) {

    var pos = {
      x: Math.floor(Y.DOM.winWidth() / 2),
      y: Math.floor(Y.DOM.winHeight() / 2)
    };

    var size = {
      width: Math.floor(el.get('offsetWidth') / 2),
      height: Math.floor(el.get('offsetHeight') / 2)
    };

    var target = contain({
      x: pos.x - size.width,
      y: pos.y - size.height
    }, size, this.padding, el);

    return {
      position: 'fixed',
      top: target.y,
      left: target.x
    };

  };

  /**
   * Enum containing references to each position type.
   *
   * @class PositionHelper.ANCHOR
   * @static
   * @namespace Squarespace.Widgets
   * @example
   *     var styles = new PositionHelper(PositionHelper.ANCHOR.MOUSE).fetch(el);
   */

  /**
   * Enum containing references to each position type.
   *
   * @for Squarespace.Widgets.PositionHelper
   * @property ANCHOR
   * @type {Object}
   */
  var ANCHOR = {
    /**
     * Centers the element around the position of the mouse.
     *
     * @for Squarespace.Widgets.PositionHelper
     * @property ANCHOR.MOUSE
     * @type {Function}
     */
    MOUSE: mouse,

    /**
     * Positions the element at the top left corner of the screen.
     *
     * @for Squarespace.Widgets.PositionHelper
     * @property ANCHOR.TOP_LEFT
     * @type {Function}
     */
    TOP_LEFT: topLeft,

    /**
     * Positions the element at the top right corner of the screen.
     *
     * @for Squarespace.Widgets.PositionHelper
     * @property ANCHOR.TOP_RIGHT
     * @type {Function}
     */
    TOP_RIGHT: topRight,

    /**
     * Positions the element at the bottom left corner of the screen.
     *
     * @for Squarespace.Widgets.PositionHelper
     * @property ANCHOR.BOTTOM_LEFT
     * @type {Function}
     */
    BOTTOM_LEFT: bottomLeft,

    /**
     * Positions the element at the bottom right corner of the screen.
     *
     * @for Squarespace.Widgets.PositionHelper
     * @property ANCHOR.BOTTOM_RIGHT
     * @type {Function}
     */
    BOTTOM_RIGHT: bottomRight,

    /**
     * Anchors the element to the right of a given element.
     *
     * @for Squarespace.Widgets.PositionHelper
     * @property ANCHOR.ELEMENT
     * @type {Function}
     */
    ELEMENT: element,

    /**
     * Positions the element in the center of the screen
     *
     * @for Squarespace.Widgets.PositionHelper
     * @property ANCHOR.CENTER
     * @type {Function}
     */
    CENTER: center
  };

  /**
   * A utility to help position elements based on a desired anchor. Returns
   * an object with one function based on the anchor to provide a unified API.
   *
   * @class PositionHelper
   * @constructor
   * @namespace Squarespace.Widgets
   * @example
   *     var posHelper = new PositionHelper(PositionHelper.ANCHOR.CENTER);
   *     var element = Y.one('.some-element');
   *     var styles = posHelper.fetch(element);
   *
   *     element.setStyles(styles);
   *
   *     // you can even pass your own positioning function
   *     posHelper = new PositionHelper(function(el) {
   *       return {
   *         top: Math.floor(Math.random() * 100),
   *         left: Math.floor(Math.random() * 100)
   *       };
   *     });
   *
   *     // this will position the element at a random (x, y) between (100, 100)
   *     styles.setStyles(posHelper.fetch(element));
   */
  Y.Squarespace.Widgets.PositionHelper = function(type, padding) {

    return {
      padding: padding || GENERAL_PADDING,

      /**
       * Fetch the styles to position the element as desired. See
       * {{#crossLink "Squarespace.Widgets.PositionHelper/ANCHOR:property"}}{{/crossLink}}
       * for options.
       *
       * @method fetch
       * @public
       * @param {Node} el The element to be positioned.
       * @param {Node} [anchor] An anchor element to guide the position of el.
       * @return {Object} An object of styles to set to get the desired position.
       */
      fetch: function(el, anchor) {
        return type.call(this, el, anchor);
      }
    };

  };

  Y.Squarespace.Widgets.PositionHelper.ANCHOR = ANCHOR;

}, '1.0', {
  requires: [
    'dom-base',
    'squarespace-damask-context-globals',
    'squarespace-ui-base'
  ]
});
