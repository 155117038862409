import { t } from 'shared/i18n';
import makeTextDynamic from 'shared/utils/makeTextDynamic';
import TemplateVersionUtils from 'shared/utils/TemplateVersionUtils';
import { isFluidImage, initializeFluidImageInVisitorView } from 'apps/App/components/Blocks/utils/fluidImageUtils';


/**
 * Binds the lightbox behavior for Image Block
 * @method
 * @param  {Y.Node} lightboxEl The element that is the image block
 * @param  {YUI} Y  A reference to the Y of the window that the image block is in.
 */
const bindLightbox = (lightboxEl, Y) => {
  const imgEl = lightboxEl.one('img[data-image]');
  if (!imgEl) {
    return;
  }

  const loader = imgEl.loader;
  if (!loader) {
    return;
  }

  // opts
  const options = {
    content: loader.getBareElement(),
    title: t("Image") };




  const description = lightboxEl.getAttribute('data-description');
  if (description) {
    options.meta = description;
  }

  const theme = lightboxEl.getAttribute('data-lightbox-theme');
  if (theme) {
    options.theme = theme;
  }

  // plug
  lightboxEl.plug(Y.Squarespace.Lightbox2Plug, {
    lightboxOptions: options });

};

/**
 * Initializes a block that has an image inside of it.
 * @method
 * @param  {Y.Node} el The <img /> contained in a block.
 * @param  {YUI} Y  A reference to the Y of the window that the image block is in.
 */
const initializeBasicBlockWithImage = (el, Y) => {
  el.setStyles({
    position: null,
    top: null,
    left: null,
    width: null,
    height: null });


  const fill = !!el.ancestor('.sized,.content-fill');

  if (fill) {
    let resizeHandle = Y.on('resize', function () {
      if (el.getDOMNode() && el.inDoc()) {
        el.fire('refresh');
      } else {
        resizeHandle.detach();
        resizeHandle = null;
      }
    }, Y.config.win);

    const imageBlockWrapperEl = el.ancestor('.image-block-wrapper');
    const layout = el.ancestor('.sqs-layout');

    if (imageBlockWrapperEl && layout && !layout.hasClass('editing')) {
      el.setStyles({
        maxWidth: 'none' });

    }
  }

  const fixedRatio = !!el.ancestor('.has-aspect-ratio');

  el.plug(Y.Squarespace.Loader2, {
    mode: fixedRatio || fill ? 'fill' : 'none',
    fixedRatio: fixedRatio });

};

/**
 * Initializes an image block
 * @method
 * @param  {Y.Node} el The <img /> contained in the image block.
 * @param  {YUI} Y  A reference to the Y of the window that the image block is in.
 */
const initializeImageBlock = (el, Y) => {
  initializeBasicBlockWithImage(el, Y);

  const blockEl = el.ancestor('.sqs-block');

  if (!blockEl) {
    return;
  }

  const lightboxEl = blockEl.one('.lightbox');

  if (lightboxEl) {
    bindLightbox(lightboxEl, Y);
  }

  if (!blockEl.hasClass('sqs-block-image')) {
    return;
  }

  const imageNode = el.getDOMNode();
  if (isFluidImage(imageNode)) {
    initializeFluidImageInVisitorView(imageNode);
  }

  let reloadImageLoader = false;

  // check width of image, set narrow width class if needed
  const imageWrapper = blockEl.one('.image-block-outer-wrapper');
  if (imageWrapper) {
    const shouldAddClass = imageWrapper.get('offsetWidth') < 415;
    imageWrapper.toggleClass('sqs-narrow-width', shouldAddClass);

    if (shouldAddClass) {
      reloadImageLoader = true;
    }
  }

  // initialize dynamic text
  const dynamicTextEl = blockEl.one('.sqs-dynamic-text-container');
  if (dynamicTextEl) {
    makeTextDynamic(dynamicTextEl.getDOMNode());
  }

  blockEl.addClass('sqs-text-ready');

  if (reloadImageLoader) {
    const lazyLoadingEnabled = TemplateVersionUtils.isSevenOne() && Y.config.win.ImageLoader.loadLazy;
    const loadImage = (
    lazyLoadingEnabled ? Y.config.win.ImageLoader.loadLazy : Y.config.win.ImageLoader.load).
    bind(Y.config.win.ImageLoader);
    loadImage(el);
  }
};

export default { initializeImageBlock, initializeBasicBlockWithImage };