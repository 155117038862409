import ImageMasks from '@sqs/enums/ImageMasks';

/*
The following "aspect-ratio" values actually represent the height when
the width is 100. The reason is that these were originally used as
percent padding values for the aspect-ratio padding trick.
*/
const onebyoneAR = 100;
const twobythreeAR = 150;
const threebytwoAR = 67;

const IMAGE_MASK_RATIOS = {
  [ImageMasks.CIRCLE]: onebyoneAR,
  [ImageMasks.DIAMOND]: onebyoneAR,
  [ImageMasks.BOTTOM_LEFT_CURVE]: onebyoneAR,
  [ImageMasks.RABBET]: onebyoneAR,
  [ImageMasks.FOUR_PETAL_FLOWER]: onebyoneAR,
  [ImageMasks.FOUR_POINT_STAR]: onebyoneAR,
  [ImageMasks.DESCENDING_RAMP]: onebyoneAR,
  [ImageMasks.DESCENDING_STAIRS]: onebyoneAR,
  [ImageMasks.OCTAGON]: onebyoneAR,
  [ImageMasks.STEPPED_CROSS]: onebyoneAR,
  [ImageMasks.TV]: onebyoneAR,
  [ImageMasks.OPEN_BOOK]: onebyoneAR,
  [ImageMasks.FLOWER]: onebyoneAR,
  [ImageMasks.EIGHT_SIDED_SHIELD]: onebyoneAR,
  [ImageMasks.ROUNDED_PENTAGON]: onebyoneAR,
  [ImageMasks.GEAR]: onebyoneAR,
  [ImageMasks.VERTICAL_OVAL]: twobythreeAR,
  [ImageMasks.HOURGLASS]: twobythreeAR,
  [ImageMasks.KEYSTONE]: twobythreeAR,
  [ImageMasks.VERTICAL_PARALLELOGRAM]: twobythreeAR,
  [ImageMasks.VERTICAL_RIGHT_LEANING_OVAL]: twobythreeAR,
  [ImageMasks.ARCHED_WINDOW]: twobythreeAR,
  [ImageMasks.VERTICAL_STACKED_CIRCLES]: twobythreeAR,
  [ImageMasks.POINTED_WINDOW]: twobythreeAR,
  [ImageMasks.AWARD]: twobythreeAR,
  [ImageMasks.NARROW_HORIZONTAL_STACKED_PILLS]: twobythreeAR,
  [ImageMasks.EGG]: twobythreeAR,
  [ImageMasks.NARROW_POW]: twobythreeAR,
  [ImageMasks.HORIZONTAL_STACKED_CIRCLES]: threebytwoAR,
  [ImageMasks.WIDE_VERTICAL_STACKED_PILLS]: threebytwoAR,
  [ImageMasks.WIDE_HORIZONTAL_STACKED_PILLS]: threebytwoAR,
  [ImageMasks.HORIZONTAL_OVAL]: threebytwoAR,
  [ImageMasks.HORIZONTAL_RIGHT_LEANING_OVAL]: threebytwoAR,
  [ImageMasks.WIDE_EIGHT_SIDED_SHIELD]: threebytwoAR,
  [ImageMasks.WIDE_OPEN_BOOK]: threebytwoAR,
};

export default IMAGE_MASK_RATIOS;
