"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TemplateCart", {
  enumerable: true,
  get: function get() {
    return _TemplateCart.default;
  }
});
Object.defineProperty(exports, "isCartPage", {
  enumerable: true,
  get: function get() {
    return _isCartPage.default;
  }
});

var _isCartPage = _interopRequireDefault(require("./shared/utils/isCartPage"));

var _TemplateCart = _interopRequireDefault(require("./components/TemplateCart"));