import CookieCutter from '@sqs/cookie-cutter';
import { t } from 'shared/i18n';
import * as appDomainUtils from 'shared/utils/appDomainUtils';
import * as UrlUtils from 'shared/utils/UrlUtils';

YUI.add('squarespace-data', function (Y) {


  // ------------------------------------------------------------------------------------------------
  // Data
  // ------------------------------------------------------------------------------------------------
  /**
   A collection of data methods for interacting with AJAX requests
   to Squarespace. Handles the session crumb.
    Config objects have properties including:
   * `secure` - prefix ssl on with squarespace if possible
   * `url` - the url
   * `success` - callback
   * `failure` - callback
   @class Data
   @static
   */


  if (Y.io) {

    Y.on('io:xdrReady', function (event) {
      Y.Data.TRANSPORT_READY = true;
    });

  }

  /**
   * Squarespace AJAX utilities.
   * @class Data
   * @static
   */
  Y.Data = {

    // Debug logs are redundant.
    // Chrome can log XHR requests in the console.
    // Most browsers have network tabs.
    // Turn this back on with: Y.Data.DEBUG = true
    DEBUG: false,

    TRANSPORT_READY: true,

    /**
     * @method addCrumb
     * @param  {String} url
     * @returns {String} url
     */
    addCrumb: function (url) {
      var paramValue = CookieCutter.get('crumb');
      if (paramValue) {
        var split = url.split('?');
        var params = split[1] ? Y.QueryString.parse(split[1]) : {};
        params.crumb = paramValue;
        url = split[0] + '?' + Y.QueryString.stringify(params);
      }
      return url;
    },

    /**
     * @method sendForm
     * @param  {Form} form
     * @param  {Object} config
     * @param  {Object} context
     */
    sendForm: function (form, config, context) {

      if (!config.data) {
        config.data = {};
      }

      var fd = form.getData();
      for (var k in fd) {
        var v = fd[k];
        if (Y.Lang.isUndefined(v) || Y.Lang.isNull(v) || Y.Lang.isObject(v) &&
        Y.Object.isEmpty(v))
        {
          continue;
        } else if (Y.Lang.isObject(v) && !Y.Lang.isArray(v)) {
          // don't encode arrays to account for getStringArrayParam() etc
          config.data[k] = Y.JSON.stringify(v);
        } else {
          config.data[k] = v;
        }
      }

      var dialog = {
        dialog: form };


      if (typeof config.failure === 'function') {
        //copy over failure method
        dialog.failure = config.failure;
      }
      config.failure = dialog;

      this.post(config, context);

    },

    cleanObjectForConsole: function (data) {

      var cleanObject = {};

      for (var entry in data) {

        var json = false;

        if (Y.Lang.isString(data[entry])) {

          try {
            json = data[entry] ? JSON.parse(data[entry]) : null; // test prevents uncatchable error in firefox
          } catch (e) {}

          if (json && Y.Lang.isObject(json)) {
            cleanObject[entry] = json;
          } else {
            cleanObject[entry] = data[entry];
          }

        } else {
          cleanObject[entry] = data[entry];
        }

      }

      return cleanObject;

    },

    /**
     * Perform a GET request
     * @method get
     * @param  {Object} config
     * @param  {Object} [context] context for callbacks
     */
    get: function (config, context) {
      this._go('GET', config, context);
    },
    /**
     * Perform a POST request
     * @method post
     * @param  {Object} config
     * @param  {Object} [context] context for callbacks
     */
    post: function (config, context) {
      this._go('POST', config, context);
    },
    /**
     * Perform a PUT request
     * @method put
     * @param  {Object} config
     * @param  {Object} [context] context for callbacks
     */
    put: function (config, context) {
      this._go('PUT', config, context);
    },
    /**
     * Perform a DELETE request
     * @method delete
     * @param  {Object} config
     * @param  {Object} [context] context for callbacks
     */
    del: function (config, context) {
      this._go('DELETE', config, context);
    },

    _go: function (method, config, context) {// normal form post/get

      config.context = context;
      config.method = method;

      if (Y.Data.DEBUG && __DEV__) {

        if (config.url.endsWith('/')) {
          console.warn('[Data]: URL ends with a slash, it probably shouldn\'t: ' + config.url);
        }

        if (config.data) {
          console.log('[Data] SEND:  ' + config.url + ' (' + config.method + '): ', Y.clone(
          config.data));
        } else {
          console.log('[Data] SEND:  ' + config.url + ' (' + config.method + ')');
        }

      }

      if (!config.success) {
        config.success = this._genericSuccess;
      }
      if (!config.responseFormat) {
        config.responseFormat = 'json';
      }
      if (config.secure && Static.SQUARESPACE_CONTEXT) {

        var validDomains = ['squarespace.com', 'sqsp.com', 'sqsp.net', 'squarespace.net', 'sqsp6.com'];
        var totallyValid = Y.Array.some(validDomains, function (validDomain) {
          return window.location.host.endsWith(validDomain);
        });

        // check that https is being used
        if (!UrlUtils.isSecure()) {
          totallyValid = false;
        }

        if (!totallyValid && !config.url.startsWith('https://')) {
          // rewrite URL to point to the secure domain
          config.url = 'https://' + appDomainUtils.getWebsiteDomain(Static.SQUARESPACE_CONTEXT.website) + config.url;
        }

        if (!totallyValid) {
          // we need to indicate that this is a cross domain request
          // to the underlying YUI transport
          config.xdr = {};
          config.xdr.credentials = true;
        }
      }

      for (var k in config.data) {
        if (Y.Lang.isUndefined(config.data[k])) {
          delete config.data[k];
          // console.log("Removing undefined field " + k + " = " + Y.Lang.isUndefined(config.data[k]));
        }
      }


      if (config.testResponse) {

        if (config.testResponseDelay) {

          Y.later(config.testResponseDelay, this, function onTestResponseTimeout() {
            Y.Data._connectionSuccessRouter(config, true, {
              responseText: Y.JSON.stringify(config.testResponse) });

          });

        } else {

          Y.Data._connectionSuccessRouter(config, true, {
            responseText: Y.JSON.stringify(config.testResponse) });


        }

      } else {
        var call = Y.bind(function () {
          config._url = config.url; // save the original url before adding crumb
          config.url = this.addCrumb(config.url);

          // Clean up the data
          if (method === 'POST' || method === 'PUT') {

            if (config.json) {

              if (Y.Lang.isObject(config.data)) {
                config.data = Y.JSON.stringify(config.data);
              }

              if (!Y.Lang.isObject(config.headers)) {
                config.headers = {};
              }

              config.headers['Content-Type'] = 'application/json';
              config.headers.charset = 'UTF-8';

            } else {

              // Clean up the properties appropriately.
              // eslint-disable-next-line no-lonely-if
              if (!Y.Lang.isString(config.data)) {
                for (var key in config.data) {
                  var v = config.data[key];
                  if (Y.Lang.isObject(v) && !Y.Lang.isArray(v)) {
                    // console.log('sanitized "' + k + '" param: ' + v + ' -> ' + Y.JSON.stringify(v));
                    config.data[key] = Y.JSON.stringify(v);
                  }
                }
              }
            }
          }

          var ioConfig = {
            method: method,
            arguments: config.arguments,
            data: config.data,
            on: {
              success: Y.bind(Y.Data._connectionSuccessRouter, Y.Data, config),
              failure: Y.bind(Y.Data._connectionFailureRouter, Y.Data, config) } };



          var configHeaders = config.headers;

          if (Y.Lang.isObject(configHeaders)) {
            ioConfig.headers = configHeaders;
          }

          if (config.xdr) {
            // this is a crossdomain request
            ioConfig.xdr = config.xdr;
            ioConfig.headers = {
              'Content-Type': t("application/x-www-form-urlencoded; charset=UTF-8") };



            ioConfig.data = Y.QueryString.stringify(config.data);
          }

          if (config.queue) {
            Y.io.queue(config.url, ioConfig);
          } else {
            Y.io(config.url, ioConfig);
          }

        }, Y.Data);

        if (config.testResponseDelay) {
          Y.later(config.testResponseDelay, this, call);

        } else {

          call();
        }
      }

    },

    _genericSuccess: function (data) {},

    _connectionSuccessRouter: function (config, status, data, args) {

      if (config.responseFormat === 'json') {

        var json = {};

        if (data.status !== 204 && data.status !== 1223) {// IE 9 translates 204 into 1223 -dbarber
          try {
            json = Y.JSON.parse(data.responseText);
          } catch (e) {
            this._error(config, {
              error: t("Internal Error: Unable to parse server response from ({url}) as JSON: {message}",

              {
                url: config.url,
                message: data.responseText }) });






            return;
          }
        }

        if (json.error) {

          this._error(config, json, args);

        } else {

          if (Y.Data.DEBUG && __DEV__) {
            console.log('[Data] RECV:  ' + config.url + ' (' + config.method + '): ', json);
          }

          config.success.apply(config.context, [json, args]);

        }

      } else if (config.responseFormat === 'raw') {

        if (Y.Data.DEBUG && __DEV__) {
          console.log('[Data] RECV:  ' + config.url + ' (' + config.method + '): ', {
            response: data.responseText });

        }

        config.success.apply(config.context, [data.responseText, args]);

      }

    },

    _connectionFailureRouter: function (config, status, data, args) {

      if (!data || data.readyState !== 4) {
        //readyState check: http://stackoverflow.com/questions/7287706/ie-9-javascript-error-c00c023f
        return;
      }

      var jsonStatusWhitelist = {
        400: true,
        401: true,
        500: true,
        502: true };


      if (config.responseFormat === 'json' && jsonStatusWhitelist[data.status]) {
        var json;
        try {
          json = Y.JSON.parse(data.responseText);
        } catch (e) {
          this._error(config, {
            error: t("Internal Error: Unable to parse server response from ({sub1}) as JSON: {sub2}",

            {
              sub1: config.url,
              sub2: data.responseText }) });






          return;
        }

        // json.billingErrorCode - BillingSystemError
        // json.error - FormFieldError
        // json.message - BadRequestException
        if (json.error || json.message || json.billingErrorCode) {
          this._error(config, json, args);
          return;
        }
      }

      var errorMessage = t("Unable to connect\u2026");


      switch (data.status) {
        case 401:
        case 403:
          errorMessage = t("Unauthorized request");


          break;
        case 500:
        case 501:
          errorMessage = t("Internal server error. Please try again later.");


          break;
        case 503:
          errorMessage = t("This service is temporarily unavailable. Please try again later.");






          break;
        default:
          if (config.retry) {
            config.retry -= 1;
            Y.later(200, this, function onConnectionRetryTimeout() {
              Y.Data._go(config.method, config, config.context);
            });
            return;
          }
          break;}


      // normalize network errors
      this._error(config, {
        status: data.status,
        error: errorMessage,
        errorKey: Y.Lang.isFunction(window.btoa) ? btoa(config.url) : '',
        network: true });

    },

    _error: function (config, json, args) {

      if (Y.Data.DEBUG && __DEV__) {
        console.error('[Data] ERROR: ' + config.url + ' (' + config.method + '): ', json);
      }

      // show error using the appropriate style

      if (json.loginRequired) {

        var doLogin = function () {
          // standard error -- login is required to access this endpoint
          if (window.SQUARESPACE_LOGIN) {
            window.SQUARESPACE_LOGIN.fire('login-required');
          } else if (__DEV__) {
            console.error('Session timed out -- login required.');
          }
        };

        if (Y.Lang.isFunction(config.failure)) {

          if (config.loginHasPriorityOnFailure) {
            doLogin();
          }

          config.failure.apply(config.context, [json, args]);

        } else {
          doLogin();
        }

      } else if (json.authorizationFail) {

        // standard error -- you are not authorized to access this endpoint
        new Y.Squarespace.SystemError(config.url, json, 'json').show();

      } else if (json.crumbFail && config._retry !== true && Y.Squarespace.Utils.areCookiesEnabled()) {

        // standard error -- crumb not correct -- retry
        CookieCutter.set('crumb', json.crumb, { path: '/' });
        config._retry = true;
        config.url = config._url;
        console.log('crumb check failed, will attempt a retry');
        this._go(config.method, config, config.context);

      } else if (typeof config.failure === 'function') {

        // user defined error callback

        config.failure.apply(config.context, [json, args]);

      } else if (Y.Lang.isObject(config.failure) && Y.Lang.isFunction(config.failure.failure)) {
        // this is a mess and needs to be fixed :/
        // when using Y.Data.sendForm the failure function is defined different
        config.failure.failure.apply(config.context, [json, args]);

      } else if (config.failure && config.failure.dialog && json.errors) {

        // dialogsystem errors -- route to dialog

        config.failure.dialog.showErrors(json.errors);
        if (config.failure.failure) {
          config.failure.failure.apply(config.context, [json, args]);
        }

      } else {

        // huge red unknown error
        new Y.Squarespace.SystemError(config.url, json, 'json').show();

      }
    } };


}, '1.0', {
  requires: [
  'io',
  'squarespace-system-error',
  'squarespace-util'] });