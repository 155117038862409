import "shared/i18n/configurations/commerce-cart.js";import "shared/i18n/configurations/product-reviews.js";import './consoleShim';
import 'shared/utils/polyfills';
import get from 'lodash/get';
import noop from 'lodash/noop';

import { Constants, PinterestSelectors } from '@sqs/websites-constants';
import { GoogleReCaptchaAPI } from '@sqs/universal-utils';
import BlockType from '@sqs/enums/BlockType';
import MapView from '@sqs/site-rendering/Maps/MapView';
import PageTypes from '@sqs/enums/PageTypes';
import WebsiteRole from '@sqs/enums/WebsiteRole';

import { isCartPage } from '@sqs/commerce-cart/utils';
import { initializeAccordionBlock } from 'shared/utils/AccordionBlockUtils';
import initializeMarqueeSVG from 'shared/utils/marquee/initializeMarqueeSVG';
import {
initializeAnimations,
polyfillAndObserve } from
'shared/utils/BlockAnimationsInitializer';
import { initializeMenuBlock } from 'shared/utils/MenuBlockInitializer';
import initializeTockBlock from 'shared/utils/TockBlockInitializer';
import {
manageCookies,
trackFormRender,
trackPageview } from
'shared/utils/census/CensusUtils';
import { mapModelToViewConfig } from 'shared/utils/GoogleMapsUtils';
import {
setResolvedWebsiteLocaleFromContext,
getResolvedWebsiteLocale } from
'shared/i18n/resolvedLocales';
import { setupToggleHandlers } from 'shared/utils/MemberAreaPricingToggleUtils';
import { t, setLocale } from 'shared/i18n';
import addLegacyPolyfills from 'shared/utils/polyfills/addLegacyPolyfills';
import ButtonBlockTrackingInitializer from 'shared/utils/census/ButtonBlockTrackingInitializer';
import { hoverBlockAlert } from 'shared/utils/CMSV7Events/collection';
import humanizeDate from 'shared/utils/humanizeDate';
import ImageBlockInitializer from 'shared/utils/ImageBlockInitializer';
import OpenTableBlockInitializer from 'shared/utils/OpenTableBlockInitializer';
import OpenTableV2BlockInitializer from 'shared/utils/OpenTableV2BlockInitializer';
import renderCharacterCounters from 'shared/components/forms/CharacterCounter';
import Singleton from 'shared/utils/Singleton';
import SocialGalleryBlockInitializer from 'shared/utils/SocialGalleryBlockInitializer';
import SummaryV2BlockInitializer, { calculateVideoPlaceholderAspectRatio } from 'shared/utils/SummaryV2BlockInitializer';
import TemplateVersionUtils from 'shared/utils/TemplateVersionUtils';
import WebsiteOverlaysUtils from 'shared/utils/WebsiteOverlaysUtils';
import { scaleAllTextInNode } from 'shared/utils/Rte/scaleAllTextInNode';

import globalActionsBootstrap from './globalActionsBootstrap';
import commonCookieBanner from './cookieBanner';
import commonDynamicText from './dynamicText';
import commonEmbeds from './embeds';
import commonNormalize from './normalize';
import { mixin as mercuryMixin, markReady } from './mercury';
import commonFonts from './fonts';

const isIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent);

/* global Y Squarespace */
/*sqs-codemod-ignore*/
window.Common = function Common(win) {
  if (win.COMMON_ALREADY_RAN) {
    console.warn('Detected second run, this is bad.');
    return;
  }

  win.COMMON_ALREADY_RAN = true;

  win.Static = win.Static || {};

  // -----------------------------------------------------------------------------
  // Common Functions
  // -----------------------------------------------------------------------------

  win.noop = noop;

  addLegacyPolyfills(win);

  /**
   * TEMPLATES USE THIS!!!!
   * See the various framework repos (e.g. bedford-framework) for instances.
   *
   * @global
   */
  win.Singleton = Singleton;

  /**
   * *************** WARNING *******************
   * DEPRECATED
   * Do not use for new components. Use Y.Base.create to make new classes.
   */
  win.Class = {

    create: function (protoDef) {
      let c;

      // create object based on constructor method

      if (protoDef.initialize) {
        c = function () {
          this.initialize.apply(this, arguments);
        };
      } else {
        c = function () {};
      }

      // assign prototype

      c.prototype = protoDef;

      // allow extension / specialization

      c.extend = function (extendedProtoDef) {// create a new class based on this one
        return win.Class.extend(this, extendedProtoDef);
      };

      c.specialize = function (extendedProtoDef, _parent) {// create a new class based on this one
        const sp = win.Class.specialize(this, extendedProtoDef);
        sp._parent = _parent;
        return sp;
      };

      return c;
    },

    extend: function (base, protoDef) {
      if (!protoDef.initialize) {// non standard -- constructor inheritance
        protoDef.initialize = base.prototype.initialize;
      }

      const c = win.Class.create(protoDef); // start with our derived methods
      // http://stackoverflow.com/questions/3911690
      var fnTest = /xyz/.test(function () {xyz;}) ? /\b_super\b/ : /.*/; // eslint-disable-line

      for (const property in base.prototype) {

        if (!c.prototype[property]) {// if it's missing?
          // just make it a reference to the base property
          c.prototype[property] = base.prototype[property];

        } else if (typeof c.prototype[property] === 'function' && fnTest.test(c.prototype[property])) {
          // if this property exists in our new class, it's a function, and it has usage of _super in it's definition.
          // put a layer on top of it, and modify the prototype of the property.
          c.prototype[property] = (function (name, fn) {
            return function () {
              const tmp = this._super;

              // Add a new ._super() method that is the same method
              // but on the super-class
              this._super = base.prototype[name];

              // The method only need to be bound temporarily, so we
              // remove it when we're done executing
              const ret = fn.apply(this, arguments);

              this._super = tmp;

              return ret;
            };
          })(property, c.prototype[property]);

          /*
           * Check out http://ejohn.org/blog/simple-javascript-inheritance/
           *
           *
           * It's pronunced Rez-ig.
           */

        }

      }

      c.prototype.superclass = base.prototype;

      return c;
    },

    specialize: function (base, protoDef) {
      const Cx = win.Class.extend(base, protoDef);
      return new Cx();
    } };


  // -----------------------------------------------------------------------------
  // Squarespace Loading
  // -----------------------------------------------------------------------------

  win.Squarespace = win.Squarespace || {};

  win.Squarespace.INITIALIZED = false;

  win.Squarespace.SQUARESPACE_INITIALIZED_ONCE = false;

  mercuryMixin(win);

  /**
   * Should we redirect the user to config to view this page?
   *
   * @method shouldRedirectToConfig
   * @param  {Window} win The window to use.
   * Do not redirect if trying not to. Used for mobile preview et al.
   */
  win.Squarespace.shouldRedirectToConfig = function (win) {// eslint-disable-line no-shadow
    const noRedirectSessionKey = 'noredirect';
    const noRedirectParams = [
    'noredirect'];


    // Never redirect to /config if connecting with Local Dev Server
    if (win.location.hostname === 'localhost') {
      return false;
    }

    if (this.getSession(noRedirectSessionKey, win)) {
      return false;
    }

    for (let i = 0; i < noRedirectParams.length; i++) {
      if (win.location.search.indexOf(noRedirectParams[i]) !== -1) {
        this.setSession(noRedirectSessionKey, true, win);
        return false;
      }
    }

    return (
      win.Static.SQUARESPACE_CONTEXT.websiteSettings &&
      win.Static.SQUARESPACE_CONTEXT.authenticatedAccount &&
      !win.Static.SQUARESPACE_CONTEXT.website.developerMode &&
      !win.Static.SQUARESPACE_CONTEXT.website.cloneable &&
      !this._userHasViewerRole(win.Static.SQUARESPACE_CONTEXT.websiteRoles) &&
      win.top === win);

  };

  /**
   * @param {string} key The key that the data is stored on
   * @param {Window} win The window to use.
   */
  win.Squarespace.getSession = function (name, win) {// eslint-disable-line no-shadow
    try {
      if (win.sessionStorage && win.sessionStorage[name]) {
        return win.sessionStorage[name];
      }
    } catch (ex) {
      return null;
    }
    return null;
  };

  /**
   * @method setSession
   * @param  {String} key  The key that the data will be stored on
   * @param  {String} value The value to set
   * @param  {Window} win The window to use.
   */
  win.Squarespace.setSession = function (name, value, win) {// eslint-disable-line no-shadow
    try {
      if (win.sessionStorage) {
        win.sessionStorage[name] = value;
        return { success: true, error: null };
      }
    } catch (exception) {
      return { success: false, error: exception };
    }

    return { success: false, error: 'Browser does not support session storage' };
  };

  /**
   * From quirksmode.org. This is a very lightweight cookie setter,
   * that you should use when you don't have YUI (e.g. before we create
   * window.Y in Squarespace.load)
   *
   * @method createCookie
   * @param  {String} name  The cookie name
   * @param  {String} value The value to set
   * @param  {Number} days How long should this hang around? Can be null.
   * @param  {String} path The path to store this cookie on.
   * @param  {Window} win The window to use.
   */
  win.Squarespace.createCookie = function (name, value, days, path, win) {// eslint-disable-line no-shadow, max-params
    let expires;

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    } else {
      expires = '';
    }

    win.document.cookie = name + '=' + value + expires + '; path=' + path;
  };

  /**
   * This is called in a footer <script> block by the page that is being
   * rendered.
   *
   * @param {Window} targetWindow The window of the page being rendered
   */
  win.Squarespace.load = function (targetWindow) {
    if (!targetWindow) {
      console.error('Squarespace.load running with an assumed window context! Results may vary!');
      targetWindow = window;
    }

    if (this.shouldRedirectToConfig(targetWindow)) {
      if (targetWindow.stop) {
        targetWindow.stop();
      }

      // set a cookie to expand the frame.
      this.createCookie(Constants.PREVIEW_FRAME_EXPANDED_COOKIE, true, null, '/', targetWindow);

      const frameUrl = encodeURIComponent(targetWindow.location.pathname + targetWindow.location.search);
      targetWindow.location.href = '/config/?frameUrl=' + frameUrl;
      return;
    }

    globalActionsBootstrap(targetWindow);

    // Pre-images/stylesheets loaded
    // We want to resize dynamic text immediately since it only depends on
    // layout, and not on YUI or images. This prevents a flash of unsized text
    // while in the CMS.
    targetWindow.document.addEventListener('DOMContentLoaded', function commonDomContentLoaded() {
      commonDynamicText(targetWindow);
    });

    // create YUI
    targetWindow.Y = targetWindow.YUI((function () {
      const config = {};
      for (const key in targetWindow.YUI_CONFIG) {
        config[key] = targetWindow.YUI_CONFIG[key];
      }
      // config.targetWindow = targetWindow;
      // config.doc = targetWindow.document;
      // config.scriptDoc = targetWindow.document; // for targetWindow.Y.Get
      // config.linkDoc = targetWindow.document; // for targetWindow.Y.Get
      return config;
    })());

    commonNormalize(targetWindow);

    // COMPONENTS AVAILABLE BEFORE DOMREADY
    // Removing this will break a number of dev sites
    targetWindow.Y.use('squarespace-image-loader');

    // PUBLIC API
    targetWindow.Y.use('squarespace-public-api');

    // Bind DOMReady, in the event that the {squarespace-footers}
    // isn't included.
    // this should only happen on developer sites.
    targetWindow.Y.on(
    'domready',
    targetWindow.Squarespace.afterBodyLoad,
    targetWindow.Squarespace);


    // common should always render with the website local
    // this `set` function unfortunately returns a value
    const locale = setResolvedWebsiteLocaleFromContext();
    setLocale(__TRANSLATION_LOCALE__, locale);
  };

  win.Squarespace.injectRollups = function (targetWindow) {
    // If in frame
    try {
      if (targetWindow.top.YUI !== targetWindow.YUI) {
        for (const mod in targetWindow.top.YUI.Env.mods) {
          if (!targetWindow.YUI.Env.mods.hasOwnProperty(mod)) {
            targetWindow.YUI.Env.mods[mod] = targetWindow.top.YUI.Env.mods[mod];
          }
        }

        const rollupsToInject = targetWindow.top.Static.SQUARESPACE_CONTEXT.frameRollups;
        for (const key in rollupsToInject) {
          if (rollupsToInject[key].css) {
            this._injectStylesheetFromTopFrame(rollupsToInject[key].css, targetWindow.document);
          }
        }
      }
    } catch (e) {
      //not in frame
    }
  };

  win.Squarespace._injectStylesheetFromTopFrame = function (cssPath, document) {
    const stylesheetHTML = '<link rel="stylesheet" href="' + cssPath + '" type="text/css" />';
    if (!document.head) {
      document.write(stylesheetHTML);
    } else {
      win.Y.one(document.head).append(stylesheetHTML);
    }
  };

  /**
   * This is a hacky, and deliberately specific fix for the squarespace-commerce-email-preview
   * rollup. Due to incorrect use of Rollup::toString on the server, the rollup packs's name
   * gets mangled into squarespace-commerce_email_preview, which causes Y.use to throw a
   * "yui: NOT loaded" warning. Since rollups are soon getting replaced with webpack, fixing
   * the root problem is IMO more risk than it's worth.
   * - japplebaum 1/6/2015
   */
  const emailPreviewKey = 'squarespace-commerce_email_preview';
  const rollupsToRemove = ['squarespace-error_reporter'];

  function filterAndFixRollups(rollups) {
    const modifiedRollups = win.Y.clone(rollups);
    const emailPreviewRollup = modifiedRollups[emailPreviewKey];

    if (win.Y.Lang.isValue(emailPreviewRollup)) {
      modifiedRollups['squarespace-commerce-email-preview'] = emailPreviewRollup;
      delete modifiedRollups[emailPreviewKey];
    }

    for (const key of rollupsToRemove) {
      if (modifiedRollups.hasOwnProperty(key)) {
        delete modifiedRollups[key];
      }
    }

    return modifiedRollups;
  }

  /**
    * Converts all dates into a human readable format
    * Operates in conjunction with JSON-Template formatter 'timesince'
    * @method humanizeAllDates
    * @param className {String} The class of the elements containing the dates to convert
    */
  win.Squarespace._humanizeAllDates = function (className) {
    Y.all(className).each(function (node) {
      const date = parseInt(node.getAttribute('data-date'), 10);
      if (!Y.Lang.isNumber(date)) {
        console.warn('Invalid date on node: ', node);
        return;
      }
      node.set('innerHTML', humanizeDate(date).capitalize());
    });
  };

  /**
   * window domready callback
   */
  win.Squarespace.afterBodyLoad = function () {
    const Y = win.Y;
    const isLoading = Y.config.doc.readyState === 'loading';
    const insidePreview = Y.Squarespace.Utils.isInDamaskFrame();

    // In react-first YUI in top frame might not yet be initialized.
    // We must delay afterBodyLoad and do the bare minimum use('squarespace-common').
    // ConfigWebsite will call afterBodyLoad again when YUI is initialized in top window.
    if (!isLoading && insidePreview && win.top.YUI_NOT_YET_LOADED) {
      // load the bare minimum to prevent site-bundle from throwing exceptions.
      Y.use(
      Object.keys(filterAndFixRollups(Y.config.win.SQUARESPACE_ROLLUPS)),
      noop);

      // YUI in the frame loaded before YUI in the top window. Wait to initialize.
      return;
    }

    // lets be real tho ... are we really ready?
    if (isLoading && insidePreview) {
      Y.once('domready', Y.config.win.Squarespace.afterBodyLoad, Y.config.win.Squarespace);
      return;
    }

    // this is to prevent afterBodyLoad() from being
    // called twice.
    if (this.AFTER_BODY_LOADED) {
      // console.trace('afterBodyLoad called for the second time. no!');
      return;
    }

    this.AFTER_BODY_LOADED = true;

    if (insidePreview) {
      this.injectRollups(win);
    }

    // basic rollup
    Y.use((function (rollups) {
      const rollupsToUse = Y.Object.keys(filterAndFixRollups(rollups));
      // only add additional rollups on inside of the frame
      if (insidePreview) {
        // these are additional rollups that we want to load in the frame
        // squarespace-management contains the ContentModeController, which coordinates
        // the editing logic inside of the frame
        const ADDITIONAL_ROLLUPS = ['squarespace-management'];

        ADDITIONAL_ROLLUPS.forEach(function (name) {
          if (rollupsToUse.indexOf(name) === -1) {
            rollupsToUse.push(name);
          }
        });
      }
      return rollupsToUse;
    })(Y.config.win.SQUARESPACE_ROLLUPS), win.Squarespace.globalInit);
  };

  win.Squarespace.addLoadTrigger = function (selector, modules) {
    const Y = win.Y;
    if (!Y.one(selector)) {
      return;
    }

    // is this in a frame?
    if (Y.Squarespace.Utils.isInDamaskFrame()) {
      const alreadyAvailableMods = [];
      // do the modules exist already?
      Y.Array.each(modules, function (mod) {
        if (Y.Lang.isObject(win.YUI.Env.mods[mod])) {
          alreadyAvailableMods.push(mod);
        }
      });

      modules = Y.Array.filter(modules, function (mod) {
        return !alreadyAvailableMods.includes(mod);
      });

      // console.time('[common:addLoadTrigger] Load Trigger Injection');

      // make sure we inject the CSS + javascript for these modules
      Y.Array.each(alreadyAvailableMods, function (module) {
        Y.use(module);

        // the css
        const rollup = Y.config.win.Static.SQUARESPACE_CONTEXT.rollups[module];
        if (!rollup) {
          console.warn("Unable to load module \'" + module + "\' for trigger \'" + selector + "\'");
          return;
        }

        if (rollup.css) {
          const stylesheets = rollup.css.split(',');
          Y.Array.each(stylesheets, function (filename) {
            this._injectStylesheetFromTopFrame(filename, Y.config.doc);
          }, this);
        }

      }, this);

      if (modules.length > 0) {
        console.error('addLoadTrigger',
        'There are modules that were requested, that were not available in the Seven top frame.',
        modules);
      }

    }

    if (modules.length === 0) {
      return;
    }

    // figure out where the resources are
    const loadSetJS = [];
    const loadSetCSS = [];
    for (let i = 0, im = modules.length; i < im; ++i) {

      const module = modules[i];

      const rollup = Y.config.win.Static.SQUARESPACE_CONTEXT.rollups[module];
      if (!rollup) {
        console.warn("Unable to load module \'" + module + "\' for trigger \'" + selector + "\'");
        return;
      }

      if (rollup.js) {
        Y.Array.each(rollup.js.split(','), function (js) {
          if (js.length > 0) {
            loadSetJS.push(js);
          }
        });
      }

      if (rollup.css) {
        Y.Array.each(rollup.css.split(','), function (css) {
          if (css.length > 0) {
            loadSetCSS.push(css);
          }
        });
      }

    }

    // load and initialize the modules
    Y.Get.load([].concat(loadSetJS, loadSetCSS), {
      win: Y.config.win },
    function (err) {
      Y.use(modules);
    });
  };

  /**
   * Initializes a content page (init blocks, hit metrics, etc.) and marks it
   * ready.
   * This is called as a callback after Y.Use in afterBodyLoad
   */
  win.Squarespace.globalInit = function (Y) {/* eslint-disable-line complexity */
    commonNormalize(win);

    // This body class prevents animations styles to leak
    // into websites having the Global Animations locked enabled.
    if (TemplateVersionUtils.isSevenOne()) {
      Y.one('body').addClass('seven-one-global-animations');
    }

    // esc manager -- damask does not have escmanager
    if (Y.Squarespace.EscManager) {
      Y.Squarespace.EscManager.attach(Y.one(Y.config.win));
    }

    // register the hit
    manageCookies();
    trackPageview();

    // humanize dates
    Y.config.win.Squarespace._humanizeAllDates('.timesince');

    // initialize layout blocks
    Y.config.win.Squarespace.initializeLayoutBlocks(Y);

    // initialize Image Block dynamic elements
    Y.config.win.Squarespace.initializeImageBlockDynamicElements(Y);

    // initialize global lightbox
    Y.config.win.Squarespace.initializeGlobalLightbox(Y);

    // initialize video
    Y.config.win.Squarespace.initializeVideo(Y);
    Y.config.win.Squarespace.initializeNativeVideo(Y);

    // initialize the Commerce cart page
    Y.config.win.Squarespace.initializeCartPage(Y);

    Y.config.win.Squarespace.initializeSvgs(Y);

    const disqusShortname = win.Static.SQUARESPACE_CONTEXT.websiteSettings.disqusShortname;
    if (Y.Lang.isValue(disqusShortname) && disqusShortname !== '') {
      Y.config.win.Squarespace.initializeDisqusCommentLinks(Y);
    }

    // notify of logout?
    if (Y.config.doc.location.href.indexOf('logout=true') !== -1) {
      Y.on('domready', function () {
        new Y.Squarespace.Widgets.Information({ /* eslint-disable-line no-new */
          'strings.title': t("Logout Successful"),


          'strings.message': t("You have been successfully logged out.") });



      });
    }

    // conditionally load comments if we see any applicable elements
    if (win.Static.SQUARESPACE_CONTEXT.websiteSettings.commentsEnabled) {
      // is this a demo collection?
      const demoCollections = win.Static.SQUARESPACE_CONTEXT.demoCollections;
      const collectionId = win.Static.SQUARESPACE_CONTEXT.collectionId;
      let isDemoCollection = false;

      if (
      Y.Lang.isArray(demoCollections) &&
      Y.Lang.isValue(collectionId))
      {
        const demoCollectionIds = Y.Array.map(demoCollections, function (col) {
          return col.collectionId;
        });

        if (demoCollectionIds.indexOf(collectionId) !== -1) {
          isDemoCollection = true;
        }
      }

      if (!isDemoCollection) {
        Y.config.win.Squarespace.addLoadTrigger('.squarespace-comments', [
        'squarespace-dialog',
        'squarespace-comments']);

      }
    }

    let LOGGED_IN;
    if (Y.Squarespace.Utils.isInDamaskFrame()) {
      LOGGED_IN = win.top.SQUARESPACE_LOGIN && win.top.SQUARESPACE_LOGIN.isLoggedIn();
    } else {
      LOGGED_IN = win.SQUARESPACE_LOGIN && win.SQUARESPACE_LOGIN.isLoggedIn();
    }

    const shouldShowAnnouncementBar = win.Static.SQUARESPACE_CONTEXT.showAnnouncementBar;

    if (LOGGED_IN || shouldShowAnnouncementBar) {
      Y.config.win.Squarespace.addLoadTrigger(
      'body',
      ['squarespace-announcement-bar']);

    }

    const shouldShowSSBadge = WebsiteOverlaysUtils.shouldShowSSBadge({
      websiteSettings: win.Static.SQUARESPACE_CONTEXT.websiteSettings });


    const shouldShowMobileInformationBar = WebsiteOverlaysUtils.shouldShowMobileInformationBar({
      win: Y.config.win,
      isMobile: Y.UA.mobile,
      settings: win.Static.SQUARESPACE_CONTEXT.websiteSettings.mobileInfoBarSettings });


    const shouldShowLicensedAssetsBar = WebsiteOverlaysUtils.shouldShowLicensedAssetsBar({
      YNodeBody: Y.one(Y.config.doc.body) });


    if (LOGGED_IN || shouldShowSSBadge || shouldShowMobileInformationBar || shouldShowLicensedAssetsBar) {
      Y.config.win.Squarespace.addLoadTrigger(
      'body',
      ['squarespace-website-overlays-manager']);

    }

    const popupSettings = win.Static.SQUARESPACE_CONTEXT.websiteSettings.popupOverlaySettings;
    const shouldShowPopupOverlay = popupSettings && WebsiteOverlaysUtils.shouldShowPopupOverlay({
      settings: popupSettings,
      context: {
        pageType: win.Static.SQUARESPACE_CONTEXT.pageType,
        collection: win.Static.SQUARESPACE_CONTEXT.collection },

      isMobile: Y.UA.mobile,
      urlSearch: window.location.search });


    if (LOGGED_IN || shouldShowPopupOverlay) {
      Y.config.win.Squarespace.addLoadTrigger(
      'body',
      ['squarespace-popup-overlay']);

    }

    if (win?.Static?.SQUARESPACE_CONTEXT?.pageType === PageTypes.REVIEWS_REQUEST) {
      import(
      /* webpackChunkName: 'async-commerce-product-reviews' */'@sqs/product-reviews').
      then(({ bootstrapProductReviewsPage }) => {
        bootstrapProductReviewsPage();
      });
    }

    // -------------------------------------------------------------------------
    // Cookie Banner
    // Y.config.win.document.body is the site or frame body here
    // -------------------------------------------------------------------------

    commonCookieBanner(Y);

    // initialize audio
    Y.config.win.Squarespace.addLoadTrigger(
    '.sqs-audio-embed, .sqs-audio-playlist, .sqs-album-block',
    ['squarespace-audio-player']);


    // initialize pinterest
    if (
    Y.Lang.isObject(win.Static.SQUARESPACE_CONTEXT.websiteSettings.pinterestOverlayOptions) &&
    win.Static.SQUARESPACE_CONTEXT.websiteSettings.pinterestOverlayOptions.mode !== 'disabled')
    {
      Y.config.win.Squarespace.addLoadTrigger(PinterestSelectors.TRIGGER_SELECTORS.join(','),
      ['squarespace-pinterest']);
    }

    // initialize products collection v2.0
    Y.config.win.Squarespace.addLoadTrigger(
    '.collection-type-products.view-list .products-collection-v2',
    ['squarespace-products-collection-list-v2']);


    Y.config.win.Squarespace.addLoadTrigger(
    '.collection-type-products.view-item .products-collection-v2',
    ['squarespace-products-collection-item-v2']);


    // initialize image zoom
    Y.config.win.Squarespace.addLoadTrigger(
    '.sqs-image-zoom-area',
    ['squarespace-image-zoom']);


    // initialize blog collection
    Y.config.win.Squarespace.addLoadTrigger(
    '.collection-type-blog.view-list .sqs-blog-list',
    ['squarespace-blog-collection-list']);


    // initialize gallery collection
    Y.config.win.Squarespace.addLoadTrigger(
    '.collection-type-gallery .sqs-gallery-list',
    ['squarespace-gallery-collection-list']);


    // initialize calendars
    Y.config.win.Squarespace.addLoadTrigger(
    '.sqs-block-calendar',
    ['squarespace-calendar-block-renderer']);


    // initialize events collection
    Y.config.win.Squarespace.addLoadTrigger(
    '.collection-type-events.view-list',
    ['squarespace-events-collection']);


    // initialize product quick view
    Y.config.win.Squarespace.addLoadTrigger(
    '.sqs-product-quick-view-button[data-id]',
    ['squarespace-product-quick-view', 'squarespace-image-zoom', 'squarespace-products-collection-item-v2']);


    // initialize chart blocks
    Y.config.win.Squarespace.addLoadTrigger(
    '.sqs-block-chart',
    ['squarespace-chartjs-helpers']);


    // initialize tourdates blocks
    Y.config.win.Squarespace.addLoadTrigger(
    '.sqs-block-tourdates[data-block-json]',
    ['squarespace-tourdates']);


    // initialize search page
    Y.config.win.Squarespace.addLoadTrigger(
    '.sqs-search-page',
    ['squarespace-search-page']);


    // initialize simple like
    Y.config.win.Squarespace.addLoadTrigger(
    '.sqs-simple-like',
    ['squarespace-simple-liking']);


    // initialize share buttons (v1)
    Y.config.win.Squarespace.addLoadTrigger(
    '.squarespace-social-buttons',
    ['squarespace-social-buttons']);


    // initialize share buttons (v2)
    if (Y.one('.sqs-share-buttons')) {
      import( /* webpackChunkName: "share-buttons" */'./share-buttons').then(
      ({ initializeShareButtons, destroyShareButtons }) => {
        win.Squarespace.onInitialize(Y, function () {
          initializeShareButtons(Y);
        });

        win.Squarespace.onDestroy(Y, function () {
          destroyShareButtons(Y);
        });
      });

    }

    // initialize animations
    initializeAnimations(Y.config.win);
    // listen for messages from content-mode.js to toggle animations in
    // expanded preview
    function globalOnSyncBodyClasses(nextWindow) {
      polyfillAndObserve(nextWindow);
    }
    Y.Global.on('_syncBodyClasses', globalOnSyncBodyClasses);

    // Ready to go!
    markReady(Y);
  };

  win.Squarespace.initializeDisqusCommentLinks = function (Y) {
    const disqusHash = '#disqus_thread';
    const sqsHashTemplate = '#comment-{id}';
    const commentLinks = Y.all('.sqs-comment-link');
    const href = 'href';
    const id = 'data-id';
    let createUrl;
    const shortname = win.Static.SQUARESPACE_CONTEXT.websiteSettings.disqusShortname;
    const disqusEnabled = Y.Lang.isString(shortname) && shortname !== '';

    if (disqusEnabled) {
      createUrl = function (node) {
        return win.Static.SQUARESPACE_CONTEXT.website.baseUrl + node.getAttribute(href) + disqusHash;
      };
    } else {
      createUrl = function (node) {
        return win.Static.SQUARESPACE_CONTEXT.website.baseUrl +
        node.getAttribute(href) +
        Y.substitute(sqsHashTemplate, {
          id: node.getAttribute(id) });

      };
    }

    commentLinks.each(function (n) {
      n.setHTML('Comments');
      n.setAttribute(href, createUrl(n));
    }, this);

    if (disqusEnabled) {
      win.disqus_shortname = shortname; // eslint-disable-line camelcase
      Y.Get.script('//' + shortname + '.disqus.com/count.js');
    }
  };

  win.Squarespace.initializeCollectionPages = function (Y, root) {

    // Events Collection already loaded just initialize
    if (Y.Squarespace.EventsCollection) {
      root.all('.sqs-events-collection-list').each(Y.Squarespace.EventsCollection.initializeListView);
      root.all('.sqs-events-collection-calendar').each(Y.Squarespace.EventsCollection.initializeCalendarView);
    } else {
      // Haven't loaded the tourdates yet, try again
      Y.config.win.Squarespace.addLoadTrigger(
      '.sqs-events-collection-list, .sqs-events-collection-calendar',
      ['squarespace-events-collection']);

    }
  };

  win.Squarespace.initializeLayoutBlocks = function (Y, root) {
    if (!root) {
      root = Y;
    }

    // initialize summary-v2 blocks
    this.initializeSummaryV2Block(Y);

    // initialize embeds and videos. These classes may not be mutually
    // exclusive.
    root.all([
    '.sqs-block-video',
    '.sqs-block-embed'].
    join(',')).each(function (blockEl) {
      commonEmbeds(blockEl, Y, win);
    });

    root.all('.sqs-block-image img[data-image]').each(function (el) {
      ImageBlockInitializer.initializeImageBlock(el, Y);
    });

    const blockImagesToInit = [
    '.sqs-block-product img[data-image]'];

    if (Y.config.win.Squarespace.SQUARESPACE_INITIALIZED_ONCE) {
      blockImagesToInit.push('.sqs-block-collectionlink img[data-image]');
    }
    root.all(blockImagesToInit.join(',')).each(function (el) {
      ImageBlockInitializer.initializeBasicBlockWithImage(el, Y);
    });

    // initialize soundcloud
    root.all('.sqs-block-soundcloud').each(function (el) {
      win.Squarespace.initializeSoundcloudBlock(el, Y);
    });

    // initialize maps
    root.all('.sqs-block-map[data-block-json]').each((node) => {
      const config = Y.JSON.parse(node.getAttribute('data-block-json'));
      const mapConfig = mapModelToViewConfig(config);
      const locale = getResolvedWebsiteLocale();

      node.setAttribute('data-animation-role', 'image');

      if (TemplateVersionUtils.isSevenOne()) {
        mapConfig.ui = Y.merge({}, mapConfig.ui, {
          scrollwheel: false });

      }

      MapView.createView(
      mapConfig.ui,
      mapConfig.location,
      node.one('.sqs-block-content').getDOMNode(),
      locale);

    });

    // Captchas, form blocks, newsletter blocks
    win.Squarespace.initializeFormBlocks(root, Y);

    root.all('.sqs-block-newsletter').each(function (node) {
      win.Squarespace.initializeNewsletterBlock(node, Y);
    });

    // initialize donate buttons
    root.all('.sqs-block-donation').each(function (node) {
      win.Squarespace.initializeDonationButton(node, Y);
    });

    // initialize accordion blocks
    document.querySelectorAll('.sqs-block-accordion').forEach(function (node) {
      win.Squarespace.initializeAccordionBlock(node);
    });

    // initialize marquee blocks
    document.querySelectorAll('.sqs-block-marquee').forEach(function (node) {
      win.Squarespace.initializeMarqueeBlock(node);
    });

    // initialize scaledText blocks
    document.querySelectorAll('.sqs-block-html .sqsrte-scaled-text-container:first-of-type').forEach(function (node) {
      win.Squarespace.initializeScaledText(node);
    });

    // initialize all button blocks
    root.all('.sqs-block-button').each(function (node) {
      win.Squarespace.initializeButtonBlock(node, Y);
    });

    // initialize member area pricing options toggles
    root.all('.sqs-block-member-area').each(function (node) {
      win.Squarespace.initializeMemberAreaBlock(node, Y);
    });

    // initialize all aspect ratioed blocks
    win.Squarespace.initializeAspectRatioBlocks(Y);

    // initialize galleries
    const galleryBasedBlocksSelector = [
    '.sqs-block-gallery',
    '.sqs-block-video'].
    join(',');

    root.all(galleryBasedBlocksSelector).each(function (el) {
      Y.SQS.Gallery.Manager.initializeBlock(el);
    });

    // initialize social galleries
    const socialGalleryBasedBlocksConfig = [
    { selector: '.sqs-block-flickr', blockType: BlockType.FLICKR },
    { selector: '.sqs-block-instagram', blockType: BlockType.INSTAGRAM },
    { selector: '.sqs-block-fivehundredpix', blockType: BlockType.FIVEHUNDREDPIX }];


    socialGalleryBasedBlocksConfig.forEach(function (config) {
      root.all(config.selector).each(function (el) {
        const container = el.one('.sqs-gallery-container');
        const asyncRenderingEnabled = container && container.getAttribute('data-async-rendering-enabled') === 'true';
        if (asyncRenderingEnabled) {
          SocialGalleryBlockInitializer(el, config.blockType).then(function () {
            Squarespace.initializeAspectRatioBlocks(Y);
            Y.SQS.Gallery.Manager.initializeBlock(el);
          });
        } else {
          Y.SQS.Gallery.Manager.initializeBlock(el);
        }
      });
    });

    // initialize opentable blocks
    root.all('.sqs-block-opentable').each(function (node) {
      win.Squarespace.initializeOpenTableBlock(node, Y);
    });

    // and opentable v2 blocks
    root.all('.sqs-block-opentable-v2').each(function (node) {
      win.Squarespace.initializeOpenTableV2Block(node.getDOMNode());
    });

    // initialize tock block
    root.all('.sqs-block-tock').each(function (node) {
      initializeTockBlock(node.getDOMNode());
    });

    // Audio Player already loaded, just initialize
    if (Y.Squarespace.Widgets.AudioPlayer) {
      root.all('.sqs-audio-embed').each(Y.Squarespace.Widgets.AudioPlayer.initializeNode);
    } else {
      // Haven't loaded the audio player yet, try again
      Y.config.win.Squarespace.addLoadTrigger(
      '.sqs-audio-embed, .sqs-audio-playlist, .sqs-album-block',
      ['squarespace-audio-player']);

    }
    // Tourdates already loaded just initialize
    if (Y.Squarespace.Tourdates) {
      root.all('.sqs-block-tourdates[data-block-json]').each(Y.Squarespace.Tourdates.initializeNode);
    } else {
      // Haven't loaded the tourdates yet, try again
      Y.config.win.Squarespace.addLoadTrigger(
      '.sqs-block-tourdates[data-block-json]',
      ['squarespace-tourdates']);

    }

    if (Y.Squarespace.CalendarBlockRenderer) {
      root.all('.sqs-block.calendar-block[data-block-json]').each(Y.Squarespace.CalendarBlockRenderer.initializeNode);
    } else {
      Y.config.win.Squarespace.addLoadTrigger(
      '.sqs-block.calendar-block[data-block-json]',
      ['squarespace-calendar-block-renderer']);

    }

    // initialize Zola blocks
    const firstZolaBlockOnPage = root.one('.sqs-block-zola');
    if (firstZolaBlockOnPage) {
      this.initializeZolaBlocks(firstZolaBlockOnPage, Y);
    }

    // initialize Acuity blocks
    const firstAcuityBlockOnPage = root.one('.sqs-block-acuity');
    if (firstAcuityBlockOnPage) {
      this.initializeAcuityBlocks(firstAcuityBlockOnPage, Y);
    }

    this.initializeSearchBlock(Y);
    this.initializeChartBlock(Y);
    this.initializeSocialLinks(Y);
    this.initializeMenuBlock(Y);
  };

  //replaces relative references of social link SVGs with absolute references on firefox
  win.Squarespace.initializeSocialLinks = function (Y) {
    if (Y.UA.gecko <= 0) {
      return;
    }
    Y.all('use[class^="SocialLinks"][*|href]').each(function (el) {
      const xlinkHref = el.getAttribute('xlink:href');
      const fragmentIndex = xlinkHref.indexOf('#');
      if (fragmentIndex !== -1) {
        el.setAttribute('xlink:href',
        Y.config.win.location.origin + Y.config.win.location.pathname + xlinkHref.substr(fragmentIndex));
      }
    });
  };

  win.Squarespace.initializeMenuBlock = function (Y) {
    Y.all('.menu-wrapper').each(function (el) {
      initializeMenuBlock(el._node);
    });
  };

  win.Squarespace.initializePageContent = function (Y, root) {
    win.Squarespace.initializeLayoutBlocks(Y, root);
    win.Squarespace.initializeCollectionPages(Y, root);
  };

  win.Squarespace.initializeImageBlockDynamicElements = function (Y) {
    const yWin = Y.config.win; // eslint-disable-line no-shadow
    function refreshDynamicEls() {
      // set narrow width class when appropriate
      Y.all('.image-block-v2').each(function (el) {
        el.toggleClass('sqs-narrow-width', el.get('offsetWidth') < 415);
        const imageNode = el.one('img');
        if (imageNode) {
          yWin.ImageLoader.load(imageNode);
        }
      });
      commonDynamicText(yWin);
    }

    // Update text on resize
    this._resizeEmitter = new Y.Squarespace.ResizeEmitter();
    this._resizeEmitter.on('resize', refreshDynamicEls, this);

    // Update text on tweak change
    Y.Global.on('tweak:change', refreshDynamicEls);
  };

  win.Squarespace.initializeSummaryV2Block = function (Y) {
    Y.all('.sqs-block-summary-v2').each(function (el) {
      SummaryV2BlockInitializer(el).then(function () {
        // scale thumbnail placeholders
        let imageAspectRatio;
        if (el.hasAttribute('data-block-json')) {
          imageAspectRatio = JSON.parse(el.getAttribute('data-block-json')).imageAspectRatio;
        }

        // COM-21308 forcing visibility for the description block
        // so that -webkit-line-clamp truncation is applied properly on Safari mobile.
        el.one('.summary-block-collection-type-lessons')?.all('.summary-description').each(function (descNode) {
          descNode._node.style.visibility = 'visible';

          // https://squarespace.atlassian.net/browse/COM-21781
          // Line clamp issue fix for Safari and mobile
          let newDescNode = descNode._node.innerHTML;
          newDescNode = newDescNode.replaceAll(/<p[^>]*>/g, '');
          newDescNode = newDescNode.slice(0, -4); // Removing last </p> - no need to line break with <br/>.
          newDescNode = newDescNode.replaceAll('</p>', '<br/>'); // Replace all others to simulate line break.
          newDescNode = '<p>' + newDescNode + '</p>';

          descNode._node.innerHTML = newDescNode;
        });

        //only needed for lessons collections
        el.one('.summary-block-collection-type-lessons')?.all('img').each(function (img) {
          img.once('load', function () {
            window.dispatchEvent(new Event('resize'));
          }, this);
        });

        el.all('.sqsSummaryBlockThumbnailPlaceholder').each((placeholder) => {
          const imageRatio = calculateVideoPlaceholderAspectRatio(imageAspectRatio);
          placeholder.setStyle('paddingBottom', imageRatio + '%');
          placeholder.get('parentNode').setStyle('paddingBottom', imageRatio + '%');
        });

        Y.SQS.Gallery.Manager.initializeBlock(el);

        // need to remove no-image class from thumbnail placeholders after gallery initialization
        // or else the item title will not be aligned properly in list view
        el.all('.sqsSummaryBlockThumbnailPlaceholder').each((placeholder) => {
          const summaryItemAncestor = placeholder.ancestor('.summary-item').getDOMNode();
          summaryItemAncestor.classList.remove('no-image');
        });
      });
    });
  };

  win.Squarespace.initializeButtonBlock = function (node, Y) {
    ButtonBlockTrackingInitializer(node);
  };

  win.Squarespace.initializeOpenTableBlock = function (el, Y) {
    OpenTableBlockInitializer(el, Y);
  };

  win.Squarespace.initializeOpenTableV2Block = function (el) {
    OpenTableV2BlockInitializer(el);
  };

  /**
   * Appends the Zola widget script to the document head, and deals with ajax
   * scenarios.
   *
   * @method initializeZolaBlocks
   * @param  {Y.Node} firstZolaBlockOnPage reference block from which to procure
   * the script src.
   * @param  {YUI} Y YUI instance
   */
  win.Squarespace.initializeZolaBlocks = function (firstZolaBlockOnPage, Y) {
    const zolaScriptNodeId = 'zola-wjs';
    const existingZolaScriptNode = Y.config.win.document.getElementById(zolaScriptNodeId);
    if (existingZolaScriptNode) {
      if (!Y.config.win.Squarespace.SQUARESPACE_INITIALIZED_ONCE) {
        // Fresh page load, don't need to do anything.
        return;
      }

      if (Y.config.win.ZolaWidget) {
        // Widget instance exists, just re-initialize it.
        Y.config.win.ZolaWidget.init();
        return;
      }

      // Nothing is bound, remove & re-append.
      existingZolaScriptNode.remove();
    }

    const zolaScriptNode = document.createElement('script');
    zolaScriptNode.src = firstZolaBlockOnPage.one('[data-zola-script-src]').getAttribute('data-zola-script-src');
    zolaScriptNode.id = zolaScriptNodeId;
    zolaScriptNode.async = true;

    Y.config.win.document.head.appendChild(zolaScriptNode);
  };

  /**
   * Re-initializes Acuity embeds within dyanmically-loaded sections.
   *
   * @method initializeAcuityBlocks
   * @param  {Y.Node} firstAcuityBlockOnPage reference block from which to procure
   * the script src.
   * @param  {YUI} Y YUI instance
   */
  win.Squarespace.initializeAcuityBlocks = function (firstAcuityBlockOnPage, Y) {
    Y.all('.sqs-block-acuity .sqs-blockStatus-alert-badge').each(function (node) {
      node.on('hover', function () {
        hoverBlockAlert();
      });
    });

    if (!Y.config.win.Squarespace.SQUARESPACE_INITIALIZED_ONCE) {
      // Fresh page load, don't need to do anything.
      return;
    }

    const existingAcuityScriptNode = firstAcuityBlockOnPage.one('script');

    const acuityScriptNode = document.createElement('script');
    acuityScriptNode.src = existingAcuityScriptNode.getAttribute('src');
    acuityScriptNode.async = true;

    existingAcuityScriptNode.remove();
    firstAcuityBlockOnPage.appendChild(acuityScriptNode);
  };

  win.Squarespace.initializeSoundcloudBlock = function (el, Y) {
    const iframeEl = el.one('iframe');

    if (iframeEl) {
      const frameSrc = iframeEl.getAttribute('src');

      // get the 'from' url, which is the pointer to the track or playlist
      try {
        const url = Y.QueryString.parse(frameSrc).url;

        if (url) {
          // check if from url is a playlist
          const isPlaylist = url.indexOf('/playlists/') >= 0;
          el.toggleClass('isPlaylist', isPlaylist);
        }
      } catch (e) {
        console.error('Issue parsing SoundCloud url: ' + frameSrc, e);
      }
    }
  };

  win.Squarespace.initializeVideoBlock = function (videoWrapper, Y) {
    // don't initialize if already initialized
    if (!videoWrapper.videoloader) {
      videoWrapper.plug(Y.Squarespace.VideoLoader);
    }
  };

  /**
   * Render form and its captcha. Not for lightboxed forms.
   *
   * @param {Window} winFromY
   * @param {Element} formEl
   */
  function renderForm(winFromY, formEl) {
    try {
      const captchaContainer = GoogleReCaptchaAPI.getCaptchaContainer(
      winFromY,
      formEl);

      // The container must be empty before rendering and cleared of previous
      // captcha id attribute, otherwise throws error
      if (captchaContainer) {
        GoogleReCaptchaAPI.destroy(captchaContainer);
        GoogleReCaptchaAPI.render(winFromY, captchaContainer);
      }

      // Track form rendered
      const formId = formEl.getAttribute('data-form-id');
      if (formId) {
        trackFormRender(formId);
      }
    } catch (error) {
      console.error('Could not render form', { formEl, error });
    }
  }

  win.Squarespace.initializeFormBlocks = function (root, Y) {
    root.all('.sqs-block-form').each(function renderFormBlock(node) {
      const formNode = node.one('form');
      if (!formNode) {return;}
      renderForm(Y.config.win, formNode.getDOMNode());
    });

    renderCharacterCounters(Y.config.doc);

    const lightboxFormsSelector = '.sqs-block-form .lightbox-handle';
    const lightboxForms = root.all(lightboxFormsSelector);
    if (lightboxForms.isEmpty()) {return;}
    if (Y.Squarespace.FormRenderingUtils) {
      Y.Squarespace.FormRenderingUtils.initializeFormLightbox(root);
      return;
    }
    Y.config.win.Squarespace.addLoadTrigger(lightboxFormsSelector, ['squarespace-form-rendering-utils']);
  };

  win.Squarespace.initializeNewsletterBlock = function (block, Y) {
    const formNode = block.one('.newsletter-form');
    if (!formNode) {return;}
    renderForm(Y.config.win, formNode.getDOMNode());

    const formCol = block.ancestor();
    block.
    toggleClass('newsletter-form-has-small-container', formCol.get('offsetWidth') < 320).
    addClass('rendered');
  };

  win.Squarespace.initializeAccordionBlock = function (block) {
    initializeAccordionBlock(block);
  };

  win.Squarespace.initializeMarqueeBlock = function (block) {
    initializeMarqueeSVG(block, Y);
  };

  win.Squarespace.initializeScaledText = function (block) {
    import( /* webpackChunkName: "async-viewport-raf-resize" */'@sqs/viewport-raf-resize').then(
    ({ default: onResize }) => {
      scaleAllTextInNode(block);
      onResize.on(() => {
        scaleAllTextInNode(block);
      }, 100);
    });

  };

  win.Squarespace.initializeDonationButton = function (buttonWrapper, Y) {
    const button = buttonWrapper.one('.sqs-donate-button');
    if (!button) {
      console.error('Careful, there was no button found inside this node!', buttonWrapper.getDOMNode());
      return;
    }

    const goToDonatePage = () => {
      // go to donation checkout page with this donation button id
      Y.Squarespace.Commerce.goToDonatePage(button.getAttribute('data-donate-page-id'));
    };

    button.on('click', goToDonatePage);

    button.on('keydown', (e) => {
      // Standard button behavior (which we have to restore here since
      // the widget is not really a button but a div or a span)
      // is to react to Enter (keyCode == 13) and Space (keyCode == 32) presses.
      if (e.keyCode === 13 || e.keyCode === 32) {
        goToDonatePage();
      }
    });
  };

  win.Squarespace.initializeMemberAreaBlock = function (block, Y) {
    setupToggleHandlers(block);
  };

  win.Squarespace.initializeGlobalLightbox = function (Y) {
    // anytag with rel="lightbox" and data-lightbox defined will work.
    Y.all('[rel="lightbox"][data-lightbox]').plug(Y.Squarespace.Lightbox2Plug);
  };

  win.Squarespace.initializeCommerce = function (Y) {
    if (!Y.Squarespace.Commerce.initializeCommerce) {
      console.warn('Commerce rollup not loaded, exiting initializeCommerce() invocation.');
      return;
    }

    Y.Squarespace.Commerce.initializeCommerce(Y);
  };

  win.Squarespace.initializeChartBlock = function (Y) {
    if (Y.ChartJS && Y.ChartJS.initializeCharts) {
      Y.ChartJS.initializeCharts();
    } else {
      // ChartJS hasn't been loaded yet. Adding a load trigger will load it only if
      // needed.
      Y.config.win.Squarespace.addLoadTrigger('.sqs-block-chart', ['squarespace-chartjs-helpers']);
    }
  };

  win.Squarespace.initializeVideo = function (Y) {
    Y.all('.sqs-video-wrapper').each(function (videoWrapper) {
      // only initialize if not already initialized and not a video block or
      // gallery slide
      if (
      !videoWrapper.videoloader &&
      !videoWrapper.ancestor('.sqs-block-video') &&
      !videoWrapper.ancestor('.slide'))
      {
        videoWrapper.plug(Y.Squarespace.VideoLoader, {
          load: videoWrapper.getAttribute('data-load') !== 'false' });

      }
    });
  };

  win.Squarespace.initializeNativeVideo = function (Y) {
    const selector = '.sqs-native-video';

    if (Y.one(selector)) {
      import(
      /* webpackChunkName: 'native-video-loader' */'../../scripts-v6/native-video-loader.js').
      then(() => {
        Y.use('squarespace-native-video-loader');

        Y.all(selector).each((videoWrapper) => {
          videoWrapper.plug(Y.Squarespace.NativeVideoLoader, {
            wrapper: videoWrapper });

        });
      });
    }
  };

  win.Squarespace.initializeSearchBlock = function (Y) {
    const searchBlocks = Y.all('.sqs-search-ui-text-input');
    if (searchBlocks.isEmpty()) {
      return;
    }

    if (!Y.Squarespace.Widgets.SearchPreview) {
      if (Y.one('.sqs-search-ui-text-input')) {
        import(
        /* webpackChunkName: "search-preview" */'./search-preview/search-preview.manifest').
        then(() => {
          Y.use('squarespace-search-preview');
        });

        return;
      }
    }

    Y.Squarespace.Widgets.SearchPreview.renderAllSearchPreviewNodes();
  };

  /**
   * Function derives its call stack name from this var
   */
  const initializeAspectRatioBlocks = function (Y) {
    const AspectRatioManager = get(Y, 'SQS.LayoutEngine.AspectRatioManager');
    if (AspectRatioManager) {
      AspectRatioManager.initAllBlocks();
    }
  };
  win.Squarespace.initializeAspectRatioBlocks = initializeAspectRatioBlocks;

  win.Squarespace._userHasViewerRole = function (rolesOnSite) {
    if (!rolesOnSite) {
      return false;
    }
    return Object.keys(rolesOnSite).length > 0 && rolesOnSite[WebsiteRole.VIEWER];
  };

  win.Squarespace.initializeCartPage = function (Y) {
    if (isCartPage(win)) {
      import(
      /* webpackChunkName: 'async-commerce-cart-page' */'@sqs/commerce-cart/bootstrap').
      then(({ bootstrapCartPage, unmountCartPage }) => {
        win.Squarespace.onInitialize(Y, function () {
          if (isCartPage(win)) {
            bootstrapCartPage({ setLocale });
          }
        });

        win.Squarespace.onDestroy(Y, function () {
          if (isCartPage(win)) {
            unmountCartPage();
          }
        });
      });
    }
  };

  win.Squarespace.initializeSvgs = function (Y) {
    Y.config.win.Squarespace.onInitialize(Y, function () {
      Y.on('domready', function () {
        if (isIE11) {
          import(
          /* webpackChunkName: 'async-svg-compatibility' */'shared/utils/SvgCompatibility').
          then(({ default: SvgCompatibility }) => {
            SvgCompatibility.parse();
          });
        }
      });
    });
  };

  // Used in loadEverything for deferred loads
  // Used by scripts-v6/slide-rendering/edit-controller.js
  // Used in template/HeaderFooterUtil.java
  window.SquarespaceFonts = commonFonts(win);

  /**
   * In the scenario that common.js is defered, we need to handle Squarespace lifecycle
   * so we invoke the Squarespace.load() and Squarespace.afterBodyLoad() methods
   * ourselves, since in the synchronous HTML version, those methods are invoked by script
   * tags on the page.
   */
  function loadEverything() {
    window.SquarespaceFonts.loadViaContext();
    window.Squarespace.load(window);
    window.Squarespace.afterBodyLoad(window.Y);
  }

  const tag = isIE11 ?
  document.querySelector('script[src*="common-"]') :
  // document.currentScript
  // @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Document/currentScript}
  document.currentScript;
  // If script has "defer" it also implies it is in 7.1
  const isDeferred = tag && tag.hasAttribute('defer');
  if (isDeferred) {
    win.document.addEventListener('DOMContentLoaded', loadEverything);
  }
};

Common(window); // eslint-disable-line no-undef