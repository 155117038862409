export default (el, Y) => {
  const win = Y.config.win;

  win.Squarespace.onInitialize(Y, function() {

    // 7/22/2014: this block hangs the page if you submit the form over https.
    // we've contacted their devs to update their code to work over https, but for
    // now we can at least prevent a negative experience for logged-in users and
    // visitors accessing over https. -schai@squarespace.com

    const LOGGED_IN = win.SQUARESPACE_LOGIN && win.SQUARESPACE_LOGIN.isLoggedIn();
    const IS_HTTPS = Y.config.doc.location.protocol === 'https:';

    const fauxBlock = el.one('.OT_Faux_Block');
    const realBlock = el.one('#OT_form:not(.OT_Faux_Block)');

    function showFauxBlock() {
      if (realBlock) {
        realBlock.remove();
      }

      fauxBlock.removeClass('sqs-block-opentable-hidden');
    }

    if (LOGGED_IN) {

      // if logged-in, show faux block with fields and button so user gets
      // an accurate idea of what the block will look like
      showFauxBlock();
      fauxBlock.all('.OT_list li').removeClass('sqs-block-opentable-hidden');

      // but don't allow them to interact with the block until they log out.
      fauxBlock.on('click', function(e) {
        e.preventDefault();
        Y.Squarespace.Widgets.Alert({
          'strings.title': 'Preview Only',
          'strings.message': ('Please log out of your Squarespace account in order to use the OpenTable Block.')
        });
      });

    } else if (win.Squarespace.SQUARESPACE_INITIALIZED_ONCE || IS_HTTPS) {

      // hide the date/time/party fields. the button will simply open the
      // restaurant's reservation page in a new window.
      showFauxBlock();

    }

  });

  win.Squarespace.onDestroy(Y, function() {

    if (Y.one('#ui-datepicker-div')) {
      Y.one('#ui-datepicker-div').hide();
    }

  });
};