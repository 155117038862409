import qs from 'qs';

YUI.add('querystring-parse', function (Y, NAME) {
  var QueryString = Y.namespace('QueryString');

  /**
   * Accept Query Strings and return native JavaScript objects.
   *
   * This is not the original YUI implementation of this method. It has been
   * reimplemented to use "qs" due to the vulnerability outlined in:
   * https://jira.squarespace.net/browse/VULN-413
   *
   * The original implementation was as per:
   * https://github.com/yui/yui3/blob/v3.17.2/src/querystring/js/querystring-parse.js#L129
   *
   * At time of writing (8/12/2021), we have 16 references to this method
   * across 15 files. All of which are located in scripts-v6.
   * None of those references utilize the optional separator configuration
   * of the initial YUI implementation.
   *
   * We are reimpleting this as a YUI module with dependencies on array-extras
   * in the interest of QA scope. When approaching this by updating the call sites
   * we testing Events Collection configured with calendar view, for instance an error
   * was thrown due to using Y.Array.filter - which is brought into scope with
   * "array-extras" - but was only doing so transitively. This approach, ensures
   * array-extras remains everywhere it was before. Of course, array-extras can be
   * added as a dependency everywhere that actually needs it, but that expands
   * QA surface area on an already overdue vulnerability.
   *
   * An implementation that updates the callsites directly where the above was seen is
   * at: https://github.com/sqsp/squarespace-v6/pull/12259
   *
   * This implementation replaces it with "qs" as the library of choice
   * within site-server. At time of writing we are on qs@6.9.4.
   *
   * The library is heavily exercised with ~50million downloads per week,
   * and no known vulnerabilities published for the version we are at:
   * https://snyk.io/vuln/npm:qs
   *
   * The last "Prototype Override Protection Bypass" having been in versions
   * <6.3.2
   *
   * @method parse
   * @param qs {String} Querystring to be parsed into an object.
   * @public
   * @static
   */
  QueryString.parse = function(_qs) {
    return qs.parse(_qs);
  };

  /**
   * Provides Y.QueryString.unescape method to be able to override default decoding
   * method.  This is important in cases where non-standard delimiters are used, if
   * the delimiters would not normally be handled properly by the builtin
   * (en|de)codeURIComponent functions.
   * Default: replace "+" with " ", and then decodeURIComponent behavior.
   *
   * @method unescape
   * @param s {String} String to be decoded.
   * @public
   * @static
   **/
  QueryString.unescape = function (s) {
    return decodeURIComponent(s.replace(/\+/g, ' '));
  };
}, '3.17.2', {
  requires: ['yui-base', 'array-extras']
});