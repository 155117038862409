// Initial index is also mirrored in the markup
// (to initially show the first element and hide others,
// so we don't see content jumping around on load).
// But we will nevertheless run the current tab setting script
// to make sure all attributes are in place and have correct values.
const INITIAL_INDEX = 0;

const ACTIVE_TAB_CLASS = 'menu-select-labels--active';

export function initializeMenuBlock(wrapper) {
  const tabs = [...wrapper.querySelectorAll('.js-menu-select-labels')];

  // If there's only a single panel in the menu, there will be
  // zero tabs, and no further code needs to run.
  // Otherwise tabs and panels arrays have the same length.
  const tabCount = tabs.length;
  if (tabCount === 0) {
    return;
  }

  const panels = [...wrapper.querySelectorAll('.js-menu')];

  // Only one tab (the active one) should be tabbable.
  // Other tabs should only get focus on arrow key presses.
  // That's according to ARIA authoring guidelines:
  // https://www.w3.org/TR/2016/WD-wai-aria-practices-1.1-20161214/examples/tabs/tabs.html
  function setCurrentTab(index, setFocus) {
    tabs.forEach((tab, tabIndex) => {
      if (tabIndex === index) {
        tab.setAttribute('tabindex', 0);
        tab.setAttribute('aria-selected', true);
        tab.classList.add(ACTIVE_TAB_CLASS);
        if (setFocus) {
          tab.focus();
        }
      } else {
        tab.setAttribute('tabindex', -1);
        tab.setAttribute('aria-selected', false);
        tab.classList.remove(ACTIVE_TAB_CLASS);
      }
    });

    // Only the panel for the current tab must be displayed
    panels.forEach((panel, panelIndex) => {
      if (panelIndex === index) {
        panel.style.display = 'block';
      } else {
        panel.style.display = 'none';
      }
    });
  }

  // Initialization
  setCurrentTab(INITIAL_INDEX, false);

  // Set click and keydown event listeners for switching between tabs
  tabs.forEach((tab, tabIndex) => {
    tab.addEventListener('click', () => {
      setCurrentTab(tabIndex, true);
    });
    tab.addEventListener('keydown', (e) => {
      // Although the primary mode of operation is using
      // arrow keys, the tabs still need to react to Enter (13)
      // and Space (32) keys because using a screen reader
      // a control can be activated before it receives focus
      // (screen reader can focus on elements without changing
      // the active element).
      if (e.keyCode === 13 || e.keyCode === 32) {
        e.stopPropagation();
        setCurrentTab(tabIndex, true);
      } else if (e.keyCode === 37) { // Left
        e.stopPropagation();
        // The "+ tabCount" bit is here to ensure we get a
        // non-negative value.
        setCurrentTab((tabIndex - 1 + tabCount) % tabCount, true);
      } else if (e.keyCode === 39) { // Right
        e.stopPropagation();
        setCurrentTab((tabIndex + 1) % tabCount, true);
      }
    });
  });
}
