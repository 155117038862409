import cookieCutter from '@sqs/cookie-cutter';
import * as CookieBannerConstants from 'shared/constants/CookieBanner';
import { getWithDefaults } from 'apps/App/screens/Settings/screens/VisitorData/utils/visitorDataTransformations';

// Keep these values in sync with styles-v6/cookie-banner.less
const DOM_VERSION = 2;
const CSS_PREFIX = `sqs-cookie-banner-v${DOM_VERSION}`;

let mountPoint;
let bannerEl;
let textEl;
let acceptEl;
let denyEl;
let buttonContainerEl;

/**
 * @param {Event} e Event Object
 * @return {Function} set cookie to true or false and remove banner on user selection
 */
const handleClick = (e) => {
  // Not dismissable when previewing in /config/settings/visitor-data
  if (document.body.classList.contains('sqs-cookie-banner-v2-forced')) {
    return false;
  }

  const button = e.target.closest(`.${CSS_PREFIX}-cta`);

  if (button) {
    const expires = new Date();
    const shouldAllowCookie = button.classList.contains(`${CSS_PREFIX}-accept`);
    const thirtyDaysFromPresent = Date.now() + (1000 * 60 * 60 * 24 * 30);

    expires.setTime(thirtyDaysFromPresent);

    cookieCutter.set('ss_cookieAllowed', shouldAllowCookie, { path: '/', expires: expires.toUTCString() });

    bannerEl.removeEventListener('click', handleClick);
    bannerEl.remove();
  }
};

/**
 * @return {DocumentFragment} with the banner and button
 */
const renderDom = (settings) => {
  const {
    cookieBannerAcceptType,
    cookieBannerCtaVariant
  } = getWithDefaults(settings);

  bannerEl = document.createElement('div');
  bannerEl.className = CSS_PREFIX;

  textEl = document.createElement('div');
  textEl.className = `${CSS_PREFIX}-text`;

  acceptEl = document.createElement('button');
  acceptEl.classList.add(`${CSS_PREFIX}-accept`, `${CSS_PREFIX}-cta`);

  const acceptWrapperEl = document.createElement('div');
  acceptWrapperEl.className = `${CSS_PREFIX}-acceptWrapper`;
  acceptWrapperEl.appendChild(acceptEl);

  bannerEl.appendChild(textEl);

  const shouldDisplayOptOutButton = (
    cookieBannerAcceptType === CookieBannerConstants.ACCEPT_TYPES.OPT_IN_AND_OUT &&
    cookieBannerCtaVariant !== CookieBannerConstants.CTA_VARIANTS.ICON
  );

  if (shouldDisplayOptOutButton) {
    denyEl = document.createElement('button');
    denyEl.classList.add(`${CSS_PREFIX}-deny`, `${CSS_PREFIX}-cta`);

    const denyWrapperEl = document.createElement('div');
    denyWrapperEl.className = `${CSS_PREFIX}-denyWrapper`;
    denyWrapperEl.appendChild(denyEl);

    buttonContainerEl = document.createElement('div');
    buttonContainerEl.className = `${CSS_PREFIX}-cta-container`;

    buttonContainerEl.appendChild(acceptWrapperEl);
    buttonContainerEl.appendChild(denyWrapperEl);

    bannerEl.appendChild(buttonContainerEl);
  } else {
    bannerEl.appendChild(acceptWrapperEl);
  }

  bannerEl.addEventListener('click', handleClick);

  return bannerEl;
};

/**
 * @param {Object} settings from Static.SQUARESPACE_CONTEXT.cookieSettings or
 * VisitorData store
 * @param {?String} [settings.cookieBannerCtaText] button click label
 * @param {?String} [settings.cookieBannerCtaVariant] button/text/icon
 * @param {?String} [settings.cookieBannerPosition] button click label
 * @param {?String} [settings.cookieBannerText] banner text must be <p>wrapped</p>!
 * @param {?String} [settings.cookieBannerTheme] light/dark
 * @param {?String} [settings.cookieBannerVariant] bar/popup
 * @return {DocumentFragment} with the banner and button
 */
const applySettings = (settings) => {
  const { isCookieBannerEnabled } = settings;
  const {
    cookieBannerCtaText,
    cookieBannerCtaVariant,
    cookieBannerPosition,
    cookieBannerText,
    cookieBannerTheme,
    cookieBannerVariant,
    cookieBannerAcceptType,
    cookieBannerOptOutCtaText,
  } = getWithDefaults(settings);

  mountPoint =
    cookieBannerPosition === CookieBannerConstants.BAR_POSITIONS.TOP ?
      ( document.body.querySelector('.sqs-announcement-bar-dropzone') ||
        document.body ) :
      document.body;

  bannerEl.className = CSS_PREFIX; // clear
  bannerEl.classList.add(
    cookieBannerVariant,
    cookieBannerTheme,
    cookieBannerPosition,
    cookieBannerCtaVariant,
    cookieBannerAcceptType,
  );

  // Must START with <p>
  textEl.innerHTML = cookieBannerText.indexOf('<p>') === 0 ?
    cookieBannerText :
    `<p>${cookieBannerText}</p>`;

  if (cookieBannerAcceptType === CookieBannerConstants.ACCEPT_TYPES.OPT_IN_AND_OUT) {
    switch (cookieBannerCtaVariant) {
    case CookieBannerConstants.CTA_VARIANTS.BUTTON:
      acceptEl.innerText = cookieBannerCtaText;
      denyEl.innerText = cookieBannerOptOutCtaText;
      break;
    case CookieBannerConstants.CTA_VARIANTS.TEXT:
      acceptEl.innerText = cookieBannerCtaText;
      denyEl.innerText = cookieBannerOptOutCtaText;
      break;
    case CookieBannerConstants.CTA_VARIANTS.ICON:
      acceptEl.innerText = ' ';
      break;
    }
  } else {
    switch (cookieBannerCtaVariant) {
    case CookieBannerConstants.CTA_VARIANTS.BUTTON:
      acceptEl.innerText = cookieBannerCtaText;
      break;
    case CookieBannerConstants.CTA_VARIANTS.TEXT:
      acceptEl.innerText = cookieBannerCtaText;
      break;
    case CookieBannerConstants.CTA_VARIANTS.ICON:
      acceptEl.innerText = ' ';
      break;
    }
  }

  // Needs to be a body class (or on the mountPoint, but this is the easy way)
  // to modify the expander
  if (cookieBannerPosition === CookieBannerConstants.BAR_POSITIONS.TOP) {
    document.body.classList.add(`${CSS_PREFIX}-top`);
  } else {
    document.body.classList.remove(`${CSS_PREFIX}-top`);
  }

  if (isCookieBannerEnabled) {
    mountPoint.classList.add(`${CSS_PREFIX}-enabled`);
    mountPoint.appendChild(bannerEl);
  } else {
    mountPoint.classList.remove(`${CSS_PREFIX}-enabled`);
  }
  return bannerEl;
};

const expireCookies = () => {
  const expiration = { expires: new Date(0).toUTCString() };
  [
    'SS_MATTR',
    'SS_MID',
    'SS_lastvisit',
    'user_segment',
    // CENSUS-4846:
    // remove references to old census cookies ('ss_cid', 'ss_cpvisit', 'ss_cvisit') after 2023/07/01
    'ss_cid',
    'ss_cpvisit',
    'ss_cvisit',
    'ss_cvr',
    'ss_cvt'
  ].forEach((key) => cookieCutter.set(key, '', expiration));
};

const unmount = () => {
  if (bannerEl) {
    bannerEl.removeEventListener('click', handleClick);
    bannerEl.remove();
  }
  bannerEl = null;
  if (mountPoint) {
    mountPoint.classList.remove(`${CSS_PREFIX}-enabled`);
  }
};

/**
 * @param {Object} settings Y.config.win.Static.SQUARESPACE_CONTEXT.cookieSettings
 * @return {Boolean} false if not appended
 */
const mount = (settings) => {
  if (!settings.force) {
    // Cookie that tells us:
    // - that they have given us consent to drop cookies
    // - we also use this to determine that we no longer need to show the banner
    // - if this cookie does not exist we should show the banner
    const cookieValue = cookieCutter.get('ss_cookieAllowed');

    // ss_cookieAllowed is stored as a string ('true' || 'false')
    const hasConsent = cookieValue === 'true';

    // If the banner is shown that means that the user has not yet consent to us
    // using Cookies to track their browser behaviour. If
    // isRestrictiveCookiePolicyEnabled is also enabled we need to delete
    // previously set cookies.
    if (!hasConsent && settings.isRestrictiveCookiePolicyEnabled) {
      expireCookies();
    }

    // The user has either accepted or declined (aka, the cookie has a value)
    if (typeof cookieValue !== 'undefined' && cookieValue !== null) {
      return false;
    }
  }

  if (!bannerEl) {
    renderDom(settings);
  }

  applySettings(settings);
  return true;
};

export { applySettings, renderDom, mount, unmount };
