const LOGOUT_PATH = '/api/auth/Logout';

import axios from '@sqs/network';

const LogoutAPI = {

  logout() {
    return axios.post(LOGOUT_PATH);
  }

};

export default LogoutAPI;
