function findAncestor(el, sel) {
  if (typeof el.closest === 'function') {
    return el.closest(sel) || null;
  }
  while (el) {
    if (el.matches(sel)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}

/**
 * Sets & refreshes element width-based font sizes.
 *
 * More info:
 * Font sizes set in viewport widths (vw) are all that is needed
 * to have dynamically sized fonts on an element,
 * but only when that element is full width.
 * This script translates vw based font widths for elements that
 * are not full width by applying a percentage based offset.
 *
 * This method expects a particular setup of wrappers/classes and a font-size set in vw:
 * <div class="sqs-dynamic-text-container">
 *   <div style="font-size: XXvw"> <-- vw unit font size needs to be already set
 *     <div class="sqs-dynamic-text"> <-- this wrapper gets a % font-size value set as an offset
 *        Text
 *     </div>
 *   </div>
 * </div>
 *
 * @param target {element} Element that wraps dynamic text elements and who's
 *                       width get's watched for resizing.
 * @return {boolean} success status
 */
export default function makeTextDynamic(node) {
  if (!node) {
    return false;
  }

  // Sets default value for 'node' if none is passed into function
  const textClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'sqs-dynamic-text';

  // Gets the appropriate window (whether you're in the site iframe or not)
  const doc = node.ownerDocument;
  const win = doc.defaultView;

  // The node might be in a document fragment or an old document that was
  // unloaded from iframe
  if (!win) {
    return false;
  }

  const windowWidth = win.innerWidth;
  const texts = Array.from(node.querySelectorAll('.' + textClass));
  texts.forEach(function (text) {
    const elementWidth = text.offsetWidth;
    const widthPercentage = Math.floor(elementWidth / windowWidth * 1000) / 10; // relative element width
    const existingPercentage = parseInt(text.getAttribute('data-width-percentage'), 10);

    // If element is unchanged from previous runs, return
    if (existingPercentage === widthPercentage) {
      return; // it's dynamic!
    }

    // Set offset font-size for each text element
    text.style.fontSize = 'calc(0.5rem + ' + widthPercentage + '%)';
    text.style.fontSize = 'max(.75rem, ' + widthPercentage + '%)';

    // Set/update calc'd ratio to reference in future runs
    text.setAttribute('data-width-percentage', widthPercentage);

    // Check font size
    const computedFontSize = parseInt(win.getComputedStyle(text, null).getPropertyValue('font-size'), 10);
    const minFontTarget = text.firstChild;
    if (minFontTarget && minFontTarget.classList) {
      if (computedFontSize > 0 && computedFontSize <= 13) {
        minFontTarget.classList.add('min-font-set');
      } else if (minFontTarget.classList.contains('min-font-set')) {
        minFontTarget.classList.remove('min-font-set');
      }
    }

    const imageBlockOuterWrapper = findAncestor(text, '.image-block-outer-wrapper');
    if (imageBlockOuterWrapper) {
      imageBlockOuterWrapper.classList.add('sqs-text-ready');
    }
  });
  return true;
}
