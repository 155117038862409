import Flag from '@sqs/enums/Flag';
import { legacyV6Flags as BetaFeaturesUtils } from '@sqs/universal-flags';
import * as trackEvent from 'shared/utils/commerce/trackEvent';
import network from '@sqs/network';
import { GoogleReCaptchaAPI } from '@sqs/universal-utils';
import { FacebookPixelConstants as pixelConstants } from '@sqs/websites-constants';
import FormHandler from 'shared/utils/FormHandler';
import * as CensusUtils from 'shared/utils/census/CensusUtils';
import { focusableSelector } from '@sqs/utils/focus';

/**
 * @module squarespace-form-submit
 */
YUI.add('squarespace-form-submit', function(Y) {

  Y.namespace('Squarespace');

  var FormSubmitV1 = Y.Base.create(
    'FormSubmitV1',
    Y.Base,
    [],
    {
      initializer: function () {
        this._submitButton = this.get('formNode').one('[type=submit]');
        this._submitButtonText = this._submitButton.get('value');

        var getMultiFieldVal = this._getMultiFieldVal;

        this._typeGetterMap = {
          date: getMultiFieldVal,
          name: getMultiFieldVal,
          time: getMultiFieldVal,
          address: getMultiFieldVal,
          phone: this._getPhoneFieldVal,
          radio: this._getRadioFieldVal,
          checkbox: this._getCheckFieldVal,
          select: this._getSelectVal,
          likert: this._getLikertVal,
          hidden: this._getHiddenVal
        };

        this._defaultGetter = this._getSingleFieldVal;

        // Fired when a form submission is successful
        Y.Global.publish('form:submitSuccess');
      },

      /**
       * @param {string|object} formIdOrObject
       * @param {?string} [formIdOrObject.collectionId]
       * @param {?string} [formIdOrObject.objectName]
       * @param {boolean} [formIdOrObject.isOverylayForm] true if this form is part of a pop-up overlay
       * @param {string} [formIdOrObject.submitButtonText]
       * @param {?string} collectionId
       * @param {?string} objectName
       * @param {boolean} [isOverylayForm] true if this form is part of a pop-up overlay
       * @param {string} [submitButtonText]
       * @return {undefined|false} false to prevent defaults
       */
      submit: function formSubmitSubmit(
        formIdOrObject,
        collectionId,
        objectName,
        isOverlayForm,
        submitButtonText
      ) {
        var formNode = this.get('formNode');

        var formId;

        // templates-v6/system/blocks/*.block updates take time to propagate
        // to all users so we conditionally check if it has...
        if (typeof formIdOrObject === 'object') {
          formId = formIdOrObject.formId;
          collectionId = formIdOrObject.collectionId;
          objectName = formIdOrObject.objectName;
          isOverlayForm = formIdOrObject.isOverlayForm;
          submitButtonText = formIdOrObject.submitButtonText;
        } else {
          formId = formIdOrObject;
        }

        try {
          // do nothing if the form is already in the process of submitting
          if (formNode.hasClass('submitting')) {
            return;
          }

          this._clearErrors();
          this._lock();

          var captchaContainer = GoogleReCaptchaAPI.getCaptchaContainer(
            Y.config.win,
            this.get('formNode').getDOMNode()
          );
          if (captchaContainer) {
            var captchaKey = GoogleReCaptchaAPI.validate(
              Y.config.win,
              captchaContainer
            );

            if (!captchaKey) {
              this._submitFailure(FormHandler.strings.CAPTCHA_INCOMPLETE_ERROR);
            } else {
              this._saveFormSubmission(formId, collectionId, objectName, isOverlayForm, submitButtonText, captchaKey);
            }
          } else {
            this._saveFormSubmission(formId, collectionId, objectName, isOverlayForm, submitButtonText);
          }
        } catch (e) {
          // on exception, add a global error and unlock the form
          this._submitFailure(FormHandler.strings.SUBMIT_ERROR);
        } finally {
          // return false so the html form doesn't actually use its submit
          // function
          return false; // eslint-disable-line no-unsafe-finally
        }
      },

      _createErrorNode: function(message) {
        return Y.Node.create('<div class="field-error">' + message + '</div>');
      },

      _clearErrors: function() {
        var formNode = this.get('formNode');

        formNode.all('.field-error').each(function(errorNode) {
          errorNode.remove();
        });

        formNode.all('.form-item.error').each(function(errorFieldNode) {
          errorFieldNode.removeClass('error');
        });
      },

      _lock: function() {
        this.get('formNode').addClass('submitting');
        this._submitButton.set(
          'value',
          FormHandler.strings.SUBMIT_TEXT_IN_PROGRESS
        );
      },

      _unlock: function() {
        this.get('formNode').removeClass('submitting');
        this._submitButton.set('value', this._submitButtonText);
      },

      _saveFormSubmission: function(formId, collectionId, objectName, isOverlayForm, submitButtonText, captchaKey) {
        var options = {
          withCredentials: true
        };

        network.post('/api/form/FormSubmissionKey', {}, options)
          .then(function (response) {
            if (response.data.key) {
              var submissionData = {
                key: response.data.key,
                formId: formId,
                collectionId: collectionId,
                objectName: objectName,
                isOverlayForm: isOverlayForm,
                submitButtonText: submitButtonText,
                form: Y.JSON.stringify(this._getData()),
                captchaKey: captchaKey
              };
              CensusUtils.addIntraPageEventFields(submissionData);
              network.post('/api/form/SaveFormSubmission', submissionData, options)
                .then(this._submitSuccess.bind(this))
                .catch(function(error) {
                  this._submitFailure(error.response);
                }.bind(this));
            } else {
              this._submitFailure(response);
            }
          }.bind(this))
          .catch(function(error) {
            this._submitFailure(error.response);
          }.bind(this));
      },

      _submitSuccess: function () {
        var formNode = this.get('formNode');
        // newsletter block and promo pop up will have class 'newsletter-form'
        // cover page newsletter link wil have id 'overlayNewsletterEmail'
        if (
          formNode &&
          (formNode.hasClass('newsletter-form') ||
            formNode.getById(pixelConstants.NEWSLETTER_OVERLAY_NODE_ID))
        ) {
          trackEvent.trackSubscribeNewsletter();
        }

        // Mark popup overlay form submit success
        Y.Global.fire('form:submitSuccess');

        var redirect = formNode.getData('success-redirect');
        if (redirect) {
          // Ensure back button does not resubmit form
          if (window.history.replaceState) {
            window.history.replaceState({}, document.title, window.location.href);
          }
          window.location.href = redirect;
        }

        this._unlock();
        this._renderSuccess();
      },

      _submitFailure: function (error) {
        var formNode = this.get('formNode');
        var isNewsletterForm = formNode.hasClass('newsletter-form');

        var errorMessage = null;
        if (Y.Lang.isString(error)) { // Error Message
          errorMessage = error;

        } else if (FormHandler.errorCodes[error.status]) { // HTTP Error
          errorMessage = FormHandler.errorCodes[error.status];

        } else if (error.status === 400 && error.data.errors) { // Field Error
          Y.Object.each(error.data.errors, function(e, errorName) {
            var fieldNode = formNode.one('[id="' + errorName + '"]');
            var titleNode = fieldNode.one('.title');

            titleNode.insert(this._createErrorNode(e), 'before');
            fieldNode.addClass('error');
          }, this);

          // Same messaging as in checkout-form-custom-form.js
          if (!isNewsletterForm) {
            errorMessage = FormHandler.strings.ERROR_ABOVE;
          }

        } else if (error.data.error) { // Server Error
          errorMessage = error.data.error;

        } else { // General Error
          errorMessage = FormHandler.strings.UNKNOWN_ERROR;

        }

        if (errorMessage) {
          var errorNode = this._createErrorNode(errorMessage);
          if (isNewsletterForm) {
            formNode.insertBefore(
              errorNode,
              formNode.one('.newsletter-form-body')
            );
          } else {
            formNode.prepend(errorNode);
          }

          // We're focusing on the error for two reasons:
          // - to scroll to the top of the form and increase
          //   the likelihood of user seeing all errors at once
          //   without having to scroll;
          // - to ensure screen reader users hear the error message
          //   and can tab through the form again and see
          //   where errors are.
          errorNode.setAttribute('tabindex', -1);
          errorNode.focus();
        } else {
          // In certain code paths, an overall error message
          // is not inserted. In that case, we want to focus
          // on the first input that has an associated error.
          var firstFieldWithError = formNode.one('.error');
          if (firstFieldWithError) {
            // Not sure if the element with an `error` class
            // can be the input itself, or only an input wrapper,
            // so check for both.
            // We don't know if the focusable element is
            // an input, a textarea, or something different,
            // so use the most generic selector for focusable elements.
            var focusableNode = firstFieldWithError.getDOMNode().matches(focusableSelector) && firstFieldWithError ||
              firstFieldWithError.one(focusableSelector);
            if (focusableNode) {
              focusableNode.focus();
            }
          }
        }

        var captchaContainer = formNode.one('.captcha-container');
        if (captchaContainer) {
          // Disable the submit button if the captcha can enable it on completion.
          // @see common.js#renderForm()
          var submitEl = this._submitButton.getDOMNode();
          if (submitEl && submitEl.getAttribute('data-captcha-bound')) {
            submitEl.disabled = true;
          }
          GoogleReCaptchaAPI.reset(Y.config.win, captchaContainer);
        }

        this._unlock();
      },

      _renderSuccess: function() {
        var formEl = this.get('formNode');
        var messageNode = formEl.one('.form-submission-text');
        var htmlNode = formEl.one('.form-submission-html');
        var postSubmitHtml = htmlNode.getData('submission-html');

        formEl.all('*').each(function(node) {
          node.addClass('hidden');
        });

        htmlNode.setHTML(postSubmitHtml);
        htmlNode.removeClass('hidden');

        messageNode.removeClass('hidden');
        messageNode.all('*').each(function(node) {
          node.removeClass('hidden');
        });

        // Either messageNode or htmlNode (or both) can contain text
        // (depending on what the customer set).
        // We're trying to focus on the first of the two
        // that has content.
        // The text nodes are not programmatically focusable
        // by default, so a negative tabindex needs to be set on the necessary one
        // (so it's focusable but not tabbable).
        var movedFocus = false;
        function focusOn(node) {
          node.setAttribute('tabindex', -1);
          node.focus();
          movedFocus = true;
        }
        if (messageNode.getDOMNode().textContent.trim().length > 0) {
          focusOn(messageNode);
        } else if (htmlNode.getDOMNode().textContent.trim().length > 0) {
          focusOn(htmlNode);
        }

        // If the formEl is above the window's current scroll, scroll to the top
        // of the formEl.
        // Don't do this if focus was moved — in that case
        // the browser natively scrolls if focused element
        // was outside viewport.
        if (!movedFocus) {
          var win = Y.config.win;
          var formElY = formEl.getY();

          if (formElY < win.scrollY) {
            win.scrollTo(0, formElY - 25);
          }
        }

        htmlNode.all('script').each(function(script) {
          // There is an existing YUI issue where using setHTML with an HTML
          // string containing script tags with src attributes will cause those
          // scripts not to be loaded. This first condition is a workaround for
          // that. -dbarber
          if (script.hasAttribute('src')) {
            var dupeScriptDOMNode = document.createElement('script');
            var attributes = script.getDOMNode().attributes;

            Y.Array.each(attributes, function(attribute) {
              dupeScriptDOMNode[attribute.name] = attribute.value;
            });

            script.replace(dupeScriptDOMNode);
          } else {
            try {
              eval(script.getHTML()); // eslint-disable-line no-eval
            } catch (error) {
              if (__DEV__) {
                console.warn(
                  'The following form submission HTML has caused a script error: ' +
                  postSubmitHtml
                );
              }
            }
          }
        }, this);
      },

      _getData: function() {
        var data = {};

        this.get('formNode').all('.form-item').each(function(fieldNode) {
          data[fieldNode.get('id')] = this._getFieldData(fieldNode);
        }, this);

        return data;
      },

      _getFieldData: function(fieldNode) {
        var typeGetterMap = this._typeGetterMap;
        var fieldClasses = fieldNode.get('className').split(new RegExp('\\s'));
        var getter;
        var isSection = false;

        Y.Array.each(fieldClasses, function(fieldClass) {
          if (Y.Object.hasKey(typeGetterMap, fieldClass)) {
            getter = typeGetterMap[fieldClass];
          } else if (fieldClass === 'section') {
            isSection = true;
          }
        }, this);

        // don't process section dividers
        if (isSection) {
          return;
        }

        if (!getter) {
          getter = this._defaultGetter;
        }

        return getter.call(this, fieldNode);
      },

      _getSingleFieldVal: function(fieldNode) {
        var valueNode = fieldNode.one('.field-element');

        if (valueNode) {
          return valueNode.get('value');
        }

        return null;
      },

      _getMultiFieldVal: function(fieldNode) {
        var values = [];

        fieldNode.all('.field-element').each(function(valueNode) {
          values.push(valueNode.get('value'));
        });

        return values;
      },

      _getPhoneFieldVal: function(fieldNode) {
        var values = this._getMultiFieldVal(fieldNode);

        // prepend an empty value for the country code, which is optional and
        // may not be present
        if (values && values.length === 3) {
          values.unshift('');
        }

        return values;
      },

      _getRadioFieldVal: function(fieldNode) {
        return this._getCheckFieldVal(fieldNode)[0];
      },

      _getCheckFieldVal: function(fieldNode) {
        var values = [];

        fieldNode.all('input').each(function(input) {
          if (input.get('checked')) {
            values.push(input.get('value'));
          }
        }, this);

        return values;
      },

      _getSelectVal: function(fieldNode) {
        return fieldNode.one('select').get('value');
      },

      _getLikertVal: function(fieldNode) {
        var data = {};

        fieldNode.all('.item').each(function(questionNode) {
          var questionValue;

          questionNode.all('input').each(function(optionInput) {
            if (optionInput.get('checked')) {
              questionValue = optionInput.get('value');
            }
          });

          if (Y.Lang.isValue(questionValue)) {
            data[questionNode.getAttribute('data-question')] = questionValue;
          }
        });

        return data;
      },

      _getHiddenVal: function(fieldNode) {

        var value;

        if (!Y.Lang.isValue(this._query)) {
          this._query = Y.QueryString.parse(window.location.search.replace(/^\?/, ''));
        }

        var key = fieldNode.get('name');

        if (Y.Object.hasKey(this._query, key)) {
          value = this._query[key];
        } else {
          value = fieldNode.get('value');
        }

        return value.toString();

      }
    },
    {
      ATTRS: {
        /**
         * The Node of the form element that is being submitted.
         * @attribute formNode
         * @type      Node
         */
        formNode: {
          value: null,
          validator: Y.Squarespace.AttrValidators.isNullOrInstanceOf(Y.Node),
          writeOnce: 'initOnly'
        }
      }
    }
  );

  /**
   * This is backwards compatible to FormSubmitV1. That means you can
   * construct it using
   *
   *    new Y.Squarespace.FormSubmit({ formNode: Y.one(form) })
   *
   * and use that instance's submit() method.
   *
   * @example
   *
   *    // V1 usage
   *    // A new instance is constructed with methods. For backwards
   *    // compatibility, Y.Squarespace.FormSubmit can be used as a function
   *    // or a constructor
   *    var form = new Y.Squarespace.FormSubmit({
   *      formNode: Y.one(document.getElementById('myform')
   *    });
   *    form.submit(
   *      formId,
   *      collectionId,
   *      objectName,
   *      isOverlayForm,
   *      submitButtonText
   *    );
   *
   *    // FormHandler usage
   *    // Note a new instance does not have to be constructed -- the function
   *    // scope is returned with exposed methods for chaining.
   *    var form = Y.Squarespace.FormSubmit(formNode).submit(...);
   *
   * @constructor
   * @see scripts-v6/slide-rendering/slices/newsletter.js
   * @see templates-v6/system/blocks/form.block
   * @see templates-v6/system/blocks/newsletter.block
   * @param {Element|object} formOrAttrs will be one or other depending on if
   * the templates-v6 cache has flushed for the visitor
   * @return {object} this function scope with public methods
   * submit,clearErrors,createErrorNode bound to a unique handler instance
   */
  Y.Squarespace.FormSubmit = function (formOrAttrs) {
    // Updated block template that passes the <form> was served
    // The onsubmit no longer constructs a new instance, so we `new` here
    var handler;
    if (formOrAttrs instanceof Element) {
      handler = BetaFeaturesUtils.isFeatureEnabled(Flag.VANILLA_FORM_HANDLER) ?
        new FormHandler(Y.config.win, formOrAttrs) :
        new FormSubmitV1({ formNode: Y.Node(formOrAttrs) });
    } else {
      // Using old block template that passes { formNode: Y.Node(form) }
      // The onsubmit constructs a new instance
      handler = new FormSubmitV1(formOrAttrs);
    }

    // Expose public methods used by async form and newsletter promo slice
    this.submit = handler.submit.bind(handler);
    if (BetaFeaturesUtils.isFeatureEnabled(Flag.VANILLA_FORM_HANDLER)) {
      this.clearErrors = handler.clearErrors.bind(handler);
      this.createErrorNode = handler.createErrorNode.bind(handler);
    } else {
      this.clearErrors = handler._clearErrors.bind(handler);
      this.createErrorNode = handler._createErrorNode.bind(handler);
    }

    return this;
  };

}, '1.0', {
  requires: [
    'base',
    'json',
    'node',
    'querystring-parse',
    'squarespace-attr-validators',
    'squarespace-util'
  ]
});
