/*
 * This file was generated by the `create-manifests` gulp task.
 * Run `npm run gulp create-manifests` to regenerate this file.
 */
import 'src/main/webapp/universal/scripts-v6/yui-config.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/yui-base/yui-base.js';
import 'src/main/webapp/universal/scripts-v6/common.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/intl/intl.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-custom-base/event-custom-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/oop/oop.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-custom-complex/event-custom-complex.js';
import 'src/main/webapp/universal/scripts-layout-engine/aspect-ratio-manager.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/base-base/base-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/attribute-base/attribute-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/attribute-core/attribute-core.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/attribute-observable/attribute-observable.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/attribute-extras/attribute-extras.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/base-core/base-core.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/base-observable/base-observable.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/base-pluginhost/base-pluginhost.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/pluginhost-base/pluginhost-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/pluginhost-config/pluginhost-config.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/base-build/base-build.js';
import 'src/main/webapp/universal/scripts-v6/promises.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/promise/promise.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/timers/timers.js';
import 'src/main/webapp/universal/scripts-v6/attr-validators.js';
import 'src/main/webapp/universal/scripts-v6/damask/context-globals.js';
import 'src/main/webapp/universal/scripts-v6/commerce-utils.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/escape/escape.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/node-base/node-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-base/event-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/node-core/node-core.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dom-core/dom-core.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/selector/selector.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/selector-native/selector-native.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dom-base/dom-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dom-style/dom-style.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/node-event-delegate/node-event-delegate.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-delegate/event-delegate.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/node-pluginhost/node-pluginhost.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/node-screen/node-screen.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dom-screen/dom-screen.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/node-style/node-style.js';
import 'src/main/webapp/universal/scripts-v6/escaping-utils.js';
import 'src/main/webapp/universal/scripts-v6/cookie-banner-styles.js';
import 'src/main/webapp/universal/scripts-v6/currency-symbols.js';
import 'src/main/webapp/universal/scripts-v6/deprecated-modules.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/cookie/cookie.js';
import 'src/main/webapp/universal/scripts-v6/escmanager.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-base-ie/event-base-ie.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-synthetic/event-synthetic.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-mousewheel/event-mousewheel.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-mouseenter/event-mouseenter.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-key/event-key.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-focus/event-focus.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-resize/event-resize.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-hover/event-hover.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-outside/event-outside.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-touch/event-touch.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-move/event-move.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-flick/event-flick.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-valuechange/event-valuechange.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-tap/event-tap.js';
import 'src/main/webapp/universal/scripts-v6/util.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/anim-base/anim-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/color-base/color-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/anim-color/anim-color.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/anim-curve/anim-curve.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/anim-xy/anim-xy.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/anim-easing/anim-easing.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/anim-node-plugin/anim-node-plugin.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/anim-scroll/anim-scroll.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/io-base/io-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/querystring-stringify-simple/querystring-stringify-simple.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/io-xdr/io-xdr.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datatype-xml-parse/datatype-xml-parse.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/io-form/io-form.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/io-upload-iframe/io-upload-iframe.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/io-queue/io-queue.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/queue-promote/queue-promote.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/json-parse/json-parse.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/json-stringify/json-stringify.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/jsonp/jsonp.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/plugin/plugin.js';
import 'src/main/webapp/universal/scripts-v6/querystring-parse.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/array-extras/array-extras.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/querystring-stringify/querystring-stringify.js';
import 'src/main/webapp/universal/scripts-v6/anim-raf.js';
import 'src/main/webapp/universal/scripts-v6/data.js';
import 'src/main/webapp/universal/scripts-v6/system-error.js';
import 'src/main/webapp/universal/scripts-v6/dom-emitters/resize.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/shared/resize-emitter.js';
import 'src/main/webapp/universal/scripts-v6/logger.js';
import 'src/main/webapp/universal/scripts-v6/plugin-node-destroy-notifier.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/shared/node-destroy-notifier.js';
import 'src/main/webapp/universal/scripts-v6/public-api.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/history-base/history-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/history-hash/history-hash.js';
import 'src/main/webapp/universal/scripts-v6/beforeunload.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/history-hash-ie/history-hash-ie.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/history-html5/history-html5.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/node-event-simulate/node-event-simulate.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/event-simulate/event-simulate.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/gesture-simulate/gesture-simulate.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/async-queue/async-queue.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/node-load/node-load.js';
import 'src/main/webapp/universal/scripts-v6/gallery-manager.js';
import 'src/main/webapp/universal/scripts-v6/image-loader.js';
import 'src/main/webapp/universal/scripts-v6/plugin-lightbox2.js';
import 'src/main/webapp/universal/scripts-v6/gismo.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/array-invoke/array-invoke.js';
import 'src/main/webapp/universal/scripts-v6/anims.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/arraylist/arraylist.js';
import 'src/main/webapp/universal/scripts-v6/widgets/lightbox2.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/dom-style-ie/dom-style-ie.js';
import 'src/main/webapp/universal/scripts-v6/animations.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/transition/transition.js';
import 'src/main/webapp/universal/scripts-v6/plugin-scroll-lock.js';
import 'src/main/webapp/universal/scripts-v6/touch-event-listener.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/shared/touch-event-listener.js';
import 'src/main/webapp/universal/scripts-v6/video-loader.js';
import 'src/main/webapp/universal/scripts-v6/ui-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/attribute-complex/attribute-complex.js';
import 'src/main/webapp/universal/scripts-v6/widgets/gismo.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/widget-base/widget-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/classnamemanager/classnamemanager.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/widget-htmlparser/widget-htmlparser.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/widget-skin/widget-skin.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/widget-uievents/widget-uievents.js';
import 'src/main/webapp/universal/scripts-v6/widgets/lightbox2-overlay.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/yui-throttle/yui-throttle.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/gallery-manager.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/gallery.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/index.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/autocolumns.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/shared/loading-behavior/by-viewport.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/shared/gismo.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/shared/rendering.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/autogrid.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/autorows.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/carousel.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/shared/loading-behavior/by-current-index.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/grid.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/list.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/stacked/index.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/stacked/transitions/index.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/stacked/transitions/base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/stacked/transitions/fade.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/stacked/transitions/ken-burns.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/stacked/transitions/new-swipe.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/stacked/transitions/scroll.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/stacked/transitions/swipe.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui-gallery/src/designs/strip.js';
import 'src/main/webapp/universal/scripts-v6/gallery-ng.js';
import 'src/main/webapp/universal/scripts-v6/legacy/class.js';
import 'src/main/webapp/universal/scripts-thirdparty/modernizr/modernizr.js';
import 'src/main/webapp/universal/scripts-v6/follow-buttons.js';
import 'src/main/webapp/universal/scripts-v6/follow-button.js';
import 'src/main/webapp/universal/scripts-v6/form-submit.js';
import 'src/main/webapp/universal/scripts-v6/gallery/index.js';
import 'src/main/webapp/universal/scripts-v6/models/shopping-cart-facade.js';
import 'src/main/webapp/universal/scripts-v6/spinner.js';
import 'src/main/webapp/universal/scripts-v6/widgets/alert.js';
import 'src/main/webapp/universal/scripts-v6/widgets/confirmation.js';
import 'src/main/webapp/universal/scripts-v6/mixins/event-registrar.js';
import 'src/main/webapp/universal/scripts-v6/widgets/position-helper.js';
import 'src/main/webapp/universal/scripts-v6/widgets/information.js';