import { createLogger } from '@sqs/universal-utils';

const log = createLogger('IntersectionObserverUtils', '#fc0');

/**
 * Use in an IntersectionObserver with threshold 0.
 * In the callback, use this to check if the element is visible to a specific
 * ratio (default IntersectionObserver threshold behavior) or if the element
 * is larger than the window and will never meet the threshold.
 *
 * @param {object} params an IntersectionObserverEntry
 * @param {Element} params.target
 * @param {number} params.intersectionRatio
 * @param {boolean} params.isIntersecting
 * @param {DOMRectReadOnly} params.boundingClientRect
 * @return {boolean}
 */
export const isRatioIntersecting = ({
  target,
  intersectionRatio,
  isIntersecting,
  boundingClientRect
}, triggerRatio = 0.5) => {
  // Must use document.body.contains for ie11 (normally document.contains is
  // fine)
  if (!document.body.contains(target)) {
    // Element is detached but the intersection observer is still observing
    // it, holding on to the reference. You should check for this and
    // unobserve the element in your callback!
    log(
      'Memory leak detected: el is no longer in document.body.',
      target
    );
    return false;
  }

  // E.g. element was destroyed
  if (!window.innerHeight) {
    return false;
  }

  // Check if a small element is visible within the trigger ratio
  // I.e., 50% of an image is visible
  if (intersectionRatio >= triggerRatio) {
    return true;
  }

  // Check if a large element that will never meet a threshold is visible
  const isElementLargerThanWindow =
    boundingClientRect.height > window.innerHeight;
  return isElementLargerThanWindow && isIntersecting;
};
