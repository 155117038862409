/**
 * @module squarespace-mixins-event-registrar
 */
YUI.add('squarespace-mixins-event-registrar', function (Y) {

  Y.namespace('Squarespace.Mixins');

  /**
   * A mixin for Y.Base created object that registers an event and then detaches
   * said event when the object is destroyed.
   *
   * @class EventRegistrar
   * @namespace Squarespace.Mixins
   * @extends Base
   */
  Y.Squarespace.Mixins.EventRegistrar = Y.Base.create('eventRegistrar', Y.Base, [], {

    destructor: function () {
      Y.Array.invoke(this.get('eventListeners'), 'detach');
    },

    /*
     * Register an event, so on destruction, it'll safely get detached.
     *
     * @param {eventHandle*|Array} e Event handle to register or an array of eventListeners
     */
    _registerEvent: function () {

      if (Y.Lang.isArray(arguments[0])) {
        this._registerEvent.apply(this, arguments[0]);
      } else {
        for (var i = 0, im = arguments.length; i < im; i++) {
          if (Y.Lang.isValue(arguments[i])) {
            this.get('eventListeners').push(arguments[i]);
          }
        }
      }

    }

  }, {
    ATTRS: {
      eventListeners: {
        value: []
      }
    }
  });

}, '1.0', {
  requires: [
    'array-invoke',
    'base'
  ]
});
