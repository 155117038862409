// -----------------------------------------------------------------------------
//
// This observer has a callback that waits for image loader.
//
// DO NOT USE ASYNC/AWAIT -- it introduces regenerator runtime and bloats
// common.js past limit
//
// -----------------------------------------------------------------------------

import { isEditMode } from 'shared/utils/ClassBasedState';
import polyfillIntersectionObserver from 'shared/utils/polyfillIntersectionObserver';
import { isRatioIntersecting } from 'shared/utils/IntersectionObserverUtils';
import { createLoggers } from '@sqs/universal-utils';

const { err: logError } = createLoggers('BlockAnimationsInitializer', '#fc0');

let observer;

/* eslint-disable max-len */
const animationsQueries = [
  '[class*="block-animation-"]:not(.block-animation-none):not(.animation-loaded)',
  '.combination-animation-custom[class*="individual-animation-"]:not(.individual-animation-none):not(.animation-loaded)',
  '.combination-animation-custom[class*="individual-text-animation-"]:not(.individual-text-animation-none):not(.animation-loaded)',
  '[class*="combination-animation-"]:not(.combination-animation-none):not([class*="combination-animation-custom"]):not(.animation-loaded)',
].join(',');
/* eslint-enable max-len */

const callback = function blockAnimationsIoCallback(entries, createdObserver) {
  entries.forEach(function(e) {
    // E.g. removed image from DOM before observer triggered, like when
    // layout-engine wipes things out on edit mode enter/exit. The observer
    // still holds node references, so we need to unobserve them.
    if (!document.body.contains(e.target)) {
      createdObserver.unobserve(e.target);
      return;
    }

    // The observed element has an image in it that has not loaded yet
    const imageToAnimate = e.target.querySelector('img.loading');
    if (imageToAnimate) {
      // We're already waiting on this image
      if (imageToAnimate.dataset.queuedBlockAnimation) {
        return;
      }
      // Check the intersection state after the image loads
      imageToAnimate.dataset.queuedBlockAnimation = 'true';
      const loadHandler = function checkIntersectionOnLoad(imageLoadEvent) {
        imageLoadEvent.currentTarget.removeEventListener('load', loadHandler);
        callback([e], createdObserver);
      };
      imageToAnimate.addEventListener('load', loadHandler);
      return;
    }

    if (isRatioIntersecting(e)) {
      e.target.classList.add('animation-loaded');
      if (!createdObserver || !createdObserver.unobserve) {
        logError('missing IntersectionObserver');
        return;
      }
      createdObserver.unobserve(e.target);
    }
  });
};

/**
 * @param {Window} win
 */
export const polyfillAndObserve = (win) => {
  if (!win || !win.document) {
    logError('Cannot init animations without a window context');
    return;
  }

  polyfillIntersectionObserver()
    .then(() => {
      if (!observer) {
        observer = new IntersectionObserver(callback, {
          root: null,
          // Fire callback each time element crosses these ratio thresholds
          // until the element is unobserved
          threshold: [ 0, 0.1, 0.25, 0.5, 1 ],
        });
      }

      const animatedItems = win.document.body.querySelectorAll(animationsQueries);
      if (animatedItems.length > 0) {
        animatedItems.forEach(function observeItem(target) {
          target.classList.remove('animation-loaded');
          observer.observe(target);
        });
      }
    })
    .catch((err = {}) => {
      logError(err.message);
    });
};

/**
 * @param {Window} win
 */
export const initializeAnimations = (win) => {
  if (!win || !win.document) {
    logError('Cannot init animations without a window context');
    return;
  }

  // No anims in edit mode
  if (isEditMode(win)) {
    return;
  }

  polyfillAndObserve(win);
  // init Image Block Animations after waiting for window to be fully loaded
  // there are async reflows that occur via the init processes that trigger
  // isIntersecting too early
  win.addEventListener('load', () => polyfillAndObserve(win));
  if (win.Y) {
    win.Y.on('template:dynamicPageReady', () => polyfillAndObserve(win));
  }
  win.addEventListener('beforeunload', () => {
    if (observer) {
      observer.disconnect();
    }
  });
};
