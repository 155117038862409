/**
 * WELCOME TO COMMON JS
 *
 * As you add things to this file keep in mind that this will be loaded on
 * every single Squarespace website and every visitor will have to download
 * this file and execute this file. This file's last measured value is 202KB
 * in production and with one billion page views every KB equals out to 1TB of
 * data that is transfered over the internet and at least 200ms of computation
 * per visitor to evaluate and run the code in this file.
 *
 * WARNING
 *
 * This file is loaded inside an iframe in two different ways:
 *
 * 1. It's loaded in the preview window when a user is editing their site.
 * 2. It's loaded in an iframe when a user is previewing templates on www or during template installation on an existing
 *    site.
 *
 * In the first case the domain of the iframe and the top window match, but in the second case the domains are
 * different, and any attempt to access window.top will throw a security exception. Please check with
 * Y.Squarespace.Utils.isInDamaskFrame() before accessing properties on top.
 */

import 'common/bootstrap';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/common.css';

// -----------------------------------------------------------------------------
// DO NOT ADD OTHER ROLLUPS HERE
//
// DO NOT ADD FILES HERE; ALL CODE IN THESE PATHS ARE EXPOSED AS A PUBLIC API.
// -----------------------------------------------------------------------------
YUI.add('squarespace-common', function(Y) {}, '1.0', { requires: [
  'get',
  'intl',
  '@sqs/layout-engine/aspect-ratio-manager',
  // don't touch promises -- not explicitly used but secretly required
  'squarespace-promises',
  'squarespace-attr-validators',
  'squarespace-commerce-utils',
  'squarespace-cookie-banner-styles',
  'squarespace-currency-symbols',
  'squarespace-deprecated-modules',
  'squarespace-damask-context-globals',
  'squarespace-escmanager',
  'squarespace-follow-buttons',
  'squarespace-form-submit',
  'squarespace-gallery',
  'squarespace-gallery-manager',
  'squarespace-gallery-ng',
  'squarespace-image-loader',
  'squarespace-models-shopping-cart-facade',
  'squarespace-plugin-lightbox2',
  'squarespace-spinner',
  'squarespace-ui-base',
  'squarespace-util',
  'squarespace-video-loader',
  'squarespace-widgets-alert',
  'squarespace-widgets-information',
  'thirdparty-modernizr',
  'yui-throttle'
] });
