// common/bootstrap.js uses the functions below, and bootstrap is loaded on every page view. To minimize bundle size,
// the functions below are isolated from other utils files to minimize the number of unnecessary dependencies loaded.

const LEFT_PRICING_OPTION_SELECTOR = '.ma-pricing-options .left-button';
const RIGHT_PRICING_OPTION_SELECTOR = '.ma-pricing-options .right-button';
const ACCESS_PAGE_CONTAINER_SELECTOR = '#sqs-member-access-page-root';

const togglePricingOptions = (buttonNode, toggleParentNode) => {
  const selectedOptionClassName = 'ma-pricing-option-selected';
  if (buttonNode.classList.contains(selectedOptionClassName)) {
    return;
  }

  const leftPricingOption = toggleParentNode.querySelector(LEFT_PRICING_OPTION_SELECTOR);
  const rightPricingOption = toggleParentNode.querySelector(RIGHT_PRICING_OPTION_SELECTOR);

  if (leftPricingOption && rightPricingOption) {
    // currently there are only two buttons for pricing options
    // if the left button is pressed, then the right button
    // should not be and vice versa
    const leftPressedVal = leftPricingOption.getAttribute('aria-pressed') === 'true';
    leftPricingOption.setAttribute('aria-pressed', `${!leftPressedVal}`);
    rightPricingOption.setAttribute('aria-pressed', `${leftPressedVal}`);
    leftPricingOption.classList.toggle(selectedOptionClassName);
    rightPricingOption.classList.toggle(selectedOptionClassName);

    toggleParentNode.querySelectorAll('.product-price > div').forEach(priceNode => {
      priceNode.classList.toggle('hide');
    });

    // The sections are part of the access denied screen only,
    // whereas only the second clause here should execute on the member area block.
    const accessPageButtonSections = toggleParentNode.querySelectorAll('.join-ma-section');
    if (accessPageButtonSections.length > 0) {
      accessPageButtonSections.forEach(joinSectionNode => {
        joinSectionNode.classList.toggle('hide');
      });
    } else {
      toggleParentNode.querySelectorAll('.join-button').forEach(joinButton => {
        joinButton.classList.toggle('hide');
      });
    }
  }
};

export const setupToggleHandlers = (toggleYUIParentNode) => {
  toggleYUIParentNode.delegate('click', () => {
    togglePricingOptions(toggleYUIParentNode.one(LEFT_PRICING_OPTION_SELECTOR).getDOMNode(),
      toggleYUIParentNode.getDOMNode());
  }, LEFT_PRICING_OPTION_SELECTOR);

  toggleYUIParentNode.delegate('click', () => {
    togglePricingOptions(toggleYUIParentNode.one(RIGHT_PRICING_OPTION_SELECTOR).getDOMNode(),
      toggleYUIParentNode.getDOMNode());
  }, RIGHT_PRICING_OPTION_SELECTOR);
};

export const togglePricingOptionsAccessScreen = (buttonClassSelector) => {
  const parentNode = document.querySelector(ACCESS_PAGE_CONTAINER_SELECTOR);
  if (parentNode) {
    const buttonNode = parentNode.querySelector(buttonClassSelector);
    togglePricingOptions(buttonNode, parentNode);
  }
};
