// this utility allows us to size multiple scaled text nodes as a group
// it does so by finding all the scaled text instances in the block given any instance within that block
// the HTML block will have a single containing element with the class .sqs-block-content
// inside could be many scaled text instances, each being a container with the class .sqsrte-scaled-text-container
// and holding no more than one span.sqsrte-scaled-text
// to properly size multiple scaled text instances we have to treat them as a group and scale them together
function scaleAllTextInNode (node) {
  if (!node) {
    return;
  }

  // find the parent container
  const blockContainer = node.parentElement;
  if (!blockContainer) {
    return;
  }

  // find all the scaled text nodes in the parent
  const allScaledTextNodeContainers = Array.from(blockContainer.querySelectorAll('.sqsrte-scaled-text-container'));
  const allScaledTextNodes = Array.from(blockContainer.querySelectorAll('.sqsrte-scaled-text'));

  // first remove all styles
  allScaledTextNodeContainers.forEach((scaledTextContainer, index) => {
    const text = allScaledTextNodes[index];

    scaledTextContainer.style.maxWidth = '';
    text.style.whiteSpace = '';
    text.style.transform = '';
    text.style.maxWidth = '';
    text.style.height = ''; // I can't explain this
  });

  // get the natural width of the containing element before we change that with descendant styles
  const containerWidth = blockContainer.getBoundingClientRect().width;

  // apply the no wrap, get the measurements, take the nowrap off
  allScaledTextNodeContainers.forEach((scaledTextContainer, index) => {
    const text = allScaledTextNodes[index];

    text.style.whiteSpace = 'nowrap';

    const { fontSize, lineHeight } = window.getComputedStyle(scaledTextContainer);
    const nodeRect = text.getBoundingClientRect();

    const nodeWidth = nodeRect.width;
    const nodeHeight = nodeRect.height;
    const scale = containerWidth / nodeWidth;
    const containerLineHeight = parseFloat((parseInt(lineHeight, 10) / parseInt(fontSize, 10)).toFixed(2));

    // set container height to occupy the correct amount of space
    scaledTextContainer.style.height = `${nodeHeight * scale}px`;
    scaledTextContainer.style.maxWidth = `${containerWidth}px`;
    scaledTextContainer.style.padding = `${nodeHeight * scale * (containerLineHeight - 1)}px 0`;
    // scale the text
    text.style.transform = `scale(${scale})`;
    text.style.whiteSpace = '';
    text.style.maxWidth = `${containerWidth}px`;
  });

  // now that we got all the correct scaling, set them all to be one line again
  allScaledTextNodeContainers.forEach((scaledTextContainer, index) => {
    const text = allScaledTextNodes[index];
    scaledTextContainer.classList.add('loaded');

    text.style.whiteSpace = 'nowrap';
  });
}

export { scaleAllTextInNode };
