// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/follow-buttons.css';
/**
 * @module squarespace-follow-buttons
 */
YUI.add('squarespace-follow-buttons', function(Y) {
  Y.namespace('Squarespace');

  /**
   * @class FollowButtonUtils
   * @namespace Squarespace
   * @static
   */
  Y.Squarespace.FollowButtonUtils = (function() {
    var destroyExistingButton = function(btn) {
      var existingWidgetBB = btn.one('.sqs-follow-button');
      if (existingWidgetBB) {
        var existingWidget = Y.Widget.getByNode(existingWidgetBB);
        if (existingWidget) {
          existingWidget.destroy();
        }
      }
    };

    var render = function(btn) {
      destroyExistingButton(btn);

      var userName = btn.getAttribute('data-username');
      if (userName && userName.length) {
        new Y.Squarespace.Widgets.FollowButton({
          username: userName
        }).render(btn);
      }
    };

    // you're weird --julian
    return {
      /**
       * @method renderAll
       */
      renderAll: function() {
        Y.all('.squarespace-follow-button').each(function(btn) {
          render(btn);
        });
      }
    };
  })();

  Y.on('domready', function() {
    Y.Squarespace.FollowButtonUtils.renderAll();
  });
}, '1.0', {
  requires: [
    'squarespace-follow-button'
  ]
});

