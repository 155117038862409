/**
 * BROKEN OUT FROM common.js Every polyfill here is.
 * Because we have historically relied on these polyfills, and because templates
 * have relied on them and because polyfills need to get injected into the frame,
 * we must continue to include these. However, we should limit ourselves to ES20XX JS
 * Please do not use non-standard polyfills going forward.
 *
 * @deprecated
 */
/* eslint-disable */
export default (win) => {
  /**
   * 11/18/19: Keeping this `matches` polyfill to support the 5k-6k monthly IE6 site visitors
   * https://code.squarespace.net/projects/V6/repos/squarespace-v6/pull-requests/25236/overview?commentId=570192
   */
  if (!win.Element.prototype.matches) {
    win.Element.prototype.matches =
      win.Element.prototype.matchesSelector ||
      win.Element.prototype.mozMatchesSelector ||
      win.Element.prototype.msMatchesSelector ||
      win.Element.prototype.oMatchesSelector ||
      win.Element.prototype.webkitMatchesSelector ||
      function(s) {
        const matches = (this.document || this.ownerDocument).querySelectorAll(s);
        let i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {
          // Silence
        }
        return i > -1;
      };
  }

  if ( typeof win.CustomEvent !== 'function' ) {
    function CustomEvent ( event, params ) {
      params = params || { bubbles: false, cancelable: false, detail: undefined };
      var evt = document.createEvent( 'CustomEvent' );
      evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
      return evt;
    }

    CustomEvent.prototype = win.Event.prototype;

    win.CustomEvent = CustomEvent;

    if (typeof win.Event !== 'function') {
      win.Event = CustomEvent;
    }
  }

  win.String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

  win.Array.prototype.removeRange = function (from, to) {
    this.splice(from,
      !to ||
      1 + to - from + (!(to < 0 ^ from >= 0) && (to < 0 || -1) * this.length));
    return this.length;
  };

  win.Array.prototype.removeAt = function (at) {
    this.removeRange(at, at);
  };

  win.Array.prototype.remove = function (item) {
    for (var i = 0; i < this.length; ++i) {
      if (this[i] === item) {
        this.removeAt(i);
        return;
      }
    }
  };

  if (!win.Array.prototype.shuffle) {
    // this modifies the original array.
    win.Array.prototype.shuffle = function () {
      var s = [];
      while (this.length) s.push(this.splice(Math.random() * this.length, 1));
      while (s.length) this.push(s.pop()[0]);
      return this;
    };
  }

  var CONTAINS_ERROR = 'Array.prototype.contains is non standard. Use Array.prototype.includes instead';
  /** @deprecated **/
  win.Array.prototype.contains = function (val) {
    if (__DEV__) {
      var e = new Error();
      console.warn(CONTAINS_ERROR, e.stack);
    }
    return this.indexOf(val) > -1;
  };

  win.Number.prototype.formatMoney = function (decimalPlaces, decimalSymbol, thousandSymbol, trimWholeNumber) {
    if (__DEV__) {
      console.warn('[formatMoney] is deprecated. Use the Money object instead.');
    }
    var n = this;
    decimalPlaces = isNaN(decimalPlaces = Math.abs(decimalPlaces))
      ? 2
      : decimalPlaces;
    if (trimWholeNumber && n % 1 === 0) {
      decimalPlaces = 0;
    }
    if (!decimalSymbol) decimalSymbol = ".";
    if (!thousandSymbol) thousandSymbol = ",";
    var s = n < 0 ? "-" : "";
    var i = parseInt(n = Math.abs(+n || 0).toFixed(decimalPlaces), 10) +
      "", j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + thousandSymbol : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandSymbol) +
      (decimalPlaces ? decimalSymbol +
      Math.abs(n - i).toFixed(decimalPlaces).slice(2) : "");
  };

  win.Date.prototype.toRelativeTime = function (since) {
    since = since || new Date();
    var delta = (since.getTime() - this.getTime()) / 1000;

    return delta < 60 ? "less than a minute ago" :
      delta < 120 ? "about a minute ago" :
        delta < 3600 ? Math.floor(delta / 60) + " minutes ago" :
          delta < 7200 ? "about an hour ago" :
            delta < 86400 ? "about " + Math.floor(delta / 3600) + " hours ago" :
              delta < 172800 ? "1 day ago" :
              Math.floor(delta / 86400) + " days ago";
  };

  win.Date.prototype.stdTimezoneOffset = function () {
    var jan = new Date(this.getFullYear(), 0, 1);
    var jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  };

  win.Date.prototype.isDST = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
  };

// -----------------------------------------------------------------------------
// Taken from DateJS
// -----------------------------------------------------------------------------

  win.Date.isLeapYear = function (y) {
    return (((y % 4 === 0) && (y % 100 !== 0)) || (y % 400 === 0));
  };

  win.Date.getDaysInMonth = function (year, month) {
    return [31, (Date.isLeapYear(year)
      ? 29
      : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  };

  win.Date.prototype.isLeapYear = function () {
    return (win.Date.isLeapYear(this.getFullYear()));
  };

  win.Date.prototype.getDaysInMonth = function () {
    return win.Date.getDaysInMonth(this.getFullYear(), this.getMonth());
  };

  win.Date.prototype.moveToFirstDayOfMonth = function () {
    return this.set({day: 1});
  };

  win.Date.prototype.moveToLastDayOfMonth = function () {
    return this.set({day: this.getDaysInMonth()});
  };

  win.Date.prototype.addDays = function (value) {
    return this.addMilliseconds(value * 86400000);
    /* 60*60*24*1000 */
  };

  win.Date.prototype.addMilliseconds = function (value) {
    this.setMilliseconds(this.getMilliseconds() + value);
    return this;
  };

  win.Date.prototype.addSeconds = function (value) {
    return this.addMilliseconds(value * 1000);
  };

  win.Date.prototype.addMinutes = function (value) {
    return this.addMilliseconds(value * 60000);
    /* 60*1000 */
  };

  win.Date.prototype.addHours = function (value) {
    return this.addMilliseconds(value * 3600000);
    /* 60*60*1000 */
  };

  win.Date.prototype.addDays = function (value) {
    return this.addMilliseconds(value * 86400000);
    /* 60*60*24*1000 */
  };

  win.Date.prototype.addWeeks = function (value) {
    return this.addMilliseconds(value * 604800000);
    /* 60*60*24*7*1000 */
  };

  win.Date.prototype.addMonths = function (value) {
    var n = this.getDate();
    this.setDate(1);
    this.setMonth(this.getMonth() + value);
    this.setDate(Math.min(n, this.getDaysInMonth()));
    return this;
  };

  win.Date.prototype.addYears = function (value) {
    return this.addMonths(value * 12);
  };

  win.Date._validate = function (value, min, max, name) {
    //if (typeof(value) !== "number") {
    //  throw new TypeError(value + " is not a Number.");
    //} else if (value < min || value > max) {
    //  throw new RangeError(value + " is not a valid value for " + name + ".");
    //}
    return true;
  };

  win.Date.validateMillisecond = function (n) {
    return win.Date._validate(n, 0, 999, "milliseconds");
  };

  win.Date.validateSecond = function (n) {
    return win.Date._validate(n, 0, 59, "seconds");
  };

  win.Date.validateMinute = function (n) {
    return win.Date._validate(n, 0, 59, "minutes");
  };

  win.Date.validateHour = function (n) {
    return win.Date._validate(n, 0, 23, "hours");
  };

  win.Date.validateDay = function (n, year, month) {
    return win.Date._validate(n, 1, win.Date.getDaysInMonth(year, month),
      "days");
  };

  win.Date.validateMonth = function (n) {
    return win.Date._validate(n, 0, 11, "months");
  };

  win.Date.validateYear = function (n) {
    return win.Date._validate(n, 1, 9999, "seconds");
  };

  win.Date.prototype.set = function (config) {
    var x = config;

    if (!x.millisecond && x.millisecond !== 0) {
      x.millisecond = -1;
    }
    if (!x.second && x.second !== 0) {
      x.second = -1;
    }
    if (!x.minute && x.minute !== 0) {
      x.minute = -1;
    }
    if (!x.hour && x.hour !== 0) {
      x.hour = -1;
    }
    if (!x.day && x.day !== 0) {
      x.day = -1;
    }
    if (!x.month && x.month !== 0) {
      x.month = -1;
    }
    if (!x.year && x.year !== 0) {
      x.year = -1;
    }

    if (x.millisecond !== -1 && win.Date.validateMillisecond(x.millisecond)) {
      this.addMilliseconds(x.millisecond - this.getMilliseconds());
    }
    if (x.second !== -1 && win.Date.validateSecond(x.second)) {
      this.addSeconds(x.second - this.getSeconds());
    }
    if (x.minute !== -1 && win.Date.validateMinute(x.minute)) {
      this.addMinutes(x.minute - this.getMinutes());
    }
    if (x.hour !== -1 && win.Date.validateHour(x.hour)) {
      this.addHours(x.hour - this.getHours());
    }
    if (x.month !== -1 && win.Date.validateMonth(x.month)) {
      this.addMonths(x.month - this.getMonth());
    }
    if (x.year !== -1 && win.Date.validateYear(x.year)) {
      this.addYears(x.year - this.getFullYear());
    }

    /* day has to go last because you can't validate the day without first knowing the month */
    if (x.day !== -1 &&
      win.Date.validateDay(x.day, this.getFullYear(), this.getMonth())) {
      this.addDays(x.day - this.getDate());
    }
    if (x.timezone) {
      this.setTimezone(x.timezone);
    }
    if (x.timezoneOffset) {
      this.setTimezoneOffset(x.timezoneOffset);
    }

    return this;
  };
};
/* eslint-enable */
