import get from 'lodash/get';

/**
 * Get app domain from static context
 */
const getAppDomain = function () {
  return get(window, 'Static.SQUARESPACE_CONTEXT.appDomain', '');
};

/**
 * Get the domain for a given website identifier. (ie. identifier + app domain)
 */
const getDomainForIdentifier = function (websiteIdentifier, appDomain) {
  return websiteIdentifier + '.' + (appDomain || getAppDomain());
};

/**
 * Get the domain for a given website. (ie. identifier + app domain)
 */
const getWebsiteDomain = function (website, appDomain) {
  return getDomainForIdentifier(website.identifier, (appDomain || getAppDomain()));
};

/**
 * Get the static domain for accessing assets
 *
 * For local dev, or if the appDomain has not been set, the static asset domain
 * is presumed to be /universal
 *
 * For infinity instances, the static asset domain is always
 * assets.infinity.sqsp.net/universal, as all Infinity instances share static
 * asset storage
 *
 * All other instances use their own asset subdomain relative to their
 * appDomain, e.g. assets.stage.sqsp.net for staging
 */
const getStaticDomain = function () {
  if (__DEV__) {
    return '/universal';
  }

  const appDomain = getAppDomain();
  if (!(appDomain)) {
    return '/universal';
  }

  const isInfinityInstance = appDomain.includes('infinity');
  if (isInfinityInstance) {
    return '//assets.infinity.sqsp.net/universal';
  }

  return '//assets.' + appDomain + '/universal';
};


export { getAppDomain, getWebsiteDomain, getDomainForIdentifier, getStaticDomain };
