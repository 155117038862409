"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.blocks.MarqueeAnimationDirection */

/* Java class defined in repository cms-legacy-data */
var MarqueeAnimationDirection;

(function (MarqueeAnimationDirection) {
  MarqueeAnimationDirection["LEFT"] = "left";
  MarqueeAnimationDirection["RIGHT"] = "right";
})(MarqueeAnimationDirection || (MarqueeAnimationDirection = {}));

var _default = MarqueeAnimationDirection;
exports["default"] = _default;
module.exports = exports.default;