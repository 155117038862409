/**
 * @module squarespace-gismo
 */
YUI.add('squarespace-gismo', function(Y) {

  /**
   * Gismo is a lightweight animation, timer, and event management system for YUI classes
   * This is a Work In Progress.
   *
   * When mixed into a class (Y.Base.create("YourClass", Y.Squarespace.SSWidget, [Y.Squarespace.Gismo]))
   * it adds the following methods:
   *
   * - _anim( [element - required], [toProperties - required], [other Y.Anim options - optional])
   *  -- usage this._anim('body', {opacity: 1}, {duration: 0.5})
   * - _event( [event - required], [type - optional])
   *  -- usage this._event(Y.one('body').on('mousemove', function() { alert('move'); }), 'mousemove')
   * - _flushEvents( [type - required] ) -- usage this._flushEvents('mousemove')
   * - _timer( [timer - required], [type - optional])
   *  -- usage this._timer(Y.later(100, this, function() { alert('hey'); }), 'alerter');
   *
   * the type parameter is used to group events and timers, so you can detach / cancel them easily.
   *  by default, the type is generic.
   *
   * @class GismoBase
   * @namespace Squarespace
   */

  Y.Squarespace.GismoBase = function() {
    this._gismoEvents = {};
    this._timers = {};
    this._anims = this._anims || [];
  };

  Y.Squarespace.GismoBase.prototype = {
    destructor: function() {
      Y.Object.each(this._gismoEvents, function(events, type) {
        Y.Array.invoke(events, 'detach');
        this._gismoEvents[type] = null;
      }, this);

      Y.Object.each(this._timers, function(timers, type) {
        Y.Array.invoke(timers, 'cancel');
        this._timers[type] = null;
      }, this);

      if (this._anims.shift) {
        Y.Array.invoke(this._anims, 'destroy');
        this._anims = null;
      } else if (this._anims.destructor) {
        this._anims.destructor();
      }
    },

    /*
     * Animations
     * @method _anim
     * @private
     */
    _anim: function(el, to, opts) {
      var anim = el.anim(to, opts || {});
      anim.on('end', function() {
        this._anims.remove(anim);
      }, this);
      this._anims.push(anim);

      return anim;
    },

    /*
     * Event Management
     * @method _event
     * @private
     */

    _event: function(e, type) {
      type = type || 'generic';
      this._gismoEvents[type] = this._gismoEvents[type] || [];
      this._gismoEvents[type].push(e);
    },

    _getEvents: function(type) {
      return this._gismoEvents[type || 'generic'];
    },

    _flushEvents: function (type) {
      var events = this._gismoEvents[type || 'generic'];

      if (events) {
        Y.Array.invoke(events, 'detach');
        this._gismoEvents[type] = null;
      }
    },

    /*
     * Timer Management
     * @method _time
     * @private
     */
    _timer: function(timer, type) {
      type = type || 'generic';
      this._timers[type] = this._timers[type] || [];
      this._timers[type].push(timer);
    }

  };

}, '1.0', { requires: [
  'array-invoke',
  'squarespace-anims'
] });
