/**
 * parses the DOM from a given node to find the elements needed to control the marquee animations
 * @param {HTMLElement} node element containing the marquee HTML
 * @returns {object} object with relevant nodes and values
 */
const getMarqueeReferences = node => ({
  wrapper: node.querySelector('.Marquee'),
  display: node.querySelector('.Marquee-display'),
  props: node.querySelector('.Marquee-props'),
  measurementContainer: node.querySelector('.Marquee-measure'),
  firstMeasuredItemNode: node.querySelector('.Marquee-measure h1, .Marquee-measure p'),
  originGroupItemNodes: Array.from(
    node.querySelectorAll('.Marquee-measure .Marquee-item')
  ),
  trackContainer: node.querySelector('.Marquee-track'),
  svg: node.querySelector('.Marquee-svg'),
  svgGroup: node.querySelector('.Marquee-svg-group'),
  svgGroupItemNodes: Array.from(
    node.querySelectorAll('.Marquee-svg-group > *')
  ),
  path: node.querySelector('.Marquee-path'),
  pathGroup: node.querySelector('.Marquee-path-group'),
  hitbox: node.querySelector('[data-hitbox]'),
  pathHitbox: node.querySelector('.Marquee-path-hitbox'),
  pathHitboxFocusOutline: node.querySelector('.Marquee-path-hitbox-focus-outline'),
  pathHitboxGroup: node.querySelector('.Marquee-path-hitbox-group'),
  textPath: node.querySelector('.Marquee-svg-text'),
  visuallyHiddenTextContent: node.querySelector('.v6-visually-hidden'),
});

export default getMarqueeReferences;
