import ButtonContext from '@sqs/enums/ButtonContext';
import { isMailto as UrlUtilsIsMailTo } from 'shared/utils/UrlUtils';
import { trackButtonClick, trackButtonView } from './CensusUtils';

function ButtonBlockTrackingInitializer(node) {

  const anchorNode = node.one('a');

  if (!anchorNode || anchorNode.getAttribute('data-initialized')) {
    return;
  }

  const buttonWrapperNode = anchorNode.get('parentNode');
  const buttonBlockNode = anchorNode.ancestor('.sqs-block-button');
  const blockId = buttonBlockNode.getAttribute('id');

  // Safari disallows triggering mailto programatically
  const href = anchorNode.getAttribute('href');
  const isTargetNewWindow = anchorNode.hasAttribute('target');
  const isMailto = UrlUtilsIsMailTo(href);

  const trackingData = {
    id: blockId,
    buttonText: anchorNode.get('innerHTML'),
    clickthroughUrl: href,
    alignment: buttonWrapperNode.getAttribute('data-alignment'),
    size: buttonWrapperNode.getAttribute('data-button-size'),
    newWindow: isTargetNewWindow,
    context: ButtonContext.BUTTON_BLOCK
  };

  trackButtonView(trackingData);

  anchorNode.on('click', function(e) {

    if (anchorNode.hasAttribute('data-tracked')) {
      anchorNode.removeAttribute('data-tracked');
      return;
    }

    const shouldPreventDefault = (!isTargetNewWindow || e.metaKey || e.ctrlKey || e.shiftKey) && !isMailto;
    if (shouldPreventDefault) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }

    trackButtonClick(trackingData).then(function() {
      if (shouldPreventDefault) {
        anchorNode.setAttribute('data-tracked', true);
        anchorNode.simulate('click');
      }
    });
  });

  anchorNode.setAttribute('data-initialized', true);

}

export default ButtonBlockTrackingInitializer;