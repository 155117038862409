/**
 * Support for RequestAnimationFrame
 *
 * Will only overwrite Y.Anim methods if native rAF/cancelRAF support exists. -bc
 *
 * @module squarespace-anim-raf
 */
YUI.add('squarespace-anim-raf', function (Y) {

  var win = Y.config.win;
  var nativeRequestAnimFrame = win.requestAnimationFrame;
  var nativeCancelAnimFrame = win.cancelAnimationFrame || win.cancelRequestAnimationFrame;

  var vendors = ['moz', 'webkit'];
  var i;

  for ( i = 0; i < vendors.length && !nativeRequestAnimFrame; i++ ) {
    nativeRequestAnimFrame = win[vendors[i] + 'RequestAnimationFrame'];
    nativeCancelAnimFrame = win[vendors[i] + 'CancelAnimationFrame'] ||
                            win[vendors[i] + 'CancelRequestAnimationFrame'];
  }

  if (nativeCancelAnimFrame && nativeRequestAnimFrame) {

    /**
     * @var _requestId
     * @type {Null|Number} requestAnimationFrame will return an interger value for its Id.
     */
    var _requestId = null;

    /**
     * Overwrites Y.Anim's default method to utilize rAF.
     * Starts timer for all Y.Anim instances
     *
     * @method _startTimer
     * @for Anim
     */
    Y.Anim._startTimer = function() {

      if (!_requestId) {
        _requestId = nativeRequestAnimFrame(Y.Anim._runAnimFrame);
      }

    };

    /**
     * Overwrites Y.Anim's default method to utilize rAF.
     * Cancels requestAnimationFrame
     *
     * @method _stopTimer
     * @for Anim
     */
    Y.Anim._stopTimer = function() {

      if (_requestId) {
        nativeCancelAnimFrame(_requestId);
        _requestId = null;
      }

    };

    /**
     * New method that calls itself at next repaint,
     *   and wraps Y.Anim's default _runAnimFrame
     *
     * @method _runAnimFrame
     * @for Anim
     */
    Y.Anim._runAnimFrame = function() {

      if (_requestId) {
        _requestId = nativeRequestAnimFrame(Y.Anim._runAnimFrame);

        Y.Anim._runFrame();
      }

    };

  }


}, '1.0', { requires: [
  'anim'
] });