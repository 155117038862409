/*
 * Squarespace Spinner
 * @module squarespace-spinner
 */
YUI.add('squarespace-spinner', function(Y) {

  /**
   * Squarespace spinner
   * @class       Spinner
   * @extends     Widget
   * @namespace   Squarespace
   * @constructor
   */
  Y.namespace('Squarespace').Spinner = Y.Base.create('Spinner', Y.Widget, [], {

    renderUI: function() {
      this.get('boundingBox').addClass(this.get('color'));
      var size = this.get('size');
      if (!Y.Lang.isNumber(size)) {
        this.get('boundingBox').addClass(size);
      }
    },

    /**
     * Spin!!
     * @method spin
     */
    spin: function() {
      this.get('boundingBox').show();
    },

    /**
     * Stahp!
     * @method stop
     */
    stop: function() {
      this.get('boundingBox').hide();
    },

    addClass: function(className) {
      this.get('boundingBox').addClass(className);
    }

  }, {
    CSS_PREFIX: 'sqs-spin',
    NS: 'spinner',
    ATTRS: {
      /**
       * Size. One of
       *  * extra-small
       *  * small
       *  * default
       *  * large
       *  * extra-large
       *  * custom value (number)
       * @attribute size
       * @type      String|Number
       * @default default
       */
      size: {
        value: 'default', // extra-small, small, default, large, extra-large
        setter: function(val) {
          if (Y.Lang.isNumber(val)) {
            this.set('width', val);
            this.set('height', val);
          }
          return val;
        }
      },
      /**
       * 'light' or 'dark'
       * @attribute color
       * @type      String
       * @default light
       */
      color: {
        value: 'light' // dark, light
      }
    }
  });
}, '1.0', { requires: [
  'base',
  'widget'
] });
