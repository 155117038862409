YUI.add('squarespace-escaping-utils', function(Y) {

  Y.namespace('Squarespace');

  // ------------------------------------------------------------------------------------------------
  // Escaping/Formatting
  // ------------------------------------------------------------------------------------------------
  /**
    A collection of functions for escaping strings.  Split out from squarespace-util on 12/30/14
    @class Escaping
    @static
    @namespace Squarespace
  */
  Y.Squarespace.Escaping = {

    /**
     * Return the string with some special HTML characters escaped.
     * Replaces fewer things than YUI's {{#crossLink "Escape/html:method"}}{{/crossLink}}.
     * @method escapeForHtml
     * @param  {String} s
     * @return {String}
     */
    escapeForHtml: function (s) {

      if (!s) {
        return s;
      }

      return s.replace(/&/g, '&amp;').
        replace(/>/g, '&gt;').
        replace(/</g, '&lt;');

    },

    /**
     * Return the string with some special HTML characters escaped, including "quotes".
     * Replaces fewer things than YUI's {{#crossLink "Escape/html:method"}}{{/crossLink}}.
     * @method escapeForHtmlTag
     * @param  {String} s
     * @return {String}
     */
    escapeForHtmlTag: function (s) {

      if (!s) {
        return s;
      }

      return s.replace(/&/g, '&amp;').
        replace(/>/g, '&gt;').
        replace(/</g, '&lt;').
        replace(/"/g, '&quot;').
        replace(/'/g, '&#x27;').
        replace(/`/g, '&#x60;'); /* The backtick character may be used as a quote character by some browsers */

    }
  };
}, '1.0', {
  requires: []
});
