import { get } from 'lodash';

const fallbackCurrency = 'USD';

let storeCurrency = null;

/**
 * Utility to get the current store currency.
 * /!\ Do not add heavy dependencies as it is served to site visitors as well.
 */
const StoreCurrencyManager = {

  getStoreCurrency() {
    if (!storeCurrency) {
      // lazy loading it to make sure the static variable is set.
      storeCurrency = get(window, ['Static', 'SQUARESPACE_CONTEXT',
        'websiteSettings', 'storeSettings', 'selectedCurrency'], fallbackCurrency);
    }
    return storeCurrency;
  },

  updateStoreCurrency(newStoreCurrency) {
    storeCurrency = newStoreCurrency;
  }
};

export default StoreCurrencyManager;
