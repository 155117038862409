/**
 * Inside/Outside YUI.Env.globalEvents sync & ALT bootstrapper
 */

import get from 'lodash/get';
import { IS_SQUARESPACE_MOBILE_APP } from 'shared/utils/SqsVersion';

/**
 * Determine if the page that loaded common.js is in an iFrame.
 * If it is, it will need its ALT stores bootstrapped with data from the
 * parent frame.
 *
 * @param {Window} targetWindow
 * @return {boolean}
 */
function inFrame(targetWindow) {
  try {
    const isSameWindow = targetWindow.top !== targetWindow;
    const hasYuiEnv = get(targetWindow, 'top.YUI.Env', false);
    return isSameWindow && hasYuiEnv;
  } catch (e) {
    /* noop */
  }
  return false;
}

/**
 * @param {Window} targetWindow
 * @return {object} merged context
 */
function getContext(targetWindow) {
  return targetWindow.top.Static.SQUARESPACE_CONTEXT;
}

/**
 * Some things (commerce, website settings, beta features) need ALT.
 * Bootstrap ALT store with site and page context from an external source.
 * Ensure that YUI.Env.globalEvents is in sync for inside and outside.
 *
 * @param {Window} targetWindow
 */
export default function bootstrap(targetWindow) {
  // The mobileV7 mobile APP is not in an iFrame. Context data is injected
  // into the webview by the app, which is merged into the backend-provided
  // context in <script data-name="static-context">)
  // @see ../../src/apps/MobileV7/utils/bootstrapAlt.js
  const isBootstrapRequired = IS_SQUARESPACE_MOBILE_APP || inFrame(targetWindow);
  if (!isBootstrapRequired) {
    return;
  }

  targetWindow.YUI.Env.globalEvents = targetWindow.top.YUI.Env.globalEvents;

  const bootstrapper = get(targetWindow, 'StaticActions.bootstrap', null);
  if (!bootstrapper) {
    return;
  }

  // The native app injects a mobile context that we need to use, merge with
  // the server-provided SQUARESPACE_CONTEXT.
  const context = getContext(targetWindow);
  bootstrapper(context); // Dispatch ALT action
}
