import { setI18nInstance as setupElements } from '@sqs/rosetta-elements/setup-i18n';
import { setI18nInstance as setupCompositions } from '@sqs/rosetta-compositions/setup-i18n';
import { setI18nInstance as setupCoreComponents } from '@sqs/core-components/setup-i18n';
import i18nInstance from 'shared/i18n';

/**
 * Order of imports must be preserved to match the dependency tree
 *
 * Core-Components depends on both Elements and Compositions
 * Compositions depends on Elements
 * Elements depends on neither
 */
setupElements(i18nInstance);
setupCompositions(i18nInstance);
setupCoreComponents(i18nInstance);
