import WebsiteType from '@sqs/enums/WebsiteType';
import StatusConstants from '@sqs/enums/StatusConstants';
import cookieCutter from '@sqs/cookie-cutter';
import trackInternal from 'shared/utils/analytics/trackInternal';
import LogoutAPI from 'shared/utils/LogoutAPI';
import isAppleiPadOS13 from 'shared/utils/isAppleiPadOS13';

/**
  Squarespace Utilities module, should really be split up into a bunch of separate modules
  It includes system-wide constants/enums at the top
  @module squarespace-util
  @requires yahoo, dom, event, element
*/
YUI.add('squarespace-util', function (Y) {

  // ---------------------------------------------------------------------------
  // Constants
  // ---------------------------------------------------------------------------

  Y.Squarespace.API_ROOT = '/api/';
  Y.Squarespace.REST_API_ROOT = '/api/rest/';

  // -------------------------------------------------------------------------------------------------------------
  // Template Helpers
  // -------------------------------------------------------------------------------------------------------------

  /**
    A haphazard collection of utility functions
    @class Utils
    @static
    @namespace Squarespace
  */
  Y.Squarespace.Utils = {

    /**
     * Sign out of Squarespace and reload the current page.
     * @method logout
     */
    logoutAndReloadPage: function () {
      LogoutAPI.logout()
        .then(function () {
          document.location.reload();
        })
        .catch(function (error) {
          if (__DEV__) {
            console.error(error);
          }
        });
    },
    /**
     * Sign out of Squarespace.
     * @method logout
     */
    logout: function () {

      if (this.LOGGING_OUT || !Static.SQUARESPACE_CONTEXT.authenticatedAccount) {
        return;
      }

      this.LOGGING_OUT = true;

      LogoutAPI.logout()
        .then(function () {
          // fire global event
          Y.Global.fire('squarespace:logout');

          var pathToRedirectTo = '/';

          if (Y.config.win.CONFIG_PANEL) {
            pathToRedirectTo = window.CONFIG_PANEL.get('previewFrame').get('url');
          }

          // notify and bounce off of https
          document.location.href = [
            'http://',
            document.location.host,
            pathToRedirectTo,
            '?logout=true'
          ].join('');

          Y.Squarespace.Utils.removeSharedSSIdentity();
        })
        .catch(function (error) {
          console.error(error);
        });
    },

    /**
      Randomly generates a GUID
      @method getGuid
      @return {String} A GUID
    */
    getGuid: function () {
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      return S4() + S4() + S4() + S4() + S4();
    },

    /**
     * Get a browser-specific classname.
     *
     * @method getBrowserClassName
     * @return {String} className
     */
    getBrowserClassName: function () {
      var browsers = {
        gecko: 'browser-gecko',
        safari: 'browser-safari',
        ie: 'browser-msie'
      };

      var className = '';

      Y.Object.some(browsers, function (val, key) {
        if (Y.UA[key]) {
          className = val;
          return true;
        }
      });

      if (isAppleiPadOS13()) {
        className += ' ipad-os-thirteen';
      }

      return className;
    },

    /**
     * Check to see if coverPageOnly is enabled.
     *
     * @method isCoverPageOnly
     * @return {Boolean} Whether or not the lock screen beta feature is enabled.
     */
    isCoverPageOnly: Y.cached(function() {
      return Y.Object.getValue(Static,
        'SQUARESPACE_CONTEXT.website.websiteType'.split('.')) === WebsiteType.COVER_PAGE;
    }),

    /**
     * Check to see if this site is just a parking page
     *
     * @method isParkingOnly
     * @return {Boolean} Whether or not the lock screen beta feature is enabled.
     */
    isParkingPage: Y.cached(function() {
      return Y.Object.getValue(Static,
        'SQUARESPACE_CONTEXT.website.websiteType'.split('.')) === WebsiteType.PARKING_PAGE;
    }),

    /**
     * Check to see if this site is IAP
     */
    isIAP: Y.cached(function() {
      return Y.Object.getValue(Static,
        ['SQUARESPACE_CONTEXT', 'website', 'siteStatus', 'value']) === StatusConstants.RESOLD;
    }),

    /**
     * Check to see if this site is IAP and in the RESOLD_GRACE_PERIOD state
     */
    isIAPGracePeriod: Y.cached(function() {
      return Y.Object.getValue(Static,
        ['SQUARESPACE_CONTEXT', 'website', 'siteStatus', 'value']) === StatusConstants.RESOLD_GRACE_PERIOD;
    }),

    /**
     * Is template developer mode enabled?
     * @method isDeveloperModeEnabled
     * @return {Boolean}
     */
    isDeveloperModeEnabled: function () {
      return !!Y.Object.getValue(Y.config.win,
        'Static.SQUARESPACE_CONTEXT.website.developerMode'.split('.'));
    },

    /**
     * Check to see if this function is in the damask preview frame.
     */
    isInDamaskFrame: function () {
      try {
        return (window.top.Y || window.top.YUI_NOT_YET_LOADED) && window.top.Y !== Y;
      } catch (e) {
        // Assume this is a cross-domain security exception from accessing window.top during template preview. In
        // template install and on www we show a preview of the template in an iframe, and win.top will be a different
        // domain. This is not a damask preview frame, we don't need editors or anything else fancy. We just want to
        // treat this preview window exactly the same as when a visitor views the site.
        return false;
      }
    },

    /**
      Simple method to check if cookies are turned off
      in the browser
      @method areCookiesEnabled
    */
    areCookiesEnabled: function () {
      if (Y.Lang.isUndefined(this._cookiesEnabled)) {
        this._cookiesEnabled = ('cookie' in document && (document.cookie.length > 0 ||
        (document.cookie = 'test').indexOf.call(document.cookie, 'test') > -1));
      }
      return this._cookiesEnabled;
    },

    /**
     * @method storeSharedSSIdentity
     */
    storeSharedSSIdentity: function () {
      try {
        var data = {
          identifier: Y.Object.getValue(Static, ['SQUARESPACE_CONTEXT', 'website',
            'identifier'
          ])
        };
        var value = btoa(JSON.stringify(data));
        cookieCutter.set('ss_lastid', value, {
          domain: Static.SQUARESPACE_CONTEXT.appDomain,
          path: '/'
        });
      } catch (e) {
        if (__DEV__) {
          console.warn('Failed to store ss_lastid', e);
        }
      }
    },

    /**
     * @method removeSharedSSIdentity
     */
    removeSharedSSIdentity: function () {
      try {
        cookieCutter.set('ss_lastid', {
          domain: Static.SQUARESPACE_CONTEXT.appDomain,
          expires: new Date(0),
          path: '/'
        });
      } catch (e) {
        if (__DEV__) {
          console.warn('Failed to remove ss_lastid', e);
        }
      }
    },

    /**
     * Binds a on 'tap' event on mobile or a 'click' event otherwise.
     *
     * @method onPointerAction
     * @param  {Node} target Node target to add.
     * @param  {Function} callback Callback function
     * @param  {Object} context Context object
     * @param  [arg*] 0..n addition arguments to supply to the subscriber
     * @return {EventHandle}
     */
    onPointerAction: function (target, callback, context) {
      var args = Array.prototype.slice.call(arguments);
      args.splice(1, 0, 'on');
      return Y.Squarespace.Utils._attachPointerAction.apply(this, args);
    },


    /**
     * Attach events to a node target.
     * @param  {Node} target Node target to add.
     * @param  {String} timing The event attaching method, ie "on" or "onceAfter"
     * @param  {Function} callback Callback function
     * @param  {Object} context Context object
     * @param  [arg*] 0..n addition arguments to supply to the subscriber
     * @return {EventHandle}
     */
    _attachPointerAction: function (target, timing, callback, context) {
      var args = Array.prototype.slice.call(arguments);
      args.splice(0, 2);
      args.splice(0, 0, 'click');

      return target[timing].apply(target, args);
    }
  };

  // ------------------------------------------------------------------------------------------------
  // Marketing Tracking
  // ------------------------------------------------------------------------------------------------

  /**
   * Marketing tracking
   * @class Marketing
   * @namespace Squarespace
   * @static
   */
  Y.Squarespace.Marketing = {

    trackLanding: function () {

      var urlParams = Y.QueryString.parse(document.location.search.substring(1));
      var validParams = ['source', 'campaign', 'subcampaign', 'channel', 'subchannel',
        'refer', 'variation', 'mkwid'
      ];

      var data = {
        'landing': document.location.href,
        'refer': document.referrer,
        'rk': parseInt(Math.random() * 99999999, 10)
      };

      if (navigator.language) {
        data.lang = navigator.language.toLowerCase();
      } else if (navigator.browserLanguage) {
        data.lang = navigator.browserLanguage.toLowerCase();
      }

      if (self.screen) {
        data.screen = screen.width + 'x' + screen.height;
      }

      for (var i = 0; i < validParams.length; ++i) {
        var p = validParams[i];
        if (p in urlParams) {
          data[p] = urlParams[p];
        }
      }

      var image = new Image(1, 1);
      image.src = '/api/track/Track?' + Y.QueryString.stringify(data);

    }

  };

  // ------------------------------------------------------------------------------------------------
  // Analytics
  // ------------------------------------------------------------------------------------------------

  /**
   * Analytics
   * @class Analytics
   * @namespace Squarespace
   * @static
   */
  Y.Squarespace.Analytics = {

    /**
     * Track an internal action.
     *
     * @param  {String}   event    Name of event
     * @param  {Object}   [data={}]     Event data to pass back, JSON.stringified
     * @param  {Function} [callback] Optional callback function
     * @return {[type]}            [description]
     */
    trackInternal: function (event, data, callback) {
      trackInternal(event, data).then(callback);
    }

  };


  // ------------------------------------------------------------------------------------------------
  // Misc
  // ------------------------------------------------------------------------------------------------
  /**
    A haphazard collection of miscellaneous functions
    @method isPercentage
    @for Squarespace.Lang
  */
  Y.namespace('Squarespace.Lang').isPercentage = function (str) {
    return (Y.Lang.isString(str) && str.search('%') > 0);
  };

  /**
   * Performs `{placeholder}` substitution on a string. The object passed as the
   * second parameter provides values to replace the `{placeholder}`s.
   * `{placeholder}` token names must match property names of the object. For example,
   *
   *`var greeting = Y.Lang.sub("Hello, {who}!", { who: "World" });`
   *
   * `{placeholder}` tokens that are undefined on the object map will be left
   * in tact (leaving unsightly `{placeholder}`'s in the output string).
   *
   * @method sub
   * @param {string} s String to be modified.
   * @param {object} o Object containing replacement values.
   * @return {string} the substitute result.
   * @static
   * @since 3.2.0
   */
  Y.namespace('Squarespace.Lang').sub = function (s, o) {
    var SUBREGEX = /\{\s*([^|}]+?)\s*(?:\|([^}]*))?\s*\}/g;

    if (!s.replace) {
      return s;
    }

    return s.replace(SUBREGEX, function (match, key) {
      var value = Y.Object.getValue(o, key.split('.'));

      if (Y.Lang.isUndefined(value)) {
        return match;
      }

      return value;
    });
  };


  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////
  // Bail out here if we're loading in the template engine
  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  if (!Y.Base) {
    return;
  }
  // All functions below here are browser only

  // WORKAROUND: YUI 3.5.0 anim cannot handle animating rgba values. These can sneak in via the tweak layer.
  // Instead of erroring out, monkeypatch Y.Color to coerce to non-alpha rgb.
  if (Y.Color) { // util.js is loaded on server side but anim isn't
    var oldToRGB = Y.Color.toRGB;

    Y.Color.re_RGBA = //eslint-disable-line camelcase
      /^rgba\(([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+),\s*(\d+(?:\.\d+)?)\)$/i;

    Y.Color.toRGB = function (val) {

      if (Y.Color.re_RGBA.exec(val)) {
        return 'rgb(' + RegExp.$1 + ',' + RegExp.$2 + ',' + RegExp.$3 + ')';
      }

      return oldToRGB(val);

    };
  }

  // Y.SQSAnim -- handles .run() calls after the node is destroyed a lot better.
  Y.SQSAnim = Y.Base.create('SQSAnim', Y.Anim, [], {
    run: function () {
      if (this.get('destroyed') || this.get('node')._node === null || !this.get('node').inDoc()) {
        this.fire('end');
        return this;
      }
      return Y.Anim.prototype.run.call(this);
    }
  });

  /**
   * Core/frontend node extensions (more/backend in ui-base)
   * @class Node
   */
  var easeInOutCubic = function (elapsedTime, initialValue, delta, totalLength) {
    elapsedTime /= totalLength / 2;
    if (elapsedTime < 1) {return delta / 2 * elapsedTime * elapsedTime * elapsedTime + initialValue;}
    elapsedTime -= 2;
    return delta / 2 * (elapsedTime * elapsedTime * elapsedTime + 2) + initialValue;
  };

  Y.augment(Y.Node, Class.create({

    /**
     * @for Node
     * @method anim
     * @param  properties
     * @param  {Object} options
     * @param  {Object} ctx
     */
    anim: function (properties, options, ctx) {
      options = Y.merge({
        node: this,
        duration: 0.5,
        easing: easeInOutCubic,
        to: properties
      }, options);

      if (this.activeAnim) {
        this.activeAnim.stop(true);
      }

      this.activeAnim = new Y.SQSAnim(options);
      this.activeAnim.on('end', function () {
        delete this.activeAnim;
      }, this);

      // bind extra events
      if (options.end) {
        this.activeAnim.on('end', options.end, ctx || options.context);
      }
      if (options.start) {
        this.activeAnim.on('start', options.start, ctx || options.context);
      }

      // node destroy
      // listen for when this node gets destroyed
      if (!this.nodedestroynotifier) {
        this.plug(Y.Squarespace.NodeDestroyNotifier, {
          onDestroy: function () {
            if (this.activeAnim) {
              this.activeAnim.stop(true);
            }
          },
          context: this
        });
      }

      return this.activeAnim;
    },

    /**
     * @method getAdjustedDimensions
     * @param  {Object} [settings]
     * @for Node
     */
    getAdjustedDimensions: function (settings) {

      // var debug = new Y.Squarespace.Debugger({name: 'getAdjustedDimensions', uid: true});

      // SCALE = {contain | cover}
      // contain -- forces the element size to be contained in the node -- will re-center the element if necessary
      // cover -- ensures the node completely covers the parent node

      var el = this;

      if (!settings) {
        settings = {};
      }
      if (!settings.scale) {
        settings.scale = 'cover';
      }
      if (!settings.align) {
        settings.align = 'center';
      }

      var parent = el.get('parentNode');
      var actualDimensions = this.getAttribute('data-image-dimensions');
      var imageWidth;
      var imageHeight;

      if (actualDimensions) {

        // Clean up dimensions data
        actualDimensions = actualDimensions.split('x');
        actualDimensions = {
          width: parseInt(actualDimensions[0], 10),
          height: parseInt(actualDimensions[1], 10)
        };

        imageWidth = actualDimensions.width;
        imageHeight = actualDimensions.height;

      } else {

        // store
        if (!el.getData('width') && !el.getData('height')) {
          el.setData('width', settings && settings.width ? parseInt(settings.width, 10) :
            el.getAttribute('width') || el.get('offsetWidth'));
          el.setData('height', settings && settings.height ? parseInt(settings.height,
            10) : el.getAttribute('height') || el.get('offsetHeight'));
        }

        // dude, don't tell me it's a percentage
        if (Y.Squarespace.Lang.isPercentage(el.getData('width'))) {
          imageWidth = parent.get('offsetWidth') * (parseInt(el.getData('width'), 10) /
            100);
        } else {
          imageWidth = parseInt(el.getData('width'), 10);
        }

        if (Y.Squarespace.Lang.isPercentage(el.getData('height'))) {
          imageHeight = parent.get('offsetHeight') * (parseInt(el.getData('height'), 10) /
            100);
        } else {
          imageHeight = parseInt(el.getData('height'), 10);
        }

      }

      var parentWidth = settings.containerWidth || parent.get('offsetWidth') - (parent.get(
        'offsetWidth') - parent.get('clientWidth'));
      var parentHeight = settings.containerHeight || parent.get('offsetHeight') - (
        parent.get('offsetHeight') - parent.get('clientHeight'));

      var imageRatio = imageWidth / imageHeight;
      var parentRatio = parentWidth / parentHeight;

      var top;
      var left;

      var ratio;

      // Set the parent to overflow none, if the overflow isn't already.
      if (settings.scale && parent.getStyle('overflow') !== 'hidden') {
        parent.setStyle('overflow', 'hidden');
      }

      // Calculate the ratio

      if (settings.sizeOnly) {
        ratio = parentWidth / imageWidth; // never rely on height -- we want to push the height as appropriate
      } else if (settings.scale === 'contain') {
        ratio = imageRatio > parentRatio ? parentWidth / imageWidth : parentHeight /
          imageHeight;
      } else {
        ratio = imageRatio > parentRatio ? parentHeight / imageHeight : parentWidth /
          imageWidth;
      }

      var newWidth = imageWidth;
      var newHeight = imageHeight;

      // And now the meat & bones
      if (settings.scale === 'contain') {

        newWidth = imageWidth * ratio;
        newHeight = imageHeight * ratio;

        top = newHeight < parentHeight ? (parentHeight - newHeight) / 2 : 0; // always set to center
        left = newWidth < parentWidth ? (parentWidth - newWidth) / 2 : 0; // yep -- center

        if (settings.align.indexOf('left') !== -1) {
          left = 0;
        } else if (settings.align.indexOf('right') !== -1) {
          left = (parentWidth - newWidth);
        }

        if (settings.align.indexOf('top') !== -1) {
          top = 0;
        } else if (settings.align.indexOf('bottom') !== -1) {
          top = (parentHeight - newHeight);
        }

        // offsets
        newWidth -= (this.get('offsetWidth') - this.get('clientWidth'));
        newHeight -= (this.get('offsetHeight') - this.get('clientHeight'));

      } else if (settings.scale === 'cover') {

        newWidth = imageWidth * ratio;
        newHeight = imageHeight * ratio;

        top = newHeight > parentHeight ? (newHeight - parentHeight) / -2 : 0;
        left = newWidth > parentWidth ? (newWidth - parentWidth) / -2 : 0;

        // If there's some focalPoint data, use it.
        if (settings.focalPoint) {

          /*
          // offsets
          var offsetX = newWidth - parentWidth;
          var offsetY = newHeight - parentHeight;

          left = -1 * parseInt( offsetX * settings.focalPoint[0], 10 );
          top = -1 * parseInt( offsetY * settings.focalPoint[1], 10 );
          */

          left = Math.min(Math.max((parentWidth / 2) - (newWidth * settings.focalPoint[0]),
            (parentWidth - newWidth)), 0);
          top = Math.min(Math.max((parentHeight / 2) - (newHeight * settings.focalPoint[1]),
            (parentHeight - newHeight)), 0);

        }
      }

      return {
        top: top,
        left: left,
        width: newWidth,
        height: newHeight
      };

    },

    /**
     * @param {Object} settings
     */
    resizeToParent: function (settings) {

      var dimensions = this.getAdjustedDimensions(settings);
      var position = this.getStyle('position');


      if (settings.sizeOnly) {

        // Set the styles.
        this.setStyles({
          'width': dimensions.width,
          'height': dimensions.height
        });

        var embedEl = this.one('embed');

        if (embedEl) {
          this.one('embed').setStyles({
            'width': dimensions.width,
            'height': dimensions.height
          });
        }

        // fix the zIndex issue
        if (this.test('iframe')) {
          var src = this.getAttribute('src');

          if (src) {
            if (src.indexOf('?') !== -1) {
              var _src = src.split('?');
              if (_src[1].indexOf('wmode=transparent') === -1) {
                this.setAttribute('src', _src[0] + '?wmode=transparent&' + _src[1]);
              }
            } else {
              this.setAttribute('src', src + '?wmode=transparent');
            }
          }
        }

        if (embedEl && embedEl.getAttribute('wmode') !== 'transparent') {
          this.one('embed').setAttribute('wmode', 'transparent');
        }

      } else {

        /*
          This is to fix strange numerical precision errors that were causing
          problems with setStyles in Firefox. For example, dimensions.left
          could be a value like 1.4210854715202004e-14. Doing setStyles with
          this value is ignored and the style is not actually set.
         *
          Really, we do not need more than 5 significant digits for this operation,
          so we can safely convert to fixed-point notation.
         */
        var precision = 5;

        // Set the styles.
        this.setStyles({
          'position': (position !== 'relative' && position !== 'absolute' ?
            'relative' : position),
          'top': dimensions.top.toFixed(precision),
          'left': dimensions.left.toFixed(precision),
          'width': dimensions.width.toFixed(precision),
          'height': dimensions.height.toFixed(precision)
        });

      }

    },

    /**
     * @method width
     */
    width: function () {
      return this.get('offsetWidth');
    },

    /**
     * @method height
     */
    height: function () {
      return this.get('offsetHeight');
    },

    /**
     * @method setWidth
     * @param width
     */
    setWidth: function (width) {
      this.setStyle('width', width);
    },

    /**
     * @method setHeight
     * @param  height
     */
    setHeight: function (height) {
      this.setStyle('height', height);
    }


  }));

  // ---------------------------------------------------------------------------
  // Cross-browser CSS3 vendor hooks
  // ---------------------------------------------------------------------------

  // This ensures that setStyle/setStyles with vendor prefixed properties generates the
  // vendor-appropriate CSS.
  var VENDOR_CAMEL_PREFIXES = ['Webkit', 'Moz', 'O', 'ms'];
  var EXPAND_PROPS = ['transition', 'transitionProperty', 'transitionDuration',
    'transitionTimingFunction', 'transitionDelay', 'backfaceVisibility', 'userSelect',
    'borderBottomLeftRadius', 'borderBottomRightRadius'
  ];

  Y.Array.each(VENDOR_CAMEL_PREFIXES, function (prefix) {
    Y.Array.each(EXPAND_PROPS, function (property) {
      var vendorProp = prefix + property.charAt(0).toUpperCase() + property.slice(1);
      if (vendorProp in Y.config.doc.documentElement.style) {
        Y.DOM.CUSTOM_STYLES[property] = {
          set: function (node, val, style) {
            style[vendorProp] = val;
            style[property] = val;
          },
          get: function (node, style) {
            Y.DOM.getComputedStyle(node, vendorProp);
          }
        };
      }
    });
  });

  // ---------------------------------------------------------------------------
  // You know when you place a breakpoint, and then the browser
  // decides it needs to put you through 15 degrees of hell where you
  // spend half your time clicking "play" on the y.io failure.
  //
  // yeah, this is for that.
  // ---------------------------------------------------------------------------

  Y.IO.prototype._destroy = function (transaction) {
    if (Y.config.win && !transaction.notify && !transaction.xdr) {
      if (!transaction.upload && transaction.c) {
        transaction.c.onreadystatechange = null;
      } else if (transaction.upload) {
        transaction.c.upload.onprogress = null;
        transaction.c.onload = null;
        transaction.c.onerror = null;
      }
    }

    transaction = transaction.c = null;
  };

}, '1.0', {
  requires: [
    'anim',
    'base',
    'event',
    'io',
    'json',
    'jsonp',
    'node',
    'node-event-delegate',
    'plugin',
    'promise',
    'querystring',
    'selector',
    'squarespace-anim-raf',
    'squarespace-data',
    'squarespace-dom-emitters-resize',
    'squarespace-logger',
    'squarespace-plugin-node-destroy-notifier',
    'squarespace-public-api',
    'squarespace-system-error',
    'squarespace-ui-base',
    'yui-later'
  ]
});
