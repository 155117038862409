/**
 * @param {Object} args
 * @param {Element} args.parent to look inside for selector
 * @param {string} args.selector to find in parent
 * @param {number} [args.minLength=1] number of nodes we expect to find matching
 * @param {number} [args.frequencyMs=100] how often in ms to recheck DOM
 * @param {number} [?args.maxRetries] how many times to recheck
 * selector. 0 means infinite retries! If > 99999999, sets to 0
 * @return {Promise} resolved when we find `minLength` nodes matching
 * `selector` in `parent`; rejected if exceeds maxRetries
 */
function waitForExistence({
  parent,
  selector,
  minLength = 1,
  frequencyMs = 100,
  maxRetries = 0, // 0 for infinite retries
} = {}) {
  if (!parent || !selector) {
    return Promise.reject('[waitForExistence] Called without a parent or selector');
  }

  return new Promise((resolve, reject) => {
    let retries = 0;
    if (maxRetries > 99999999) { // avoid int overflow on incrementing retries
      maxRetries = 0;
    }

    const check = () => {
      if (!parent) {
        // if you're waiting for an iframe.contentDocument to exist and the
        // iframe has navigated away or been removed, the contentDocument's
        // parent (iframe) will no longer have a reference
        reject('[waitForExistence] parent does not or no longer exists');
        return;
      }
      const nodes = Array.from(parent.querySelectorAll(selector));
      if (nodes.length >= minLength) {
        resolve(nodes);
        return;
      }
      if (maxRetries !== 0) {
        retries = retries + 1;
        if (retries > maxRetries) {
          reject(`[waitForExistence] Did not find ${selector} in ${frequencyMs * retries}ms`);
          return;
        }
      }
      window.setTimeout(check, frequencyMs);
    };

    check();
  });
}

export default waitForExistence;
