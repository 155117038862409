import { clamp, debounce } from 'lodash';
import onResize from '@sqs/viewport-raf-resize';
import IntersectionScroll from '@sqs/viewport-intersection-scroll';
import MarqueeAnimationDirection from '@sqs/enums/MarqueeAnimationDirection';
import animationDirectionToValue from 'shared/constants/marquee/animationDirectionToValue';
import lerp from 'shared/utils/marquee/lerp';
import getMarqueeReferences from 'shared/utils/marquee/getMarqueeReferences';
import setIdOnMarqueeReferences from 'shared/utils/marquee/setIdOnMarqueeReferences';
import Wave from './Wave';

const mediaQueryList = window.matchMedia('(prefers-reduced-motion: reduce)');

const getPrefersReducedMotion = () => mediaQueryList.matches;

/**
 * The `MarqueeSVG` controller is used for marquee's that use the
 * 'wave' display. For this option, we use an svg approach so that
 * we may leverage `<textPath>`.
 */
export default class MarqueeSVG {
  /** @type {object} */
  defaultAnimationProps = {
    waveFrequency: 1,
    waveIntensity: 0,
    animationDirection: MarqueeAnimationDirection.LEFT,
    animationSpeed: 1.0,
    pausedOnHover: false,
  };

  /** @type {object} */
  info = {
    pathLength: 0,
  };

  /** @type {number} */
  prevRegionWidth = -1;

  /** @type {number} */
  prevTextHeight = -1;

  /** @type {object} */
  uniforms = {
    hover: { value: 1 },
  };

  /** @type {IntersectionScroll} */
  intersectionScroll = null;

  /** @type {number} */
  rAFID = null;

  /**
   * @param {HTMLElement} node - the target node
   * @param {Object} Y - the YUI Global
   * @class
   */
  constructor (node, Y) {
    this.node = node;
    this.Y = Y;

    this.ref = getMarqueeReferences(this.node);

    // get a unique ID to use on elements referring to the SVG path
    const blockId = this.node.closest('.sqs-block-marquee').id.replace('block-', '');

    // set block references
    setIdOnMarqueeReferences(blockId, this.ref);

    // get the props from the DOM to control the animation settings
    this.props = {
      ...this.defaultAnimationProps, // just in case
      ...JSON.parse(this.ref.props.textContent)
    };

    // mark the marquee as ready so the measure elements are hidden
    this.ref.wrapper.dataset.ready = true;

    const { animationDirection, animationSpeed } = this.props;

    /** @type {Wave} */
    this.wave = new Wave({
      container: this.ref.textPath,
      animationDirection,
      originGroupItemNodes: this.ref.originGroupItemNodes,
      path: this.ref.path,
      sharedUniforms: this.uniforms,
      animationSpeed: animationDirectionToValue[animationDirection] * animationSpeed,
      svgGroupItemNodes: this.ref.svgGroupItemNodes,
      trackContainer: this.ref.trackContainer,
    });

    this.render();

    this.bindListeners();
  }

  /**
   * Render Marquee element using available block dimensions
   *
   * @param {object} props - the config props
   * @param {boolean} props.isForce - whether the resize was forced (see section tweak)
   * @override
   */
  render = ({ isForce = false } = {}) => {
    const { waveIntensity } = this.props;
    const regionWidth = Math.max(this.node.clientWidth, 50);
    const measurementRect = this.ref.measurementContainer.getBoundingClientRect();
    const firstMeasuredItemRect = this.ref.firstMeasuredItemNode.getBoundingClientRect();
    const measuredItems = this.ref.originGroupItemNodes.map((node) => {
      const rect = node.getBoundingClientRect();
      const computedStyle = window.getComputedStyle(node);
      const spacing = parseFloat(computedStyle.marginRight) || 0;

      return {
        width: rect.width,
        spacing,
      };
    });

    const textHeight = firstMeasuredItemRect.height;
    const transformYOffset = textHeight * 0.1;

    if (
      regionWidth !== this.prevRegionWidth ||
      textHeight !== this.prevTextHeight ||
      isForce
    ) {
      // Redraw the path only when relevant dimensions
      // have changed
      this.redrawPath({
        regionWidth,
        textHeight,
        transformYOffset,
      });
    }

    this.prevRegionWidth = regionWidth;
    this.prevTextHeight = textHeight;

    this.wave.onResize({
      measuredItems: measuredItems,
      measuredWidth: Math.max(measurementRect.width, 50),
      pathLength: this.info.pathLength,
      regionWidth,
    });

    // if there is wave intensity we increase the overall height to account for the waves
    // add a magic 6 to account for the 2px of focus outline above and below and a 1px gap on either side
    this.ref.svg.style.height = `${textHeight + waveIntensity + 6}px`;
    // translate the svg down to account for the line height of the measured text being removed in the SVG
    this.ref.svg.style.transform = `translateY(${transformYOffset}px)`;

    // outline offset is magic numbers to make the stroke 2px thick depending on the curvature
    this.ref.pathHitboxFocusOutline.style.transform = `translateY(${transformYOffset + 2}px)`;
  };

  /**
   * re-render the marquee, forcing it to recalculate all dimensions
   * doesn't not rebind/remove listeners or other lifecycle methods
   */
  rerender = () => {
    this.debouncedRerender = debounce(this.render, 1000);

    this.debouncedRerender();
  };

  /**
   * Redraw the motion path (on init/resize)
   *
   * @param {object} props - the config props
   * @param {number} props.regionWidth - the region width
   * @param {number} props.textHeight - the text height
   * @private
   */
  redrawPath ({ regionWidth = 100, textHeight = 10, transformYOffset = 0 } = {}) {
    const { waveIntensity, waveFrequency } = this.props;
    const waveFrequencyWidth = lerp(250, 1000, clamp(waveFrequency * 0.1, 0, 1));
    const waveFrequencyCurve = waveFrequencyWidth * 0.333;
    const numPoints =
      Math.ceil((regionWidth + waveFrequencyWidth * 2) / waveFrequencyWidth) + 1;
    const startPosition = -waveFrequencyWidth;
    const offsetY = textHeight * 0.662;
    const getCurveData = (offset = 0) => [...Array(numPoints).keys()].reduce((res, i) => {
      const x = startPosition + i * waveFrequencyWidth;
      // Approximate y offset to take into account ascenders/descenders (avoid clipping)
      const y = offset + (i % 2 === 0 ? 0 : waveIntensity);

      return (
        res +
        (i === 0 ? ` L${x},${y}` : ` S${x - waveFrequencyCurve},${y} ${x},${y}`)
      );
    }, '');

    // Build the path data for the hitbox
    const pathData = `M${startPosition},${offsetY} ${getCurveData(offsetY)}`;
    const pathDataForHitBoxFocusOutline = `
      M${startPosition},${waveIntensity + textHeight}
      ${getCurveData(textHeight - transformYOffset)}
      M${startPosition},${Math.max(waveIntensity, transformYOffset)}
      ${getCurveData(transformYOffset)}
    `;

    this.ref.path.setAttribute('d', pathData);

    // Approximate the offset of text underline
    this.ref.pathGroup.setAttribute(
      'transform',
      `translate(0, ${textHeight * 0.05})`
    );

    // For links, apply a hitbox region that follows the wave
    this.ref.pathHitbox.setAttribute('d', pathData);
    this.ref.pathHitbox.setAttribute('stroke-width', textHeight);

    // pathHitboxFocusOutline
    this.ref.pathHitboxFocusOutline.setAttribute('d', pathDataForHitBoxFocusOutline);
    this.ref.pathHitboxGroup.setAttribute(
      'transform',
      `translate(0, ${-offsetY + offsetY * 0.75})`
    );

    this.info.pathLength = this.ref.path.getTotalLength();

    this.lerps = this.buildLerps(3);
  }

  /**
   * Build the interpolations that are used to cache the path point
   * data for faster lookup (x/y/rotation). The alternative would be to call the
   * `getPointAtLength(n)` for every tracked item on animation frame which is
   * expensive and unnecessary if we can pre-cache this data at a specified
   * acceptable precision and use internally known values to get the result.
   *
   * @param {integer} precision - the precision (higher number = less precision)
   * @returns {object} the configured lerps
   * @private
   */
  buildLerps (precision = 3) {
    const count = Math.ceil(this.info.pathLength / precision) + 1;

    return [...Array(count).keys()].reduce(
      (res, i) => {
        const n = i * precision;
        const point = this.ref.path.getPointAtLength(n);

        res.points[i] = point;
        res.index[i] = n;
        res.x[i] = point.x;
        res.y[i] = point.y;

        if (i > 0) {
          // Apply rotation values to the previous index
          const prevIndex = i - 1;
          const p1 = res.points[prevIndex];
          const p2 = res.points[i];

          res.rotation[prevIndex] =
            (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;

          if (i === count - 1) {
            // Is last index, use the previous rotation
            res.rotation[i] = res.rotation[prevIndex];
          }
        }

        return res;
      },
      {
        index: [],
        points: [],
        x: [],
        y: [],
        rotation: [],
      }
    );
  }

  onTick () {
    this.wave.tick(this.lerps);
  }

  bindListeners () {
    onResize.on(this.render, 0);

    mediaQueryList.addEventListener('change', this.handleMediaQueryListChange);

    this.intersectionScroll = new IntersectionScroll(this.node, {
      onIntersection: this.onIntersection,
      normalize: false,
    });

    if (this.ref.hitbox && this.props.pausedOnHover) {
      this.ref.hitbox.addEventListener('mouseenter', this.onPointerEnter);
      this.ref.hitbox.addEventListener('touchstart', this.onPointerEnter);
      this.ref.hitbox.addEventListener(['touchend', 'touchcancel'], this.onPointerLeave);
      this.ref.hitbox.addEventListener(['mouseleave'], this.onPointerLeave);
    }

    this.tweakListener = this.Y.Global.on('tweak:change', this.rerender);

    // call rerender when all the fonts are ready in case they happen to load later than the rest of the page
    document.fonts.ready.then(() => {
      this.rerender();
    });
  }

  /**
   * Unbind the listeners (called on destroy)
   *
   * @private
   */
  unbindListeners () {
    onResize.off(this.render, 0);

    mediaQueryList.removeEventListener('change', this.handleMediaQueryListChange);

    this.intersectionScroll?.destroy();

    if (this.ref.hitbox && this.props.pausedOnHover) {
      this.ref.hitbox.removeEventListener('mouseenter', this.onPointerEnter);
      this.ref.hitbox.removeEventListener('touchstart', this.onPointerEnter);
      this.ref.hitbox.removeEventListener(['touchend', 'touchcancel'], this.onPointerLeave);
      this.ref.hitbox.removeEventListener(['mouseleave'], this.onPointerLeave);
    }

    this.tweakListener?.detach();
  }

  handleMediaQueryListChange = (event) => {
    const prefersReducedMotion = event.matches;
    if (prefersReducedMotion) {
      this.stop();
    } else {
      this.start();
    }
  }

  /**
   * On viewport intersection (via `IntersectionScroll`)
   * When a marquee block is out of the viewport we stop the animation to limit the work the browser is doing
   *
   * @param {boolean} isIntersecting - whether the node is intersecting the viewport
   * @private
   */
  onIntersection = (isIntersecting) => {
    if (isIntersecting && !getPrefersReducedMotion()) {
      this.isImmediate = true;
      this.start();

      return;
    }

    this.stop();
  }

  /**
   * On pointer enter
   *
   * @private
   */
  onPointerEnter = () => {
    this.ref.pathHitbox.dataset.hover = true;
    this.uniforms.hover.value = 0;
  }

  /**
   * On pointer leave
   *
   * @private
   */
  onPointerLeave = () => {
    this.ref.pathHitbox.dataset.hover = false;
    this.uniforms.hover.value = 1;
  }

  /**
   * Start animation (when Marquee is visibile in viewport)
   *
   * @private
   */
  start () {
    this.rAFID = requestAnimationFrame(this.tick);
  }

  /**
   * Stop (on intersection exit)
   *
   * @private
   */
  stop () {
    cancelAnimationFrame(this.rAFID);
  }

  /**
   * restart the animation; used when moving/resizing the block in the editor
   *
   * @public
   */
  restart () {
    this.stop();
    this.start();
  }

  tick = () => {
    this.onTick();
    this.rAFID = requestAnimationFrame(this.tick);
  }

  destroy = () => {
    this.unbindListeners();
    this.stop();
    this.wave.destroy();
    this.debouncedRerender?.cancel?.();
    delete this.node;
  }
}
