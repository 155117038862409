module.exports = {
  "03ed8a0a8208f38d91ea43fcfb2674fa": "Cart",
  "129d605196848a5638c140d2ee728388": "Checkout",
  "177e608763b10b394a59507332431b9e": "Update the missing or invalid information above.",
  "267b971d79b2fd102479491abeece7a1": "Unable to update quantity",
  "379317016f8f9e771879bf7dd63c15b6": "Continue Shopping",
  "4f4eb57cee331e4bce9b9b2b9e8787ec": "{price}, total price for {item}",
  "4f7eec6a53703ee7e5f759e1d5e2a932": "{item} alert information",
  "5594067ec2eb7899758e4961f9edc86b": "Unable to update form",
  "5b0c6983e64dfd82f5948970fdb62cd4": "Remove {item}",
  "76034908ec4e681b5486eb2040618f52": "Summary",
  "87bb6b3e520f3909e9e90f2386e89960": "Save",
  "896b6eee6556a3723e31ad740e64d38a": "Shopping Cart",
  "8dbc0b4f78369772fd9d831f990d543c": "{name} variant for {item}",
  "8e733ed90c5bf49d5613c720508a60dd": "Please try again.",
  "8ee71d998d748ba00e9e80234ce3a89d": "Subtotal label",
  "90f2d72735116a9f7d4ec2aef34957bb": {
    "one": "One item in cart",
    "other": "{%n} items in cart"
  },
  "97a89a6a325cbc309ebe4e597f9fc1f9": "Subtotal price",
  "9f6b08d833a751dcd0af336891007285": "No image",
  "a4cde40c49be65e0781faad94fcbe519": "Quantity, {item}",
  "a8958871ab8577d6667b57be6ef975ed": "{item}",
  "b22005e29f0c7dd253328702493c917e": "Unable to open form",
  "b97b63060e510629f77447c892a821ab": "Edit {item} details",
  "bffdc4040665ab2a6af115da4073829a": "To check out, your order subtotal must be {subtotal} or more.",
  "ca02eaf2ea5e4b8b5f41df0576414393": {
    "one": "More than one item in cart",
    "other": "More than {%n} items in cart"
  },
  "d043e6155f48bd75d2b2c48f3b0e9258": "Please check your internet connection.",
  "d16d3227422da60a63f98c59082c1029": "Subtotal",
  "d16fa1f1d19313d233e2e75b13a1ddf3": "{item} image",
  "dd5ce0a01f6e1c36fa9177510819e3d6": "Edit product details",
  "e66ff745b1e97900c957e5a0a9f01ead": "Cancel",
  "ea8d571cae88f8ae3cf5a6e7b007fa5f": "You have nothing in your shopping cart.",
  "f62e405c0a46f7352e63fede4db0d913": "Okay",
  "f8ab967379a4c6bbb037e255f201604a": "Edit Details",
  "fd657139d48231170d72b9031b2a97c7": "Unable to remove item"
};