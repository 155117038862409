import get from 'lodash/get';
import { mount, unmount } from 'shared/utils/CookieBanner';
import WebsiteOverlaysUtils from 'shared/utils/WebsiteOverlaysUtils';

export default function initCookieBanner(Y) {
  // Only show on pages/collections, not api endpoint-rendered pages such as
  // /api/commerce/admin/email-preview/contribution-confirmed (Settings
  // > Donations confirmation email preview)
  // @see site-server/src/main/java/com/squarespace/v6/template/SquarespaceHeaders.java
  if (!Y || !get(Y, 'config.win.Static.COOKIE_BANNER_CAPABLE')) {
    return;
  }

  const cookieBannerSettings = get(Y, 'config.win.Static.SQUARESPACE_CONTEXT.cookieSettings');
  const shouldShowCookieBanner = cookieBannerSettings && WebsiteOverlaysUtils.shouldShowCookieBanner({
    settings: cookieBannerSettings,
    urlSearch: window.location.search,
  });
  if (shouldShowCookieBanner) {
    mount(Y.config.win.Static.SQUARESPACE_CONTEXT.cookieSettings);
  }

  // Events fired from damask
  Y.Global.on('cookieBanner:force', function heardCookieBannerForce(settings) {
    // Need to check that the window (iframe) reference still exists
    if (!get(Y, 'config.win.document.body')) {
      return;
    }
    mount(Y.merge({ force: true }, settings));
    Y.config.win.document.body.classList.add('sqs-cookie-banner-v2-forced');

    Y.Global.once('cookieBanner:unforce', function heardCookieBannerUnforce(updateSettings) {
      Y.Global.detach('cookieBanner:update');
      // Need to check that the window (iframe) reference still exists
      if (!get(Y, 'config.win.document.body')) {
        return;
      }
      Y.config.win.document.body.classList.remove('sqs-cookie-banner-v2-forced');
      // Remount but let normal cookie logic take place
      mount(updateSettings);
    });

    Y.Global.on('cookieBanner:update', function heardCookieBannerUnforce(updateSettings) {
      // Need to check that the window (iframe) reference still exists
      if (!get(Y, 'config.win.document.body')) {
        return;
      }
      unmount();
      mount(Y.merge({ force: true }, updateSettings));
    });
  });
}
