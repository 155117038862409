/**
 * Provide utilities for working with {{#crossLink "Promise"}}Promises{{/crossLink}}.
 *
 * @module squarespace-promises
 */
YUI.add('squarespace-promises', function(Y) {

  function noop() {

  }

  /**
   * @class Promises
   * @namespace Squarespace
   * @static
   */
  var Promises = Y.namespace('Squarespace.Promises');

  /**
   * Execute an arbitrary number of promises and return a
   * promise containing an array of the results of each promise,
   * in the order they were given.
   *
   * The resulting promise rejects if any of the given promises
   * fail to resolve.
   *
   * @for Squarespace.Promises
   * @method all
   * @param {Array} promises* The promises to resolve
   * @return {Promise} A promise that resolves to an array of the values to which the given promises resolve.
   * @static
   * @example
   *     Y.Squarespace.Promises.all( Y.when(2), Y.when(3) )
   *       .then(function(array) {
   *         console.log(array); // prints [2, 3]
   *       });
   */
  Promises.all = function() {
    var promises = new Y.Array(arguments);
    return new Y.Promise(function(resolve, reject) {
      var results = [];
      var numPromises = promises.length;
      var valuesResolved = 0;
      var markComplete = function(index) {
        return function(value) {
          valuesResolved++;
          results[index] = value;

          if (valuesResolved >= numPromises) {
            resolve(results);
          }
        };
      };

      // per the promises spec, if an empty array is passed in, resolve with an empty array
      // http://www.ecma-international.org/ecma-262/6.0/#sec-promise.all-resolve-element-functions
      if (numPromises === 0) {
        return resolve([]);
      }

      Y.Array.each(promises, function(promise, index) {
        Y.when(promise).then(markComplete(index), reject);
      });
    });
  };

  /**
   * Resolve a single promise then pass its value to `next` if
   * it is successful.
   *
   * @for Squarespace.Promises
   * @method resolve
   * @param {Promise} promise The promise to resolve
   * @param {Function} fn The function to run after the promise is resolved.
   * @return {Promise} The promise gained by chaining the given function to the resolution of the given promise.
   * @static
   * @example
   *     // This...
   *     Y.Squarespace.Promises.resolve(promise, fn);
   *     // Is equivalent to this
   *     promise.then(fn);
   */
  Promises.resolve = function(promise, next) {
    return promise.then(next);
  };

  /**
   * Attempt to resolve a single promise then pass its erroneous value to `next` if
   * it is not successful.
   *
   * @for Squarespace.Promises
   * @method reject
   * @param {Promise} promise The promise to reject
   * @param {Function} fn The function to run after the promise is rejected
   * @return {Promise} The promise gained by chaining the given function to the rejection of the given promise.
   * @static
   * @example
   *     // This..
   *     Y.Squarespace.Promises.reject(promise, fn);
   *     // Is equivalent to this
   *     promise.then(noop, fn);
   */
  Promises.reject = function(promise, next) {
    return promise.then(noop, next);
  };

  /**
   * Create a promise that always rejects with a given value
   *
   * @for Squarespace.Promises
   * @method rejectWith
   * @param value {Object} The value to reject with
   * @return {Promise} The promise that always rejects to the given value.
   * @static
   */
  Promises.rejectWith = function (value) {
    return Y.Squarespace.Promises.create(function (resolve, reject) {
      reject(value);
    });
  };

  /**
   * Run a function when a promise finishes, regardless of its resolved or rejected status.
   *
   * @for Squarespace.Promises
   * @method finish
   * @param {Promise} promise The promise to which the function will be attached
   * @param {Function} fn The function to run whenever the promise is resolved or rejected.
   * @return {Promise} The promise that is the result of attaching the fn to both the resolve/reject results.
   * @static
   * @example
   *     // Log the final result of the promise, no matter what.
   *     function log(res) { console.log(res); };
   *
   *     // This..
   *     Y.Squarespace.Promises.finish(promise, log);
   *     // Is equivalent to this
   *     promise.then(log, log);
   */
  Promises.finish = function(promise, finishFn) {
    return promise.then(finishFn, finishFn);
  };

  /**
   * Chains an arbitary number of promise-producing functions under a given context.
   * This is particuarly useful for chaining member functions.
   *
   * @for Squarespace.Promises
   * @method chainFns
   * @param {Array} promises* The promises to chain together
   * @param {Object} [ctx] The context in which the functions will be run
   * @return {Function} A function that will resolve each promise generated by the given functions in a chain.
   * @static
   * @example
   *     // This...
   *     Y.Squarespace.chainFns([
   *       this._firstDialog,
   *       this._confirmationDialog,
   *       this._RESTcall,
   *       this._showSuccessDialog
   *     ], this)();
   *
   *     // Is equivalent to this
   *
   *     this._firstDialog()
   *         .then(Y.bind(this._confirmationDialog, this))
   *         .then(Y.bind(this._RESTcall, this))
   *         .then(Y.bind(this._showSuccessDialog, this));
   *
   *
   *     // Because the result is just a promise, error handling is normal
   *
   *     var actions = Y.Squarespace.chainFns([
   *       this._firstDialog,
   *       this._confirmationDialog,
   *       this._RESTcall,
   *       this._showSuccessDialog
   *     ], this)();
   *
   *     Y.Squarespace.Promises.reject(actions, Y.bind(this._showErrors, this));
   *
   */
  Promises.chainFns = function(promises, ctx) {
    return function() {
      ctx = ctx || this;
      var first = promises.length > 0 ? promises.shift().apply(ctx, arguments) : Y.when();
      var resolveWithContext = function(promise, promiseFn) {
        return Y.Squarespace.Promises.resolve(Y.when(promise), Y.bind(promiseFn, ctx));
      };

      return Y.Array.reduce(promises, first, resolveWithContext);
    };
  };

  /**
   * Create a promise.
   * This has a very similar API to Y.Promise's constructor except it takes a context.
   *
   * @for Squarespace.Promises
   * @method create
   * @param {Function} fn The function with which the promise will be created
   * @param {Object} ctx The context in which the function will be run
   * @return {Promise} The created promise
   * @static
   *
   * @example
   *     // This...
   *     Y.Squarespace.Promises.create(function(resolve, reject) {}, this);
   *     // Is equivalent to this
   *     new Y.Promise(Y.bind(function(resolve, reject) {}, this));
   *
   */
  Promises.create = function(fn, ctx) {
    return new Y.Promise(Y.bind(fn, ctx));
  };

}, '1.0', {
  requires: [
    'promise'
  ]
});
