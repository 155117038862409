/**
 * @module squarespace-widgets-gismo
 */
YUI.add('squarespace-widgets-gismo', function (Y) {

  Y.namespace('Squarespace.Widgets');

  /**
   * I know, I know. You're wondering what this is. It barely makes sense,
   * because Gismos are a competing paradigm with Widgets. Yes, it's as absurd as
   * a "Bird-bat" or a "Whale-fish" or running Windows on a Mac. It's legacy, I'm sorry.
   *
   * @class Gismo
   * @extends Widget
   * @namespace Squarespace.Widgets
   * @uses Squarespace.GismoBase
   * @constructor
   */
  Y.Squarespace.Widgets.Gismo = Y.Base.create('Gismo', Y.Widget,
    [Y.Squarespace.GismoBase]);

}, '1.0', {
  requires: [
    'base',
    'squarespace-gismo',
    'widget'
  ]
});
