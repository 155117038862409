/**
 * These polyfills are included in damask (outside the frame), common.js (V6
 * sites outside config), and the dynamic assets loader (inside the frame, V6)
 */

import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/array';
import 'core-js/es/promise';
import 'core-js/es/string';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/stable/dom-collections/for-each';
import 'core-js/stable/url-search-params';
import './classList'; // polyfill-library version of this feature does not work in our context

import 'polyfill-library/polyfills/__dist/requestIdleCallback/min';
import 'polyfill-library/polyfills/__dist/Element.prototype.remove/min';
import 'polyfill-library/polyfills/__dist/Element.prototype.closest/min';
