/**
 * Provides a goto utility module for fetching globals from either the Damask
 * frame or the preview frame.
 *
 * @module squarespace-damask-context-globals
 * @example
 *   var targetY = Y.Squarespace.Damask.ContextGlobals.get('Y');
 *
 *   var contentWindow = Y.Squarespace.Damask.ContextGlobals.getWindow();
 *   contentWindow.get('Static.SQUARESPACE_CONTEXT');
 *
 *   var frameY = Y.Squarespace.Damask.ContextGlobals.fromFrame('Y');
 */
YUI.add('squarespace-damask-context-globals', function(Y) {

  Y.namespace('Squarespace.Damask');

  /**
   * A colletion of utility functions for accessing globals from frames
   * in Damask.
   *
   * @class ContextGlobals
   * @namespace Squarespace.Damask
   * @static
   */
  Y.Squarespace.Damask.ContextGlobals = {

    /**
     * Checks whether or not we are in the Damask universe, and that
     * the CONFIG_PANEL (the global reference to the Damask controller) is setup.
     *
     * @method isDamask
     * @public
     * @static
     */
    isDamask: function() {
      return (Y.Lang.isValue(CONFIG_PANEL));
    },

    /**
     * Gets the preview frame's contentWindow.
     *
     * @method frameWindow
     * @return {Node} The window object wrapped in Node.
     * @public
     * @static
     */
    frameWindow: function() {

      if (!Y.Lang.isValue(Y.config.win.frames['sqs-site-frame'])) {
        console.warn('[ContextGlobals] frameWindow method called from a non-damask frame.');
        return Y.config.win;
      }

      return CONFIG_PANEL.getFrameContentWindow();
    },

    /**
     * Gets the top frame's contentWindow.
     *
     * @method topWindow
     * @return {Node} The window object wrapped in Node.
     * @public
     * @static
     */
    topWindow: function() {
      var win = Y.config.win;

      try {
        // check if current window is indeed inside the damask iframe.
        if (Y.Lang.isValue(win.frameElement) && win.frameElement.id === 'sqs-site-frame') {
          win = win.parent;
        }
      } catch (e) {
        // nothing to do, fall back to default.
      }

      return Y.one(win);
    },

    /**
     * Gets a reference from the preview frame.
     *
     * @method fromFrame
     * @param {String} [path] The path to the requested value.
     * @return {Any} The value pulled from the preview frame globals, or window if path is unspecified.
     * @public
     * @static
     */
    fromFrame: function (path) {

      var win = this.frameWindow();

      if (Y.Lang.isValue(path)) {

        if (Y.Lang.isArray(path)) {
          return Y.Object.getValue(win.get(path[0]), path.slice(1));
        }

        return win.get(path);

      }

      return win;

    },

    /**
     * Gets a reference from the top frame.
     *
     * @method fromTop
     * @param {String|Array} [path] The path to the requested value.
     * @return {Any} The value pulled from the preview frame globals.
     * @public
     * @static
     */
    fromTop: function (path) {

      var win = this.topWindow();

      if (Y.Lang.isValue(path)) {

        if (Y.Lang.isArray(path)) {
          return Y.Object.getValue(win.get(path[0]), path.slice(1));
        }

        return win.get(path);
      }

      return win;

    },

    /**
     * Gets a reference from the frame if we're in Damask, or the regular
     * global if we're in Cameron.
     *
     * @method get
     * @param {String} [path] The path to the requested value.
     * @return {Any} The value pulled from from the context.
     * @public
     * @static
     */
    get: function(key) {

      if (this.isDamask()) {
        return this.fromFrame(key);
      }
      return this.fromTop(key);


    },

    /**
     * Gets the contentWindow from the frame if we're in Damask, or the regular
     * global if we're in Cameron.
     *
     * @method getWindow
     * @return {Node} The window object wrapped in Node.
     * @public
     * @static
     */
    getWindow: function() {

      if (this.isDamask()) {
        return this.frameWindow();
      }
      return this.topWindow();


    },

    /**
     * Gets a reference from the global scope closest to a node.
     *
     * @method relative
     * @param {Node} node The node to travel up from.
     * @param {String|Array} [path] The path to the requested value.
     * @return {Any} The value requested, or the frame's content window if no path is specified.
     * @public
     * @static
     */
    relative: function (node, path) {

      if (this.isDamask()) {

        var frameY = this.fromFrame('Y');
        if (node.inDoc(frameY.config.doc)) {
          return this.fromFrame(path);
        }

      }

      var topY = this.fromTop('Y');
      if (node.inDoc(topY.config.doc)) {
        return this.fromTop(path);
      }
      if (__DEV__) {
        console.warn('Node is not placed in any document yet');
      }
      return null;

    }

  };

}, '1.0', {
  requires: []
});
