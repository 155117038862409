/*
  FLUID IMAGE UTILS
  This file exists to make sure the Fluid-Engine-compatible layout of
  the image block renders properly in editor mode and site-visitor mode.
*/

import IMAGE_MASK_RATIOS from 'shared/constants/ImageMaskRatios';

export const RESIZE_OBSERVER_NAME = 'objectFitContain';

export function isFluidImage(contentBoxDom) {
  return contentBoxDom && !!contentBoxDom.closest('.design-layout-fluid');
}

export function initializeFluidImageInVisitorView(imageNode) {
  setFluidImageContainerVisitorStyles(imageNode);

  if (doesImageNeedResizeListener(imageNode)) {
    const imageMask = getImageMask(imageNode);
    const isObjectFitCover = false;
    const maskDimensions = getMaskDimensions(imageNode);
    syncDimensionsWithObjectFitContain(imageMask, maskDimensions, isObjectFitCover);
  }
}

function getCurrentShape(imageNode) {
  const imageMask = getImageMask(imageNode);
  const currentShape = imageMask && imageMask.getAttribute('data-shape-mask');
  return currentShape;
}

function getShapeDimensions(currentShape) {
  const shapeAspectRatioHeight = IMAGE_MASK_RATIOS[currentShape];
  const shapeDimensions = { width: 100, height: shapeAspectRatioHeight };
  return shapeDimensions;
}

function getMaskDimensions(imageNode) {
  if (!imageNode) { return null; }
  const currentShape = getCurrentShape(imageNode);
  const imgDimensions = getImageDimensions(imageNode);
  const maskDimensions = currentShape ? getShapeDimensions(currentShape) : imgDimensions;
  return maskDimensions;
}

function getImageDimensions(imageNode) {
  const dimensionsAttribute = imageNode.getAttribute('data-image-dimensions');
  if (!imageNode || !dimensionsAttribute) { return; }
  const rawImageDimensions = dimensionsAttribute.split('x');
  const formattedImageDimensions = {
    width: rawImageDimensions[0],
    height: rawImageDimensions[1],
  };
  return formattedImageDimensions;
}

function getImageMask(imageNode) {
  return imageNode?.closest('.sqs-image-content');
}

function doesImageNeedResizeListener(imageNode) {
  const isObjectFitContain = imageNode.getAttribute('data-stretch') !== 'true';
  return isObjectFitContain;
}

function setFluidImageContainerVisitorStyles(imageNode) {
  const element = imageNode.closest('.sqs-block-content');
  const stylesToAppend = { height: '100%', width: '100%' };
  appendStyles(element, stylesToAppend);
}

export function formatImageUploaderContainer(imageContainer) {
  const stylesToAppend = { height: '100%', width: '100%' };
  appendStyles(imageContainer, stylesToAppend);
  appendStyles(imageContainer.closest('.sqs-image'), stylesToAppend);
  appendStyles(imageContainer.closest('.fluid-image-editor-wrapper'), stylesToAppend);
  appendStyles(imageContainer.closest('.sqs-block-content'), stylesToAppend);
}

function appendStyles(element, stylesToAppend) {
  if (!element || !stylesToAppend) { return; }
  Object.assign(element.style, stylesToAppend);
}

export function setFluidImageContainerEditorStyles(contentBoxDom) {
  const fullHeightAndWidth = { height: '100%', width: '100%' };
  appendStyles(contentBoxDom, fullHeightAndWidth);
  appendStyles(contentBoxDom.closest('.sqs-image'), fullHeightAndWidth);
  appendStyles(contentBoxDom.closest('.fluid-image-editor-wrapper'), fullHeightAndWidth);
  appendStyles(contentBoxDom.closest('.design-layout-fluid'), fullHeightAndWidth);
}

export function adjustFluidImageMask(imageMask, imgDimensions, isObjectFitCover) {
  if (isObjectFitCover) {
    formatMaskForObjectFitCover(imageMask);
  } else {
    formatMaskForObjectFitContain(imageMask, imgDimensions);
  }
}

function formatMaskForObjectFitCover(imageMask) {
  imageMask.style.height = '100%';
  imageMask.style.width = '100%';
}

function formatMaskForObjectFitContain(imageMask, imgDimensions) {
  if (!imgDimensions || !imageMask) { return; }
  const maskContainer = imageMask.parentElement;
  if (!maskContainer) { return; }

  if (isEditing(imageMask)) {
    addAlignment(maskContainer);
  }

  const maskContainerRect = maskContainer.getBoundingClientRect();
  const maskContainerWidth = maskContainerRect.width;
  const maskContainerHeight = maskContainerRect.height;
  const maskContainerAspectRatio = maskContainerWidth / maskContainerHeight;

  const imgAspectRatio = imgDimensions.width / imgDimensions.height;

  const dimensionToFill = maskContainerAspectRatio > imgAspectRatio ? 'height' : 'width';
  imageMask.style[dimensionToFill] = '100%';

  if (dimensionToFill === 'height') {
    imageMask.style.width = (maskContainerHeight * imgAspectRatio) + 'px';
  } else {
    imageMask.style.height = (maskContainerWidth / imgAspectRatio) + 'px';
  }
}

function isEditing(imageMask) {
  return !!imageMask.closest('.fluid-image-editor-wrapper');
}

function addAlignment(maskContainer) {
  maskContainer.classList.add('sqs-block-alignment-wrapper');
  appendStyles(maskContainer, {
    display: 'flex',
    justifyContent: getHorizontalFlexAlignment(maskContainer),
  });
}

function getHorizontalFlexAlignment(maskContainer) {
  let alignment = 'center';
  const layoutContainer = maskContainer.closest('.design-layout-fluid');

  if (!layoutContainer) { return alignment; }

  if (layoutContainer.classList.contains('image-position-left')) {
    alignment = 'flex-start';
  }
  if (layoutContainer.classList.contains('image-position-right')) {
    alignment = 'flex-end';
  }

  return alignment;
}

export function syncDimensionsWithObjectFitContain(
  imageMask,
  imgDimensions,
  yuiContext
) {
  const resizeListenerTarget = getResizeListenerTarget(imageMask);
  const imageNode = imageMask.querySelector('img');

  const observerAlreadyExists = !!(
    resizeListenerTarget._sqsResizeObservers && resizeListenerTarget._sqsResizeObservers[RESIZE_OBSERVER_NAME]
  );
  if (observerAlreadyExists) {
    unsyncDimensionsWithObjectFitContain(imageMask);
  }

  const resizeMask = createMaskResizingCallback(imageMask, imageNode, imgDimensions, yuiContext);
  attachResizeObserver({ observerName: RESIZE_OBSERVER_NAME, resizeListenerTarget, callback: resizeMask });
}

function createMaskResizingCallback(imageMask, imageNode, imgDimensions, yuiContext) {
  const callback = function() {
    let latestImgDimensions = imgDimensions;
    if (yuiContext) {
      const model = yuiContext.contentImage.get('model');
      latestImgDimensions = model.get('dimensions');
    }
    const currentShape = getCurrentShape(imageNode);
    if (currentShape) {
      latestImgDimensions = getShapeDimensions(currentShape);
    }

    const isObjectFitCover = false;
    adjustFluidImageMask(imageMask, latestImgDimensions, isObjectFitCover);
  };
  return callback;
}

export function unsyncDimensionsWithObjectFitContain(imageMask) {
  const resizeListenerTarget = getResizeListenerTarget(imageMask);
  if (!resizeListenerTarget._sqsResizeObservers) { return; }
  removeResizeObserver({ resizeListenerTarget, observerName: RESIZE_OBSERVER_NAME });
}

function getResizeListenerTarget(imageMask) {
  return imageMask.closest('.image-block-outer-wrapper');
}

export function attachResizeObserver({ observerName, resizeListenerTarget, callback }) {
  const resizeObserver = new ResizeObserver(function(entries) {
    for (let i = 0; i < entries.length; i++) {
      callback();
    }
  });

  resizeObserver.observe(resizeListenerTarget);

  if (!resizeListenerTarget._sqsResizeObservers) {
    resizeListenerTarget._sqsResizeObservers = {};
  }

  resizeListenerTarget._sqsResizeObservers[observerName] = resizeObserver;
}

export function removeResizeObserver({ resizeListenerTarget, observerName }) {
  const resizeObserver = resizeListenerTarget._sqsResizeObservers &&
    resizeListenerTarget._sqsResizeObservers[observerName];
  if (!resizeObserver) { return; }
  resizeObserver.unobserve(resizeListenerTarget);
  resizeListenerTarget._sqsResizeObservers[observerName] = null;
}
