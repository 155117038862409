import { securifyBlockEmbed } from 'shared/utils/UrlUtils';

/**
 * @param {YUI.Node} blockContent
 * @param {YUI} Y
 */
const resizeEmbedBlock = (blockContent, Y) => {
  if (!blockContent) {
    return;
  }

  // Looks weird, but Y.JSON.parse("") throws an unexpected EOE exception.
  const blockJsonString = blockContent.getAttribute('data-block-json');
  if (blockJsonString) {
    // Twitter embeds are loaded dynamically. Do not try to measure / resize them.
    const blockJson = Y.JSON.parse(blockJsonString);
    if (blockJson && blockJson.providerName !== 'Twitter') {
      blockContent.get('children')
        .each((el) => el.resizetoparent({ sizeonly: true }));
    }
  }

  blockContent.setStyles({
    visibility: 'visible',
    opacity: 1,
    height: 'auto'
  });
};

/**
 * Initialize video and embed blocks
 *
 * @param {YUI.Node} block
 * @param {YUI} Y
 * @param {Window} win
 */
export default (block, Y, win) => {
  const blockContent = block.one('.sqs-block-content');
  if (!blockContent) {
    return;
  }

  securifyBlockEmbed(blockContent);

  // Video blocks
  const isVideoBlock = blockContent.test('.sqs-block-video .sqs-block-content');
  if (isVideoBlock) {
    const videoWrapperEl = blockContent.one('.sqs-video-wrapper');
    if (videoWrapperEl) {
      win.Squarespace.initializeVideoBlock(videoWrapperEl, Y);
    }
    return;
  }

  // Embed blocks
  const hasIntrinsicChild = blockContent.one('.intrinsic');
  if (!hasIntrinsicChild) {
    resizeEmbedBlock(blockContent, Y);
  }
};
