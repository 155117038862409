import ErrorReporter from '@sqs/error-reporter';

/**
* Gallery
* @module squarespace-gallery
*/
YUI.add('squarespace-gallery', function(Y) {

  Y.namespace('Squarespace');

  var DEBUG = false;
  var Class = Y.Squarespace.Legacy.Class;

  Y.augment(Y.Node, Class.create({
    outerWidth: function( includeMargin ) {
      var marginLeft = parseFloat( this.getComputedStyle('marginLeft') );
      var marginRight = parseFloat( this.getComputedStyle('marginRight') );

      ErrorReporter.trackCaughtError('squarespace-gallery-deprecation', 'YNode#outerWidth invoked');

      return this.get('offsetWidth') + marginLeft + marginRight;
    },
    outerHeight: function( includeMargin ) {
      var marginTop = parseFloat( this.getComputedStyle('marginTop') );
      var marginBottom = parseFloat( this.getComputedStyle('marginBottom') );

      ErrorReporter.trackCaughtError('squarespace-gallery-deprecation', 'YNode#outerHeight invoked');

      return this.get('offsetHeight') + marginTop + marginBottom;
    }
  }));

  /**
   * @class Gallery
   * @namespace Squarespace
   * @constructor
   *
   */
  Y.Squarespace.Gallery = Class.create({

    // Default options
    /* eslint-disable no-multi-spaces */
    defaultOpts: {
      previous: false,              // selector for "previous" element
      next: false,                  // selector for "next" element

      itemSelector: false,          // the itemSelector. Default: children
      startIndex: 0,                // Initial slide index
      loop: false,                  // Does this gallery loop around?

      design: 'off',                 // No design -- this will use Y.Squarespace.GalleryDesigns.off
      designOptions: {},             // Design options get passed to the new instance of the design.

      autoplay: false,
      autoplayOptions: {
        timeout: 1000,
        resumeAfter: 1000,
        randomize: false
      },

      currentIndex: false,          // selector to display the current index
      totalSlides: false,           // selector to display the total slides,

      linked: false,                // array of linked galleries.

      keyboard: {                   // object of keyboard bindings.
        previous: 'down:37',
        next: 'down:39'
      },

      renderWithCanvas: false,

      loaderOptions: {},

      updateContainerHeight: false,

      focusOnClick: false
    },
    /* eslint-enable no-multi-spaces */

    /**
     * @method getContainer
     */
    getContainer: function() {
      return this.elems.container;
    },

    /**
     * @method getSlide
     * @param  {Number} i
     */
    getSlide: function(i) {
      return this.elems.slides.item(i);
    },

    /**
     * @method getCurrentSlide
     */
    getCurrentSlide: function() {
      return this.elems.slides.item(this.data.currentIndex);
    },

    /**
     * @method getCurrentSlideId
     */
    getCurrentSlideId: function() {
      return this.getCurrentSlide().getAttribute('data-slide-id') || this.data.currentIndex;
    },

    /**
     * @method getSlides
     */
    getSlides: function() {
      return this.elems.slides;
    },

    /**
     * @method initialize
     * @param  {Object} params
     */
    initialize: function(params) {

      ErrorReporter.trackCaughtError('squarespace-gallery-deprecation', 'Y.Squarespace.Gallery initialized!');

      // Before they merge, check if keyboard is a boolean & true, and unset it then (we'll use our keyboard settings)
      if ( Y.Lang.isBoolean(params.keyboard) && params.keyboard ) {
        delete params.keyboard;
      }

      // Merge the params
      this.params = Y.merge( this.defaultOpts, params );
      this.elems = {};

      var $gallery = Y.one( this.params.slideshowElement );

      // Check if it's null
      if (Y.Lang.isNull($gallery)) {
        throw new Error("No gallery '" + this.params.slideshowElement + "' container found.");
      }

      // And then get the $slides
      var $slides = ( this.params.itemSelector ? $gallery.all( this.params.itemSelector ) : $gallery.get('children') );

      // Save the elements
      this.elems.container = $gallery;
      this.elems.slides = $slides;

      // Don't create two instances.
      if (this.getContainer().getData('galleryInstance')) {
        this.getContainer().getData('galleryInstance')._debug.warn('Node already bound to gallery instance.');
        return;
      }

      // Debugger
      this._debug = new Y.Squarespace.Debugger({
        name: 'Gallery',
        uid: true
      });

      this.getContainer().setData('galleryInstance', this);

      // Check if there's a history hash
      this.history = new Y.HistoryHash();

      // History
      if (this.params.historyHash || this.history.get('itemId')) {
        this.history = new Y.HistoryHash();

        var currentHistoryHash = this.history.get('itemId') || this.history.get(this.params.historyHash);
        var currentIndex;
        if (currentHistoryHash) {
          this.elems.slides.each(function(slide, n) {
            if (slide.getAttribute('data-slide-id') === currentHistoryHash) {
              currentIndex = n;
            }
          });

          if (!Y.Lang.isNumber(currentIndex)) {
            currentIndex = parseInt(this.history.get(this.params.historyHash), 10);
          }

          if (this.history.get('itemId')) {
            this.history.replaceValue('itemId', null);
          }

          if (Y.Lang.isNumber(currentIndex)) {
            this.params.startIndex = currentIndex;
          }
        }
      }

      // Save some info
      this.data = {
        currentIndex: this.params.startIndex,
        totalSlides: $slides.size()
      };

      // Store events
      this.events = [];

      // Check that slides exist
      if (this.data.totalSlides <= 0) {
        // throw new Error("No slides found.");
        return false;
      }

      // Check the design, save the design, set the gallery in the design, set the design options
      if (!Y.Squarespace.GalleryDesigns[this.params.design]) {
        var availableDesigns = [];
        for (var design in Y.Squarespace.GalleryDesigns) {
          availableDesigns.push( design );
        }

        throw new Error(
          "Design '" + this.params.design + "' not found. Currently loaded designs: [" +
          availableDesigns.join(', ') +
          '].'
        );
      }

      // Initiate the design
      this.design = new (Y.Squarespace.GalleryDesigns[ this.params.design ])();
      this.design.gallery = this;
      this.design.options = Y.merge( this.design.options, this.params.designOptions );

      // Bind the events
      if (this.params.previous) {
        Y.all(this.params.previous).each(function (element) {
          this.events.push(element.on('click', function (e) {
            e.halt();
            this.previousSlide();
          }, this));
        }, this);
      }

      if (this.params.next) {
        Y.all(this.params.next).each(function (element) {
          this.events.push(element.on('click', function (e) {
            e.halt();
            this.nextSlide();
          }, this));
        }, this);
      }

      if (this.params.focusOnClick) {
        $slides.each(function(slide) {
          slide.on('click', function() {
            this.fire('changeIndex', $slides.indexOf(slide));
          }, this);
        }, this);
      }

      // Check if keyboard is attached
      if (this.params.keyboard) {
        var proceedCheck = function() {
          if (this.params.keyboardWhenInFrame) {
            return this.getContainer().inRegion(Y.one(this.params.keyboardWhenInFrame).get('region'));
          }

          return true;
        };

        if (this.params.keyboard.previous) {
          this.events.push(
            Y.on('key', function() {
              if (!proceedCheck.call(this)) { return; }
              this.previousSlide();
            }, window, this.params.keyboard.previous, this)
          );
        }

        if (this.params.keyboard.next) {
          this.events.push(
            Y.on('key', function() {
              if (!proceedCheck.call(this)) { return; }
              this.nextSlide();
            }, window, this.params.keyboard.next, this)
          );
        }
      }

      // listen for children
      if (this.params.linked) {
        Y.Array.each(this.params.linked, function(link) {
          this.linkGallery( link );
        }, this);
      }

      // Trigger the setup
      this.setup();

      // Trigger the first index
      this.fire('changeIndex', this.params.startIndex);

      // Set that it was initialized
      this.isInitialized = true;

    },

    /**
     * @method linkGallery
     * @param  gallery
     */
    linkGallery: function( gallery ) {

      // Internal function that handles trigger
      function sync( source, toIndex ) {
        // If the source is set, that means it was called by a child.
        if (source) {
          this._changeIndex( toIndex );
        }

        // Now we iterate through the children, and make sure we don't call the source again
        Y.Array.each(this.elems.linked, function (link) {
          if (source !== link) {
            link._changeIndex( toIndex );
          }
        });
      }

      // Setup this instance for linking galleries.
      if (!this.elems.linked) {
        this.on('changeIndex', function(toIndex) {
          sync.call( this, false, toIndex );
        }, this);

        this.elems.linked = [];
      }

      // Add the new linked gallery
      this.elems.linked.push(gallery);
      gallery.on('changeIndex', function (toIndex) {
        sync.call(this, gallery, toIndex);
      }, this);

    },

    /**
     * @method setup
     */
    setup: function() {

      // Bind listeners
      this.on('changeIndex', this._changeIndex);

      // Now we load the slides
      this.loadSlides();

      // It just calls the setup on the design.
      this.design.setup( this.elems.container, this.elems.slides );

      // Autoplay
      if (this.params.autoplay) {
        var gallery = this;
        setInterval(function() {
          if (gallery.params.autoplayOptions.randomize) {
            var next = Math.ceil(Math.random() * gallery.data.totalSlides);
            while (next === gallery.data.currentIndex) {
              next = Math.ceil(Math.random() * gallery.data.totalSlides);
            }
            gallery.fire('changeIndex', next);
          } else {
            gallery.nextSlide();
          }
        }, this.params.autoplayOptions.timeout);

      }

      // Debug
      if (DEBUG) {
        console.log( 'Y.Squarespace.Gallery.setup', [this.params, this.data, this.elems] );
      }

      // Update Container Height
      if (this.params.updateContainerHeight) {
        this.on('changeIndex', this.updateContainerHeight);
        // this.updateContainerHeight();
      }
    },

    /**
     * @method getSlideDimension
     * @param  {Number} index
     * @param  {String} dimension e.g. width
     */
    getSlideDimension: function(index, dimension) {

      var el = this.elems.slides.item(index);
      var imgEl = el;

      if (el.one('img,canvas')) {
        imgEl = el.one('img,canvas');
      }

      if (imgEl && el.getStyle('display') === 'inline') {
        el = imgEl;
      }

      var _offsetDim = (dimension === 'width' ? 'offsetWidth' : 'offsetHeight');

      if (imgEl.loader) {
        if (!imgEl.loader.get('loaded')) {
          return imgEl.loader.getDimensionForValue(
            dimension,
            el.get(dimension === 'width' ? 'offsetHeight' : 'offsetWidth')
          );
        }
        return imgEl.loader.get('node').get(_offsetDim);
      }

      return el.get(_offsetDim);
    },

    /**
     * @method updateContainerHeight
     */
    updateContainerHeight: function() {

      // default params
      var config = {
        container: this.elems.container
      };

      if (Y.Lang.isObject(this.params.updateContainerHeight)) {
        config = Y.merge(config, this.params.updateContainerHeight);
      }

      var height = this.getSlideDimension(this.data.currentIndex, 'height');

      if (config.maxHeight && height > config.maxHeight) {
        height = config.maxHeight;
      }

      var container = Y.all(config.container);

      if (config.anim && this._updateContainerHeightRanOnce) {
        container.each(function(el) {
          el.anim({}, Y.merge({
            to: {
              height: height
            },
            duration: 0.3,
            easing: Y.Easing.easeOutStrong
          }, config.anim)).run();
        });
      } else {
        container.setStyles({
          height: height
        });
      }

      // record that it ran once
      this._updateContainerHeightRanOnce = true;

    },

    //
    //Previous & Next handlers
    //

    /**
     * Previous handler
     * @method previousSlide
     */
    previousSlide: function() {
      var toIndex = this.data.currentIndex - 1;
      if (toIndex < 0 && !this.params.loop) { return; }
      this.fire( 'changeIndex', toIndex );
    },

    /**
     * Next handler
     * @method nextSlide
     */
    nextSlide: function() {
      var toIndex = this.data.currentIndex + 1;
      if (toIndex >= this.data.totalSlides && !this.params.loop) { return; }
      this.fire( 'changeIndex', toIndex );
    },

    /**
     * This updates the previous & after element's .disabled class
     * @method beforeChange
     * @param  data
     */
    beforeChange: function( data ) {
      // If the loop is set to false, then worry about adding and removing disabled classes.
      if (!this.params.loop) {
        if (this.params.next) {
          if (data.toIndex >= this.data.totalSlides - 1) {
            Y.all(this.params.next).addClass('disabled');
          } else {
            Y.all(this.params.next).removeClass('disabled');
          }
        }

        if (this.params.previous) {
          if (data.toIndex <= 0) {
            Y.all(this.params.previous).addClass('disabled');
          } else {
            Y.all(this.params.previous).removeClass('disabled');
          }
        }

      }

      // Call the global event
      this.fire('before-change', data);

    },

    /**
     * @method afterChange
     * @param  data
     */
    afterChange: function( data ) {

      // Also, change the indices
      if ( this.params.currentIndex || this.params.totalSlides ) {

        Y.all( this.params.currentIndex ).each(function(element) {
          element.set( 'innerHTML', this.data.currentIndex + 1 );
        }, this);

        Y.all( this.params.totalSlides ).each(function(element) {
          element.set( 'innerHTML', this.data.totalSlides );
        }, this);

      }

      // Change the hash
      if (this.params.historyHash) {
        this.history.replaceValue(this.params.historyHash, this.getCurrentSlideId());
      }

      // Call the global event
      this.fire('after-change', data);

    },

    /**
     * @method changeIndex
     * @public
     * @param  {Number} i
     */
    changeIndex: function(i) {
      this.fire('changeIndex', i);
    },

    /**
     * The main change function
     * @private
     */
    _changeIndex: function( i ) {

      // Gallery Instances don't respond to it's own calls.
      if ( Y.Lang.isObject(i) ) { return; }

      // Dummy proof -- just reset the value to the ends.
      if ( !this.params.loop ) {
        if ( i < 0 ) { i = 0; }
        if ( i >= this.data.totalSlides ) { i = this.data.totalSlides - 1; }
      }

      // Remember -- no such thing as a negative index
      if ( i < 0 ) { i = this.data.totalSlides + i; }

      // Check if the currentIndex is the same
      if ( this.isInitialized && this.data.currentIndex === i ) {
        return;
      }

      // Debug
      if ( DEBUG ) {
        console.log( 'Y.Squarespace.Gallery.changeIndex', [i]);
      }

      // Save the "from" & "to" indices
      var fromIndex = this.data.currentIndex;
      var toIndex = i % this.data.totalSlides;

      // Save the slides element
      var $slides = this.elems.slides;

      /* eslint-disable camelcase */
      // Figure out the direction -- there's gotta be a better way to do this.
      var last_to_first = ( fromIndex === this.data.totalSlides - 1 && toIndex === 0 );
      var first_to_last = ( fromIndex === 0 && toIndex === this.data.totalSlides - 1 );

      var direction;

      if ( ( fromIndex < toIndex && !last_to_first && !first_to_last ) ||
        ( fromIndex > toIndex && last_to_first && !first_to_last ) ) {
        direction = 'fwd';
      } else if ( ( fromIndex > toIndex && !first_to_last ) || ( fromIndex < toIndex && first_to_last ) ) {
        direction = 'rev';
      }
      /* eslint-enable camelcase */

      // Change Data
      var changeData = {
        fromIndex: fromIndex,
        toIndex: toIndex,
        direction: direction
      };

      // Trigger the before change
      this.beforeChange( changeData ); // internal

      // Add & remove the classes
      $slides.filter('.active').removeClass('active');
      $slides.item(toIndex).addClass('active');

      // Trigger the design change
      // this.design.change(toIndex, ( toIndex == fromIndex ? false : fromIndex ));
      this.fire('change', changeData);

      // Save the change
      this.data.currentIndex = toIndex;

      // Trigger the after change
      this.afterChange( changeData );

    },


    //
    //A bit more efficient loading
    //

    /**
     * @method loadSlides
     */
    loadSlides: function( render ) {

      // Figure out what the elements
      var $images = this.elems.container.all('img[data-src][data-image-dimensions][data-image-focal-point]');


      $images.plug(Y.Squarespace.Loader2, this.params.loaderOptions);

    }

  });

  // Add all the great stuff from Y.EventTarget to Y.Squarespace.Gallery
  Y.augment( Y.Squarespace.Gallery, Y.EventTarget );

  //
  //This is the basic gallery design object.
  //
  Y.Squarespace.GalleryDesigns = {};

  /**
   * Base Design Object.
   * Interface
   * @class off
   * @namespace Squarespace.GalleryDesigns
   */
  Y.Squarespace.GalleryDesigns.off = Class.create({
    gallery: false, // the reference to the gallery object
    options: {},

    /**
     * Abstract method
     * @method beforeChange
     * @param  {Number} fromIndex
     */
    beforeChange: function( fromIndex ) {},
    /**
     * Abstract method
     * @method afterChange
     * @param  {Number} toIndex
     */
    afterChange: function( toIndex ) {},
    /**
     * Abstract method
     * @method change
     * @param  {Number} fromIndex
     * @param  {Number} toIndex
     */
    change: function( fromIndex, toIndex ) {},
    /**
     * Abstract method
     * @method setup
     */
    setup: function() {},

    /**
     * Get gallery
     * @method getGallery
     */
    getGallery: function() { return this.gallery; },

    events: []
  });

}, '1.0', { requires: [
  'node',
  'event-key',
  'squarespace-image-loader',
  'squarespace-legacy-class',
  'squarespace-ui-base',
  'history'
] });
