/**
 * @module squarespace-plugin-lightbox2
 */
YUI.add('squarespace-plugin-lightbox2', function(Y) {

  Y.namespace('Squarespace.Plugin');

  /**
   * A plugin for LightBox2
   */
  Y.Squarespace.Lightbox2Plug = Y.Squarespace.Plugin.Lightbox2 =
  Y.Base.create('Lightbox2Plug', Y.Plugin.Base,
    [Y.Squarespace.GismoBase],
    {

      initializer: function() {
        this._event(this.get('host').on('click', function(e) {
          e.preventDefault();

          var ctx = this;
          Y.use('squarespace-ui-base', function() {
            var lightbox = new Y.Squarespace.Lightbox2(Y.merge({
              content: Y.Node.create(ctx.get('host').getAttribute('data-lightbox'))
            }, ctx.get('lightboxOptions')));
            lightbox.render();
          });
        }, this));
      },

      destructor: function () {

        var content = this.get('lightboxOptions.content');
        if (content instanceof Y.Node) {
          content.destroy(true);
        }

      }

    }, {
      NS: 'lightbox2plugin',
      ATTRS: {

      /**
       * Presumably something.
       * @type {Object}
       */
        lightboxOptions: {}
      }
    });


}, '1.0', {
  requires: [
    'base',
    'node',
    'plugin',
    'squarespace-gismo',
    'squarespace-widgets-lightbox2'
  ]
});
