import { t } from 'shared/i18n';
import { mediaQueries } from '@sqs/universal-utils';
import { containFocus } from '@sqs/utils/focus';

/**
 * Upgraded confirmation dialog widget.
 *
 * @example
 *     // this dialog will be centered around the mouse
 *     var confirm = new Y.Squarespace.Widgets.Confirmation({
 *       'strings.title': 'Hello',
 *       'strings.message': 'World'
 *     });
 *
 *     confirm.on('confirm', function() {
 *       console.log('You did it!');
 *     });
 *
 *     confirm.on('cancel', function() {
 *       console.log('Okay, nevermind.');
 *     });
 *
 *     // confirmation centered on screen
 *     confirm = new Y.Squarespace.Widgets.Confirmation({
 *       position: Y.Squarespace.Widgets.Confirmation.ANCHOR.CENTER,
 *       'strings.title': 'Centered',
 *       'strings.message': "I'm in the center!"
 *     });
 *
 * @module squarespace-widgets-confirmation
 */
YUI.add('squarespace-widgets-confirmation', function (Y) {

  Y.namespace('Squarespace.Widgets');

  var TYPE = {
    /**
     * Shows cancel, reject and confirm buttons.
     *
     * @for Squarespace.Widgets.Confirmation
     * @property TYPE.CONFIRM_OR_REJECT
     * @type {Number}
     */
    CONFIRM_OR_REJECT: 0,

    /**
     * Shows cancel and confirm buttons.
     *
     * @for Squarespace.Widgets.Confirmation
     * @property TYPE.CONFIRM_OR_CANCEL
     * @type {Number}
     */
    CONFIRM_OR_CANCEL: 1,

    /**
     * Shows a confirm button only.
     *
     * @for Squarespace.Widgets.Confirmation
     * @property TYPE.CONFIRM_ONLY
     * @type {Number}
     */
    CONFIRM_ONLY: 2,

    /**
     * Does not render any buttons.
     *
     * @for Squarespace.Widgets.Confirmation
     * @property TYPE.NO_BUTTONS
     * @type {Number}
     */
    NO_BUTTONS: 3 };


  /**
   * The base confirmation dialog. Displays a small confirmation message asking
   * the user to either confirm their decision or cancel it.
   *
   * The default behavior positions the dialog centered around the mouse, and
   * provides the option to "confirm" or "cancel."
   *
   * @class Confirmation
   * @constructor
   * @namespace Squarespace.Widgets
   * @extends Widget
   */
  var Confirmation =
  Y.Squarespace.Widgets.Confirmation =
  Y.Base.create('confirmation', Y.Widget, [
  Y.Squarespace.Mixins.EventRegistrar],
  {

    initializer: function () {

      this._posHelper = new Y.Squarespace.Widgets.PositionHelper(this.get('position'), this.get('padding'));
      this._resizeEmitter = new Y.Squarespace.ResizeEmitter();

      if (this.get('escToCancel')) {
        Y.Squarespace.EscManager.addTarget(this);
        this.ignoreBodyClicks = true;
      }

      var events = ['show', 'hide', 'confirm', 'reject', 'cancel'];

      Y.Array.each(events, function (e) {
        this.publish(e, {
          emitFacade: true,
          broadcast: 2 });

      }, this);

      this._timers = [];

    },

    destructor: function () {

      Y.Squarespace.EscManager.removeTarget(this);

      this._resizeEmitter.destroy();
      this._resizeEmitter = null;
      this._posHelper = null;

      this._overlay = null;
      this._buttonsNode = null;
      this._cancelButton = null;
      this._rejectButton = null;
      this._confirmButton = null;

      while (this._timers.length !== 0) {
        this._timers.shift().cancel();
      }

      this._timers = null;

      this.detachAll();

    },

    renderUI: function () {

      Confirmation.superclass.renderUI.call(this);

      var zIndex = this.get('zIndex');

      var boundingBox = this.get('boundingBox');
      var contentBox = this.get('contentBox');

      var template = this.constructor.TEMPLATE;
      if (Y.Lang.isString(template)) {
        contentBox.prepend(template);
      }

      boundingBox.addClass(this.get('className'));

      if (Y.Lang.isValue(zIndex)) {
        boundingBox.setStyle('z-index', zIndex);
      }

      contentBox.addClass('clear');
      contentBox.one('.title').setContent(this.get('strings.title'));
      var titleId = Y.guid();
      contentBox.one('.title').setAttribute('id', titleId);
      contentBox.one('.message').setContent(this.get('strings.message'));
      var messageId = Y.guid();
      contentBox.one('.message').setAttribute('id', messageId);

      contentBox.setAttribute('role', 'dialog');
      // Labelling the dialog using a combination of the title and the message.
      // It's fine if either is empty, it does not negatively affect
      // accessible name resolution.
      contentBox.setAttribute('aria-labelledby', titleId + ' ' + messageId);

      this._buttonsNode = contentBox.one('.buttons');
      this._renderButtons();

      if (this.get('visible')) {
        this.show();
      }
    },

    bindUI: function () {

      var buttonClick = Y.bind(function buttonClickWrapper(response) {
        return function confirmationButtonClick(e) {
          e.halt();
          this._handleResponse(response);
        };
      }, this);

      var buttonKeyPress = Y.bind(function buttonKeyWrapper(response) {
        return function buttonKeyPress(e) {// eslint-disable-line no-shadow
          // 32 === space bar
          // 13 === return
          if (e.keyCode === 32 || e.keyCode === 13) {
            e.halt();
            this._handleResponse(response);
          }
        };
      }, this);

      if (Y.Lang.isValue(this._confirmButton)) {
        this._confirmButton.on('click', buttonClick('confirm'), this);
        this._confirmButton.on('keydown', buttonKeyPress('confirm'), this);
      }

      if (Y.Lang.isValue(this._cancelButton)) {
        this._cancelButton.on('click', buttonClick('cancel'), this);
        this._cancelButton.on('keydown', buttonKeyPress('cancel'), this);
      }

      if (Y.Lang.isValue(this._rejectButton)) {
        this._rejectButton.on('click', buttonClick('reject'), this);
        this._rejectButton.on('keydown', buttonKeyPress('reject'), this);
      }

      this.after('positionChange', function () {
        this._posHelper = new Y.Squarespace.Widgets.PositionHelper(this.get('position'), this.get('padding'));
      }, this);

      if (this.get('position') !== Y.Squarespace.Widgets.PositionHelper.ANCHOR.MOUSE) {
        this._registerEvent(this._resizeEmitter.on('resize', this._updatePosition, this));
      }

      var hideAfterTime = this.get('hideAfterTime');
      if (Y.Lang.isNumber(hideAfterTime)) {
        this._registerTimer(Y.later(hideAfterTime, this, this._hideAfterTime));
      }

      if (this.get('destroyOnHide')) {
        this.onceAfter('hide', this.destroy, this);
      }

      this.before('hide', function beforeHide() {
        this._revertFocusContainment();

        if (Y.Lang.isValue(this._overlay)) {
          this._overlay.remove(true);
        }
      }, this);

      this.on('cancel', this.hide, this);
      this.onceAfter('confirm', this.hide, this);
      this.onceAfter('reject', this.hide, this);

    },

    /**
     * What to do when an action button is pressed. Useful for subclassing
     * Confirmation and wanting to treat the events differently.
     *
     * @method _handleResponse
     * @param {String} response The user's response (confirm, reject or cancel).
     * @protected
     */
    _handleResponse: function (response) {
      this.fire(response);
    },

    /**
     * Renders buttons depending on the chosen style.
     *
     * @method _renderButtons
     * @protected
     */
    _renderButtons: function () {

      var style = this.get('style');

      if (style === TYPE.NO_BUTTONS) {
        return;
      }

      if (style !== TYPE.CONFIRM_ONLY) {
        this._cancelButton = this._buttonsNode.appendChild(this._createButton('cancel'));
      }

      if (style === TYPE.CONFIRM_OR_REJECT) {
        this._rejectButton = this._buttonsNode.appendChild(this._createButton('reject'));
      }

      this._confirmButton = this._buttonsNode.appendChild(this._createButton('confirm'));

    },

    /**
     * Utility to create a single button. The type parameter must be
     * present in the strings attribute for use as the button text.
     *
     * @method _createButton
     * @protected
     * @param {String} type The button type (usually confirm/reject/cancel)
     * @return {Node} The button node
     */
    _createButton: function (type) {

      var element = 'div';

      return Y.Node.create(Y.Lang.sub(
      '<{element} role="button" tabindex="0" class="confirmation-button no-frame {type}">{sub1}</{element}>', {
        type: type,
        sub1: this.get('strings.' + type),
        element: element }));

    },

    /**
     * Updates the position of the confirmation dialog.
     *
     * @method _updatePosition
     * @protected
     */
    _updatePosition: function () {

      var boundingBox = this.get('boundingBox');

      var styles = this._posHelper.fetch(boundingBox, this.get('anchor'));
      boundingBox.setStyles(styles);

    },

    /**
     * Renders an overlay behind the confirmation dialog.
     *
     * @method _renderOverlay
     * @protected
     */
    _renderOverlay: function () {

      var boundingBox = this.get('boundingBox');
      var zIndex = this.get('zIndex') || parseInt(boundingBox.getStyle('z-index'), 10);

      this._overlay = boundingBox.insertBefore('<div></div>', boundingBox);
      this._overlay.addClass(this.getClassName('overlay'));

      this._overlay.setStyle('z-index', zIndex - 1);

      if (!this.get('showOverlay')) {
        this._overlay.setStyle('opacity', '0');
      }

      this._overlay.on('click', function (e) {
        e.halt();
        this._handleResponse('cancel');
      }, this);

    },

    /**
     * Show the dialog.
     *
     * @method show
     * @public
     */
    show: function () {

      if (!Y.Lang.isValue(this._overlay)) {
        this._renderOverlay();
      }

      var boundingBox = this.get('boundingBox');

      this.set('visible', true);

      boundingBox.setStyle('width', this.get('width'));
      this._updatePosition();

      boundingBox.addClass('shown');

      this._revertFocusContainment = containFocus({
        container: boundingBox.getDOMNode(),
        // The library default value for `root` is `document.body`,
        // which does not work correctly when we're in config
        // (`document.body` being the containing document's body).
        root: Y.one('body').getDOMNode(),
        // Set focus on the confirm button or, if there's no confirm
        // button, perform the library default action
        setFocusOnContain: this._confirmButton && this._confirmButton.getDOMNode() || true });


      this.fire('show');

    },

    /**
     * Hide the dialog.
     *
     * @method hide
     * @public
     * @param {Boolean} [skipTransition] Skip the hide fade transition.
     */
    hide: function (skipTransition) {

      var setHidingClasses = function (boundingBox, remove) {

        var fn = boundingBox.addClass;
        if (remove) {
          fn = boundingBox.removeClass;
        }

        Y.bind(fn, boundingBox, 'hiding')();
        if (Y.Lang.isValue(Y.UA.mobile)) {
          Y.bind(fn, boundingBox, 'mobile')();
        }

      };

      var boundingBox = this.get('boundingBox');

      var doHide = function () {

        // do nothing if this has already been called
        if (Y.Lang.isValue(boundingBox.getDOMNode()) &&
        boundingBox.hasClass('hiding')) {

          setHidingClasses(boundingBox, true);
          this.set('visible', false);
          this.fire('hide');

        }

      };

      if (Y.Lang.isBoolean(skipTransition) && skipTransition) {
        doHide.call(this);
      } else {
        setHidingClasses(boundingBox);
        boundingBox.once([
        'transitionend',
        'oTransitionEnd',
        'otransitionend',
        'webkitTransitionEnd'],
        doHide, this);

        this._registerTimer(Y.later(600, this, doHide));
      }

    },

    /**
     * A method to call after a time has elapsed.
     * @return {[type]} [description]
     */
    _hideAfterTime: function () {
      this.hide();
    },

    /**
     * Register a timer so on destruction this timer
     * will be canceled (so you don't have ghost
     * timer callbacks running after instance destruction).
     *
     * @method _registerTimer
     * @param a Y.later() or Y.soon() handle
     * @return {Object} The later handler.
     */
    _registerTimer: function (receipt) {
      this._timers.push(receipt);
    } },

  {

    CSS_PREFIX: 'sqs-widgets-confirmation',
    TEMPLATE: '<div class="title"></div>' +
    '<div class="message"></div>' +
    '<div class="buttons"></div>',

    /**
     * Enum describing the different styles of button combinations for
     * the confirmation dialog.
     *
     * @property TYPE
     * @type {Object}
     */
    TYPE: TYPE,
    ANCHOR: Y.Squarespace.Widgets.PositionHelper.ANCHOR,

    show: function (options) {
      return new Confirmation(options);
    },

    ATTRS: {
      // Override
      className: {
        value: 'question',
        validator: Y.Lang.isString },


      /**
       * Determines whether or not hitting the Esc key cancels the confirmation
       * dialog (i.e. whether or not to have EscManager target the dialog).
       *
       * @attribute escToCancel
       * @type {Boolean}
       * @default true
       */
      escToCancel: {
        value: true,
        validator: Y.Lang.isBoolean },


      /**
       * Hides the confirmation dialog after the given number of milliseconds.
       *
       * @attribute hideAfterTime
       * @type {Number}
       */
      hideAfterTime: {
        validator: Y.Squarespace.AttrValidators.isNullOrNumber },


      /**
       * Determines whether or not the entire widget is destroyed after it
       * has been hidden.
       *
       * @attribute destroyOnHide
       * @type {Boolean}
       * @default true
       */
      destroyOnHide: {
        value: true,
        validator: Y.Lang.isBoolean },


      /**
       * The position of the dialog. See PositionHelper.ANCHOR to view all the
       * possible positions. If the user is on a mobile device, it will default
       * to special mobile styles, but if you provide a different
       * PositionHelper, it will override the mobile version. Make sure you
       * handle mobile if you need to.
       *
       * @attribute position
       * @type {Function}
       * @default Squarespace.Widgets.PositionHelper.ANCHOR.MOUSE
       */
      position: {
        getter: function (value) {

          if (Y.Lang.isValue(value)) {
            return value;
          }

          if (Y.Lang.isValue(Y.UA.mobile) || mediaQueries.isSubDesktop()) {
            return Y.Squarespace.Widgets.PositionHelper.ANCHOR.CENTER;
          }

          return Y.Squarespace.Widgets.PositionHelper.ANCHOR.MOUSE;

        },
        validator: Y.Lang.isFunction },


      /**
       * If the position is set to PositionHelper.ANCHOR.ELEMENT, the dialog
       * will anchored to this element given.
       *
       * @attribute anchor
       * @type {Node}
       */
      anchor: {},

      /**
       * Sets the padding around the confirmation dialog.
       *
       * @attribute padding
       * @type {Number}
       * @default 20
       */
      padding: {
        value: 20,
        validator: Y.Lang.isNumber },


      // Override
      render: {
        valueFn: function () {
          var body = Y.one(Y.Squarespace.Damask.ContextGlobals.fromTop('Y.config.doc.body'));

          if (Y.Lang.isValue(body)) {
            return body;
          }
          return Y.one(Y.config.doc.body);
        } },


      /**
       * Determines the buttons rendered for the confirmation dialog. See
       * the {{#crossLink "Squarespace.Widgets.Confirmation/TYPE:property"}}{{/crossLink}} property.
       *
       * @attribute style
       * @type {Number}
       * @default Confirmation.TYPE.CONFIRM_OR_CANCEL
       */
      style: {
        value: TYPE.CONFIRM_OR_CANCEL,
        validator: Y.Lang.isNumber },


      /**
       * Whether or not to show an overlay behind the dialog.
       *
       * @attribute showOverlay
       * @type {Boolean}
       * @default false
       */
      showOverlay: {
        value: false,
        validator: Y.Lang.isBoolean },


      /**
       * Text used to render the dialog.
       *
       * @attribute strings
       * @type {Object}
       */
      strings: {
        value: {
          /**
           * The title of the dialog.
           *
           * @attribute strings.title
           * @type {String}
           * @default ''
           */
          title: '',

          /**
           * The message of the dialog.
           *
           * @attribute strings.message
           * @type {String}
           * @default ''
           */
          message: '',

          /**
           * Confirm button text.
           *
           * @attribute strings.confirm
           * @type {String}
           * @default 'Confirm'
           */
          confirm: t("Confirm"),




          /**
           * Cancel button text.
           *
           * @attribute strings.cancel
           * @type {String}
           * @default 'Cancel'
           */
          cancel: t("Cancel"),




          /**
           * Reject button text.
           *
           * @attribute strings.reject
           * @type {String}
           * @default 'Reject'
           */
          reject: t("Reject") } },






      /**
       * Set the width for the dialog.
       *
       * @attribute width
       * @type {Any}
       */
      width: {
        value: 350 },


      /**
       * Set the base zIndex for the dialog.
       *
       * @attribute zIndex
       * @type {Number}
       */
      zIndex: {
        validator: Y.Squarespace.AttrValidators.isNullOrNumber },


      /**
       * Holder for any custom instrumentation key
       * should there not be a title on the dialog
       *
       * @attribute instrumentation
       * @type String
       */
      instrumentationTitle: {
        value: null } } });




}, '1.0', {
  requires: [
  'base',
  'squarespace-damask-context-globals',
  'squarespace-dom-emitters-resize',
  'squarespace-escmanager',
  'squarespace-mixins-event-registrar',
  'squarespace-util',
  'squarespace-widgets-position-helper'] });