import loadjs from 'loadjs';
import findParent from '@sqs/utils/dom/findParent';
import waitForExistence from 'shared/utils/waitForExistence';

const wrapNodes = (nodes, className) => {
  let traverseCount = 0;
  let wrapperNode = findParent(nodes[0], `.${className}`, () => ++traverseCount > 3);

  if (!wrapperNode) {
    const firstNode = nodes[0];
    const parentNode = firstNode.parentNode;

    wrapperNode = parentNode.ownerDocument.createElement('div');
    wrapperNode.classList.add(className);
    parentNode.insertBefore(wrapperNode, firstNode);
  }

  nodes.forEach(node => wrapperNode.appendChild(node));
  return wrapperNode;
};

const setLoaded = (blockEl) => {
  const wrapperEl = blockEl.querySelector('.squarespace-opentable-new-age-wrapper');
  wrapperEl.classList.add('loaded');
  wrapperEl.classList.remove('loading');
};

/**
 * @param {Element} blockEl
 * @return {Promise}
 */
const waitForOpenTableReady = (blockEl) => {
  const wrapperEl = blockEl.querySelector('.squarespace-opentable-new-age-wrapper');
  if (!wrapperEl) {
    return Promise.reject('[opentablev2] No wrappers');
  }
  wrapperEl.classList.add('loading');

  if (wrapperEl.querySelector('iframe')) {
    setLoaded(blockEl);
    return Promise.resolve();
  }



  const cookies = document.cookie.split(';');

  const noticeBehaviorIncludesEu = (string) => {
    const behaviorSetting = string && string.split('=')[1].split(',');
    return behaviorSetting && behaviorSetting.includes('eu');
  };

  const evaluatePrivacyConsentCookie = (string) => {
    const behaviorSetting = string.split('=')[1].split(',');
    const consentGiven = behaviorSetting.includes('2') || behaviorSetting.includes('3');
    return consentGiven;
  };

  const hasNoticeBehaviorEuCookie = () => {
    const noticeBehavior = cookies.find((item) => {
      if (item.includes('notice_behavior')) {
        return item;
      }
    });

    return noticeBehaviorIncludesEu(noticeBehavior);
  };

  const hasGDPRConsentOrIsNotEU = () => {
    const privacyConsent = cookies.find((item) => {
      if (item.includes('cmapi_cookie_privacy')) {
        return item;
      }
    });

    const isEu = hasNoticeBehaviorEuCookie(cookies);

  // if visitor is not in the EU, can load script as normal
    if (!isEu) {
      return true;
    }

  // if visitor is in the EU, check for consent before allowing script to load
    if (isEu && privacyConsent && evaluatePrivacyConsentCookie(privacyConsent)) {
      return true;
    }

    return false;
  };

  // This is for GDPR compliance
  // If the website is cloneable (and therefore owned by Squarespace), we can't let OpenTable
  // set it's tracking cookies for visitors in the EU who have not given consent.
  // In opentable-v2.block, we block initial loading of the script via JSON-T for cloneable sites.
  // Then we check in here for the trustarc cookie that signal the visitor is consent has been given
  // if the visitor is from the EU and load the script in response.
  if (window.Static.SQUARESPACE_CONTEXT.website.cloneable && hasGDPRConsentOrIsNotEU()) {
    const openTableScript = wrapperEl.dataset.script;
    const openTableScriptTag = document.createElement('script');
    openTableScriptTag.setAttribute('src', openTableScript);
    wrapperEl.appendChild(openTableScriptTag);
  }

  const stylePickers = waitForExistence({
    parent: wrapperEl,
    selector: '.ot-date-picker, .ot-time-picker, .ot-party-size-picker'
  })
    .then((pickerEls) => wrapNodes(pickerEls, 'squarespace-opentable-picker-wrapper'));
  const wrapButtons = waitForExistence({
    parent: wrapperEl,
    selector: '.ot-dtp-picker-button',
  })
    .then((buttonEls) => wrapNodes(buttonEls, 'squarespace-opentable-button-wrapper'));
  const addTitles = waitForExistence({
    parent: wrapperEl,
    selector: '.ot-title'
  })
    .then((titleEls) => {
      titleEls.forEach(node => {
        const text = node.innerHTML;
        const heading = node.ownerDocument.createElement('h1');
        heading.classList.add('squarespace-ot-title');
        heading.innerHTML = text;
        node.parentNode.insertBefore(heading, node);
        node.parentNode.removeChild(node);
      });
    });

  return Promise.all([ stylePickers, wrapButtons, addTitles ])
    .then(waitForExistence({
      parent: wrapperEl,
      selector: '.ot-select-label'
    }))
    .then(() => setLoaded(blockEl))
    .catch((err) => {
      console.error('[opentable] widget DOM never rendered');
    });
};

/**
 * @param {Window} win
 * @param {Element} blockEl
 * @return {Promise}
 */
const reloadScript = (win, blockEl) => new Promise((resolve, reject) => {
  const scriptEl = blockEl.querySelector('script');

  const theme = blockEl.clientWidth > 850 ? 'wide' : 'standard';

  // We need to cleanroom the inner script with iframe
  //
  // We need to NOT use overlay since the postMessage 'message' listener is
  // anonymously bound TWICE on window, which would cause two popups to occur.
  // @see OT.Common.Helpers.navigate
  // @see {@link https://components.otstatic.com/components/reservation-widget-standard/3.1.9/assets/js/bundle.js}
  // For AJAX pages we want iframe on reload; for damask (editing) we want to
  // show our custom widget even if it doesn't work.
  const isDamask = win.Y.Squarespace.Utils && win.Y.Squarespace.Utils.isInDamaskFrame();
  const iframe = isDamask ? 'false' : 'true';
  const oldScriptSrc = scriptEl.getAttribute('src');
  const nextScriptSrc = oldScriptSrc.replace(/&(iframe|overlay|theme)=\w+/g, '');
  const scriptSrc = `${nextScriptSrc}&iframe=${iframe}&overlay=false&theme=${theme}`;
  loadjs(scriptSrc, {
    before: (path, reloadScriptEl) => {
      // Store this before scriptEl is removed from DOM
      const parent = scriptEl.parentNode;

      // ---------------------------------------------------------------------
      // Destroy previous
      // ---------------------------------------------------------------------

      try {
        // Unbind events and destroy namespaces. The OT script will check for
        // these when it loads.
        if (win.OT && win.OT.Events) {
          Object.keys(win.OT.Events.get()).forEach((k) => win.OT.Events.remove(k));
        }
        if (win.oc) {
          win.oc.events.reset();
        }
      } catch (err) {
        console.error(err.message);
      }

      // ---------------------------------------------------------------------
      // Render next
      // ---------------------------------------------------------------------

      // Inject the original script over again. It will re-inject the script
      // tags removed above and re-initialize the <oc-component> and its inner
      // DOM.
      parent.innerHTML = '';
      parent.appendChild(reloadScriptEl);

      // prevent loadjs default head insertion
      return false;
    },
    success: () => {
      try {
        if (isDamask && win.oc) {
          win.oc.renderUnloadedComponents(); // render oc-component
        }
        waitForOpenTableReady(blockEl).then(() => {
          if (win.oc && blockEl.querySelector('.ot-hide')) {
            const dtpEl = blockEl.querySelector('.ot-dtp-picker');
            win.oc.$(dtpEl).OTdtp('init'); // remove .ot-hide and render jquery dtp
          }
          resolve();
        });
      } catch (err) {
        console.error(err.message);
        reject('[opentable] could not reloadScript and reinit block');
      }
    },
    error: () => {
      reject('[opentable] loadjs failed');
    }
  });
});

export default (blockEl) => {
  const win = blockEl.ownerDocument.defaultView;

  if (!win.Squarespace.SQUARESPACE_INITIALIZED_ONCE) {
    // First time rendering the page. Rendering from server so the script will
    // load.
    waitForOpenTableReady(blockEl);
  } else {
    // Initialized before (and we're in the editor, or on this page via
    // Squarespace/mercury AJAX page navigation), so we need to rerender the
    // widget as an iframe.
    reloadScript(win, blockEl).catch((err) => {
      console.error(err);
    });
  }

  // If this is first run, the common.js initializer will eventually set
  // SQUARESPACE_INITIALIZED_ONCE after this.
};