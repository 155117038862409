/**
 * Upgraded information dialog widget.
 *
 * @example
 *     // this dialog will be at the top right corner of the screen
 *     // and will fade away after 2000 ms
 *     var info = new Y.Squarespace.Widgets.Information({
 *       'strings.title': 'Hello',
 *       'strings.message': 'World'
 *     });
 *
 * @module squarespace-widgets-information
 */
YUI.add('squarespace-widgets-information', function(Y) {

  Y.namespace('Squarespace.Widgets');

  /**
   * Doesn't show buttons and puts the dialog in the top right corner.
   * The Growl effect, if you will.
   *
   * @class Information
   * @constructor
   * @namespace Squarespace.Widgets
   * @extends Squarespace.Widgets.Confirmation
   */
  var Information =
  Y.Squarespace.Widgets.Information =
  Y.Base.create('information', Y.Squarespace.Widgets.Confirmation, [], {

    // Override
    _hideAfterTime: function () {
      this.hide();
      this._handleResponse('confirm');
    },

    // Override
    _renderOverlay: function() {

      if (!this.get('showOverlay')) {
        return;
      }

      Information.superclass._renderOverlay.call(this);

    }

  }, {

    CSS_PREFIX: 'sqs-widgets-confirmation',

    TYPE: Y.Squarespace.Widgets.Confirmation.TYPE,

    ANCHOR: Y.Squarespace.Widgets.Confirmation.ANCHOR,

    TEMPLATE: Y.Squarespace.Widgets.Confirmation.TEMPLATE,

    show: function(options) {
      return new Information(options);
    },

    ATTRS: {
      // Override
      className: {
        value: 'okay'
      },
      // Override
      style: {
        value: Y.Squarespace.Widgets.Confirmation.TYPE.NO_BUTTONS
      },
      // Override
      position: {
        value: Y.Squarespace.Widgets.PositionHelper.ANCHOR.TOP_RIGHT
      },
      // Override
      hideAfterTime: {
        value: 2000
      },
      // Override
      escToCancel: {
        value: false
      }
    }
  });

}, '1.0', {
  requires: [
    'base',
    'squarespace-widgets-confirmation',
    'squarespace-widgets-position-helper'
  ]
});

