"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TEMPLATE_CART_SUBTOTAL_CLASS = exports.TEMPLATE_CART_QUANTITY_CLASS = exports.TEMPLATE_CART_INITIALIZED_CLASS = exports.TEMPLATE_CART_INDICATOR_CLASS = exports.TEMPLATE_CART_CONTAINER_CLASS = exports.TEMPLATE_CART_CLASS = exports.FULL_PAGE_CART_ROOT_ID = exports.FULL_PAGE_CART_CONTAINER_ID = void 0;
// root element container the full-page cart bootstrap and container
var FULL_PAGE_CART_ROOT_ID = 'sqs-cart-root'; // indicates where the full-page cart should be rendered

exports.FULL_PAGE_CART_ROOT_ID = FULL_PAGE_CART_ROOT_ID;
var FULL_PAGE_CART_CONTAINER_ID = 'sqs-cart-container'; // body class that indicates that the template integrated cart should be rendered

exports.FULL_PAGE_CART_CONTAINER_ID = FULL_PAGE_CART_CONTAINER_ID;
var TEMPLATE_CART_INDICATOR_CLASS = 'sqs-has-custom-cart'; // template integrated cart container

exports.TEMPLATE_CART_INDICATOR_CLASS = TEMPLATE_CART_INDICATOR_CLASS;
var TEMPLATE_CART_CONTAINER_CLASS = 'sqs-custom-cart'; // template integrated cart selector

exports.TEMPLATE_CART_CONTAINER_CLASS = TEMPLATE_CART_CONTAINER_CLASS;
var TEMPLATE_CART_CLASS = 'sqs-template-integrated-shopping-cart'; // appended to the template integrated cart once the cart model is loaded

exports.TEMPLATE_CART_CLASS = TEMPLATE_CART_CLASS;
var TEMPLATE_CART_INITIALIZED_CLASS = 'sqs-cart-initialized'; // indicates where to number of items in the cart, not guaranteed to exist

exports.TEMPLATE_CART_INITIALIZED_CLASS = TEMPLATE_CART_INITIALIZED_CLASS;
var TEMPLATE_CART_QUANTITY_CLASS = 'sqs-cart-quantity'; // indicates where to renter cart sub-total, not guaranteed to exist

exports.TEMPLATE_CART_QUANTITY_CLASS = TEMPLATE_CART_QUANTITY_CLASS;
var TEMPLATE_CART_SUBTOTAL_CLASS = 'sqs-cart-subtotal';
exports.TEMPLATE_CART_SUBTOTAL_CLASS = TEMPLATE_CART_SUBTOTAL_CLASS;