/**
 * IE 11 window.console shim
 * window.console is only defined when the IE 11 DevTools are open
 */
/* eslint-disable */
if (typeof console === 'undefined') {
  console = {
    assert: function () {},
    group: function () {},
    groupEnd: function () {},
    log: function () {},
    dir: function () {},
    warn: function () {},
    error: function (args) {
      var error = Array.prototype.slice.call(arguments);
      throw new Error(error); // make sure this stays. in Rhino, we want console.error to throw an exception.
    }
  };
}
/* eslint-enable */
