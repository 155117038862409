import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import set from 'lodash/set';
import timing from '@sqs/rum-collector';

/**
 * @module squarespace-deprecated-modules
 */
YUI.add('squarespace-deprecated-modules', function squarespaceDeprecatedModules(Y) {

  // =======================================================================
  // Decorate deprecated functions with console.warn message
  // =======================================================================

  /**
   * @param {Function} original
   * @param {string} path to function in Y
   * @param {string} alternative message with an alternative to use
   * @return {Function} original
   */
  function withDeprecationMessage(original, path, alternative) {
    // E.g. Y.Cookie.get.name === 'get'
    return function decoratedWithDeprecationMessage() {
      console.warn('[DEPRECATED] Called `Y."' + path + '`. ' + alternative);
      timing.measure('deprecated_yui_module_used', { detail: path });
      return original.apply(this, arguments);
    };
  }

  /**
   * @param {string} path to a function in Y to decorate
   * @param {function} decorator higher order function
   * @param {string} alternative to use
   * @example
   * decorate('Cookie.get', withDeprecationMessage, 'blah');
   */
  function decorate(path, decorator, alternative) {
    var original = get(Y, path);
    if (!isFunction(original)) {
      return;
    }
    var decorated = decorator(original, path, alternative);
    set(Y, path, decorated);
  }

  /**
   * Decorate an entire module's top-level methods
   * @param {string} path to a function in Y to decorate
   * @param {function} decorator higher order function
   * @param {string} alternative to use
   * @example
   * decorateModule('Cookie', withDeprecationMessage, 'blah');
   */
  function decorateModule(path, decorator, alternative) {
    var yuiModule = get(Y, path, {});
    Object.keys(yuiModule).forEach(function decorateMethod(key) {
      var methodPath = [ path, key ].join('.');
      decorate(methodPath, decorator, alternative);
    });
  }

  // =======================================================================
  // Main
  // =======================================================================

  decorateModule('Cookie', withDeprecationMessage,
    'Use @sqs/cookie-cutter instead.');

}, '1.0', {
  requires: [
    // Y.Cookie has been replaced by @sqs/cookie-cutter
    // Keep this until we're certain of that though.
    'cookie',
  ],
});
