import { t } from 'shared/i18n';
import * as UrlUtils from 'shared/utils/UrlUtils';
/**
* Video Loader
* @module squarespace-video-loader
*/
YUI.add('squarespace-video-loader', function (Y) {

  Y.namespace('Squarespace');

  /**
   * @class       VideoLoader
   * @extends     Plugin.Base
   * @namespace   Squarespace
   * @constructor
   */
  Y.Squarespace.VideoLoader = Y.Base.create('VideoLoader', Y.Plugin.Base,
  [],
  {
    initializer: function () {
      var host = this.get('host');

      host.plug(Y.Squarespace.NodeDestroyNotifier, {
        onDestroy: function () {
          this.destroy();
        },
        context: this });


      this._setVariables();

      //1000 taken from the tweak:afterclose timeout below, while also allowing
      //time for the timeout in resize to take effect on parent lightbox,
      //otherwise end up positioning with stale representative sizing
      this._resizeEmitter = new Y.Squarespace.ResizeEmitter({ timeout: 1000 });
      this._onFullscreenChangeHandler = this.handleFullScreenChange.bind(this);

      this._modeClass = 'video-' + this.get('mode');
      host.addClass(this._modeClass);

      this.once('loaded', this.bindUI, this);

      if (this.get('load')) {
        this.load();
      }

      this.after('forceAutoplayChange', function () {
        this._augmentVideoSrc();
      });
    },

    destructor: function () {
      this.prefixedFullScreenEvents.forEach(function (prefixedEvent) {
        Y.config.doc.removeEventListener(prefixedEvent, this._onFullscreenChangeHandler);
      }.bind(this));

      this._resizeEmitter.destroy();
    },

    load: function () {
      // don't load more than once
      if (this._loaded) {return;}

      // remove intrinsic and overlay for invalid videos
      if (this.get('type') === 'invalid') {
        this._intrinsic = false;

        if (this.get('overlay')) {
          this.get('overlay').remove();
          this._set('overlay', null);
        }
      }

      var host = this.get('host');
      var mode = this.get('mode');

      // remove existing mode-specific class and add new one
      host.removeClass(this._modeClass);

      this._modeClass = 'video-' + mode;
      host.addClass(this._modeClass);

      // apply intrinsic wrapper for none and fit modes
      var intrinsic;
      var intrinsicInner;

      if (this._intrinsic) {
        var videoWidth = this.getWidth();
        var videoHeight = this.getHeight();

        var paddingBottom;

        if (videoWidth && videoHeight) {
          paddingBottom = videoHeight / videoWidth * 100;
        } else {
          paddingBottom = 56.3;
        }

        intrinsic = this._getOrCreateNode('.intrinsic', '<div class="intrinsic"></div>');
        intrinsicInner = this._getOrCreateNode('.intrinsic-inner', '<div class="intrinsic-inner"></div>');

        intrinsicInner.setStyle('paddingBottom', paddingBottom + '%');

        var parent = host.get('parentNode');
        var parentPosition = parent.getComputedStyle('position');

        if (mode === 'fit') {

          host.setStyles(host.getAdjustedDimensions({
            scale: 'contain',
            width: this.getWidth(),
            height: this.getHeight() }));


          if (parentPosition === 'static') {
            parent.setStyle('position', 'relative');
          }

        } else if (mode === 'fill') {
          if (parentPosition === 'static') {
            parent.setStyle('position', 'relative');
          }
        }

        if (this.get('overlay')) {
          this.get('overlay').wrap(intrinsicInner);
          intrinsicInner.wrap(intrinsic);
        } else {
          intrinsic.appendChild(intrinsicInner);
          host.appendChild(intrinsic);
        }
      }

      // make visible
      var overlay = this.get('overlay');
      if (overlay) {
        overlay.setStyle('opacity', 1);

        var opaqueNode = overlay.one('.sqs-video-opaque');
        if (!opaqueNode) {
          opaqueNode = Y.Node.create('<div class="sqs-video-opaque"></div>');
          overlay.appendChild(opaqueNode);
        }

        var videoIconNode = overlay.one('.sqs-video-icon');
        if (!videoIconNode) {
          videoIconNode = Y.Node.create('<div class="sqs-video-icon"></div>');
          overlay.appendChild(videoIconNode);
        }

        // Set play button focusability and screen reader attributes
        // based on whether it should be inactive.
        // Then listen to changes of the "inactive" attribute.
        this._setPlayButtonAttributes(this.get('inactive'));
        this.after('inactiveChange', function (e) {
          this._setPlayButtonAttributes(e.newVal);
        }, this);

        // refresh to correctly set image dimensions
        var overlayImage = overlay.one('img');

        if (overlayImage) {
          overlayImage.fire('refresh');

          overlayImage.plug(Y.Squarespace.Loader2, {
            load: true,
            mode: 'fill' });


          overlayImage.fire('refresh');
        }
      } else {

        if (intrinsic) {
          intrinsicInner.appendChild(this.get('video'));
        } else {
          host.appendChild(this.get('video'));
        }

        this._set('showingVideo', true);
      }

      this._cacheOverlayWrapperHeight();
      this.fire('loaded');
      this._loaded = true;

    },

    _setPlayButtonAttributes: function (inactive) {
      var videoIconNode = this.get('overlay').one('.sqs-video-icon');
      if (inactive) {
        // The element is by default a plain div, so remove all attributes
        // that make it keyboard- and screen-reader-accessible (it will stay
        // visually only for decoration).
        videoIconNode.removeAttribute('tabindex');
        videoIconNode.removeAttribute('role');
        videoIconNode.removeAttribute('aria-label');
      } else {
        videoIconNode.setAttribute('tabindex', 0);
        videoIconNode.setAttribute('role', 'button');
        videoIconNode.setAttribute('aria-label', t("Play"));



      }
    },

    _cacheOverlayWrapperHeight: function () {
      var parentNode = this.get('host').get('parentNode');
      var heightToCache = parentNode.get('offsetHeight');
      parentNode.setData('cached-height', heightToCache);
    },

    _getOrCreateNode: function (selector, newMarkup) {

      var exisiting = this.get('host').one(selector);

      if (exisiting) {
        return exisiting;
      }

      return Y.Node.create(newMarkup);

    },

    /**
     * @method unload
     */
    unload: function () {
      var host = this.get('host');

      host.setStyles({
        top: null });


      // show overlay
      this.showOverlay();

      // remove elements from the DOM
      if (this.get('overlay')) {
        this.get('overlay').remove();
      }
      this.get('video').remove();

      // destroy intrinsic if it exists
      if (this._intrinsic) {
        host.one('.intrinsic').remove(true);
      }

      // re-append overlay
      if (this.get('overlay')) {
        this.get('overlay').setStyle('opacity', 0);
        host.appendChild(this.get('overlay'));
      }

      this._setIntrinsic();

      this._loaded = false;
    },

    reload: function () {
      this.unload();
      this.load();
    },

    handleFullScreenChange: function (e) {
      this.isFullScreen = !this.isFullScreen;
    },

    showVideoIfNotInactive: function () {
      if (!this.get('inactive')) {
        this.showVideo();
      }
    },

    bindUI: function () {

      // show video on overlay click (only in frontend)
      if (this.get('overlay')) {
        this.get('overlay').on('click', this.showVideoIfNotInactive, this);
        this.get('overlay').one('.sqs-video-icon').on('keydown', function (e) {
          var isEnterPressed = e.keyCode === 13;
          var isSpacePressed = e.keyCode === 32;
          // Button standard behavior is to get activated on Enter or Space keydown.
          if (isEnterPressed || isSpacePressed) {
            this.showVideoIfNotInactive();
          }
        }, this);
      }

      // reload video after change in mode
      this.after('modeChange', this.reload, this);

      // correctly set intrinsic top after resize and tweak open/close
      this._resizeEmitter.on('resize', function () {
        if (this.get('mode') === 'fit') {
          this._resetFitTop();
        }

        if (this.get('overlay')) {
          var overlayImage = this.get('overlay').one('img');

          if (overlayImage) {
            overlayImage.fire('refresh');
          }
        }
      }, this);

      this._resizeEmitter.on('resize:end', function () {
        if (this.get('mode') === 'fit' && !this.isFullScreen) {
          this._cacheOverlayWrapperHeight();
          this._resetFitTop();
        }
      }, this);

      this.prefixedFullScreenEvents.forEach(function (prefixedEvent) {
        Y.config.doc.addEventListener(prefixedEvent, this._onFullscreenChangeHandler);
      }.bind(this));


      Y.Global.on('tweak:aftershow', function () {
        if (this.get('mode') === 'fit') {
          this._resetFitTop();
        }
      }, this);

      Y.Global.after('tweak:afterclose', function () {
        if (this.get('mode') === 'fit') {
          setTimeout(Y.bind(function () {
            this._resetFitTop();
          }, this), 1000);
        }
      }, this);
    },

    /**
     * @method showVideo
     */
    showVideo: function () {
      // don't show video if already showing or no overlay exists
      if (this.get('showingVideo') || !this.get('overlay')) {return;}

      this._set('showingVideo', true);

      this._showOverlayOnOthers();

      var video = this.get('video');

      video.setStyle('opacity', 0);

      if (this._intrinsic) {
        this.get('host').one('.intrinsic-inner').appendChild(video);
      } else {
        this.get('host').appendChild(video);
      }

      // transition from overlay to video
      var overlay = this.get('overlay');
      var opaqueNode = overlay.one('.sqs-video-opaque');

      opaqueNode.anim({ opacity: 1 }, {
        duration: 1,
        start: function () {
          overlay.one('.sqs-video-icon').setStyle('visibility', 'hidden');
        },
        end: function () {
          this.get('video').setStyle('opacity', 1);
          if (this.get('showingVideo')) {
            overlay.setStyle('visibility', 'hidden');
          }
          if (overlay.hasClass('no-thumb')) {
            opaqueNode.setStyle('opacity', 1);
          } else {
            opaqueNode.setStyle('opacity', 0);
          }
          overlay.one('.sqs-video-icon').setStyle('visibility', null);
        },
        context: this }).
      run();
    },

    /**
     * @method showOverlay
     */
    showOverlay: function () {
      // don't show overlay if already not showing or no overlay exists
      if (!this.get('showingVideo') || !this.get('overlay')) {return;}

      this._set('showingVideo', false);

      this.get('overlay').setStyle('opacity', 0);
      this.get('overlay').setStyle('visibility', null);

      // transition from video to overlay
      var video = this.get('video');

      video.anim({ opacity: 0 }, {
        duration: 0.3,
        start: function () {
          this.get('overlay').anim({ opacity: 1 }, {
            duration: 0.3 }).
          run();
        },
        end: function () {
          video.remove();
        },
        context: this }).
      run();
    },

    /**
     * @method refreshVideo
     */
    refreshVideo: function () {
      // don't refresh if already loaded
      if (!this.get('showingVideo')) {return;}

      var video = this.get('video');

      if (this.get('type') === 'object') {
        video.remove();

        var host = this.get('host');

        if (this._intrinsic) {
          host.one('.intrinsic-inner').appendChild(video);
        } else {
          host.appendChild(video);
        }
      } else if (this.get('type') === 'iframe') {
        video.setAttribute('src', video.getAttribute('src'));
      }
    },

    /**
     * @method play
     */
    play: function () {
      // do nothing if api is not supported
      if (!this.get('apiFriendly')) {return;}

      var player = this.get('video').getDOMNode().contentWindow;

      switch (this._providerName) {
        case 'Vimeo':
          player.postMessage('{"method": "play", "value": "true"}', '*');
          break;

        case 'YouTube':
          player.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
          break;}

    },

    /**
     * @method pause
     */
    pause: function () {
      // do nothing if api is not supported
      if (!this.get('apiFriendly')) {return;}

      var player = this.get('video').getDOMNode().contentWindow;

      switch (this._providerName) {
        case 'Vimeo':
          player.postMessage('{"method": "pause", "value": "true"}', '*');
          break;

        case 'YouTube':
          player.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
          break;}

    },

    /**
     * @method getWidth
     */
    getWidth: function () {
      return this.get('video').getAttribute('width');
    },

    /**
     * @method getHeight
     */
    getHeight: function () {
      return this.get('video').getAttribute('height');
    },

    _setVariables: function () {
      var host = this.get('host');

      this._setIntrinsic();

      // this.get('overlay')
      this._set('overlay', host.one('.sqs-video-overlay'));

      // this.get('video'), this.get('type')
      var videoNode = Y.Node.create(host.getAttribute('data-html'));

      var invalidNodeWithCustomFeaturedImage = Y.Node.create(
      '<div class="sqs-video-invalid-wrapper"><div class="state-message">' + t("Custom featured images only available with embedded videos. Go back into embed settings to turn off featured images.") +






      '</div></div>');


      var invalidNode = Y.Node.create(
      '<div class="sqs-video-invalid-wrapper"><div class="state-message">' + t("Invalid video embed.") +


      '</div></div>');


      var video;
      if (!videoNode) {
        video = invalidNode;
        this._set('type', 'invalid');
      } else if (videoNode.test('iframe')) {
        video = videoNode;
        this._set('type', 'iframe');
      } else if (videoNode.test('object')) {
        video = videoNode;
        this._set('type', 'object');
      } else if (videoNode.one('iframe')) {
        video = videoNode.one('iframe');
        this._set('type', 'iframe');
      } else if (videoNode.one('object')) {

        if (videoNode.one('embed')) {
          video = videoNode.one('object');
          this._set('type', 'object');
        } else {
          video = invalidNode;
          this._set('type', 'invalid');
        }
      } else if (this.get('overlay')) {
        video = invalidNodeWithCustomFeaturedImage;
        this._set('type', 'invalid');
      } else {
        video = invalidNode;
        this._set('type', 'invalid');
      }

      this._set('video', video);

      // this._providerName
      var providerName = host.getAttribute('data-provider-name');
      if ((!providerName || providerName === '') && this.get('type') !== 'invalid') {
        var src = this.get('video').getAttribute('src');
        if (src.match('player.vimeo.com')) {
          providerName = 'Vimeo';
        } else if (src.match('www.youtube.com')) {
          providerName = 'YouTube';
        }
      }

      this._providerName = providerName;

      // There's not much of a consistent API for testing whether an embedded iFrame
      // element has put us in full screen.
      // So keep a local reference. And we'll update ourselves.
      this.isFullScreen =
      Y.config.doc.fullScreen ||
      Y.config.doc.mozFullScreen ||
      Y.config.doc.webkitIsFullScreen;


      this.prefixedFullScreenEvents = [
      'fullscreenchange',
      'webkitfullscreenchange',
      'mozfullscreenchange',
      'MSFullscreenChange'];


      if (this.get('type') === 'invalid') {
        host.addClass('video-invalid');
      } else {
        this._augmentVideoSrc();
        host.removeClass('video-invalid');
      }
    },

    _setIntrinsic: function () {
      this._intrinsic = this.get('mode') === 'none' || this.get('mode') === 'fit';
    },

    _resetFitTop: function () {
      var host = this.get('host');

      var parentHeight = host.get('parentNode').get('offsetHeight');

      // Get a best fit. Following a resize - when sizes are off, as aren't
      // Reset until the fullscreenchange transition completes.
      if (parentHeight > Y.config.win.innerHeight) {
        parentHeight = host.get('parentNode').getData('cached-height') || Y.config.win.innerHeight;
      }

      var intrinsic = host.one('.intrinsic');
      intrinsic.setStyle('top', null);

      var intrinsicHeight = intrinsic.get('offsetHeight');

      host.setStyle('top', (parentHeight - intrinsicHeight) / 2 + 'px');
    },

    _augmentVideoSrc: function () {
      var type = this.get('type');
      var video = this.get('video');
      var srcEl = type === 'iframe' ? video : video.one('embed') || video;
      var srcAttribute = srcEl.test('object') ? 'data' : 'src';
      var src = srcEl.getAttribute(srcAttribute);
      var splitSrc = src.split('?');
      var baseUrl = splitSrc[0];
      var queryString = splitSrc[1] || '';
      var queryParams = Y.QueryString.parse(queryString);
      var providerName = this._providerName;

      // set autoplay if overlay exists
      if (this.get('overlay') || this.get('forceAutoplay')) {
        if (providerName === t("Wistia, Inc."))

        {
          queryParams.autoPlay = 'true';
        } else if (providerName === 'Animoto') {
          queryParams.options = 'autostart';
        } else {
          queryParams.autoplay = 1;
        }
      }

      // Set https for object embeds and providers that are sensitive to protocol
      var protocolSensitiveProviders = [
      'Vimeo',
      'YouTube',
      'Wistia, Inc.',
      'Animoto'];


      if (type === 'object' || protocolSensitiveProviders.indexOf(providerName) !== -1) {
        if (UrlUtils.isSecure()) {
          baseUrl = UrlUtils.securifyURL(baseUrl);
        }
      }

      /**
       * SIX-9838 fixing corrupt data during a small period where
       * we were incorrectly adding 'http://' to Vimeo src attributes
       * that already contained '//'. -dbarber
       */
      baseUrl = baseUrl.replace('http:////', '//');
      baseUrl = baseUrl.replace('https:////', '//');

      // enable API interactions for supported services
      if (type === 'iframe') {
        switch (providerName) {
          case 'YouTube':
            queryParams.enablejsapi = 1;
            this._set('apiFriendly', true);
            break;}

      }

      src = baseUrl + '?' + Y.QueryString.stringify(queryParams);

      srcEl.setAttribute(srcAttribute, src);
    },

    _showOverlayOnOthers: function () {
      Y.all('.sqs-video-wrapper').each(function (videoWrapperEl) {
        if (videoWrapperEl === this.get('host')) {return;}
        videoWrapperEl.videoloader.showOverlay();
      }, this);
    } },


  {
    NS: 'videoloader',
    ATTRS: {
      /**
       * @attribute apiFriendly
       * @type      Boolean
       * @readOnly
       */
      apiFriendly: {
        value: false,
        readOnly: true },


      /**
       * @attribute inactive
       * @type      Boolean
       */
      inactive: {
        value: false },


      /**
       * @attribute load
       * @type      Boolean
       * @default   true
       */
      load: {
        value: true },


      /**
       * One of
       *    * none
       *    * fit
       *    * fill
       *    * null
       * @attribute mode
       * @type      String
       */
      mode: {
        value: 'none',
        validator: function (val) {
          return ['none', 'fit', 'fill', null].indexOf(val) !== -1;
        } },


      /**
       * @attribute overlay
       * @type      Boolean
       * @readOnly
       */
      overlay: {
        value: null,
        readOnly: true },


      /**
       * @attribute showingVideo
       * @type      Boolean
       * @readOnly
       */
      showingVideo: {
        value: false,
        readOnly: true },


      /**
       * @attribute type
       * @readOnly
       */
      type: {
        value: null,
        readOnly: true },


      /**
       * @attribute video
       * @readOnly
       */
      video: {
        value: null,
        readOnly: true },


      /**
       * Force the video to autoplay
       * @type {Boolean}
       * @default false
       */
      forceAutoplay: {
        value: false } } });




}, '1.0', { requires: [
  'base',
  'node',
  'plugin',
  'querystring',
  'squarespace-dom-emitters-resize',
  'squarespace-image-loader',
  'squarespace-plugin-node-destroy-notifier',
  'squarespace-ui-base'] });