import * as CookieBannerConstants from 'shared/constants/CookieBanner';

/**
 * @param {string} test enum value to look for
 * @return {string} corrected enum value
 */
export const getBarPosition = (test = '') => {
  if (Object.values(CookieBannerConstants.BAR_POSITIONS).includes(test)) {
    return test;
  }
  if ([
    CookieBannerConstants.POPUP_POSITIONS.TOP_LEFT,
    CookieBannerConstants.POPUP_POSITIONS.TOP_RIGHT,
  ].includes(test)) {
    return CookieBannerConstants.BAR_POSITIONS.TOP;
  }
  return CookieBannerConstants.BAR_POSITIONS.BOTTOM;
};

/**
 * @param {string} test enum value to look for
 * @return {string} corrected enum value
 */
export const getPopupPosition = (test = '') => {
  if (test === CookieBannerConstants.BAR_POSITIONS.TOP) {
    return CookieBannerConstants.POPUP_POSITIONS.TOP_LEFT;
  }
  if (test === CookieBannerConstants.BAR_POSITIONS.BOTTOM) {
    return CookieBannerConstants.POPUP_POSITIONS.BOTTOM_LEFT;
  }
  if (test && Object.values(CookieBannerConstants.POPUP_POSITIONS).includes(test)) {
    return test;
  }
  return CookieBannerConstants.POPUP_POSITIONS.BOTTOM_LEFT;
};

export const getWithDefaults = function ({
  // Remember the destructure only sets for undefined values and not empty
  // strings
  cookieBannerCtaText,
  cookieBannerCtaVariant,
  cookieBannerPosition,
  cookieBannerText = CookieBannerConstants.DEFAULT_TEXT,
  cookieBannerTheme,
  cookieBannerVariant,
  cookieBannerOptOutCtaText,
  cookieBannerAcceptType,
  ...rest
} = {}) {
  // Ensure some text exists
  cookieBannerCtaText = cookieBannerCtaText || CookieBannerConstants.DEFAULT_CTA_TEXT;
  cookieBannerText = cookieBannerText.replace('<p></p>', '') || CookieBannerConstants.DEFAULT_TEXT;

  // Ensure an option is selected
  cookieBannerCtaVariant = (cookieBannerCtaVariant && CookieBannerConstants.CTA_VARIANTS[cookieBannerCtaVariant]) ?
    cookieBannerCtaVariant :
    CookieBannerConstants.CTA_VARIANTS.ICON;
  cookieBannerTheme = (cookieBannerTheme && CookieBannerConstants.THEMES[cookieBannerTheme]) ?
    cookieBannerTheme :
    CookieBannerConstants.THEMES.DARK;
  cookieBannerVariant = (cookieBannerVariant && CookieBannerConstants.VARIANTS[cookieBannerVariant]) ?
    cookieBannerVariant :
    CookieBannerConstants.VARIANTS.BAR;

  // Ensure an option is selected, given a specific variant
  cookieBannerPosition = cookieBannerVariant === CookieBannerConstants.VARIANTS.BAR ?
    getBarPosition(cookieBannerPosition) :
    getPopupPosition(cookieBannerPosition);

  cookieBannerOptOutCtaText = cookieBannerOptOutCtaText || CookieBannerConstants.DEFAULT_OPT_OUT_TEXT;
  cookieBannerAcceptType = cookieBannerAcceptType || CookieBannerConstants.ACCEPT_TYPES.OPT_IN;

  return {
    cookieBannerCtaText,
    cookieBannerCtaVariant,
    cookieBannerPosition,
    cookieBannerText,
    cookieBannerTheme,
    cookieBannerVariant,
    cookieBannerOptOutCtaText,
    cookieBannerAcceptType,
    ...rest
  };
};

/**
 * @param {object} data from VisitorData.jsx component's props
 * @return {object} for api, should match EuCookieComplianceResource.java
 */
export const transformComponentDataToApiPayload = function (data) {
  const {
    cookieBannerCtaText,
    cookieBannerCtaVariant,
    cookieBannerPosition,
    cookieBannerText,
    cookieBannerTheme,
    cookieBannerVariant,
    cookieBannerOptOutCtaText,
    cookieBannerAcceptType,
    isActivityLogEnabled,
    isAnalyticsCookiesDisabled,
    isCookieBannerEnabled,
    isRestrictiveCookiePolicyAbsolute,
  } = getWithDefaults(data);

  return {
    cookieBannerCtaText,
    cookieBannerVariant,
    cookieBannerPosition,
    cookieBannerCtaVariant,
    cookieBannerText: '' + cookieBannerText === '<p></p>' ? '' : '' + cookieBannerText,
    cookieBannerTheme,
    cookieBannerOptOutCtaText,
    cookieBannerAcceptType,
    isCookieBannerEnabled,
    isRestrictiveCookiePolicyEnabled: isAnalyticsCookiesDisabled || false,
    isVisitorDataRestricted: !isActivityLogEnabled,
    isRestrictiveCookiePolicyAbsolute: isRestrictiveCookiePolicyAbsolute || false,
    // RTE gives <p></p> for empty, so clean that up
    // Also, RTE uses String object so convert to standard String first
  };
};

/**
 * @param {object} data from the API, should match EuCookieComplianceResource.java
 * @return {object} to use in component as props, should match VisitorData.jsx
 */
export const transformApiDataToComponentProps = function (data) {
  const {
    cookieBannerCtaText,
    cookieBannerCtaVariant,
    cookieBannerPosition,
    cookieBannerText,
    cookieBannerTheme,
    cookieBannerVariant,
    cookieBannerOptOutCtaText,
    cookieBannerAcceptType,
    isCookieBannerEnabled,
    isRestrictiveCookiePolicyAbsolute,
    isRestrictiveCookiePolicyEnabled,
    isVisitorDataRestricted,
  } = getWithDefaults(data);

  return {
    cookieBannerCtaText,
    cookieBannerCtaVariant,
    cookieBannerPosition,
    cookieBannerText,
    cookieBannerTheme,
    cookieBannerVariant,
    cookieBannerOptOutCtaText,
    cookieBannerAcceptType,
    isActivityLogEnabled: !isVisitorDataRestricted,
    isAnalyticsCookiesDisabled: isRestrictiveCookiePolicyEnabled,
    isCookieBannerEnabled,
    isRestrictiveCookiePolicyAbsolute,
  };
};
