import { easing } from '@sqs/rosetta-tokens';

const ANIMATION_DURATION_MS = 250;

export const ACCORDION_ITEMS_CONTAINER_SELECTOR = '.accordion-items-container';
export const ACCORDION_ITEM_CLICK_TARGET_CLASSNAME = 'accordion-item__click-target';
export const ACCORDION_ITEM_CLICK_TARGET_SELECTOR = `.${ACCORDION_ITEM_CLICK_TARGET_CLASSNAME}`;
export const ACCORDION_ITEM_CLASSNAME = 'accordion-item';
export const ACCORDION_ITEM_SELECTOR = `.${ACCORDION_ITEM_CLASSNAME}`;
export const ACCORDION_DROPDOWN_CLASSNAME = 'accordion-item__dropdown';
export const ACCORDION_DROPDOWN_SELECTOR = `.${ACCORDION_DROPDOWN_CLASSNAME}`;
export const DROPDOWN_OPEN_CLASSNAME = 'accordion-item__dropdown--open';
export const DROPDOWN_PRE_OPEN_CLASSNAME = 'accordion-item__dropdown--pre-open';
export const IS_EXPANDED_FIRST_ITEM_ATTRIBUTE = 'data-is-expanded-first-item';

// ************************************************************
// Public functions
// ************************************************************

export function initializeAccordionBlock(blockNode) {
  const accordionItemsContainer = blockNode.querySelector(ACCORDION_ITEMS_CONTAINER_SELECTOR);
  const accordionItems = Array.from(accordionItemsContainer.querySelectorAll(ACCORDION_ITEM_SELECTOR));
  const allowMultipleOpenItems = accordionItemsContainer.getAttribute(
    'data-should-allow-multiple-open-items'
  ) === 'true';
  const shouldAnimate = true;

  accordionItems.forEach(function(item, itemIndex) {
    const clickTarget = item.querySelector(ACCORDION_ITEM_CLICK_TARGET_SELECTOR);
    const dropdownElement = item.querySelector(ACCORDION_DROPDOWN_SELECTOR);
    clickTarget.addEventListener('click', function() {
      _toggleAccordionItem({
        item,
        dropdownElement,
        accordionItems,
        allowMultipleOpenItems,
        shouldAnimate
      });
    });
    _attachItemId(blockNode, clickTarget, dropdownElement, itemIndex);
  });

  syncIsFirstItemExpanded(accordionItemsContainer);
}

export function openAccordionItem(accordionHtml, itemIndex, shouldAnimate) {
  const item = accordionHtml.querySelectorAll(ACCORDION_ITEM_SELECTOR)[itemIndex];
  if (!item) { return; }
  const dropdownElement = item.querySelector(ACCORDION_DROPDOWN_SELECTOR);
  _openAccordionItem(item, dropdownElement, shouldAnimate);
}

export function closeAccordionItem(accordionHtml, itemIndex, shouldAnimate) {
  const item = accordionHtml.querySelectorAll(ACCORDION_ITEM_SELECTOR)[itemIndex];
  if (!item) { return; }
  const dropdownElement = item.querySelector(ACCORDION_DROPDOWN_SELECTOR);
  _closeAccordionItem(item, dropdownElement, shouldAnimate);
}

export function closeAllAccordionItems(accordionHtml, shouldAnimate) {
  const accordionItems = Array.from(accordionHtml.querySelectorAll(ACCORDION_ITEM_SELECTOR));
  _closeAllAccordionItems(accordionItems, shouldAnimate);
}

export function toggleRelevantAccordionItem(accordionHtml, currentBlockDisclosureRoute) {
  const openItemIndex = _getDisclosureIndex(currentBlockDisclosureRoute);
  const shouldAnimate = false;
  const shouldOpenItem = openItemIndex !== -1 && typeof openItemIndex !== 'undefined';

  if (shouldOpenItem) {
    openAccordionItem(accordionHtml, openItemIndex, shouldAnimate);
  } else {
    closeAllAccordionItems(accordionHtml, shouldAnimate);
  }
}

/**
 * This function makes sure the first accordion item is expanded or contracted
 * according to the user's preference.
 * However, some disclosures (such as the array items) have their own logic for
 * which item to expand, so if we're in a disclosure that already handles expansion,
 * we return early to avoid interfering.
**/
export function syncIsFirstItemExpanded(accordionHtml, disclosureRoute) {
  if (_doesCurrentDisclosureHandleExpansion(disclosureRoute)) { return; }

  const isExpandedFirstItem = accordionHtml.getAttribute(IS_EXPANDED_FIRST_ITEM_ATTRIBUTE) === 'true';
  const firstItemIndex = 0;

  if (isExpandedFirstItem) {
    openAccordionItem(accordionHtml, firstItemIndex);
  } else {
    closeAccordionItem(accordionHtml, firstItemIndex);
  }
}

export function getInitialAccordionBlockHeight(blockNode) {
  const accordionItemsContainer = blockNode.querySelector(ACCORDION_ITEMS_CONTAINER_SELECTOR);
  if (!accordionItemsContainer) {
    return blockNode.clientHeight;
  }

  const accordionItems = Array.from(accordionItemsContainer.querySelectorAll(ACCORDION_ITEM_SELECTOR));
  const openItems = accordionItems.filter(_isOpen);

  _closeAllAccordionItems(accordionItems);
  syncIsFirstItemExpanded(accordionItemsContainer);

  const height = accordionItemsContainer.clientHeight;

  openItems.forEach((item) => {
    const dropdownElement = item.querySelector(ACCORDION_DROPDOWN_SELECTOR);
    _openAccordionItem(item, dropdownElement);
  });

  return height;
}


// ************************************************************
// Private functions
// ************************************************************

function _doesCurrentDisclosureHandleExpansion(disclosureRoute) {
  const routePatternsThatAlreadyExpandAccordionItems = [
    'accordionItems/',
    'accordionItemDescription',
  ];

  const isExpansionHandled = (new RegExp(
    routePatternsThatAlreadyExpandAccordionItems.join('|')
  )).test(disclosureRoute);

  return isExpansionHandled;
}

function _getDisclosureIndex(disclosureRoute) {
  let index = disclosureRoute ? disclosureRoute.split('/')[1] : -1;
  if (_shouldExpandFirstItem(disclosureRoute)) { index = 0; }
  return index;
}

function _shouldExpandFirstItem(disclosureRoute) {
  const descriptionRelatedDisclosures = new Set([
    'accordionItemDescription',
  ]);
  return descriptionRelatedDisclosures.has(disclosureRoute);
}

function _toggleAccordionItem({
  item,
  dropdownElement,
  accordionItems,
  allowMultipleOpenItems,
  shouldAnimate
}) {
  const isAnimating = item.hasAttribute('data-is-item-animating');
  if (isAnimating) { return; }
  if (_isOpen(item)) {
    _closeAccordionItem(item, dropdownElement, shouldAnimate);
  } else {
    if (!allowMultipleOpenItems) {
      _closeAllAccordionItems(accordionItems, shouldAnimate);
    }
    _openAccordionItem(item, dropdownElement, shouldAnimate);
  }
}

function _openAccordionItem(item, dropdownElement, shouldAnimate) {
  const clickTarget = item.querySelector(ACCORDION_ITEM_CLICK_TARGET_SELECTOR);
  item.setAttribute('data-is-open', 'true');
  clickTarget.setAttribute('aria-expanded', 'true');

  if (!shouldAnimate) {
    dropdownElement.classList.add(DROPDOWN_OPEN_CLASSNAME);
    return;
  }

  animateOpen(item, dropdownElement);
}

function animateOpen(item, dropdownElement) {
  item.setAttribute('data-is-item-animating', 'true');
  dropdownElement.classList.add(DROPDOWN_PRE_OPEN_CLASSNAME);
  const height = dropdownElement.getBoundingClientRect().height;

  dropdownElement.classList.remove(DROPDOWN_PRE_OPEN_CLASSNAME);
  dropdownElement.style.height = '0px';
  dropdownElement.classList.add(DROPDOWN_OPEN_CLASSNAME);
  dropdownElement.style.transition = 'height ' + ANIMATION_DURATION_MS + 'ms ' + easing.product.default;

  _runOnNextRenderingFrame(function() {
    dropdownElement.style.height = height + 'px';

    window.setTimeout(function() {
      dropdownElement.style.removeProperty('height');
      dropdownElement.style.removeProperty('transition');
      item.removeAttribute('data-is-item-animating');
    }, ANIMATION_DURATION_MS);
  });
}

function _closeAccordionItem(item, dropdownElement, shouldAnimate) {
  const clickTarget = item.querySelector(ACCORDION_ITEM_CLICK_TARGET_SELECTOR);
  item.removeAttribute('data-is-open');
  clickTarget.setAttribute('aria-expanded', 'false');

  if (!shouldAnimate) {
    dropdownElement.classList.remove(DROPDOWN_OPEN_CLASSNAME);
    return;
  }

  animateClose(item, dropdownElement);
}

function animateClose(item, dropdownElement) {
  item.setAttribute('data-is-item-animating', 'true');

  const height = dropdownElement.getBoundingClientRect().height;

  dropdownElement.style.height = height + 'px';
  dropdownElement.style.transition = 'height ' + ANIMATION_DURATION_MS + 'ms ' + easing.product.default;

  _runOnNextRenderingFrame(function() {
    dropdownElement.style.height = '0px';

    window.setTimeout(function() {
      dropdownElement.classList.remove(DROPDOWN_OPEN_CLASSNAME);
      dropdownElement.style.removeProperty('height');
      dropdownElement.style.removeProperty('transition');
      item.removeAttribute('data-is-item-animating');
    }, ANIMATION_DURATION_MS);
  });
}

function _closeAllAccordionItems(accordionItems, shouldAnimate) {
  const itemsToClose = accordionItems.filter(_isOpen);
  itemsToClose.forEach(function(item) {
    const dropdownElement = item.querySelector(ACCORDION_DROPDOWN_SELECTOR);
    _closeAccordionItem(item, dropdownElement, shouldAnimate);
  });
}

function _isOpen(accordionItem) {
  return accordionItem.hasAttribute('data-is-open');
}

function _runOnNextRenderingFrame(fn) {
  // reference: https://medium.com/@paul_irish/requestanimationframe-scheduling-for-nerds-9c57f7438ef4
  window.requestAnimationFrame(function() {
    window.requestAnimationFrame(fn);
  });
}

// This is for accessibility because aria-expanded needs to know
// which click target corresponds to which dropdown region
function _attachItemId(blockNode, clickTarget, dropdownElement, itemIndex) {
  const blockId = blockNode.id;
  if (!blockId) { return; }
  const clickTargetId = 'button-' + blockId + '-' + itemIndex;
  const dropdownElementId = 'dropdown-' + blockId + '-' + itemIndex;

  clickTarget.setAttribute('id', clickTargetId);
  clickTarget.setAttribute('aria-controls', dropdownElementId);

  dropdownElement.setAttribute('id', dropdownElementId);
  dropdownElement.setAttribute('aria-labelledby', clickTargetId);
}
