import BlockType from '@sqs/enums/BlockType';
import network from '@sqs/network';
import querystring from 'qs';

function ensureLatest(el) {
  const wrapper = el.querySelector('.summary-block-wrapper');
  if (!wrapper) {
    return Promise.resolve();
  }

  if (!wrapper.matches('.summary-block-collection-type-events')) {
    return Promise.resolve();
  }

  let widgetJSONValue;

  try {
    widgetJSONValue = JSON.parse(el.getAttribute('data-block-json'));
  } catch (e) {
    console.warn('Issue with decoding the JSON: ', e);
    return Promise.resolve();
  }

  let collectionId = el.ownerDocument.defaultView.Static.SQUARESPACE_CONTEXT.collectionId;

  const layoutNode = el.closest('.sqs-layout[data-type="page"]');
  if (layoutNode) {
    collectionId = layoutNode.getAttribute('id').replace('page-', '');
  }

  const postData = querystring.stringify({
    collectionId,
    widgetJSON: JSON.stringify({
      type: BlockType.SUMMARY_V2,
      id: el.getAttribute('id').replace('block-', ''),
      value: widgetJSONValue
    })
  }, { arrayFormat: 'repeat' });

  const postConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  };

  return network.post('/api/widget/GetWidgetRendering', postData, postConfig).then(result => {

    const content = el.querySelector('.sqs-block-content');

    if (content) {
      content.innerHTML = result.data.html;
    }

  });
}

export const calculateVideoPlaceholderAspectRatio = (imageAspectRatio) => {
  let paddingBottom = 65; // default padding
  if (imageAspectRatio !== 'Auto') {
    paddingBottom = 100 / imageAspectRatio;
  }

  return paddingBottom || 0;
};

/**
 * CMS-24201
 * The summary gallery has an auto-sizable grid option.
 * When using autosized images, the images have no initial width and height.
 * Because of that, the smallest possible src is used for the image.
 * Images will look low-res as a result.
 */

/**
 * @param {YUI.Node|Node} el
 */
export default (el) => {

  if (el.getDOMNode) {
    el = el.getDOMNode();
  }

  return ensureLatest(el).then(() => {

    if (!el.querySelector('.summary-block-setting-design-autogrid')) {
      return Promise.resolve();
    }

    const images = [...el.querySelectorAll('img')];

    let imagesLoaded = 0;

    const onImageLoaded = (e) => {
      e.target.removeEventListener('load', onImageLoaded);
      imagesLoaded++;
      if (imagesLoaded === images.length) {
        imagesLoaded = 0;
        // Force all images to src swap to best src
        // N.B. The image may still be animating if it has image animation classes
        // set (e.g. site setting, but generally only image blocks have the
        // classes)
        window.dispatchEvent(new Event('resize'));
      }
    };

    const maskOne = (image) => {
      image.addEventListener('load', onImageLoaded);
    };

    images.forEach(maskOne);

  });

};
