import PropTypes from 'prop-types';
import { keyBy } from 'lodash';

/**
 * Helper function to create "enums" as frozen plain objects in the format of
 * `{ FOO: 'FOO', BAR: 'BAR' }` but with the following non-enumerable additions:
 *
 * `includes(value: string): boolean` - Convenience for using `Object.values()`
 * to check if a value is valid
 *
 * `propType: function` - Premade `PropTypes.oneOf()` (not required by default)
 *
 * @param {...string} values Enum values
 */
export default function createEnum(...values) {
  const obj = keyBy(values);
  Object.defineProperties(obj, {
    includes: { value: x => values.includes(x) },
    propType: { value: PropTypes.oneOf(values) }
  });
  return Object.freeze(obj);
}
