import { t } from 'shared/i18n';
import getDocScrollNode from '@sqs/utils/scroll/getDocScrollNode';
import crossBrowserResize from '@sqs/cross-browser-resize';
import selfThrottle from 'shared/utils/selfThrottle';
import { containFocus } from '@sqs/utils/focus';

/**
 * @module squarespace-widgets-lightbox2
 */
YUI.add('squarespace-widgets-lightbox2', function (Y) {

  Y.namespace('Squarespace.Widgets');

  /**
   * A lightbox used to render sets of images and videos with a fullscreen overlay.
   *
   * @class Lightbox2
   * @namespace Squarespace.Widgets
   * @extends Squarespace.Widgets.Gismo
   */
  Y.Squarespace.Widgets.Lightbox2 = Y.Squarespace.Lightbox2 =
  Y.Base.create('Lightbox2', Y.Squarespace.Widgets.Gismo, [], {

    initializer: function () {

      Y.config.win.ACTIVE_LIGHTBOX = this;

      var contentBox = this.get('contentBox');
      contentBox.set('role', 'dialog');
      contentBox.set('aria-label', this.get('title'));

      this._overlay = new Y.Squarespace.Lightbox2Overlay({ theme: this.get('theme') });
      this._overlay.on('hide', this.hideAndDestroy, this);

      this.bMobile = Y.SQS.Gallery.Manager.isMobile();

      this.requestUpdateHeightAndRefresh = selfThrottle(this.updateHeightAndRefresh.bind(this));

    },

    destructor: function () {

      this._flushEvents();

      if (Y.Lang.isValue(this._slideshow)) {
        this._slideshow.destroy();
        this._slideshow = null;
      }

      this._overlay.destroy();
      this._overlay = null;

      Y.config.win.ACTIVE_LIGHTBOX = null;

      if (this.crossBrowserResizeHandle) {
        crossBrowserResize.removeListener(this.crossBrowserResizeHandle);
      }

    },

    renderUI: function () {
      Y.Squarespace.Lightbox2.superclass.renderUI.call(this);

      Y.one('body').addClass('sqs-lightbox-open');

      var contentBox = this.get('contentBox');

      this._overlay.render(contentBox);

      var closeElLabel = t("Close");


      this._closeEl = Y.Node.
      create(
      '<a class="sqs-lightbox-close" role="button" tabindex="0" aria-label="' +
      closeElLabel +
      '"></a>').

      appendTo(contentBox).
      hide();

      if (this.get('set').length > 1) {
        if (!this.bMobile && this.get('controls.previous')) {
          var previousElLabel = t("Previous Item");


          this._previousEl = Y.Node.
          create(
          '<a class="sqs-lightbox-previous" role="button" tabindex="0" aria-label="' +
          previousElLabel +
          '"></a>');

          contentBox.appendChild(this._previousEl);
        }

        if (!this.bMobile && this.get('controls.next')) {
          var nextElLabel = t("Next Item");


          this._nextEl = Y.Node.
          create(
          '<a class="sqs-lightbox-next" role="button" tabindex="0" aria-label="' +
          nextElLabel +
          '"></a>');

          contentBox.appendChild(this._nextEl);
        }
      } else if (!this.get('set').length) {
        this.set('set', [{ content: this.get('content'), meta: this.get('meta') }]);
      }

      this._slideshow = this.createSlideshow(contentBox);
      this._lightboxContentEl = this._slideshow.get('container');


      if (this.bMobile) {
        var metaTriggerElLabel = t("Toggle Caption");


        this._metaTriggerEl = Y.Node.
        create(
        '<a class="sqs-lightbox-meta-trigger" role="button" tabindex="0" aria-label="' +
        metaTriggerElLabel +
        '">&bull;</a>').

        appendTo(contentBox);
      }

      this._lightboxContentEl.setStyles({
        opacity: 1 });


      this._overlay.once('shown', function () {
        this.ignoreBodyClicks = true;
        Y.Squarespace.EscManager.addTarget(this);

        this._lightboxContentEl.show();
        if (this._closeEl) {
          this._closeEl.show();
        }

        if (this._slideshow) {
          var videoEl = this._slideshow.get('slides').
          item(this._slideshow.get('currentIndex')).
          one('.sqs-video-wrapper');

          if (videoEl) {
            videoEl.videoloader.showVideo();
          }
        }

        this.revertFocusContainment = containFocus({
          container: contentBox._node });

      }, this);

      contentBox.plug(Y.Squarespace.Plugin.ScrollLock);
    },

    // We're not using proper semantic elements for controls
    // (because I wasn't comfortable touching the relevant styles yet),
    // so instead we're manually handling both the click and
    // keydown (Enter and Space) events.
    // If we can use the native <button> element in the future, we will only have to
    // subscribe to the click event (not keydown).
    bindClickAndKeyDownEvents: function (el, handler, eventType) {
      this._event(el.on('click', handler, this), eventType);
      this._event(el.on('keydown', function (e) {
        // 13 for Enter, 32 for Space
        if (e.keyCode === 13 || e.keyCode === 32) {
          handler.call(this, e);
        }
      }, this), eventType);
    },

    bindUI: function () {

      Y.Squarespace.Lightbox2.superclass.bindUI.call(this);

      this._slideshow.after('currentIndexChange', Y.bind(function (e) {
        this.set('currentSetIndex', e.newVal);
        this.syncUI();
      }, this));

      if (Y.config.win.orientation !== undefined) {
        this._event(
        Y.one(Y.config.win).on('orientationchange', this.requestUpdateHeightAndRefresh, this));

      }

      if (this._closeEl) {
        this.bindClickAndKeyDownEvents(this._closeEl, function (e) {
          e.halt();
          this.hideAndDestroy(true);
        });
      }

      if (this._metaTriggerEl) {
        this.bindClickAndKeyDownEvents(this._metaTriggerEl, function () {
          this.toggleMeta();
        }, 'meta-trigger');
      }

      if (this._previousEl) {
        this.bindClickAndKeyDownEvents(this._previousEl, function (e) {
          e.halt();
          this.previous();
        }, 'previous-click');

        var throttledPrevious = Y.throttle(Y.bind(this.previous, this), 200);

        this._event(
        Y.on('key', function (e) {
          e.halt();
          throttledPrevious();
        }, Y.config.win, 'down:37', this),
        'keyboard');


      }

      if (this._nextEl) {
        this.bindClickAndKeyDownEvents(this._nextEl, function (e) {
          e.halt();
          this.next();
        }, 'next-click');

        var throttledNext = Y.throttle(Y.bind(this.next, this), 200);

        this._event(
        Y.on('key', function (e) {
          e.halt();
          throttledNext();
        }, Y.config.win, 'down:39', this),
        'keyboard');


        if (!this.bMobile) {
          this.setupNavigationFade();
        }
      }

      if (!this.isMobile) {
        this._event(this.get('contentBox').on('click', this.onContentBoxClick, this));
      }

      this._event(Y.on('transition:scroll:tapped', function (e) {
        this.onContentBoxClick(e.touchEvent);
      }, this));

      this.crossBrowserResizeHandle = crossBrowserResize.addListener(
      this.requestUpdateHeightAndRefresh.bind(this));


    },

    syncUI: function () {
      var scrollTop = getDocScrollNode(Y.config.doc).scrollTop;

      this.get('contentBox').setStyles({
        top: scrollTop,
        height: Y.config.win.innerHeight });


      var slideEl = this._slideshow._currentSlide();

      var metaEl = slideEl.one('.sqs-lightbox-meta') || this.createMetaEl(slideEl);

      if (metaEl) {

        if (Y.Lang.isValue(this._metaTriggerEl)) {
          this._metaTriggerEl.show();
        }

        if (!this.bMobile) {

          var firstEl = slideEl.one('.sqs-lightbox-padder').get('children').item(0);
          metaEl.setStyles({
            bottom: parseInt(firstEl.getStyle('top'), 10) - 1 + 'px',
            left: firstEl.getStyle('left'),
            right: parseInt(firstEl.getStyle('left'), 10) - 1 + 'px' });


        } else {

          metaEl.appendTo(slideEl);

          metaEl.setStyles({
            bottom: this.bMetaEnabled ? 0 : '-5000px',
            left: 0,
            right: 0 });


          metaEl.addClass('overlay-description-visible');

        }

      } else if (Y.Lang.isValue(this._metaTriggerEl)) {
        this._metaTriggerEl.hide();
      }
    },

    updateHeightAndRefresh: function () {
      this.syncUI();
      this._slideshow.refresh();
    },

    onContentBoxClick: function (e) {

      if (!e.target.ancestor('a', true)) {

        e.halt();

        if (e.target.ancestor('.sqs-video-wrapper')) {
          e.target.ancestor('.sqs-video-wrapper').videoloader.showVideo();
          return;
        }

        if (!this.bMobile) {
          var viewWidth = Y.one('body').get('winWidth'),
          viewHeight = Y.one('body').get('winHeight'),
          viewPaddingPercent = 0.02;

          // If within viewport boundary
          if (e.clientX > viewWidth * viewPaddingPercent && e.clientX < viewWidth * (1 - viewPaddingPercent) &&
          e.clientY > viewHeight * viewPaddingPercent && e.clientY < viewHeight * (1 - viewPaddingPercent)) {
            // If on right side of viewport, go next
            if (e.clientX >= viewWidth / 2 && this._nextEl) {
              this.next();
              return;
            } else if (e.clientX < Y.one('body').get('winWidth') / 2 && this._previousEl) {
              this.previous();
              return;
            }
          }
        }

        if (e.target.ancestor('img', true)) {
          return;
        }

        this.hideAndDestroy(true);
      }

    },

    previous: function () {
      this._slideshow.previousSlide();
    },

    next: function () {
      this._slideshow.nextSlide();
    },

    close: function () {
      this.hideAndDestroy();
    },

    toggleMeta: function () {
      this.get('contentBox').all('.sqs-lightbox-meta').each(Y.bind(function (metaEl) {
        this.bMetaEnabled = parseInt(metaEl.getStyle('bottom'), 10) === 0 ? true : false;
        this._anim(metaEl, { bottom: this.bMetaEnabled ? '-5000px' : '0' }, { duration: this.get('speed') }).run();
        this.bMetaEnabled = !this.bMetaEnabled;
      }, this));
    },

    hideAndDestroy: function (overlay) {
      if (Y.Lang.isBoolean(overlay) && overlay) {
        this._overlay.overlayHideAndDestroy();
      } else {
        this.once('hidden', this.destroy);
        this.hide();
      }
      Y.one('body').removeClass('sqs-lightbox-open');

      if (this.revertFocusContainment) {
        this.revertFocusContainment();
      }

      if (this.get('historyHash')) {
        this._slideshow.set('currentIndex', null); // for deeplink
      }
    },

    hide: function () {
      var duration = this.get('speed');
      var waitToDestroy;

      this.get('contentBox').setStyles({
        transition: 'opacity ' + duration + 's ease',
        // All of the z-index values I've come across seem arbitrary. I chose
        // 889 because it's one higher than what gallery-ng-designs sets. That's
        // probably not a great reason, though.
        zIndex: 889,
        opacity: 0 });


      // This timeout matches the duration. But the duration is in seconds, so
      // we have to multiple by 1000 to get the value in milliseconds.
      waitToDestroy = Y.later(duration * 1000, this, function () {
        Y.Squarespace.EscManager.removeTarget(this);
        this.fire('hidden');

        // For memory management. Not really sure if I need to null local
        // variables, but it can't hurt.
        waitToDestroy.cancel();
        waitToDestroy = null;
      });
    },

    // Show nav arrows only when mouse moves
    setupNavigationFade: function () {
      var aTimer;
      var cancelFade = function () {if (aTimer) {aTimer.cancel();}};
      var fadeLater = function (elem) {
        cancelFade();

        if (elem && elem.inViewportRegion()) {
          elem.addClass('mouseover').siblings().removeClass('mouseover');
          aTimer = Y.later(500, this, function () {
            if (elem && elem.inViewportRegion()) {elem.removeClass('mouseover');}
          });
        }
      };

      this._event(this._nextEl.on('mouseenter', function () {
        cancelFade();
      }), 'nav-next-onmouse');
      this._event(this._previousEl.on('mouseenter', function () {
        cancelFade();
      }), 'nav-prev-onmouse');

      this._event(this._lightboxContentEl.on('mousemove', Y.bind(function (e) {
        if (e.clientX < Y.one('body').get('winWidth') / 2) {
          fadeLater(this._previousEl);
        } else {
          fadeLater(this._nextEl);
        }
      }, this)), 'container-hovering');
    },

    createSlideshow: function (contentBox) {
      var slideshowEl = Y.Node.create('<div class="sqs-lightbox-slideshow"></div>').appendTo(contentBox);

      var slideSet = this.get('set');
      for (var i = 0; i < slideSet.length; i++) {
        var slide = Y.Node.create('<div class="sqs-lightbox-slide"></div>').appendTo(slideshowEl);
        var slidePadder = Y.Node.create('<div class="sqs-lightbox-padder"></div>').appendTo(slide);
        var content = Y.one(slideSet[i].content.cloneNode(true)).appendTo(slidePadder).
        setAttribute('data-image-stretch', 'false') // make sure it doesn't stretch
        .setStyle('opacity', null) // null out any styles set explicitly
        .removeClass('video-fill'); // remove existing video mode (images won't have any)

        // deeplinking: if we're opening the lightbox in a gallery collection, populate the lightbox slide with the
        // information it needs for history to work.
        var slideAncestor = slideSet[i].content.ancestor();
        if (this.get('historyHash') && slideAncestor) {
          if (slideAncestor.getAttribute('data-slide-url')) {
            slide.setAttribute('data-slide-url', slideAncestor.getAttribute('data-slide-url'));
          }
          if (slideAncestor.getAttribute('data-slide-id')) {
            slide.setAttribute('data-slide-id', slideAncestor.getAttribute('data-slide-id'));
          }
        }

        content.all('iframe').remove(); // get rid of initialized video (if any)
      }

      var slideshow = new Y.SQS.Gallery.Gallery2({
        container: slideshowEl,
        currentIndex: this.get('currentSetIndex'),
        loop: !this.bMobile,
        autoplay: false,
        refreshOnResize: true,
        refreshOnOrientationChange: true,
        design: 'stacked',
        keyboard: false,
        designOptions: {
          clickBehavior: false,
          easing: Y.Easing.easeInOutExpo,
          speed: 0.6,
          autoHeight: false,
          transition: this.bMobile ? 'scroll' : 'fade',
          transitionOptions: {
            direction: 'horizontal',
            allowPageScroll: false } },


        loaderOptions: {
          mode: 'fit' },

        lazyLoad: true,
        videoLoaderOptions: this.get('videoLoaderOptions'),
        historyHash: this.get('historyHash') });


      return slideshow;
    },

    createMetaEl: function (el) {
      el = el.one('.sqs-lightbox-padder'); // this is what we work with
      var metaElContent = this.getCurrentElMeta();
      if (metaElContent) {
        var metaEl = Y.Node.create('<div class="sqs-lightbox-meta"></div>');
        metaEl.appendChild(metaElContent);
        el.appendChild(metaEl);

        if (!this.bMobile) {
          this._event(el.one(':first-child').on('mouseenter', function () {
            metaEl.addClass('overlay-description-visible');
          }, this), 'meta-hovering');

          this._event(el.one(':first-child').on('mouseleave', function () {
            metaEl.removeClass('overlay-description-visible');
          }, this), 'meta-hovering');

          this._event(metaEl.on('mouseenter', function () {
            metaEl.addClass('overlay-description-visible');
          }, this), 'meta-hovering');

          this._event(metaEl.on('mouseleave', function () {
            metaEl.removeClass('overlay-description-visible');
          }, this), 'meta-hovering');
        }

        return metaEl;
      }
    },

    getCurrentElContent: function () {
      if (this.get('set').length > 0) {
        return this.get('set')[this.get('currentSetIndex')].content;
      }
      return this.get('content');

    },

    getCurrentElMeta: function () {
      if (this.get('set').length > 0) {
        return this.get('set')[this.get('currentSetIndex')].meta;
      }
      return this.get('meta');

    } },

  {
    NS: 'lightbox2',
    ATTRS: {

      content: {},

      controls: {
        value: {
          previous: false,
          next: false } },



      currentSetIndex: {
        value: 0 },


      meta: {},

      padding: {
        value: 100 },


      set: {
        value: [] },


      speed: {
        value: 0.6 },


      /**
        * Toggles background color of lightbox overlay (via class name "light").
        *
        * @attribute theme
        * @type String
        */
      theme: {
        value: 'dark' },


      title: {
        value: t("Gallery") },




      videoLoaderOptions: {
        value: {} },


      historyHash: {
        value: false } } });




}, '1.0', {
  requires: [
  'base',
  'dom-style-ie',
  'event-key',
  'event-mouseenter',
  'node',
  'plugin',
  'squarespace-animations',
  'squarespace-dom-emitters-resize',
  'squarespace-escmanager',
  'squarespace-plugin-scroll-lock',
  'squarespace-touch-event-listener',
  'squarespace-video-loader',
  'squarespace-widgets-gismo',
  'squarespace-widgets-lightbox2-overlay',
  'yui-throttle'] });