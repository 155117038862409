/**
 * @type {boolean}
 */
export const IS_SQUARESPACE_IOS_APP = !!(
  window.webkit &&
  window.webkit.messageHandlers &&
  window.webkit.messageHandlers.isSquarespaceEditor
);

/**
 * @type {boolean}
 */
export const IS_SQUARESPACE_ANDROID_APP = !!(
  window.SquarespaceApp &&
  window.SquarespaceApp.isSquarespaceEditor
);

export const IS_SQUARESPACE_MOBILE_APP =
  IS_SQUARESPACE_ANDROID_APP || IS_SQUARESPACE_IOS_APP;
