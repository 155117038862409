module.exports = {
  "25715d36c9ca9d059bbf11ff729612c6": "{priceString} every month for 1 year",
  "2c250d0489f32d561c726074e98d9c64": "{priceString} every year",
  "42859f6128248121c17b306fa16971c7": "{priceString} every {billingPeriodValue} months for {durationValue} months",
  "457a2a91ddb30a52adb7165b957b88ff": "{priceString} every {billingPeriodValue} weeks",
  "46b4f499eec735aa2c2d7b58f1e7a1d5": "Unavailable",
  "4706d1dfe81fa89aad3b4b7c5616dc84": "{priceString} every {billingPeriodValue} weeks for {durationValue} years",
  "4aa38087a0cae1f21d31e48dd975d905": "{priceString} every week for {durationValue} years",
  "4b3e1cb6e224ba0e35bb00ef6898c284": "{priceString} every week for {durationValue} weeks",
  "53e6d4cacab49879c5a331593bd40434": "{priceString} every month",
  "73971392005d45d4c5c3cef9f5f2eca5": "{priceString} every year for {durationValue} years",
  "94eb8523959c0442994c223a91d02a4a": "{priceString} every {billingPeriodValue} months for 1 year",
  "99025daa1dfc7337d3248543d4d6fbee": "{priceString} every {billingPeriodValue} months for {durationValue} years",
  "9c389d07dcc5ef2603005128c1154983": "{priceString} every month for {durationValue} months",
  "b503ccc15acfba32b443b15f9be7dc86": "{priceString} every {billingPeriodValue} years for {durationValue} years",
  "bc4942e6eb5560957076ee72bd450a87": "{priceString} every month for {durationValue} years",
  "c7094898f54b9d547da8e1c71ec88eca": "{priceString} every {billingPeriodValue} years",
  "cb02edc9d115111d1e997a8436f6efad": "{priceString} every {billingPeriodValue} weeks for 1 year",
  "d62034f3ffac6a74673302b5aef4e5c0": "{priceString} every {billingPeriodValue} months",
  "dd21aee6f89a601924a4e78dbaa52a18": "{priceString} every {billingPeriodValue} weeks for {durationValue} weeks",
  "e4f13be78f017226a1e6d93301bb8eb4": "{priceString} every week",
  "f1921be45352fa2fe9386ecc5d886b39": "{priceString} every week for 1 year"
};