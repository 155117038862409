import "shared/i18n/configurations/commerce-currency.js";import CookieCutter from '@sqs/cookie-cutter';
import MeasurementStandard from '@sqs/enums/MeasurementStandard';
import ProductTypes from '@sqs/enums/ProductTypes';
import WorkflowStates from '@sqs/enums/WorkflowStates';
import { t } from 'shared/i18n';
import { fromCents } from '@sqs/commerce-currency';
import MoneyFormatterProvider from 'shared/i18n/commerce/MoneyFormatterProvider';
import StoreCurrencyManager from 'shared/utils/commerce/StoreCurrencyManager';
import get from 'lodash/get';
import uuidv4 from 'uuid/v4';

/*sqs-codemod-ignore*/
/**
 * Commerce-related utility functions
 *
 * @module squarespace-commerce-utils
 */
YUI.add('squarespace-commerce-utils', function (Y) {

  Y.namespace('Squarespace');

  /**
   * Commerce utils
   * @class Commerce
   * @namespace Squarespace
   * @static
   */
  Y.Squarespace.Commerce = {

    /**
     * Shared date format for commerce where some accuracy is needed (time).
     * It looks like that: Feb 20, 2015  10:28am
     * The unicode characters are unbreakable spaces.
     */
    COMMERCE_DATE_TIME_FORMAT: 'lllLT',

    /**
     * GLOBAL SETTINGS
     */

    /**
     * @method isExpressCheckout
     */
    isExpressCheckout: function () {
      return Static.SQUARESPACE_CONTEXT.websiteSettings.storeSettings.expressCheckout;
    },

    /**
     * @method measurementStandard
     */
    measurementStandard: function () {
      return Y.Object.getValue(Static,
      ['SQUARESPACE_CONTEXT', 'websiteSettings', 'storeSettings', 'measurementStandard']);
    },

    /**
     * @method weightUnit
     * @return {String} e.g. lbs or kg
     */
    weightUnit: function () {
      return this.measurementStandard() === MeasurementStandard.IMPERIAL ? 'lbs' : 'kg';
    },

    /**
     * e.g. " or cm
     * @method dimensionUnit
     * @return {String}
     */
    dimensionUnit: function () {
      return this.measurementStandard() === MeasurementStandard.IMPERIAL ? '"' : 'cm';
    },

    /**
     * Fetch system-wide maximum values
     * @return system-wide caps
     */
    maxes: function () {
      return {
        // a million bucks
        productPrice: 100000000,
        // 60 characters
        skuStringLength: 60 };

    },

    /**
     * GLOBAL METHODS
     */
    /**
     * Redirect to checkout
     * @method goToCheckoutPage
     */
    goToCheckoutPage: function () {
      if (!(CookieCutter.get('hasCart') === 'true' || Y.Lang.isValue(CookieCutter.get('CART')))) {
        alert( // eslint-disable-line no-alert
        t("Either your session has expired or you do not have cookies enabled. We cannot continue to checkout."));



        return;
      }

      window.top.location = '/commerce/goto-checkout';
    },

    goToSubscriptionPage: function (cartToken) {
      if (!cartToken) {
        return;
      }

      window.top.location = '/checkout/subscribe?cartToken=' + cartToken;
    },

    /**
     * Redirect to donate
     * @method goToDonatePage
     * @param  donatePageId
     */
    goToDonatePage: function (donatePageId) {

      var url = '/checkout/donate?donatePageId=' + donatePageId;

      if (Y.Squarespace.Utils.isInDamaskFrame()) {
        window.top.location = url;
      } else {
        window.location = url;
      }
    },

    /**
     * Redirect to gift card checkout
     * @method goToGiftCardPage
     * @param giftCardId
     * @param denominationVariant
     */
    goToGiftCardPage: function (giftCardId, denominationVariant) {
      giftCardId = encodeURIComponent(giftCardId);
      var giftCardSku = denominationVariant ? encodeURIComponent(denominationVariant.sku) : null;
      var websiteId = Static.SQUARESPACE_CONTEXT.website.id;

      var url = '/checkout/giftcard?websiteId=' + websiteId +
      '&giftCardProductId=' + giftCardId + (denominationVariant ? '&sku=' + giftCardSku : '');

      if (Y.Squarespace.Utils.isInDamaskFrame()) {
        window.top.location = url;
      } else {
        window.location = url;
      }
    },

    isValidUSZip: function (str) {
      return /^\d{5}$/.test(str);
    },

    /**
     * FORMATTERS
     */

    /**
     Takes a variant and generates a descriptor string (e.g. "Small / Red / Cotton")
     @method variantFormat
     @param variant The variant to format
     @param [doNotEscapeHtml=false] whether to escape html or not. It escapes by default.
     */
    variantFormat: function (variant, doNotEscapeHtml) {
      var variantValues = [];
      var variantOptionValues = variant.optionValues;

      for (var i = 0; i < variantOptionValues.length; i++) {
        var unescapedVariantValue = variantOptionValues[i].value;
        variantValues.push(doNotEscapeHtml ?
        unescapedVariantValue :
        Y.Escape.html(unescapedVariantValue));
      }

      return variantValues.join(' / ');
    },

    /**
     * HTML span with formatted money
     * @method moneyString
     * @param  {Number} cents
     * @param  {Boolean} (Optional) whether you'd like to trim the cents off
     * @return {String} html
     * @deprecated Use the CommerceMoneyFormatter and Money instances.
     */
    moneyString: function (cents, trimZeroFractions) {
      if (__DEV__) {
        console.warn('currencyUtils#moneyString is deprecated.' +
        ' Please use the `CommerceMoneyFormatter` instead.');
      }
      var storeCurrency = StoreCurrencyManager.getStoreCurrency();
      var money = fromCents(cents, storeCurrency);
      var options = {
        trimZeroFractions: trimZeroFractions };

      return MoneyFormatterProvider.formatter.format(money, options);
    },

    /**
     * Html string formatted price
     * @method priceString
     * @param item
     * @return {String} html
     */
    priceString: function (item) {
      var normalPrice = this.normalPrice(item);
      var structuredContent = item.structuredContent;

      switch (structuredContent.productType) {
        case ProductTypes.PHYSICAL:
        case ProductTypes.SERVICE:
          if (this.onSale(item)) {
            if (this.variedPrices(item)) {
              return t("from {startingPrice}",

              { startingPrice: this.moneyString(this.fromPrice(item)) });




            }
            return this.moneyString(this.salePrice(item)) +
            ' <span class="original-price">' + this.moneyString(normalPrice) + '</span>';

          } else if (this.variedPrices(item)) {
            return t("from {startingPrice}",

            { startingPrice: this.moneyString(this.fromPrice(item)) });




          }

          return this.moneyString(normalPrice);

        case ProductTypes.DIGITAL:
          if (this.onSale(item)) {
            return this.moneyString(this.salePrice(item)) +
            ' <span class="original-price">' + this.moneyString(normalPrice) + '</span>';
          }

          return this.moneyString(normalPrice);
        case ProductTypes.GIFT_CARD:
          if (this.variedPrices(item)) {
            return t("from {startingPrice}",

            { startingPrice: this.moneyString(normalPrice) });




          }
          return this.moneyString(normalPrice);
        default:
          // this should never happen
          break;}

    },

    isFiniteSubscriptionPlan: function (subscriptionPlan) {
      return !!Number(subscriptionPlan.numBillingCycles);
    },

    /**
     * Generates a string representation of the subscription plan.
     *
     * Subscription plans surface differently for the merchant than for the shopper.
     * This is the string representation that the merchant sees.
     */
    merchantSubscriptionDetailsString: function (subscriptionPlan) {
      if (!subscriptionPlan) {
        return '';
      }

      var billingPeriodMagnitude = subscriptionPlan.billingPeriod.value;
      var billingPeriodUnit = subscriptionPlan.billingPeriod.unit.toLowerCase() + 'ly';
      var isFinite = this.isFiniteSubscriptionPlan(subscriptionPlan);
      if (isFinite) {
        var numBillingCycles = subscriptionPlan.numBillingCycles;
      }

      return get({
        'magnitude == 1': {
          'weekly': {
            'indefinite': t("renews every week"),
            'finite': t("renews every week for {p} payments", { p: numBillingCycles }) },

          'monthly': {
            'indefinite': t("renews every month"),
            'finite': t("renews every month for {p} payments", { p: numBillingCycles }) },

          'yearly': {
            'indefinite': t("renews every year"),
            'finite': t("renews every year for {p} payments", { p: numBillingCycles }) } },


        'magnitude > 1': {
          'weekly': {
            'indefinite': t("renews every {n} weeks", { n: billingPeriodMagnitude }),
            'finite': t("renews every {n} weeks for {p} payments", {
              n: billingPeriodMagnitude,
              p: numBillingCycles }) },


          'monthly': {
            'indefinite': t("renews every {n} months", { n: billingPeriodMagnitude }),
            'finite': t("renews every {n} months for {p} payments", {
              n: billingPeriodMagnitude,
              p: numBillingCycles }) },


          'yearly': {
            'indefinite': t("renews every {n} years", { n: billingPeriodMagnitude }),
            'finite': t("renews every {n} years for {p} payments", {
              n: billingPeriodMagnitude,
              p: numBillingCycles }) } } },



      [
      billingPeriodMagnitude === 1 ? 'magnitude == 1' : 'magnitude > 1',
      billingPeriodUnit,
      isFinite ? 'finite' : 'indefinite']);

    },

    /**
     * @method getTotalStockRemaining
     * @param  item
     * @return {Number} totalStock
     */
    getTotalStockRemaining: function (item) {
      var structuredContent = item.structuredContent;

      if (structuredContent.productType === ProductTypes.DIGITAL) {
        return Infinity;
      }
      var totalStock = 0;

      for (var i = 0; i < structuredContent.variants.length; i++) {
        var variant = structuredContent.variants[i];

        if (variant.unlimited) {
          return Infinity;
        }

        totalStock += variant.qtyInStock;

      }

      return totalStock;

    },

    /**
     * @method summaryFormFieldString
     * @param field
     * @return {String} html
     */
    summaryFormFieldString: function (field) {
      var valueString;
      var escapedValues;

      if (Y.Lang.isObject(field.values)) {
        escapedValues = Y.Array.reduce(Y.Object.keys(field.values), {}, function (values, key) {
          return Y.Object.setValue(values, key, Y.Squarespace.Escaping.escapeForHtml(field.values[key]));
        });
      }

      function getStringForValue(value) {
        var string;

        switch (value) {
          case '-2':
            string = t("Strongly Disagree");


            break;

          case '-1':
            string = t("Disagree");


            break;

          case '0':
            string = t("Neutral");


            break;

          case '1':
            string = t("Agree");


            break;

          case '2':
            string = t("Strongly Agree");


            break;}


        return string;
      }

      switch (field.type) {
        case 'name':
          valueString = escapedValues.First + ' ' + escapedValues.Last;
          break;

        case 'phone':
          var countryCode = escapedValues.Country;

          valueString = '';

          if (countryCode && countryCode !== '') {
            valueString += '+' + countryCode + ' ';
          }

          valueString += escapedValues.Areacode + '-' + escapedValues.Prefix + '-' +
          escapedValues.Line;
          break;

        case 'likert':

          valueString = '<div style="padding-left:5px;">';

          for (var key in escapedValues) {
            var value = escapedValues[key];
            valueString +=
            '<div><span style="font-weight:bold;">' + key + ':</span> ' + getStringForValue(value) + '</div>';
          }

          valueString += '</div>';
          break;

        case 'address':
          valueString = '<div style="padding-left:5px;"><div>' + escapedValues.Line1 + '</div>';

          var line2 = escapedValues.Line2;

          if (line2 && line2 !== '') {
            valueString += '<div>' + line2 + '</div>';
          }

          valueString += '<div>' + escapedValues.City + ', ' + escapedValues.State + ' ' +
          escapedValues.Zip + ' ' + escapedValues.Country + '</div></div>';
          break;

        case 'date':
          valueString = escapedValues.Month + '/' + escapedValues.Day + '/' + escapedValues.Year;
          break;

        case 'time':
          valueString = (escapedValues.Hour || '00') + ':' + (
          escapedValues.Minute || '00') + ':' + (escapedValues.Second || '00') + ' ' + escapedValues.Ampm;
          break;

        case 'checkbox':
          /**
             * Because form submissions are not modeled, we have no
             * easy way to migrate bad checkbox submissions (value -> values).
             * We need this client-side fix.
             */
          var oldValue = field.value;

          if (!Y.Lang.isValue(escapedValues) && Y.Lang.isValue(oldValue)) {
            escapedValues = [oldValue];
          } else if (Y.Lang.isValue(escapedValues)) {
            escapedValues = Y.Object.values(escapedValues);
          }

          valueString = escapedValues.join(', ');
          break;

        default:
          valueString = Y.Squarespace.Escaping.escapeForHtml(field.value);
          break;}


      if (valueString === '') {
        valueString = t("N/A");


      }

      return '<div style="margin-top:3px;">' +
      '<span style="font-weight:bold;">' + field.rawTitle + ':</span> ' + valueString +
      '</div>';
    },

    /**
     * @method hasVariants
     * @param item
     * @return {Boolean}
     */
    hasVariants: function (item) {
      var structuredContent = item.structuredContent;

      return structuredContent.productType !== ProductTypes.DIGITAL && structuredContent.variants.length >= 1;
    },

    /**
     * @method variedPrices
     * @param  item
     * @return {Boolean}
     */
    variedPrices: function (item) {
      var structuredContent = item.structuredContent;

      switch (structuredContent.productType) {
        case ProductTypes.PHYSICAL:
        case ProductTypes.SERVICE:
        case ProductTypes.GIFT_CARD:
          var variants = structuredContent.variants;

          if (variants.length <= 1) {
            return false;
          }

          var firstVariant = variants[0];

          for (var i = 1; i < variants.length; i++) {
            var variant = variants[i];

            if (variant.onSale !== firstVariant.onSale ||
            firstVariant.onSale && variant.salePrice !== firstVariant.salePrice ||
            variant.price !== firstVariant.price) {

              return true;
            }
          }

          return false;

        case ProductTypes.DIGITAL:
          return false;}

    },

    /**
     * @method onSale
     * @param  item
     * @return {Boolean}
     */
    onSale: function (item) {
      var structuredContent = item.structuredContent;

      switch (structuredContent.productType) {
        case ProductTypes.PHYSICAL:
        case ProductTypes.SERVICE:
          var variants = structuredContent.variants;
          var onSale = false;

          for (var i = 0; i < variants.length; i++) {
            var variant = variants[i];

            if (variant.onSale) {
              onSale = true;
              break;
            }
          }

          return onSale;

        case ProductTypes.DIGITAL:
          return structuredContent.onSale;}

    },

    /**
     * @method soldOut
     * @param  item
     * @return {Boolean}
     */
    soldOut: function (item) {
      var structuredContent = item.structuredContent;

      switch (structuredContent.productType) {
        case ProductTypes.PHYSICAL:
        case ProductTypes.SERVICE:
          var variants = structuredContent.variants;
          var soldOut = true;

          for (var i = 0; i < variants.length; i++) {
            var variant = variants[i];

            if (variant.unlimited || variant.qtyInStock > 0) {
              soldOut = false;
              break;
            }
          }

          return soldOut;

        case ProductTypes.DIGITAL:
          return false;}

    },

    /**
     * @method normalPrice
     * @param  item
     * @return {Number}
     */
    normalPrice: function (item) {
      var structuredContent = item.structuredContent;
      var variants = structuredContent.variants;

      switch (structuredContent.productType) {
        case ProductTypes.PHYSICAL:
        case ProductTypes.SERVICE:
          if (variants.length === 0) {
            return 0;
          }

          var normalPrice = variants[0].price;

          for (var i = 1; i < variants.length; i++) {
            var currPrice = variants[i].price;

            if (currPrice > normalPrice) {
              normalPrice = currPrice;
            }
          }

          return normalPrice;

        case ProductTypes.DIGITAL:
          return Y.Lang.isUndefined(structuredContent.priceCents) ? 0 : structuredContent.priceCents;
        case ProductTypes.GIFT_CARD:
          return variants[0].price;
        default:
          // this should never happen
          break;}

    },

    /**
     * @method salePrice
     * @param  item
     * @return {Number}
     */
    salePrice: function (item) {
      var structuredContent = item.structuredContent;

      switch (structuredContent.productType) {
        case ProductTypes.PHYSICAL:
        case ProductTypes.SERVICE:
          var variants = structuredContent.variants;

          if (variants.length === 0) {
            return 0;
          }

          var salePrice = null;

          for (var i = 0; i < variants.length; i++) {
            var variant = variants[i];
            var variantSalePrice = variant.salePrice;

            if (variant.onSale && (salePrice === null || variant.salePrice < salePrice)) {
              salePrice = variantSalePrice;
            }
          }

          return salePrice;

        case ProductTypes.DIGITAL:
          return Y.Lang.isUndefined(structuredContent.salePriceCents) ? 0 : structuredContent.salePriceCents;}

    },

    /**
     * @method fromPrice
     * @param item
     * @return
     */
    fromPrice: function (item) {
      var structuredContent = item.structuredContent;

      switch (structuredContent.productType) {
        case ProductTypes.PHYSICAL:
        case ProductTypes.SERVICE:
          var variants = structuredContent.variants;

          if (variants.length === 0) {
            return 0;
          }

          var firstVariant = variants[0];
          var fromPrice = firstVariant.onSale ? firstVariant.salePrice : firstVariant.price;

          for (var i = 1; i < variants.length; i++) {
            var variant = variants[i];
            var currPrice = variant.onSale ? variant.salePrice : variant.price;

            if (currPrice < fromPrice) {
              fromPrice = currPrice;
            }
          }

          return fromPrice;

        case ProductTypes.DIGITAL:
          return Y.Lang.isUndefined(structuredContent.priceCents) ? 0 : structuredContent.priceCents;}

    },

    /**
     * capitalizes first letter of every word
     * @method capitalizeFirst
     * @param  {String} string
     * @return {String}
     */
    capitalizeFirst: function (string) {
      string = string.toLowerCase();
      var arr = string.split(new RegExp('\\s+'));
      var caps = Y.Array.map(arr, function (word) {
        return word.charAt(0).toUpperCase() + word.substr(1);
      });
      return caps.join(' ');
    },

    /**
     * Returns a unique product variant id
     *
     * @method generateVariantId
     * @return {String}
     */
    generateVariantId: function () {
      return uuidv4();
    },

    /**
     * @method generateSKU
     * @return {String}
     */
    generateSKU: function () {
      var code = '',
      chars = '0123456789';

      var generate = function () {
        var newCode = '';
        for (var i = 0; i < 7; ++i) {
          var idx = Math.floor(Math.random() * chars.length);
          newCode += chars.charAt(idx);
        }
        return 'SQ' + newCode;
      };

      code = generate();
      return code;
    },

    /**
     * Convert a workflow state into a product-contextualized label
     *
     * @method getLabelForWorkflowState
     */
    getLabelForWorkflowState: function (workflowState) {

      switch (workflowState) {
        case WorkflowStates.PUBLISHED:
          return t("Available");


        case WorkflowStates.SCHEDULED:
          return t("Scheduled");


        case WorkflowStates.DRAFT:
          return t("Hidden");


        default:
          return t("Unsupported");}



    } };

}, '1.0', {
  requires: [
  'escape',
  'node',
  'squarespace-escaping-utils'] });