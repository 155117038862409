export default class WaveGroup {
  /** @type {object} */
  static defaultProps = {
    container: null,
    domContainer: null,
    originGroupItemNodes: [],
    path: null,
    svgGroupItemNodes: [],
  };

  /** @type {object} */
  info = {
    numItems: 0,
  };

  /**
   * @param {object} props - the config props
   * @class
   */
  constructor (props) {
    this.props = props;

    /** @type {Array} */
    this.items = this.getItems();

    this.info.numItems = this.items.length;
  }

  /**
   * Get the configured items for the group
   *
   * @returns {object} the items array and tracked item indices array
   * @private
   */
  getItems () {
    const { container, svgGroupItemNodes } =
      this.props;

    return svgGroupItemNodes.reduce(
      (res, node) => {
        const clonedNode = node.cloneNode(true);
        const item = {
          x: 0,
          node: clonedNode,
          domNode: null,
        };

        res.push(item);

        container.appendChild(clonedNode);

        return res;
      },
      []
    );
  }

  /**
   * Position the items (on resize)
   *
   * @param {object} props - the config props
   * @param {number} props.x - the start x position
   * @param {array} props.measuredItems - the measured rect's of each child node of the origin group
   * @returns {number} the adjusted x value
   * @public
   */
  positionItems ({ x, measuredItems } = {}) {
    let resX = x;

    for (let i = 0; i < this.info.numItems; i += 1) {
      const item = this.items[i];
      const measuredItem = measuredItems[i];

      item.x = resX;
      item.node.setAttribute('x', item.x);

      resX += measuredItem.width + measuredItem.spacing;
    }

    return resX;
  }

  /**
   * Destroy
   *
   * @public
   */
  destroy () {
    this.items = [];
    this.info.numItems = 0;
  }
}
