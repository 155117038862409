const TOCK_SRC = 'https://www.exploretock.com/tock.js';
const CONTAINER_BREAKPOINT = 750;
const FORCE_COLUMN_CLASS = 'Tock_widget_container-columnLayout';

const updateForceColumnClassOnTockNode = (tockNode) => {
  const blockContainerNode = tockNode.closest('.tock-block');
  if (blockContainerNode) {
    tockNode.classList.toggle(FORCE_COLUMN_CLASS, blockContainerNode.clientWidth <= CONTAINER_BREAKPOINT);
  }
};

const createResizeObserver = (tockNode) => new ResizeObserver(() => {
  updateForceColumnClassOnTockNode(tockNode);
});

const initializeTockBlock = (node) => {
  // Access the tock block's document and window regardless of being in config or edit mode
  const tockDoc = node.ownerDocument;
  const tockWindow = tockDoc.defaultView;
  const tockNode = node.querySelector('#Tock_widget_container');

  /**
   * Less than ideal way to add a custom class to the tock button after initialization.
   * This should be replaced if the tock script is updated to support an attr that would add the class during init.
   */
  const observer = new MutationObserver(() => {
    const tockButtonNode = tockNode.querySelector('.TockButton-blue');
    tockButtonNode.classList.add('sqs-button-element--primary');
    observer.disconnect();
  });
  observer.observe(tockNode, { childList: true });

  let tockBusinessSlug;
  if (tockNode) {
    tockBusinessSlug = tockNode.getAttribute('data-tock-business-slug');
    updateForceColumnClassOnTockNode(tockNode);

    if (tockWindow._tockResizeObserver) {
      tockWindow._tockResizeObserver.disconnect();
      tockWindow._tockResizeObserver = undefined;
    }
    if (tockWindow.ResizeObserver) {
      tockWindow._tockResizeObserver = createResizeObserver(tockNode);
      tockWindow._tockResizeObserver.observe(node);
    }
  }

  if (!tockWindow.tock) {
    tockWindow.tock = { version: '1.0' };
    tockWindow._tockLoadPromise = new Promise((resolve, reject) => {
      const scriptNode = tockDoc.createElement('script');
      tockDoc.head.appendChild(scriptNode);

      scriptNode.onload = () => resolve();
      scriptNode.onerror = () => reject();

      scriptNode.async = true;
      scriptNode.src = TOCK_SRC;
    });
  }

  tockWindow._tockLoadPromise.then(() => {
    tockWindow.tock.load(tockBusinessSlug);
  });
};

export default initializeTockBlock;
