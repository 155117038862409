"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ImageMask */

/* Java class defined in repository cms-legacy-data */
var ImageMasks;

(function (ImageMasks) {
  ImageMasks["ARCHED_WINDOW"] = "arched-window";
  ImageMasks["AWARD"] = "award";
  ImageMasks["BOTTOM_LEFT_CURVE"] = "bottom-left-curve";
  ImageMasks["CIRCLE"] = "circle";
  ImageMasks["DESCENDING_RAMP"] = "descending-ramp";
  ImageMasks["DESCENDING_STAIRS"] = "descending-stairs";
  ImageMasks["DIAMOND"] = "diamond";
  ImageMasks["EGG"] = "egg";
  ImageMasks["EIGHT_SIDED_SHIELD"] = "eight-sided-shield";
  ImageMasks["FLOWER"] = "flower";
  ImageMasks["FOUR_PETAL_FLOWER"] = "four-petal-flower";
  ImageMasks["FOUR_POINT_STAR"] = "four-point-star";
  ImageMasks["GEAR"] = "gear";
  ImageMasks["HORIZONTAL_OVAL"] = "horizontal-oval";
  ImageMasks["HORIZONTAL_RIGHT_LEANING_OVAL"] = "horizontal-right-leaning-oval";
  ImageMasks["HORIZONTAL_STACKED_CIRCLES"] = "horizontal-stacked-circles";
  ImageMasks["HOURGLASS"] = "hourglass";
  ImageMasks["KEYSTONE"] = "keystone";
  ImageMasks["NARROW_HORIZONTAL_STACKED_PILLS"] = "narrow-horizontal-stacked-pills";
  ImageMasks["NARROW_POW"] = "narrow-pow";
  ImageMasks["OCTAGON"] = "octagon";
  ImageMasks["OPEN_BOOK"] = "open-book";
  ImageMasks["POINTED_WINDOW"] = "pointed-window";
  ImageMasks["RABBET"] = "rabbet";
  ImageMasks["ROUNDED_PENTAGON"] = "rounded-pentagon";
  ImageMasks["STEPPED_CROSS"] = "stepped-cross";
  ImageMasks["TV"] = "tv";
  ImageMasks["VERTICAL_OVAL"] = "vertical-oval";
  ImageMasks["VERTICAL_PARALLELOGRAM"] = "vertical-parallelogram";
  ImageMasks["VERTICAL_RIGHT_LEANING_OVAL"] = "vertical-right-leaning-oval";
  ImageMasks["VERTICAL_STACKED_CIRCLES"] = "vertical-stacked-circles";
  ImageMasks["WIDE_EIGHT_SIDED_SHIELD"] = "wide-eight-sided-shield";
  ImageMasks["WIDE_HORIZONTAL_STACKED_PILLS"] = "wide-horizontal-stacked-pills";
  ImageMasks["WIDE_OPEN_BOOK"] = "wide-open-book";
  ImageMasks["WIDE_VERTICAL_STACKED_PILLS"] = "wide-vertical-stacked-pills";
})(ImageMasks || (ImageMasks = {}));

var _default = ImageMasks;
exports["default"] = _default;
module.exports = exports.default;