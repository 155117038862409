import styles from '@sqs/site-rendering/Maps/constants/styles';
import { createErrorLogger } from '@sqs/universal-utils';

const logError = createErrorLogger('GoogleMapsUtils');

const StylesById = [
  null,
  styles.Original,
  styles.Grayscale,
  styles.Satellite,
  styles.MinimalLight,
  styles.MinimalDark,
  styles.MinimalBlue,
];

/**
 * Styles are stored by id.
 * Use to get back the style configuration object.
 *
 * @param  {Number} id
 * @return {Object}    style descriptor
 */
export const getStyleById = (id) => StylesById[id];

export const Styles = {
  ORIGINAL: 1,
  GRAYSCALE: 2,
  SATELLITE: 3,
  MINIMAL_LIGHT: 4,
  MINIMAL_DARK: 5,
  MINIMAL_BLUE: 6,
};

/**
 * Get the config for a MapView from the block data model.
 *
 * @param  {Object} model model as object, not Model
 * @return {Object} MapView config
 */
export const mapModelToViewConfig = ({
  location,
  controls,
  terrain,
  labels,
  style,
}) => ({
  location,
  ui: {
    showControls: controls,
    showTerrain: terrain,
    showLabels: labels,
    style: StylesById[style],
  },
});

const MAP_UPDATE_DEBOUNCE_MS = 60;

// Map of { mapView: { cb, timeout, options } }
const updaters = new Map();

/**
 * Merge nextOptions with previous options if they haven't been set yet
 * Eventually will update the mapView with the combined options.
 *
 * @param {object} mapView
 * @param {object} nextOptions to merge into any queued options updates
 */
export const updateUiOptions = (mapView, nextOptions) => {
  if (!mapView || !nextOptions) {
    return;
  }

  const { timeout, options = {} } = updaters.get(mapView) || {};
  if (timeout) {
    // @TODO Ensure that the timeout is created and cleared in the same window
    // instance as the mapView is rendered! Do this if this function is ever
    // called from BOTH inside and outside frame.
    window.clearTimeout(timeout);
  }

  const finalOptions = { ...options, ...nextOptions };

  const cb = () => {
    try {
      mapView.updateUIOptions(finalOptions);
    } catch (error) {
      logError(error);
    }
  };

  updaters.set(mapView, {
    cb,
    // @TODO Ensure that the timeout is created and cleared in the same window
    // instance as the mapView is rendered! Do this if this function is ever
    // called from BOTH inside and outside frame.
    timeout: window.setTimeout(cb, MAP_UPDATE_DEBOUNCE_MS),
    options: finalOptions,
  });
};

/**
 * @param {object} mapView
 * @param {boolean} [isCancel=false] flush or cancel the current timeout?
 */
export const destroyUpdater = (mapView, isCancel = false) => {
  if (!mapView) {
    return;
  }

  const { cb, timeout } = updaters.get(mapView) || {};
  if (timeout) {
    window.clearTimeout(timeout);
  }
  if (!isCancel && cb) {
    cb();
  }
  updaters.delete(mapView);
};
