import { t } from 'shared/i18n';
import substituteString from 'shared/utils/formatting/substituteString';
import * as Dialogs from 'shared/constants/Dialogs';

YUI.add('squarespace-system-error', function (Y) {

  Y.namespace('Squarespace');

  /**
   * Usage:
   *
   * new Y.Squarespace.SystemError('/api/some/endpoint', {
   *   error: 'Too much redirection',
   *   errorKey: 'SQS_ERROR_09'
   * }, 'json').show();
   *
   */

  /**
    Puts that big red error on the screen
    @class SystemError
    @namespace Squarespace
    @constructor
    @param url {String} The url requested that caused the error
    @param data {Object} The server response
  */
  Y.Squarespace.SystemError = Class.create({

    initialize: function (url, data, fmt) {

      this.url = Y.Squarespace.Escaping.escapeForHtml(url);
      this.data = data;
      this.format = fmt;
      Y.config.win.__clearError = this.clear;

    },

    clear: function () {
      Y.one('.sqs-system-error').remove();
      Y.one(Dialogs.yuiDialogOverlaySelector + '.sqs-system-error-overlay').remove();
    },

    /**
     * @method show
     */
    show: function () {

      var error;
      var errorMessage = '';
      if (this.data.cleaned) {

        error = '<div style="font-weight: normal; font-size: 22px; margin-top: -2px;">' + t("Something Went Wrong!") +



        '</div><div style="font-weight: normal; font-size: 13px; padding-top: 5px;">' + t("Our system has encountered an error.  This exception has been automatically logged and reported.  We will return shortly.") +








        '</div>';

      } else if (this.data.network) {

        errorMessage = t("Unable to connect to Squarespace.  Please try again.");



        if (Y.Lang.isString(this.data.error) && this.data.error.length > 0) {
          errorMessage = this.data.error;
        }

        error = '<div style="font-weight: normal; font-size: 17px;">' + errorMessage + '</div>';

        if (this.url) {
          // SIX-7937 - lets try to record the error, if network is down we won't see anything though ...
          try {
            Y.Squarespace.Analytics.trackInternal('system_network_error', {
              urlRequested: this.url });

          } catch (e) {
            if (__DEV__) {
              console.error('network error', e);
            }
          }
        }

      } else {

        errorMessage = Y.Squarespace.Escaping.escapeForHtml(this.data.error || this.data.message || '');

        error = substituteString(
        '<div style="font-weight: normal; font-size: 17px;">{sub1} &nbsp;' +
        '<span style="color: #888;">({sub2})</span></div><br/><pre>{sub3}</pre>',
        {
          sub1: this.url,
          sub2: this.format.toUpperCase(),
          sub3: errorMessage.replace(new RegExp('\n', 'g'), '<br/>') // eslint-disable-line no-control-regex
        });

        if (__DEV__) {
          console.error('SERVER: ' + errorMessage);
        }

      }

      // don't visually stack errors forever -- of any type

      if (Y.one('.sqs-system-error')) {
        return; // don't keep piling these up
      }

      // error
      var clearButton = '<input type="button" value="Clear" onclick="Y.config.win.__clearError();"/>';
      var style = 'position: absolute; -webkit-user-select: text; left: 25px; top: 25px; right: 25px; ' +
      'font-size: 12px; color: #fff; padding: 26px 20px 20px 80px; border-radius: 5px; overflow: auto; height: 90%; ' +
      'z-index: 10000001;';

      var errorMsg = error + '<br/><br/>' +
      '<input type="button" value="' + t("Reload Page") +

      '" onclick="Y.config.doc.location.reload();"/> &nbsp; ';

      errorMsg += clearButton + '<br/><br/><br/>';

      if (this.data.errorKey) {
        errorMsg += '<span style="color: #777; font-size: 10px;">' + this.data.errorKey + '</span>';
      }

      this.errorEl = Y.Node.create('<div class="sqs-system-error" style="' + style + '">' +
      errorMsg +
      '</div>');

      Y.one(Y.config.doc.body).append(this.errorEl);

      // and a lovely little overlay
      this.overlayEl = Y.Node.create(
      '<div class="' + Dialogs.yuiDialogOverlayClassName + ' sqs-system-error-overlay"></div>');

      this.overlayEl.setStyles({
        zIndex: 10000000 });


      Y.one(Y.config.doc.body).append(this.overlayEl);

      var anim = new Y.Anim({
        node: this.overlayEl,
        to: { opacity: 1 },
        duration: 0.35,
        easing: Y.Easing.easeOutStrong });

      anim.run();
    } });


  // This lack of requires makes me very nervous...
}, '1.0', {
  requires: [
  'squarespace-escaping-utils',
  'squarespace-util'] });