// Not dependent on YUI!
import makeTextDynamic from 'shared/utils/makeTextDynamic';

const DEFAULT_SELECTORS = [
  '.image-block-v2 .sqs-dynamic-text-container'
].join(',');

/**
 * Initialize DynamicText sizing on the given elements
 *
 * @param {Window} win
 * @param {String} [selectors=DEFAULT_SELECTORS] comma delimited
 */
export default function initDynamicText(
  win = window,
  selectors = DEFAULT_SELECTORS
) {
  [...win.document.querySelectorAll(selectors)].forEach(
    // makeTextDynamic runs differently based on number of args,
    // so be sure to only pass the node.
    (el) => makeTextDynamic(el)
  );
}
