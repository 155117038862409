YUI.add('@sqs/layout-engine/aspect-ratio-manager', function (Y) {

  /**
   * @namespace SQS.LayoutEngine
   * @class     AspectRatioManager
   * @extends   Base
   */
  var AspectRatioManager = Y.Base.create('AspectRatioManager', Y.Base, [], {

    initializeBlock: function (el, jsonPayload) {
      var aspectRatio = jsonPayload;

      if (Y.Lang.isObject(aspectRatio)) {
        aspectRatio = aspectRatio.aspectRatio;
      }

      if (Y.Lang.isValue(aspectRatio)) {
        el.addClass('sqs-intrinsic');
        el.setStyle('paddingBottom', aspectRatio + '%');

        if (el.one('> div')) {
          el.one('> div').addClass('sqs-intrinsic-content');
        }
      }
    },

    /**
     *
     * @method destroyBlock
     * @param  {Node} el
     */
    destroyBlock: function (el) {
      var intrinsic = el.one('.sqs-intrinsic');
      if (intrinsic) {
        intrinsic.removeClass('sqs-intrinsic');
        intrinsic.setStyle('padding-bottom', null);
        if (intrinsic.one('.sqs-intrinsic-content')) {
          intrinsic.one('.sqs-intrinsic-content').removeClass('sqs-intrinsic-content');
        }
      }
    },

    /**
     * @method initAllBlocks
     */
    initAllBlocks: function() {
      var AspectOnlyBlocks = Y.all('.sqs-block[data-aspect-ratio]:not(.sqs-block-image)');

      AspectOnlyBlocks.each(function (n) {
        Y.SQS.LayoutEngine.AspectRatioManager.initializeBlock(
          n.one('.sqs-block-content'), Y.JSON.parse(n.getAttribute('data-aspect-ratio'))
        );
      }, this);

    }

  });

  Y.namespace('SQS.LayoutEngine');

  Y.SQS.LayoutEngine.AspectRatioManager = new AspectRatioManager();
  if (Y.config.win.Squarespace && Y.config.win.Squarespace.onInitialize) {
    Y.config.win.Squarespace.onInitialize(Y, Y.SQS.LayoutEngine.AspectRatioManager.initAllBlocks);
  }

}, '1.0', {
  requires: [
    'base'
  ]
});
