import { t } from 'shared/i18n';
import humanizeDate from 'shared/utils/humanizeDate';
import * as DialogConstants from 'shared/constants/Dialogs';

/**
 * Basic User Interface Elements
 * @module squarespace-ui-base
 */
YUI.add('squarespace-ui-base', function (Y) {

  Y.namespace('Squarespace');

  // Enable HTML5 postMessage support events in YUI
  Y.mix(Y.Node.DOM_EVENTS, {
    message: true });


  Y.Squarespace.DIALOG_ZINDEX_BASE = DialogConstants.zIndexes.BASE;

  Y.Anim._intervalTime = 10;

  // ------------------------------------------------------------------
  // Y.ANIM OVERRIDE TO PROTECT AGAINST
  // DOM NODES BEING REMOVED BY OTHER COMPONENTS WHILE
  // IT IS ANIMATING.
  //
  // FROM: http://yuilibrary.com/yui/docs/api/files/anim_js_anim.js.html#l112
  // ------------------------------------------------------------------

  var NUM = Number;
  Y.Anim.DEFAULT_SETTER = function (anim, att, from, to, elapsed, duration, fn, unit) {
    var node = anim._node,
    domNode = node._node,
    val = fn(elapsed, NUM(from), NUM(to) - NUM(from), duration);

    if (node._node === null) {
      if (__DEV__) {
        console.warn('Could not find the animating DOMNode. Might have been destroyed. Bailing out for safety.');
      }
      return;
    }

    if (domNode) {
      if ('style' in domNode && (att in domNode.style || att in Y.DOM.CUSTOM_STYLES)) {
        unit = unit || '';
        node.setStyle(att, val + unit);
      } else if ('attributes' in domNode && att in domNode.attributes) {
        node.setAttribute(att, val);
      } else if (att in domNode) {
        domNode[att] = val;
      }
    } else if (node.set) {
      node.set(att, val);
    } else if (att in node) {
      node[att] = val;
    }
  };


  /**
   * Text Shrink
   * @class       TextShrink
   * @extends     Plugin.Base
   * @namespace   Squarespace
   * @constructor
   */

  Y.Squarespace.TextShrink = Y.Base.create('TextShrink', Y.Plugin.Base, [], {

    initializer: function () {

      var el = this.get('host');

      // default parent
      if (!Y.Lang.isValue(this.get('parentEl'))) {
        this.set('parentEl', el.ancestor());
      }

      // setup el
      this._setupEl();

      Y.on('domready', this.refresh, this);

      this._resizeEvent = Y.on('resize', function () {
        if (this._timeout) {
          this._timeout.cancel();
        }
        this._timeout = Y.later(100, this, this.refresh);
      }, Y.config.win, this);

      this._tweakChange = Y.Global.on('tweak:change', this.refresh, this);

      this._tweakSaveResetClose = Y.Global.on(['tweak:save', 'tweak:reset', 'tweak:close'], this.refresh, this);

    },

    refresh: function () {
      this._reset();

      // re-setup el
      this._setupEl();

      // process
      this._adjust();
    },

    isOverflowing: function () {

      var width = this._getWidths();

      if (Math.abs(width.el - width.parentEl) <= 3) {
        width.el = width.parentEl = Math.min(width.el, width.parentEl);
      }

      return width.el > width.parentEl;

    },

    _adjust: function () {

      var el = this.get('host');
      var width = this._getWidths();

      if (this.isOverflowing()) {

        var fontSize = this._getStyleValue(el, 'fontSize');
        fontSize = width.parentEl / width.el * fontSize;
        this._setFontSize(Math.floor(fontSize));

      }

      return true;

    },

    _setFontSize: function (size) {

      var el = this.get('host');
      var minFontSize = this.get('minFontSize');
      if (minFontSize && size < minFontSize) {
        size = minFontSize;
      }

      if (!isNaN(size)) {
        el.setStyle('fontSize', size + 'px');
      }

    },

    _getStyleValue: function (elem, propName) {

      return parseInt(elem.getStyle(propName), 10);

    },

    _getWidths: function () {

      var el = this.get('host');
      var parentEl = this.get('parentEl');
      var elWidth = el.get('scrollWidth');
      var parentPadding = this._getStyleValue(parentEl, 'paddingLeft') + this._getStyleValue(parentEl, 'paddingRight');
      var parentWidth = parentEl.get('offsetWidth') - parentPadding;

      // el may not have a scrollwidth depending on how the dom is being initialized when this is called
      if (elWidth === 0 || !Y.Lang.isValue(elWidth)) {
        elWidth = el.get('offsetWidth');
      }

      // if (parentWidth == 0 || !Y.Lang.isValue(parentWidth)) {
      //   console.warn('[TextShrink] Parent has no width.', parentEl);
      // }

      return { parentEl: parentWidth, el: elWidth };

    },

    _setupEl: function () {

      var el = this.get('host');
      el.setAttribute('data-shrink-original-size', this._getStyleValue(el, 'fontSize'));
      this.letterSpacing =
      this._getStyleValue(el, 'letterSpacing') / el.getAttribute('data-shrink-original-size') + 'em';
      el.setStyle('letterSpacing', this.letterSpacing);

    },

    _reset: function () {
      var el = this.get('host');
      el.setStyles({
        letterSpacing: null,
        fontSize: null });

    },

    destructor: function () {
      if (this._resizeEvent) {
        this._resizeEvent.detach();
      }
      if (this._tweakChange) {
        this._tweakChange.detach();
      }
      if (this._tweakSaveResetClose) {
        this._tweakSaveResetClose.detach();
      }
    } },

  {
    ATTRS: {
      minFontSize: {
        value: 11 },

      parentEl: {
        value: null // default will be
      } },

    NS: 'TextShrink' });




  /**
   * Simple Flexible Grid
   * @class       SimpleFlexibleGrid
   * @extends     Plugin.Base
   * @namespace   Squarespace
   * @constructor
   */
  Y.Squarespace.SimpleFlexibleGrid = Y.Base.create('SimpleFlexibleGrid', Y.Plugin.Base, [], {

    initializer: function () {
      this._stylesheetEl = Y.Node.create('<style></style>');
      this.get('host').append(this._stylesheetEl);

      this._resizeEvent = Y.on('resize', this.syncUI, Y.config.win, this);

      this.syncUI();
    },

    syncUI: function () {
      var numOfBoxes;
      if (this.get('minWidth')) {
        numOfBoxes = Math.floor(this.get('container').get('offsetWidth') / this.get('minWidth'));
      } else {// max width is expected
        numOfBoxes = Math.ceil(this.get('container').get('offsetWidth') / this.get('maxWidth'));
      }

      this._stylesheetEl.set('innerHTML', this.get('selector') + '{width:' + 100 / numOfBoxes + '% !important;}');
      this.get('host').fire('simpleFlexibleGridSyncUI');
    },

    destructor: function () {
      this._stylesheetEl.remove();
      this._resizeEvent.detach();
    } },

  {
    ATTRS: {
      /**
       * @attribute container
       */
      container: {},

      /**
       * @attribute selector
       */
      selector: {},

      /**
       * @attribute maxWidth
       */
      maxWidth: {},

      minWidth: {} },

    NS: 'simple-flexible-grid' });



  // -----------------------------------------------------------------------------------------------------------------
  // Mouse & Touch Tracking
  // -----------------------------------------------------------------------------------------------------------------
  Y.Squarespace.Mouse = {
    lastX: 100,
    lastY: 100,
    client: {
      lastX: 100,
      lastY: 100 },

    debug: function () {
      this.isDebugging = true;

      if (!this._debugNode) {
        this._debugNode = Y.one(Y.config.doc.body).appendChild('<div></div>');
        this._debugNode.setStyles({
          position: 'fixed',
          width: 50,
          height: 50,
          borderRadius: '50%',
          background: 'rgba(20, 170, 255, 0.5)',
          marginLeft: -25,
          marginTop: -25,
          zIndex: Math.pow(10, 5),
          pointerEvents: 'none' });

      }

      this._debugNode.setStyles({
        top: Y.Squarespace.Mouse.lastY,
        left: Y.Squarespace.Mouse.lastX });

    } };


  if (Y.Object.getValue(Y.config.win, 'Static.SQUARESPACE_CONTEXT.authenticatedAccount'.split('.'))) {
    Y.on(Y.UA.mobile ? 'touchmove' : 'mousemove', function (e) {
      Y.Squarespace.Mouse.lastX = e.pageX;
      Y.Squarespace.Mouse.lastY = e.pageY;

      Y.Squarespace.Mouse.client.lastX = e.clientX;
      Y.Squarespace.Mouse.client.lastY = e.clientY;

      if (Y.Squarespace.Mouse.isDebugging) {
        Y.Squarespace.Mouse.debug();
      }
    }, Y.one('html'));
  }

  /**
   * Image Helper
   * @class Image
   * @namespace Squarespace
   * @static
   */
  Y.Squarespace.Image = {

    /**
     * @method loadAndFit
     * @param  {Node} node
     * @param  {String} imageSrc
     */
    loadAndFit: function (node, imageSrc) {

      var img = new Image();
      img.src = imageSrc;

      Y.one(img).on('load', function (e, imgNode, loadImg) {
        imgNode.set('src', loadImg.src);
        imgNode.resizeToParent({ scale: 'cover' });
      }, this, node, img);

    },

    /**
     * @method straighten
     * @param  {Node} node
     * @param  {Number} originalWidth
     * @param  {Number} originalHeight
     * @param  {Number} deg
     */
    straighten: function (node, originalWidth, originalHeight, deg) {

      var degForRad = (deg < 0 ? 360 - deg : deg) % 360,
      calculated = degForRad;
      if (degForRad >= 90) {
        calculated = degForRad % 90 - 90;
      }
      if (degForRad >= 180) {
        calculated = -1 * degForRad % 180 - 180;
      }
      if (degForRad >= 270) {
        calculated = degForRad % 270 - 90;
      }

      var radian = Math.abs(calculated) * (Math.PI / 180);
      var scale;

      if (originalHeight > originalWidth) {
        scale = (originalWidth * Math.cos(radian) + originalHeight * Math.sin(radian)) / originalWidth;
      } else {
        scale = (originalHeight * Math.cos(radian) + originalWidth * Math.sin(radian)) / originalHeight;
      }

      node.setStyle('transform', 'rotate(' + deg + 'deg) scale(' + Math.abs(scale) + ')');

    } };



  /**
   * Positioning Helper
   * @class Position
   * @namespace Squarespace
   * @constructor
   */
  Y.Squarespace.Position = Class.create({

    initialize: function (params) {

      this.EDGE_MARGIN = 20;

      this.avoidElX = params.avoidElX;
      this.avoidElY = params.avoidElY;

      this.xdir = params.xdir;
      this.ydir = params.ydir;
      this.x = params.x;
      this.y = params.y;
      this.xo = params.xo ? params.xo : 0; // x offset
      this.yo = params.yo ? params.yo : 0; // y offset
      this.w = params.w;
      this.h = params.h;

      this.initialX = this.getX();
      this.initialY = this.getY();

    },

    /**
     * @method setX
     * @param  {Number} x
     */
    setX: function (x) {this.x = x;},
    /**
     * @method setY
     * @param  {Number} y
     */
    setY: function (y) {this.y = y;},

    /**
     * @method getX
     * @return {Number}
     */
    getX: function () {return this.x + this.getXO();},
    /**
     * @method getY
     * @return {Number}
     */
    getY: function () {return this.y + this.getYO();},

    /**
     * [X, Y]
     * @method getXY
     * @return {Array}
     */
    getXY: function () {return [this.getX(), this.getY()];},
    /**
     * initialX - X
     * @method getXAdj
     * @return {Number}
     */
    getXAdj: function () {return this.initialX - this.getX();},
    /**
     * initialY - Y
     * @method getYAdj
     * @return {Number}
     */
    getYAdj: function () {return this.initialY - this.getY();},

    /**
     * @method getXO
     * @return {Number}
     */
    getXO: function () {
      return (this.avoidElX && this.xdir === 'right' ?
      this.avoidElX.get('offsetWidth') : 0) + this.xo;
    },
    /**
     * @method getYO
     * @return {Number}
     */
    getYO: function () {
      return (this.avoidElY && this.ydir === 'top' ?
      -this.avoidElY.get('offsetHeight') : 0) + this.yo;
    },

    /**
     * Out of window?
     * @method isRightViolation
     * @return {Boolean}
     */
    isRightViolation: function () {
      return this.x + this.w - Math.abs(this.getXO()) + this.EDGE_MARGIN > Y.one(document.body).get('winWidth');
    },
    /**
     * Out of window?
     * @method isLeftViolation
     * @return {Boolean}
     */
    isLeftViolation: function () {
      return this.x - Math.abs(this.getXO()) - this.EDGE_MARGIN < 0;
    },
    /**
     * Out of window?
     * @method isTopViolation
     * @return {Boolean}
     */
    isTopViolation: function () {
      return this.y - Math.abs(this.getYO()) - this.EDGE_MARGIN < Y.one(document.body).get('docScrollY');
    },
    /**
     * Out of window?
     * @method isBottomViolation
     * @return {Boolean}
     */
    isBottomViolation: function () {
      return this.y + this.h - Math.abs(this.getYO()) + this.EDGE_MARGIN >
      Y.one(document.body).get('winHeight') + Y.one(document.body).get('docScrollY');
    },

    /**
     * corrects a position to ensure that we aren't overlapping with window edges, given our dimensions
     * on violation, reflect the object over the x/y axis to correct position
     * @method reflectFix
     */
    reflectFix: function () {



      if (this.xdir === 'right' && this.isRightViolation()) {// right
        this.xdir = 'left';
        this.getX = function () {return this.x - this.w - this.getXO() + 11;}; // ############## +11 HACK?
      } else if (this.xdir === 'left' && this.isLeftViolation()) {// left
        this.xdir = 'right';
        this.getX = function () {return this.x + this.w + this.getXO();};
      }

      if (this.ydir === 'bottom' && this.isBottomViolation()) {// bottom
        this.ydir = 'top';
        this.getY = function () {return this.y - this.h - this.getYO();};
        console.log('bottom violation .. now ' + this.getY());
      } else if (this.ydir === 'top' && this.isTopViolation()) {// top
        this.ydir = 'bottom';
        this.getY = function () {return this.y + this.h + this.getYO();};
        console.log('top violation');
      }

      if (this.getY() - this.EDGE_MARGIN < 0) {// top
        this.getY = function () {return Y.one(document.body).get('winHeight') - this.h - this.EDGE_MARGIN;};
        this.ydir = 'bottom';
      } else if (this.getY() + this.h + this.EDGE_MARGIN >
      Y.one(document.body).get('winHeight') + Y.one(document.body).get('docScrollY')) {// bottom
        this.getY = function () {
          return Y.one(document.body).get('winHeight') +
          Y.one(document.body).get('docScrollY') - this.EDGE_MARGIN - this.h;
        };
        this.ydir = 'top';
      }

      if (this.getX() - this.EDGE_MARGIN < 0) {// left
        this.getX = function () {return this.EDGE_MARGIN;};
        this.ydir = 'left';
      } else if (this.getX() + this.w + this.EDGE_MARGIN > Y.one(document.body).get('winWidth')) {// right
        this.getX = function () {return Y.one(document.body).get('winWidth') - this.w - this.EDGE_MARGIN;};
        this.ydir = 'right';
      }

      // double check -- the logic above is great and all.
      // maybe a little too great.
      // just check to see nothings off screenY, and make sure it appears on screen.
      if (this.getY() < Y.one(document.body).get('docScrollY')) {
        console.log('fixing y offscreen issues.');
        this.yo = Y.one(document.body).get('docScrollY') + this.EDGE_MARGIN - this.y;
      }

    },

    /**
     * corrects a position to ensure that we aren't overlapping with window edges, given our dimensions
     * on violation, nudge the element to push it within the window box
     * @method nudgeFix
     */
    nudgeFix: function () {

      if (this.isRightViolation()) {// right
        this.getX = function () {
          return Y.one(document.body).get('winWidth') +
          Y.one(document.body).get('docScrollX') - this.w - this.EDGE_MARGIN;
        };
      } else if (this.isLeftViolation()) {// left
        this.getX = function () {return this.EDGE_MARGIN;};
      }

      if (this.isBottomViolation()) {// bottom
        this.getY = function () {
          return Y.one(document.body).get('winHeight') +
          Y.one(document.body).get('docScrollY') - this.h - this.EDGE_MARGIN;
        };
      } else if (this.isTopViolation()) {// top
        this.getY = function () {return this.EDGE_MARGIN;};
      }

    },

    /**
     * @method overflowFix
     */
    overflowFix: function () {

      if (this.isRightViolation()) {// right
        this.x = Y.one(document.body).get('winWidth') +
        Y.one(document.body).get('docScrollX') - this.w - this.EDGE_MARGIN;
      } else if (this.isLeftViolation()) {// left
        this.x = this.EDGE_MARGIN;
      }

      if (this.isBottomViolation()) {// bottom
        this.y = Y.one(document.body).get('winHeight') +
        Y.one(document.body).get('docScrollY') - this.h - this.EDGE_MARGIN;
      } else if (this.y - Math.abs(this.getXO()) - this.EDGE_MARGIN < 0) {// top
        this.y = this.EDGE_MARGIN;
      }

    } });




  // -----------------------------------------------------------------------------------------------------------------
  // Tooltips
  // -----------------------------------------------------------------------------------------------------------------

  /**
   * Globally turn tooltips on or off
   * @class ToolTipManager
   * @namespace Squarespace
   * @static
   */
  Y.Squarespace.ToolTipManager = {

    canShow: true,

    /**
     * @method disableTooltips
     */
    disableTooltips: function () {

      this.canShow = false;

      if (this.currentTip) {
        this.currentTip.hide();
        this.currentTip = null;
      }

    },

    /**
     * @method enableTooltips
     */
    enableTooltips: function () {

      this.canShow = true;

    } };



  /**
   * @class Tooltip
   * @namespace Squarespace
   * @constructor
   */
  Y.Squarespace.ToolTip = Class.create({

    initialize: function (params) {

      this.params = params;

      // resolve target
      var targetEls = Y.all(this.params.target);
      if (targetEls.size() === 0) {
        if (__DEV__) {
          console.warn("Couldn't find ToolTip target: " + this.params.target);
        }
        return;
      }

      // defaults
      if (this.params.showTimeout === undefined) {this.params.showTimeout = 1200;}
      if (this.params.width === undefined) {this.params.width = 250;}
      if (this.params.style === undefined) {this.params.style = 'info';}
      this.mouseOffset = 15;
      this.events = [];

      targetEls.each(function (n) {
        this.events.push(n.on('mouseover', function (e) {

          this.lastX = e.pageX;
          this.lastY = e.pageY;

          this.move();

          if (this.showTimer) {
            return;
          }

          this.showTimer = Y.later(this.params.showTimeout, this, this.show);

        }, this));

        this.events.push(n.on('mouseout', function (e) {

          if (this.showTimer) {
            this.showTimer.cancel();
            this.showTimer = null;
          }

          this.hide();

        }, this));

        this.events.push(n.on('click', function (e) {// in case tip somehow gets orphaned

          if (!this.params.clickToShow) {
            this.hide();
          } else {
            this.show();
            e.halt(); // only halt if we're using the click
          }

        }, this));

      }, this);

      this.events.push(Y.Global.on('manager:change-mode', function (e) {

        this.hide();

      }, this));

    },

    destroy: function () {

      for (var i = 0; i < this.events.length; ++i) {
        this.events[i].detach();
      }

      if (this.el) {
        this.el.remove();
        this.el = null;
      }

      this.events = [];
      this.destroyed = true;
      this.shown = false;

    },

    move: function () {

      if (!this.el) {
        return;
      }

      this.position.x = this.lastX;
      this.position.y = this.lastY;

      if (this.a) {

        this.el.setStyles({
          left: this.position.getX() + 'px' });


      } else {

        this.el.setStyles({
          left: this.position.getX() + 'px',
          top: this.position.getY() + 'px' });

      }

    },

    show: function () {

      if (this.el || this.destroyed || this.shown) {
        return;
      }
      if (!Y.Squarespace.ToolTipManager.canShow && !this.params.dialogTooltip) {
        return;
      }

      this.el = Y.Node.create('<div class="sqsp-tooltip "' + this.params.style + '>' +
      '<div class="title">' + this.params.title + '</div>' +
      '</div>');

      if (this.params.body) {
        this.el.append('<div class="description">' + this.params.body + '</div>');
      }

      Y.one(document.body).append(this.el);

      // animate in

      this.position = new Y.Squarespace.Position({
        xdir: 'right',
        ydir: 'bottom',
        x: this.lastX,
        y: this.lastY,
        xo: this.mouseOffset,
        yo: this.mouseOffset,
        w: this.el.get('offsetWidth'),
        h: this.el.get('offsetHeight') });


      this.position.reflectFix();

      this.el.setStyles({
        left: this.position.getX() + 'px',
        top: this.position.getY() - this.position.yo + 'px',
        width: this.params.width + 'px',
        zIndex: 2000000001,
        opacity: 0 });


      if (this.params.icon) {
        this.el.setStyle('backgroundImage', 'url(' + this.params.icon + ')');
      }

      this.shown = true;

      this.a = new Y.Anim({
        node: this.el,
        to: {
          top: this.position.getY(),
          opacity: 1 },

        duration: 0.3,
        easing: Y.Easing.easeOutStrong });


      this.a.on('end', function () {
        this.a = null;
      }, this);
      this.a.run();

      Y.Squarespace.ToolTipManager.currentTip = this;

    },

    hide: function () {

      if (!this.el) {
        return;
      }

      var a = new Y.Anim({ node: this.el, to: { top:
          this.position.getY() + 15, opacity: 0 }, duration: 0.3, easing: Y.Easing.easeOutStrong });
      a.on('end', function () {
        this.get('node').remove();
      });
      a.run();

      this.el = null;
      this.a = null;
      this.shown = false;

    } });




  // -----------------------------------------------------------------------------------------------------------------
  // Lightbox
  // -----------------------------------------------------------------------------------------------------------------

  // showing -> overlay-visible -> content-ready -> content-visible -> hiding -> hidden

  Y.Squarespace.Lightbox = Class.create({

    defaultOpts: {
      overlayShowDuration: 0.35,
      contentShowDuration: 0.6,
      margin: 0,
      opacity: 1,
      transition: 'fade',
      clickAnywhereToExit: true,
      name: null,
      content: '<div>&nbsp;</div>',
      theme: 'black',
      overlayStyle: 'orb' // other possibilites include: plain, orb,
    },

    name: 'Lightbox',

    initialize: function (params) {

      this.enabled = true;

      this.params = Y.merge(this.defaultOpts, params);

      if (!this.params.containerNode) {
        this.params.containerNode = Y.one('body');
      }

      if (!this.params.transition) {
        this.params.transition = 'fade';
      }

      if (!this.params.zIndex) {
        Y.Squarespace.DIALOG_ZINDEX_BASE += 10;
        this.params.zIndex = Y.Squarespace.DIALOG_ZINDEX_BASE;
      }

      this.events = [];
    },

    enable: function () {
      this.enabled = true;
      if (this.overlayEl) {
        this.overlayEl.setStyle('display', 'block');
      }
    },

    disable: function () {
      this.enabled = false;
      if (this.overlayEl) {
        this.overlayEl.setStyle('display', 'none');
      }
    },

    getContentEl: function () {
      return this.contentEl;
    },

    show: function () {

      var lightboxClass = 'sqs-lightbox' + (this.params.name ? ' sqs-lightbox-' + this.params.name : '');

      if (Y.Lang.isArray(this.params.classNames)) {
        lightboxClass = lightboxClass + ' ' + this.params.classNames.join(' ');
      }

      this.contentEl = Y.Node.create('<div class="' + lightboxClass + '"></div>');

      if (Y.Lang.isArray(this.params.content)) {
        this.params.content.forEach(function (contentNode) {
          this.contentEl.append(contentNode);
        }, this);
      } else {
        this.contentEl.append(this.params.content);
      }

      this.contentEl.setStyles({
        'position': 'fixed',
        'opacity': '0',
        'zIndex': this.params.zIndex });


      this.events.push(Y.on('resize', this.position, Y.one(window), this));

      if (!this.params.disableNormalClose) {
        Y.Squarespace.EscManager.addTarget(this);
      }

      this.params.containerNode.append(this.contentEl);

      // show overlay
      if (!this.params.contentOnly) {

        var overlayClass = 'sqs-lightbox-overlay' + (this.params.name ?
        ' sqs-lightbox-overlay-' + this.params.name : '') + ' ' + this.params.theme;

        if (this.params.name) {
          overlayClass += ' sqs-lightbox-overlay-' + this.params.name;
        }

        if (this.params.overlayStyle) {
          overlayClass += ' sqs-lightbox-overlay-style-' + this.params.overlayStyle;
        }

        if (Y.Lang.isArray(this.params.classNames)) {
          overlayClass = overlayClass + ' ' + this.params.classNames.join(' ');
        }

        this.overlayEl = Y.Node.create('<div class="' + overlayClass + '"></div>');
        this.overlayEl.setStyles({
          'zIndex': this.params.zIndex - 1 });


        this.params.containerNode.append(this.overlayEl);

        this.fire('showing');

        this.a = Y.Squarespace.Transitions.getTransition({
          el: this.overlayEl,
          opacity: this.params.opacity,
          name: 'fade',
          direction: 'in',
          duration: this.params.overlayShowDuration });


        this.a.on('end', function () {
          this.fire('overlay-visible');
          this.a = null;
          this._showContent();
        }, this);
        this.a.run();

        // events

        if (!this.params.disableNormalClose) {
          this.events.push(Y.on('click', this.close, this.overlayEl, this));
        } else {
          this.events.push(Y.on('click', function (e) {
            e.halt();
          }, this.overlayEl, this));
        }

      } else {

        this._showContent();

      }

      // click to exit

      if (this.params.clickAnywhereToExit) {
        this.events.push(Y.on('click', this.hide, this.contentEl, this));
      }

    },

    position: function () {

      if (!this.enabled) {
        return;
      }

      var verticalWidth = Y.one(document).get('winWidth');
      var verticalHeight = Y.one(document).get('winHeight');

      var width,
      height;

      if (verticalWidth < this.naturalW + this.params.margin * 2) {
        width = verticalWidth - this.params.margin * 2;
      } else {
        width = this.naturalW;
      }

      if (verticalHeight < this.naturalH + this.params.margin * 2) {
        height = verticalHeight - this.params.margin * 2;
      } else {
        height = this.naturalH;
      }

      var offsetLeft = (verticalWidth - width) / 2;
      var offsetTop = (verticalHeight - height) / 2;

      // contentAttached
      if (this.contentAttached) {

        this.contentEl.setStyles({
          'left': offsetLeft + 'px',
          'top': offsetTop + 'px',
          'width': width + 'px' });


        if (!this.params.noHeightConstrain) {
          this.contentEl.setStyles({
            'height': height + 'px' });

        }

        // resize target
        if (this.resizeTarget) {
          this.resizeTarget.resizeToParent({ scale: 'contain' });
        }

      }

      // if (this.overlayEl) {

      //   this.overlayEl.setStyles({
      //     "height": "100%",
      //     "width": "100%"
      //   });
      //
      // }

      this.fire('position', {
        width: width,
        height: height,
        verticalWidth: verticalWidth,
        verticalHeight: verticalHeight,

        maxWidth: verticalWidth - this.params.margin * 2,
        maxHeight: verticalHeight - this.params.margin * 2 });


    },

    _showContent: function () {

      // add
      this.contentAttached = true;

      // resize to parent
      this.resizeTarget = this.contentEl.one('.resize-target');

      // measure
      this.naturalW = this.contentEl.one('*').get('offsetWidth');
      this.naturalH = this.params.height || this.contentEl.one('*').get('offsetHeight');

      this.position();

      // show
      this.fire('content-ready');

      var a = Y.Squarespace.Transitions.getTransition({
        el: this.contentEl,
        name: this.params.transition,
        direction: 'in',
        duration: this.params.contentShowDuration });


      a.on('end', function () {
        this.fire('content-visible');
      }, this);

      a.run();

    },

    close: function (e) {

      if (!this.params.clickAnywhereToExit) {
        if (!e || this.overlayEl && this.overlayEl.compareTo(e.target)) {
          return;
        }

      }

      this.hide();
    },

    hide: function () {

      var a;

      if (!this.enabled) {
        return;
      }
      if (this.a) {this.a.stop();}
      this.a = null;

      this.fire('hiding');

      // remove close target

      Y.Squarespace.EscManager.removeTarget(this);

      // hide

      if (this.overlayEl) {

        // overlay

        a = Y.Squarespace.Transitions.getTransition({
          el: this.overlayEl,
          name: 'fade',
          direction: 'out',
          duration: this.params.overlayShowDuration });


        if (this.params.overlayShowDuration > this.params.contentShowDuration) {
          this.a = a; // this is the animation that should trigger the destroy
        }

        a.run();

      }

      // content
      a = Y.Squarespace.Transitions.getTransition({
        el: this.contentEl,
        name: this.params.transition,
        direction: 'out',
        duration: this.params.contentShowDuration });


      // no overlay destroy -- trigger destroy from content
      if (!this.a) {this.a = a;}

      a.run();

      // hacky -- there's a small chance for a race condition here if durations are stupidly small
      // -- but why would they be..
      this.a.on('end', function () {

        this.destroy();
        this.fire('hidden');

      }, this);

    },

    destroy: function () {

      for (var i = 0; i < this.events.length; ++i) {
        this.events[i].detach();
      }

      if (this.contentEl) {this.contentEl.remove();}
      if (this.overlayEl) {this.overlayEl.remove();}

      this.contentEl = null;
      this.overlayEl = null;
      this.events = [];

    } });



  Y.augment(Y.Squarespace.Lightbox, Y.EventTarget);


  // -----------------------------------------------------------------------------------------------------------------
  // Standard Transitions
  // -----------------------------------------------------------------------------------------------------------------

  Y.Squarespace.CustomAnim = Class.create({

    initialize: function (params) {
      this.params = params;
    },

    cancel: function () {

    },

    stop: function () {

    } });



  Y.augment(Y.Squarespace.CustomAnim, Y.EventTarget);
  Y.augment(Y.Squarespace.CustomAnim, Y.Attribute);

  Y.Squarespace.Transitions = {

    STANDARD_DURATION: 0.3,

    getTransition: function (params) {

      if (!params.el || !params.el._node) {
        if (__DEV__) {
          console.warn("ui-base: [Transitions] You're trying to animate a non-existent element, " +
          'returning blank animation');
        }
        return new Y.Anim();
      }

      if (!params.duration) {
        params.duration = Y.Squarespace.Transitions.STANDARD_DURATION;
      }

      return Y.Squarespace.Transitions.transitionsByName[params.name](params);

    },

    transitionsByName: {

      'fade': function (params) {

        return new Y.Anim({ node: params.el, to: { opacity: !Y.Lang.isValue(params.opacity) ?
            params.direction === 'in' ? 1 : 0 : params.opacity },
          duration: Y.Squarespace.Transitions.STANDARD_DURATION, easing: Y.Easing.easeOutStrong });

      },

      'scale': function (params) {

        return new (Class.extend(Y.Squarespace.CustomAnim, {

          run: function () {

            if (!params.opacity) {params.opacity = 1;}
            if (!Y.Lang.isValue(params.duration)) {params.duration = Y.Squarespace.Transitions.STANDARD_DURATION;}

            var el = this.params.el;

            if (this.params.direction === 'in') {

              el.setStyles({
                'transform': 'scale(.95)',
                opacity: 0 });


              Y.later(10, el, el.setStyles, {
                'transitionProperty': 'transform, opacity',
                'transitionDuration': params.duration + 's',
                'transitionTimingFunction': 'ease-out',
                'backface-visibility': 'hidden' });


              Y.later(20, el, el.setStyles, {
                'transform': 'scale(1)',
                'opacity': params.opacity });


            } else {

              el.setStyles({
                'transform': 'scale(1)',
                opacity: params.opacity });


              Y.later(10, el, el.setStyles, {
                'transitionProperty': 'transform, opacity',
                'transitionDuration': params.duration + 's',
                'transitionTimingFunction': 'ease-out',
                'backfaceVisibility': 'hidden' });


              Y.later(20, el, el.setStyles, {
                'transform': 'scale(.95)',
                'opacity': 0 });


            }

            Y.later(params.duration * 1000 + 100, this, function () {this.fire('end', { _event: true });});

          } }))(

        params);

      },

      'none': function (params) {

        return new (Class.extend(Y.Squarespace.CustomAnim, {

          run: function () {

            if (this.params.direction === 'in') {

              this.el.setStyle('opacity', 1);

            } else {

              this.el.setStyle('opacity', 0);

            }

            this.fire('end');

          } }))(

        params);

      } } };







  // ----------------------------------------------------------------------
  // Basic Effects
  // ----------------------------------------------------------------------

  Y.Squarespace.Effects = {

    bounce: function (el) {

      el.setStyles({
        transform: 'scale(1)' });


      Y.later(10, el, el.setStyles, {
        transitionProperty: 'transform, opacity',
        transitionDuration: '.1s',
        transitionTimingFunction: 'ease-out',
        backfaceVisibility: 'hidden' });


      Y.later(20, el, el.setStyles, {
        transform: 'scale(1.1)' });


      Y.later(100, el, el.setStyles, {
        transform: 'scale(1)' });


    },

    focus: function (node) {

      node.setStyles({
        boxShadow: '0px 0px 0px rgb(20, 170, 255)' });


      Y.later(10, node, node.setStyles, {
        transitionProperty: 'boxShadow',
        transitionDuration: '.2s',
        transitionTimingFunction: 'ease-out' });


      Y.later(20, node, node.setStyles, {
        boxShadow: '0px 0px 10px rgb(20, 170, 255)' });


      Y.later(200, node, node.setStyles, {
        boxShadow: null });


    },

    blink: function (el) {

      var a = new Y.Anim({ node: el, to: { opacity: 0 }, duration: 0.6, easing: Y.Easing.easeOutStrong });
      a.on('end', function () {

        var anim =
        new Y.Anim({ node: this.get('node'), to: { opacity: 1 }, duration: 0.6, easing: Y.Easing.easeOutStrong });
        anim.on('end', function () {

        });
        anim.run();

      });
      a.run();

    },

    shimmy: function (node) {

      node.setStyles({
        transform: 'translateX(0px)' });


      if (Y.UA.safari) {

        Y.later(10, node, node.setStyles, {
          webkitTransitionProperty: '-webkit-transform',
          webkitTransitionDuration: '.3s',
          webkitTransitionTimingFunction: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)' // bounce
        });

      } else {

        Y.later(10, node, node.setStyles, {
          transitionProperty: 'transform',
          transitionDuration: '.3s',
          transitionTimingFunction: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)' // bounce
        });

      }

      Y.later(20, node, node.setStyles, {
        transform: 'translateX(-33px)' });


      Y.later(200, node, node.setStyles, {
        transform: null });


    } };




  // Y.NODE EXTENSIONS
  Y.augment(Y.Node, Class.create({

    intersectXY: function (left, top) {// or x, y

      var region = this.get('region');

      return (
        region.left < left && left < region.right &&
        region.top < top && top < region.bottom);


    },

    forceClass: function (name, force) {

      this.toggleClass(name, !!force);

    },

    data: function (className) {// OLD DATA METHOD

      var n = this._node;

      if (className) {
        while (n) {
          if (n.data && n.className.indexOf(className) !== -1) {
            return n.data;
          }
          n = n.parentNode;
        }
      } else {
        while (n) {
          if (n.data) {return n.data;}
          n = n.parentNode;
        }
      }

      return null;

    },

    setNodeData: function (data) {// OLD DATA METHOD

      this._node.data = data;

    } }));



  /*
   * ActionOverlay
   */
  Y.Squarespace.RelativeTimeDisplay = Y.Base.create('RelativeTimeDisplay', Y.Plugin.Base, [], {

    initializer: function () {

      this.timer = Y.later(this.get('interval'), this, this.syncUI, {}, true);
      this.syncUI();

    },

    syncUI: function () {
      // is it in the seconds range?
      var secondsDifference = Math.floor((new Date().getTime() - this.get('date').getTime()) / 1000);
      if (secondsDifference < 60) {
        if (secondsDifference === 0) {
          this.get('host').setContent(t("just now"));


        } else {
          this.get('host').setContent(t("about {secondsDifference} seconds ago", {
            secondsDifference: secondsDifference }));



        }
      } else {
        this.get('host').setContent(humanizeDate(this.get('date'), true));
      }

    },

    destructor: function () {
      this.timer.cancel();
    } },

  {
    NS: 'RelativeTimeDisplay',
    CSS_NAMESPACE: 'sqs-action-overlay',
    ATTRS: {
      date: {
        valueFn: function () {
          return new Date();
        } },


      interval: {
        value: 5000 } } });




  // hold an object positions on the screen, and then animate it
  // used to animate re-ordering images in the gallery manager
  Y.Squarespace.PositionAnimator = Y.Base.create('PositionAnimator', Y.Plugin.Base, [], {

    hold: function () {

      this.set('originalTop', this.get('host').get('offsetTop'));
      this.set('originalLeft', this.get('host').get('offsetLeft'));

      this.previousPosition = this.get('host').getStyle('position');

    },

    // release animation is randomized slightly unless uniform is true
    release: function (uniform) {

      // calculate the difference
      var offsetTop = this.get('host').get('offsetTop');
      var offsetLeft = this.get('host').get('offsetLeft');

      var duration = this.get('baseSpeed') + (uniform ? 0 : Math.random() * 0.2);
      var delay = uniform ? 0 : 200 * Math.random();

      var newTop = this.get('originalTop') - offsetTop;
      var newLeft = this.get('originalLeft') - offsetLeft;

      this.get('host').setStyles({
        position: 'relative',
        top: newTop,
        left: newLeft,
        transition: 'none' });


      var ctx = this;

      var anim = this.get('host').anim({
        top: 0,
        left: 0 },
      {
        from: {
          top: newTop,
          left: newLeft },

        duration: duration,
        end: function () {
          if (!this.get('node')._node) {
            return;
          }
          this.get('node').setStyles({
            position: this.previousPosition,
            top: null,
            left: null,
            transition: null });


          if (ctx.get('releasedFn')) {
            ctx.get('releasedFn')();
          }

          ctx.fire('released');
        } });


      Y.later(delay, anim, anim.run);

    },

    destructor: function () {

    } },
  {
    NS: 'positionAnimator',
    ATTRS: {
      originalTop: {},
      originalLeft: {},
      releasedFn: {
        value: false },

      baseSpeed: {
        value: 0.2 } } });




  Y.Anim.behaviors.translate = {
    set: function (anim, att, from, to, elapsed, duration, fn) {

      var left = fn(elapsed, parseInt(from[0], 10), parseInt(to[0], 10) - parseInt(from[0], 10), duration);
      var top = fn(elapsed, parseInt(from[1], 10), parseInt(to[1], 10) - parseInt(from[1], 10), duration);

      anim._node.setStyles({
        transform: 'translate(' + left + 'px, ' + top + 'px)' });


    },
    get: function (anim) {
      if (__DEV__) {
        console.warn('You are using the translate Y.Anim without an explicit from translate! ' +
        'This motion will be incorrect!');
      }

      return [0, 0];

    } };


}, '1.0', { requires: [
  'anim',
  'attribute',
  'base',
  'event-custom',
  'node',
  'plugin'] });