"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Selectors = require("../constants/Selectors");

/**
 * Is Cart Page?
 * Checks for the presence of `id=FULL_PAGE_CART_ROOT_ID` node on given window.
 * Accepts a window object for when this check is run from /config
 * and needs to check for the tag's presence in the frame.
 */
var _default = function _default() {
  var win = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window;
  return !!win.document.getElementById(_Selectors.FULL_PAGE_CART_ROOT_ID);
};

exports.default = _default;
module.exports = exports.default;