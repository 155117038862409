/**
 * sets the given id on relevant DOM nodes
 * @param {HTMLElement} node element containing the marquee HTML
 */
const setIdOnMarqueeReferences = (blockId, marqueeReferenceNodes) => {
  // add an ID to elements referring to the SVG path
  marqueeReferenceNodes.path.setAttribute('id', blockId);
  marqueeReferenceNodes.pathHitbox.setAttribute('id', blockId);

  // add an href to the text path so it can be referred to by the SVG elements
  marqueeReferenceNodes.textPath.setAttribute('href', '#' + blockId);
  marqueeReferenceNodes.svgGroup.setAttribute('href', '#' + blockId);

  // add an ID to the visually hidden content for a11y
  // add 'ariaLabel' to make it unique from other SVG-related IDs for this marquee instance
  marqueeReferenceNodes.visuallyHiddenTextContent.setAttribute('id', 'ariaLabel' + blockId);
  // reference ID of the visually hidden content for a11y
  marqueeReferenceNodes.hitbox.setAttribute('aria-labelledby', 'ariaLabel' + blockId);
};

export default setIdOnMarqueeReferences;
