import network from '@sqs/network';

/**
 * @param {YUI.Node|Node} el
 */
export default function initializeSocialGalleryBlock(el, blockType) {

  if (el.getDOMNode) {
    el = el.getDOMNode();
  }

  let widgetJSONValue;
  try {
    widgetJSONValue = JSON.parse(el.getAttribute('data-block-json'));
  } catch (e) {
    console.warn('Issue with decoding the JSON: ', e);
    return Promise.reject();
  }

  const payload = JSON.stringify({
    type: blockType,
    id: el.getAttribute('id').replace('block-', ''),
    value: widgetJSONValue
  });
  const postData = `widgetJSON=${payload}`;

  return network.post('/api/widget/GetWidgetRendering', postData).then(result => {

    const content = el.querySelector('.sqs-block-content');

    if (content && result.data && result.data.html) {
      content.innerHTML = result.data.html;
    }

  });
}