/**
 * This is required in the case of loading `@sqs/product-reviews`
 * before `@sqs/core-components` or `@sqs/rosetta-*`
 * Both packages are transitive/direct dependencies of @sqs/product-reviews
 */
import './core-components';

import i18nInstance from 'shared/i18n';
import { setI18nInstance } from '@sqs/product-reviews/cjs/i18n';

setI18nInstance(i18nInstance);