YUI.add('squarespace-gallery-ng', function(Y) {

  Y.namespace('Squarespace');

  Y.Squarespace.Gallery2 = Y.SQS.Gallery.Gallery2;
  Y.Squarespace.GalleryDesigns2 = Y.SQS.Gallery.Designs;

}, '1.0', {
  requires: [
    '@sqs/yui-gallery'
  ]
});