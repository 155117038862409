YUI.add('squarespace-plugin-scroll-lock', function(Y) {

  /**
    Scroll lock is a utility designed to lock the scrolling in a element.
    There's a dope demo at: /universal/test/scroll-lock/index.html

    @class ScrollLock
    @namespace Squarespace
    @extends Plugin.Base
    @constructor
   */

  Y.namespace('Squarespace.Plugin').ScrollLock =
  Y.Base.create('ScrollLock', Y.Plugin.Base, [], {
    initializer: function (config) {

      var host = this.get('host');
      var node = host.getDOMNode();

      // store this
      this.listener = function(e) {

        // stop propogation
        e.stopPropagation();

        // if the element doesn't have a scrollTop (meaning it doesn't have a scroll bar -- prevent)
        if (!Y.Lang.isNumber(node.scrollTop)) {
          e.preventDefault();
          return false;
        }

        var nodeHeight = parseInt(host.getComputedStyle('height'), 10);
        var currentScrollTop = node.scrollTop;
        var maxScrollTop = node.scrollHeight - nodeHeight;

        // prevent the default behavior (scrolling the element behind it) if:
        // #1: if scroll is trying to go up, and the element is already scrolled all the way to the top
        // #2: if the scroll is trying to go down, and the element is already scrolled all the way to the bottom

        var distanceMoved = e.wheelDelta || -e.detail; // detail is for wheel and DOMMouseScroll

        if (
          currentScrollTop <= 0 && distanceMoved > 0 ||
          currentScrollTop >= maxScrollTop && distanceMoved < 0
        ) {
          e.preventDefault();
          return false;
        }
      };

      // we're adding the listener at a low-level.
      if (node.addEventListener) {
        node.addEventListener('mousewheel', this.listener); // webkit
        node.addEventListener('DOMMouseScroll', this.listener); // FF
        node.addEventListener('MozMousePixelScroll', this.listener);
      } else if (node.attachEvent) {
        node.attachEvent('onmousewheel', this.listener);
      }
    },

    destructor: function() {

      var node = this.get('host').getDOMNode();

      if (node.removeEventListener) {
        node.removeEventListener('mousewheel', this.listener);
        node.removeEventListener('DOMMouseScroll', this.listener);
        node.removeEventListener('MozMousePixelScroll', this.listener);
      } else if (node.detachEvent) {
        node.detachEvent('onmousewheel', this.listener);
      }

    }
  },
  // Static properties
  {
    NS: 'ScrollLock',
    ATTRS: {}
  });

}, '1.0', {
  requires: [
    'base',
    'node',
    'plugin'
  ]
});