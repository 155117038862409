import substituteString from 'shared/utils/formatting/substituteString';

YUI.add('squarespace-logger', function (Y) {

  var logQueue = [],
    logTimer = null;

  var Logger = Y.Base.create('Logger', Y.Base, [], {
    initializer: function() {
      Y.one(Y.config.win).on('beforeunload', this.flush, this);

      this._syncDebugActiveElement();
      this.after('debugActiveElementChange', this._syncDebugActiveElement, this);
    },

    _syncDebugActiveElement: function() {
      if (this._debugActiveElementTimer) {
        this._debugActiveElementTimer.cancel();
      }

      if (this.get('debugActiveElement')) {
        this._debugActiveElementTimer = Y.later(500, this, function() {
          this.debug({
            focusElement: this._getDOMNodeDescriptor(Y.config.doc.activeElement)
          });
        }, null, true);
      }
    },

    _getDOMNodeDescriptor: function(node) {

      node = Y.one(node); // just in case
      var tagName = node.get('tagName').toLowerCase();
      var id = node.getAttribute('id');
      var classNames = node.get('className').split(' ');

      var classNameDescriptor = '';
      if (classNames.length > 0) {
        classNameDescriptor = '.' + classNames.join('.');

        if (classNameDescriptor.length > 20) {
          classNameDescriptor = classNameDescriptor.substr(0, 20) + '…';
        }
      }

      return tagName + (id ? '#' + id : '') + classNameDescriptor;

    },

    enqueue: function(logEntry) {
      logQueue.push(logEntry);
      this.wait();
    },

    flush: function(sync) {
      try {
        clearTimeout(logTimer);
      } catch (err) {}

      logTimer = null;

      if (logQueue.length > 0) {
        Y.Data.post({
          url: '/api/events/Trace',
          data: {
            data: JSON.stringify(logQueue)
          },
          failure: function() {} // do nothing.
        });
      }

      logQueue = [];
    },

    wait: function() {
      if (logTimer === null) {
        logTimer = setTimeout(Y.bind(this.flush, this), this.get('timerLength'));
      }
    },

    log: function(type, data) {
      if (Y.Lang.isValue(type)) {
        this.enqueue({
          pageUrl: document.location.href,
          type: type,
          data: JSON.stringify(Y.Lang.isValue(data) ? data : {})
        });
      }
    },

    trace: function (name, data) {
      try {
        var a = {};
        a.lalala();
      } catch (ex) {
        this.log(name, Y.merge(data, {
          stack: ex.stack
        }));
      }
    },

    debug: function(debugData) {
      var node = Y.one('#logger-debug') ||
      Y.one(Y.config.doc.body).appendChild(Y.Node.create('<div id="logger-debug"></div>'));
      node.setStyles({
        position: 'fixed',
        right: 50,
        top: 50,
        background: 'rgba(150,100,100,0.9)',
        border: 'solid 1px white',
        color: 'white',
        borderRadius: '3px',
        padding: 20,
        zIndex: 10000000,
        fontSize: '10px'
      });

      node.setContent(substituteString('<xmp style="margin:0">{sub1}</xmp>', {
        sub1: JSON.stringify(debugData, null, 2)
      }));
    }
  }, {
    ATTRS: {
      timerLength: {
        value: 30 * 1000
      },

      debugActiveElement: {
        value: false
      }
    }
  });


  /* ***** ***** ***** ***** *****
  PUBLIC INSTANCE OF Logger API
  ***** ***** ***** ***** ***** */
  Y.namespace('Squarespace');

  if (!Y.Squarespace.Logger) {
    Y.Squarespace.Logger = new Logger();
  }

}, '1.0', {
  requires: [
    'base',
    'event',
    'io-base',
    'node'
  ]
});
