import WaveGroup from './WaveGroup';

/**
 * The `Wave` manages the rendering of content along the wave path
 * and is managed by the primary controller `MarqueeSVG`.
 */

export default class Wave {
  /** @type {object} */
  static defaultProps = {
    animationDirection: 'left',
    animationSpeed: -1,
    container: null,
    originGroupItemNodes: [],
    path: null,
    sharedUniforms: { hover: { value: 0 } },
    svgGroupItemNodes: [],
    trackContainer: null,
  };

  /** @type {object} */
  waveGroups = [];

  /** @type {object} */
  info = {
    measuredWidth: 0,
    numGroups: 0,
  };

  /**
   * @param {object} props - the config props
   * @class
   */
  constructor (props) {
    this.props = props;

    /** @type {object} */
    this.uniforms = {
      ...this.props.sharedUniforms,
      progress: { value: 0 },
    };
  }

  /**
   * On resize, determine the minimum number of clones to create
   * a seamless loop and create them if necessary
   *
   * @param {object} props - the config props
   * @param {number} props.regionWidth - the region width
   * @param {number} props.pathLength - the total length
   * @param {number} props.measuredWidth - the measured width of the origin group
   * @param {array} props.measuredItems - the measured rect's of each child node of the origin group
   * @public
   */
  onResize ({ regionWidth, pathLength, measuredWidth, measuredItems }) {
    const {
      path,
      trackContainer,
      container,
      originGroupItemNodes,
      svgGroupItemNodes,
    } = this.props;
    const minWidth =
      Math.ceil((pathLength + measuredWidth) / measuredWidth) * measuredWidth;
    const numberOfItemsRequired = Math.ceil(minWidth / measuredWidth);

    this.info.measuredWidth = measuredWidth;

    // Set the dash array for text underline capability
    path.setAttribute(
      'stroke-dasharray',
      this.getStrokeDashArray(measuredItems)
    );

    // only add groups to the wave if we need them
    while (
      this.info.numGroups < numberOfItemsRequired &&
      (container.childElementCount / originGroupItemNodes.length) < numberOfItemsRequired
    ) {
      this.info.numGroups += 1;

      const group = new WaveGroup({
        container,
        domContainer: trackContainer,
        originGroupItemNodes,
        path,
        svgGroupItemNodes,
      });

      this.waveGroups.push(group);
    }

    this.positionGroups(measuredItems);
  }

  /**
   * Gets the stroke dash array based on the measured items.
   * This is used to generate text underline by using the path's
   * built in stroke dash capabillity.
   *
   * @param {object} measuredItems - the measured rect's of each child node of the origin group
   * @returns {string} the compiled path stroke dash array string
   */
  getStrokeDashArray (measuredItems) {
    return measuredItems.reduce(
      (res, item) => (res + `${item.width} ${item.spacing} `),
      ''
    );
  }

  /**
   * Position the groups
   *
   * @param {array} measuredItems - the measurements (rect) of each child node of the origin group
   */
  positionGroups (measuredItems) {
    let x = 0;

    this.waveGroups.forEach((group) => {
      x = group.positionItems({ x, measuredItems });
    });
  }

  /**
   * Tick on rAF
   *
   * @param {object} lerps - the preconfigured interpolations
   * @public
   */
  tick (lerps) {
    const { container, path, animationSpeed } = this.props;
    const len = this.info.measuredWidth;

    this.uniforms.progress.value -= animationSpeed * this.uniforms.hover.value;

    const x =
      (((len + this.uniforms.progress.value) % len) + len) % len;

    container.setAttribute('startOffset', -x);
    path.setAttribute('stroke-dashoffset', x);
  }

  /**
   * Destroy
   *
   * @public
   */
  destroy () {
    this.waveGroups?.forEach((group) => group.destroy());
    this.waveGroups = null;
  }
}
