module.exports = {
  "035d6db48366598d55c20c93e0c44ae4": "Thanks for your feedback",
  "10d06f3cc584f88f516b460f757876b7": "You have already reviewed this purchase",
  "14c484b8f59becfc9289037e340c3a5d": "Continue Shopping",
  "162bc6da1abeb8f06f87b62c96d22bf7": "Something went wrong",
  "2701f1d9dcb3d93702ea50717cce9354": "Try submitting your review again",
  "384b3777b20b19cd61953eaa7e051043": "Review",
  "623c88477a8b3fef5dd67843fa8dbf7e": "Submit",
  "75e6c93da70e9792d0a01dae20499ad7": "Close",
  "b063faac29a0c4f947fdf9586a0a1d2f": "Rating (required)",
  "cf99e3390d505a466a43de7d194687b4": "Please select a star rating",
  "d00161a7f82400bab5d2f6be1c43885a": {
    "one": "Rate your other purchase",
    "other": "Rate your other purchases"
  },
  "d517f2bae8e7873c9b13cd3d52839410": "Your review request has expired",
  "e0c04bc42f2ab551c09d41345fd25155": "Rate your purchase",
  "e3a6618e2010bfff8207009bc3ef07b4": "There are no products to review",
  "e4398ec9a661fe01116cd3c6bb35a7e2": "characters left",
  "efa2df24f7875c0a02986e53f90d467c": "Describe your experience"
};